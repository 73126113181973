export class Alert {
    type: AlertTypeEnum = 0;
    message= '';
    time?: number;
}

export enum AlertTypeEnum {
    Success,
    Error,
    Info,
    Warning
}
