import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ICalendarDateActive, IMY } from 'src/app/interfaces/calendar';
import { IRnumbList } from 'src/app/interfaces/rnumb.interface';
import { LoadService } from 'src/app/services/application/load.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { RnumbService } from 'src/app/services/rnumb.service';
import { strToDate } from '../application/global.function';
import { DicomViewModalComponent } from './dicom-view-modal/dicom-view-modal.component';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
    // MOBILE COPY
    statusTab = 0; // Тип закладки (0-Предстоящие, 1- Завершенные)
    sDate: string = '';
    isNotDate: boolean = false; // Нет данных
    dateSelectMyRec = ''; // выбранная дата в Предстоящие
    dateSelect = ''; // выбранная дата в Завер
    loadingRnumbList = false;

    rnumbPages: IRnumbList[] = [];

    myrec_count: number = 0;

    countRecToHeigh = 10;

    classApplied = false;

    private _setting: any;
    _selectedDate: string = ''; // выбранная дата

    rnumbList: IRnumbList[] = [];
    filteredRnumbList: IRnumbList[] = [];
    closeResult: string = '';
    modalReference!: NgbModalRef;

    // PC COPY
    blockBodystyleHeight = { height: 'auto' };
    blockCalendaryStyleHeight = { height: 'auto' };
    outletH: number = 0;
    selectedDateInCalenadr: string = '';

    set selectedDate(value: string) {
        this._selectedDate = value;
        this.setFilterDate(this._selectedDate);
        this.selectedDateInCalenadr = moment(value).format('DD MMMM');
    }
    get selectedDate() {
        return this._selectedDate;
    }

    constructor(
        private cs: CalendarService,
        private load: LoadService,
        private title: Title,
        private rs: RnumbService,
        private modalService: NgbModal
    ) {
        this._setting = this.load.configData.pages.history;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }
    }

    ngOnInit(): void {
        this.changeTab(0); // Переход к предстоящим
        this.getRnumbList();
    }

    async getRnumbList() {
        let dt: ICalendarDateActive[] = [];
        this.rnumbList = [];
        this.filteredRnumbList = [];
        this.loadingRnumbList = true;

        try {
            // todo: нужно сначала переделать процедуру, чтобы она получала входные даты для фильтрации и пагинации. Как сделано на остальных страницах
            const res = await this.rs.getRnumbList().toPromise();
            this.rnumbList = res;

            this.rnumbList.forEach((item) => {
                item.beginDate = strToDate(item.dat_bgn);
                item.endDate = strToDate(item.dat_end);

                dt.push({
                    date: Number(moment(item.beginDate).format('YYYYMMDD')),
                });
            });

            this.rnumbList.sort(
                (a, b) => a.beginDate?.getTime()! - b.beginDate?.getTime()!
            );

            // todo: избавиться от filteredRnumbList
            this.filteredRnumbList = this.rnumbList;
            this.rnumbPages = this.filteredRnumbList;
        } catch (error) {
            dt.push({ date: Number(moment().format('YYYYMMDD')) });
        } finally {
            this.loadingRnumbList = false;
        }
    }

    changeTab(n: number) {
        this.isNotDate = false;
        this.statusTab = n;
        this.sDate = ''; // Обнуляем выбранную дату
    }

    setFilterDate(dt: string) {
        if (dt.length == 0) {
            this.filteredRnumbList = this.rnumbList;
        } else {
            this.filteredRnumbList = this.rnumbList.filter(
                (item) => moment(item.beginDate).format('YYYY-MM-DD') === dt
            );
        }
    }

    calendarClick(dt: string) {
        if (this.sDate != dt) {
            this.sDate = dt;

            if (this.statusTab == 0) {
                // Предстоящие
                this.dateSelectMyRec = dt;
            } else {
                // Завершенные
                this.dateSelect = dt;
            }
            this.cs.setSelectedDate(dt);
            this.setFilterDate(dt);
        } else {
            this.cs.notSelect(true); // Передаем событие
            this.sDate = '';
        }
    }

    dateGet(event: ICalendarDateActive[]) {
        this.isNotDate = false;
        //
        let maxDT: IMY;
        const calendarDate = event?.sort((a, b) => a.date - b.date);
        let count = 3;
        if (event && event.length > 0) {
            // Данные есть
            let s: string = calendarDate[0].date + '';
            const cdYear = Number(s[0] + s[1] + s[2] + s[3]);
            const cdMounth = Number(s[4] + s[5]);
            maxDT = { m: cdMounth, y: cdYear };
            if (this.outletH < 930) {
                count = 2;
            }
            this.cs.setParams({
                year_begin: cdYear,
                mounth_begin: cdMounth,
                count: count,
                cdActive: calendarDate,
                orderByStart: false,
                MaxDate: maxDT,
                selectDate: this.dateSelect,
            });
        } else {
            // Нет данных
            this.isNotDate = true;
            let dt = new Date(); // По текущей дате
            maxDT = { m: dt.getMonth(), y: dt.getFullYear() };
            const interval = setInterval(() => {
                if (this.outletH > 0) {
                    if (this.outletH < 930) {
                        count = 2;
                    }
                    this.cs.setParams({
                        year_begin: dt.getFullYear(),
                        mounth_begin: dt.getMonth(),
                        count: count,
                        cdActive: [],
                        orderByStart: true,
                        MaxDate: maxDT,
                        selectDate: this.dateSelect,
                    });
                    clearInterval(interval);
                }
            }, 100);
        }
    }

    dateGetMyRec(event: ICalendarDateActive[]) {
        this.myrec_count = event.length; // Количество моих посещений
        let count = 2;
        let minDT: IMY;
        this.isNotDate = false;
        if (event && event.length > 0) {
            // Данные есть
            const calendarDate = event?.sort((a, b) => a.date - b.date);

            let s: string = calendarDate[0].date + '';
            const cdYear = Number(s[0] + s[1] + s[2] + s[3]);
            const cdMounth = Number(s[4] + s[5]);
            minDT = { m: cdMounth, y: cdYear };
            this.cs.setParams({
                year_begin: cdYear,
                mounth_begin: cdMounth,
                count: count,
                cdActive: calendarDate,
                orderByStart: true,
                MinDate: minDT,
                selectDate: this.dateSelectMyRec,
            });
        } else {
            // Нет данных
            this.isNotDate = true;
            let dt = new Date(); // По текущей дате
            minDT = { m: dt.getMonth(), y: dt.getFullYear() };
            this.cs.setParams({
                year_begin: dt.getFullYear(),
                mounth_begin: dt.getMonth(),
                count: count,
                cdActive: [],
                orderByStart: true,
                MinDate: minDT,
                selectDate: this.dateSelectMyRec,
            });
        }
    }

    openDicomModal(studyID: string, e: MouseEvent) {
        e.stopPropagation();
        const modal = this.modalService.open(DicomViewModalComponent, {
            backdropClass: 'dicom-modal__backdrop',
            animation: false,
        });
        modal.componentInstance.studyID = studyID;
    }
}
