import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IHistoryEvents } from 'src/app/interfaces/history.interface';
import { DicomViewModalComponent } from '../../dicom-view-modal/dicom-view-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HistoryItemComponent } from 'src/app/modals/history-item/history-item.component';
import { HistoryPdfModal } from 'src/app/modals/history-pdf-modal/history-pdf.component';
import {
    getNameDay,
    getTekDay,
    getTime,
} from 'src/app/components/application/global.function';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { ConfigService } from 'src/app/services/application/config.service.';
import { LoadService } from 'src/app/services/application/load.service';

@Component({
    selector: 'app-visit-card',
    templateUrl: './visit-card.component.html',
    styleUrls: ['./visit-card.component.scss'],
})
export class VisitCardComponent {
    @Input() visit!: IHistoryEvents;
    @Input() gridTemplateColumns!: string;
    pdfImageURL = 'assets/img/svg/pdf-view.svg';
    txtPhone = '8 800 555 666';
    showFilials = true;
    pdfLoading = false;

    constructor(
        private modalService: NgbModal,
        private dialog: MatDialog,
        private http: HttpNewService,
        private alert: AlertService,
        private configS: ConfigService,
        private load: LoadService
    ) {
        if (
            load.configData.pages.services &&
            load.configData.pages.services.WEB_LK_SERVICES_INFO
        ) {
            this.txtPhone = load.configData.pages.services.WEB_LK_SERVICES_INFO;
            this.showFilials = Boolean(
                load.configData.pages.history.showFilials
            );
        }
    }

    historyItem(id: number, tp: string) {
        const dialogRef = this.dialog.open(HistoryItemComponent, {
            width: 'auto',
            height: 'auto',
            closeOnNavigation: true,
            data: { type: tp, id: id },
        });
    }

    async gotToDownload(id: number, tp: string, $event: MouseEvent) {
        $event.stopPropagation();
        this.pdfLoading = true;
        
        const statePdf: number = this.load.configData.pages.history.PDFButtonState ?? 0;
        let statePdfFull: number = statePdf ? 1 : 0;

        switch(statePdfFull) {
            case 1:
                try {
                    const res = await this.http
                    .get(`/history/events/item/visitpdf/${tp}/${id}`, null, null, false).toPromise();
                    this.dialog.open(HistoryPdfModal, {
                        width: 'auto',
                        height: 'auto',
                        closeOnNavigation: true,
                        data: res,
                    });
                    this.pdfLoading = false;
                } catch(e) {
                    this.alert.error('Ошибка загрузки');
                    this.pdfLoading = false;
                }
                break;
            default:        
        try {
            const res = await this.http
                .get(this.getDownloadUrlForPDF(id, tp), null, null, true)
                .toPromise();
            const emptyPage = window.open('about:blank', '_blank');
            if (emptyPage) {
                const file = new Blob([res], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                emptyPage.location.href = fileURL;
            }
        } catch (error) {
            this.alert.error('Ошибка загрузки');
        } finally {
            this.pdfLoading = false;
        }
        }
    }

    getDownloadUrlForPDF(id: number, tp: string) {
        return `/history/events/item/${tp}/${id}.pdf`;
    }

    openDicomModal(
        accessionNumber: string,
        studyDate: string,
        studyInstanceUID: string,
        e: MouseEvent
    ) {
        e.stopPropagation();
        const modal = this.modalService.open(DicomViewModalComponent);
        modal.componentInstance.accessionNumber = accessionNumber;
        modal.componentInstance.studyDate = studyDate
            .match(/(\d{0,4})(\d{0,2})(\d{0,2})/)
            ?.splice(1, 4)
            .reverse()
            .join('.');
        modal.componentInstance.studyInstanceUID = studyInstanceUID;
    }

    async downloadDicomArchive(studyID: string, e: MouseEvent) {
        e.stopPropagation();
        this.http.get(this.getDownloadUrl(studyID), null);
    }

    getDownloadUrl(studyIUID: string): string {
        return (
            this.configS.getValue('hostBackend') +
            `/history/visit/dicom/download?studyIUID=${studyIUID}`
        );
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    getTekDay(dt: Date): string {
        return getTekDay(dt);
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    getTime(dt: Date): string {
        return getTime(dt);
    }
}
