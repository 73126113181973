import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import * as moment from "moment";
import { getDayWithYear, getNameDay, getTekDay, getTime } from 'src/app/components/application/global.function';
import { IPayment } from 'src/app/interfaces/payment.interface';
import { ConfigService } from 'src/app/services/application/config.service.';

@Component({
    selector: 'app-pay-details-dialog',
    templateUrl: './pay-details-dialog.component.html',
    styleUrls: ['./pay-details-dialog.component.scss'],
})
export class PayDetailsDialogComponent implements OnInit {
    
    // doctorImg: string;
    // doctorId: number | null;
    // doctorsMock =  {
    //     "service": {
    //         "img_pt": "IMG_PT_NAL",
    //         "pay_type": "Аванс - Другой тип оплаты",
    //         "paystatus": 1,
    //         "pt_dat_str": "21.07.2022 10:23",
    //         "all_amount": 2000,
    //         "pay_nal": -2000,
    //         "pay_card": 4000,
    //         "pay_from_avans": null,
    //         "lpu_name": null,
    //         "pt_keyid": 27889807,
    //         "patkeyid": 384551420,
    //         "confirmation_url": null,
    //         "srv_qty": 1,
    //         "srv_info": "Аванс@!@@!@@!@@!@21.07.2022 10:22@!@2000",
    //         "rnum": 2,
    //         "services": [
    //             {
    //                 "name": "Аванс",
    //                 "doc_id": "123",
    //                 "doc": "",
    //                 "spec_name": "",
    //                 "dtstr": "21.07.2022 10:22",
    //                 "price": "2000"
    //             },
    //             {
    //                 "name": "Pidor",
    //                 "doc_id": "123",
    //                 "doc": "",
    //                 "spec_name": "",
    //                 "dtstr": "21.07.2022 10:22",
    //                 "price": "2000"
    //             },
    //             {
    //                 "name": "Gay",
    //                 "doc_id": "333",
    //                 "doc": "",
    //                 "spec_name": "",
    //                 "dtstr": "21.07.2022 10:22",
    //                 "price": "2000"
    //             }
    //         ],
    //         "dtSort": "2022-07-21T07:23:00.000Z",
    //         "pt_dat": "2022-07-21T07:23:00.000Z"
    //     }
    // };
    slideToggle: boolean = false;
    uniqueDoctors = this.data.service.services.reduce((acc, doc, index) => {
        const existedDoc = acc.findIndex((d: any) => d.doc_id === doc.doc_id);
        if (existedDoc >= 0) {
            acc[existedDoc] = {
                ...acc[existedDoc],
                service: [...acc[existedDoc].service, doc]
            }
        } else {
            acc = [...acc, {
                ...doc,
                service: [doc]
            }]
        }
        
        return acc;
    }, [] as any);
   
    constructor(
        public dialogRef: MatDialogRef<PayDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { service: IPayment },
        
        // @Inject(MAT_DIALOG_DATA) public patient: { service: IPatient },
        // @Inject(MAT_DIALOG_DATA) public PatientService: { service: PatientService },
        // @Inject(MAT_DIALOG_DATA) public iservice: { service: IDoctor },
        
        private configS: ConfigService
    ) {
        // this.doctorId = data.service.doctor_id;
        // this.doctorImg = this.doctorId ? this.getImgSrcDoc(this.doctorId) : '';
    }

    ngOnInit(): void {
        
       
    }
    
    strTodate(elem: string) {
        // let myDate =  moment(new Date(elem)).format('D MMM YYYY');
        let myDate = moment (elem, "DD-MM-YYYY, h:mm:ss" ).format ( "DD MMMM YYYY" + ' в ' + "h:mm" );


        return myDate
    } 

    slideMe() {
        this.slideToggle=!this.slideToggle
    }
    /** Закрыть диалоговое окно */
    close() {
        this.dialogRef.close();
    }

    /** Получение даты в формате "D MM" */
    getTekDay(dt: Date): string {
        return getTekDay(dt);
    }

    /** Получение дня недели */
    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }
    getDayWithYear(dt: Date): string {
        return getDayWithYear(dt);
    }
    

    /** Получение времени формата "HH:mm" */
    getTime(dt: Date): string {
        return getTime(dt);
    }

    /** Получение url для аватарки доктора */
    getImgSrcDoc(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    /** Получение фио в формате "Фамилия И. О." из строки "Фамилия Имя Отчество" */
    getFioDoc(doc: string): string{
        let result = doc;
        let fio = doc.split(' ');
        if (fio[0] && fio[0].length > 0){
            result = fio[0];
            if (fio[1] && fio[1].length > 0){
                result += ` ${fio[1][0]}.`;
            }
            if (fio[2] && fio[2].length > 0){
                result += ` ${fio[2][0]}.`;
            }
        }
        return result;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param {any} event
     * @param {string} type
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue('hostBackend')}/img/${type}/not.png`;
    }
    //переход на оплату
    toPay(el: any) {
        window.open(el);
    }
  
    
    sklonenie(number:number, txt:any) {
        var cases = [2, 0, 1, 1, 1, 2];
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }
    
}
