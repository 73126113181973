import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'text-input',
    templateUrl: 'text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextInputComponent),
        },
    ],
})
export class TextInputComponent implements ControlValueAccessor {
    @Input() mask: string | undefined | null = null;
    @Input() clearIfNotMatch = false;
    @Input() dropSpecialCharacters = false;
    @Input() placeholder = '';
    @Input() errorMsg = '';
    @Input() prefix = '';
    @Input() type: 'text' | 'date' | 'numeric' = 'text';
    @Input() maxDate = moment().add(100, 'year').format('YYYY-MM-DD');
    @Input() minDate = moment().add(-100, 'year').format('YYYY-MM-DD');
    @Input() isDisabled = false;
    @Input() maxLength: number | string | null = null;
    @Output() onChange: EventEmitter<string> = new EventEmitter();
    @Output() blur = new EventEmitter();

    @Input('value') public _value = '';
    public set value(value: string) {
        this._value = value;
        this.propagateChange(this._value);
        this.onChange.next(value);
    }
    public get value(): string {
        return this._value;
    }

    public inputFocused = false;

    public propagateChange = (_: any) => {};

    public registerOnTouched(fn: any): void {}

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public writeValue(value: any): void {
        if (value !== undefined) {
            this.value = value;
        }
    }

    changeInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.value = target.value;
    }

    changeDateInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.value = moment(target.value, 'DD.MM.YYYY').format('YYYY-MM-DD');
    }

    blurInput() {
        this.inputFocused = false;
        this.blur.next(undefined);
    }

    focusInput() {
        this.inputFocused = true;
    }
}
