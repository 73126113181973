import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPeriod } from '../../../interfaces/period.interface';

@Component({
    selector: 'app-panel-table-pagination',
    templateUrl: './panel-table-pagination.component.html',
    styleUrls: ['./panel-table-pagination.component.scss'],
})
export class PanelTablePaginationComponent implements OnInit {
    @Input() page = 1;
    @Input() CountRec = 0; // Всего записей
    @Input() CountRecPage = 3; // Кол записей на странице
    @Input() nameBlock = ''; // Наименование
    @Input() textItogo = ''; // Текст ИТОГО
    @Input() textItogoStrEnd = []; // окончания, если пустой массив то не применяем
    @Input() HeaderHide = false; // Показывать заголовок с календарем ?

    /*
  @Input() dtBegin = new Date();    // Дата начала
*/
    _dtBegin: Date | null = null;
    _dtEnd: Date | null = null;

    @Input() // Дата начала
    set dtBegin(value: Date | null) {
        this._dtBegin = value;
    }

    get dtBegin(): Date | null {
        return this._dtBegin;
    }

    @Input()
    set dtEnd(value: Date | null) {
        this._dtEnd = value;
    }

    get dtEnd(): Date | null {
        return this._dtEnd;
    }

    @Output() onChangePeriod = new EventEmitter<IPeriod>(); // Смена даты
    @Output() onChangedPage = new EventEmitter<Number>(); // Смена страници

    constructor() {}

    ngOnInit(): void {
        //
        //    this.CountRec = 36;
        /*
    this.CountRecSet.emit(this.CountRec);
*/
    }

    // Смена периуда
    onChangedPeriod(dt: IPeriod): void {
        this.onChangePeriod.emit(dt);
    }

    /* Событие выбора страницы */
    changedPage(page: number) {
        this.onChangedPage.emit(page);
    }
}
