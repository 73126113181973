import { Injectable } from '@angular/core';

import { HttpNewService } from './http-new.service';
import { ILpu } from '../../interfaces/lpu';
import { Store } from '../../services/store.service';
import { Router } from '@angular/router';

@Injectable()
export class LoadService {
    private _configData: any;
    private _configDataDEF: any = {}; // Настройки по умолчанию
    private _LpuData: ILpu | null;
    private _LpuDataDEF: ILpu; // Настройки по умолчанию
    public configLoaded = false;

    constructor(private httpNew: HttpNewService, private router: Router) {
        this._configDataDEF = Object.assign(
            {},
            {
                menu: {
                    items: 'h-doctor,to-doctor,history,laboratory,abonements,services',
                },
            },
            { currency: [{ code: '1', symbol: ' ₽' }] },
            {
                pages: {
                    login: {
                        title: null,
                        CreatePatient: false,
                        CreatePatientListParams: '',
                    },
                    home: { title: 'Главная' },
                    abonements: {
                        title: null,
                        Pay: {
                            showbalans: true,
                            showFieldrealAmount: true,
                        },
                        showSostav: true,
                    },
                    laboratory: {
                        title: 'Лаборатория',
                        showPayerInfo: true,
                    },
                    history: { title: 'Мои посещения', showFilials: true },
                    controls: { title: null },
                    personal: {
                        title: 'Личные данные',
                        WEB_LK_ACCESS_STATEMENT: false,
                    },
                    todoctor: {
                        title: 'Запись к врачу',
                        WEB_LK_TALON_PRICE_INFO:
                            '* - Стоимость может быть другой при обращении в клинику',
                    },
                    services: {
                        title: 'Услуги',
                        WEB_LK_SERVICES_INFO:
                            '<span style="font-weight: 700;">8 800 000</span> <span> или лично.</span>',
                        TABLE_SERVICES_LIST:
                            '(N=Дата услуги;F=psDate;T=s;A=tc)' +
                            '(N=Код услуги;F=sdCode;T=s;A=tc)' +
                            '(N=Название услуги;F=sdName;T=s;A=tl)' +
                            '(N=Сумма;F=psAmount;T=f;A=tr)' +
                            '(N=Наименование врача;F=psDocExec;T=s;A=tl)',
                    },
                    maps: { title: null, allGPS_COORDINATES: '' },
                    payments: {
                        title: 'Финансы',
                        WEB_LK_FIN: false,
                        WEB_LK_FIN_HISTORY: false,
                        WEB_LK_FIN_PAY: false,
                        WEB_LK_PAY_GO_TXT:
                            'Сейчас вы будете перенаправлены на страницу оплаты',
                        WEB_LK_PAY_LIFETIME: 60,
                    },
                    recovery: {
                        title: 'Восстановление пароля',
                        WEB_LK_RECOVERY_TO_EMAIL: false,
                    },
                },
            }
        );

        this._LpuDataDEF = {
            currencyCode: '1',
            currencyName: 'рубль',
            currencyShortName: 'руб.',
            address: 'Неизвестен',
            name: 'Неизвестен',
        };
        this._LpuData = null;
    }

    private MergeRecursive(obj1: any, obj2: any) {
        const objRes = Object.assign({}, obj1);
        for (const p of Object.keys(obj2)) {
            try {
                if (obj2[p] != null) {
                    if (typeof obj2[p] === 'object') {
                        objRes[p] = this.MergeRecursive(objRes[p], obj2[p]);
                    } else {
                        objRes[p] = obj2[p];
                    }
                }
            } catch (e) {
                objRes[p] = obj2[p];
            }
        }
        return objRes;
    }

    get LpuData(): ILpu | null {
        return this._LpuData;
    }

    /* установка параметра */
    public setParam_WEB_LK_MODULES(value: string) {
        if (
            this._configData &&
            this._configData.menu &&
            this._configData.menu.items
        ) {
            this._configData.menu.items = value;
            this.configLoaded = true;
            //      Store.setData('WEB_LK_MODULES', {val: value});
        }
    }

    get configData(): any {
        const WEB_LK_MODULES = <any>Store.getData('WEB_LK_MODULES');
        if (WEB_LK_MODULES && WEB_LK_MODULES.val) {
            this.setParam_WEB_LK_MODULES(WEB_LK_MODULES.val);
        }
        return this._configData;
    }

    // Загрузка конфиг файла на сервере
    load(): Promise<any> {
        this._configData = null;
        //
        return new Promise((res, rej) => {
            this.httpNew.getALL('/api/app.config', null).subscribe(
                (result) => {
                    //
                    // Проверка на параметры (если параметры не указанны в АДМИНКЕ)
                    //
                    this._configData = this.MergeRecursive(
                        Object.assign({}, this._configDataDEF),
                        result.data
                    );
                    this.configLoaded = true;
                    //                    this._configData = result.data;
                    //
                    // if (this._)

                    return res(this._configData);
                },
                (err) => {
                    console.error(
                        'Ошибка загрузки app.config, значения по умолчанию.',
                        err
                    );
                    this.router.navigate(['/503']);
                    //   При ошибке. значения по умолчанию
                    this._configData = this._configDataDEF;
                    return res(this._configData);
                }
            );
        });
    }

    // Загрузка данных о мед учреждении
    loadLpu(): Promise<any> {
        this._LpuData = null;
        //
        return new Promise((res, rej) => {
            this.httpNew.getALL('/api/lpuinfo', null).subscribe(
                (result) => {
                    this._LpuData = result.data;
                    return res(result);
                },
                (err) => {
                    //   При ошибке. значения по умолчанию
                    this._LpuData = this._LpuDataDEF;
                    return res(this._configData);
                }
            );
        });
    }
}
