/*
Сервис по работе Высотой основного блока
17-05-2022 Событийную часть отключил, работает из LS
 */

import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ISize} from "../interfaces/size.interface";
import {Store} from "./store.service";

const storeKey = 'WEB_LK_DEVICE_TYPE';

enum DEVICE_TYPES {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  PC = 'pc'
}

const MOBILE_MAX_WIDTH_PX = 768;

@Injectable()
export class Size {
  private h :ISize = {outletH: 0, blockHeaderH:91};
  private width: number;
  pc: string = 'pc' as const;
  mobile: string = 'mobile' as const;
  tablet: string = 'tablet' as const;

  constructor(){
    this.width = window.innerWidth;
    window.addEventListener('resize', (ev: UIEvent) => {
      const currentWindow: Window = ev.target as Window;
      this.width = currentWindow.innerWidth;
    })
    // this.deviceType = 
  }
//  deviceTypeSubject = new Subject<string>();

  resizeSubject = new Subject<ISize>();

  setH(hh: ISize) {
// 
    this.h = hh;
    if (this.resizeSubject) {
      this.resizeSubject.next(this.h);
    }
  }

  // Событие изменение размера
  onResize$(): Observable<ISize> {
    return this.resizeSubject.asObservable();
  }

  // Получить высоту
  getH(): ISize {
// 
      return this.h;
  }

// Тип девайса
  setW(width: number){
    this.width = width;
    this.setDeviceType(width);
  }

  setDeviceType(width: number) {
    this.width = width;
    // Сохраняем
      Store.setData(storeKey, {val: this.deviceType});
  }

  private get deviceType(): DEVICE_TYPES {
    return this.width > MOBILE_MAX_WIDTH_PX ? DEVICE_TYPES.PC : DEVICE_TYPES.MOBILE
  }

  // Получить тип девайса
  getDeviceType(): string {
      // Получаем в текущих настройках
      return this.deviceType;
  }

}
