import {Component, Input, OnInit} from '@angular/core';
import {IResearchForm} from "../../../../interfaces/lab.interface";

@Component({
  selector: 'app-forma-material-mobile',
  templateUrl: './forma-material.component.html',
  styleUrls: ['./forma-material.component.scss']
})
export class FormaMaterialMobileComponent implements OnInit {
  @Input() data: IResearchForm[] = [];
//  patLevel: "<"
  constructor() { }

  ngOnInit(): void {
// 
  }

  isShowLine(val: number | null | undefined, max: number | null | undefined, min: number | null | undefined): boolean {
    if (val && val>0 && max && max>0 && min && min>0) {
      return true;
    } else {
      return  false;
    }
  }


}
