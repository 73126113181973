import {AbstractControl} from "@angular/forms";
/**********************
*  Проверка на длинну + контрольная сумма
*  TEST 160-767-845 93 Артур 122
 *********************/
export function snilsValidator(control: AbstractControl) {
  const val: string = control.value;
  if (val.length && val.length == 14 && validSnilstest(val)) {
    return null;
  };
  return { invaliSnilsNumber: true };
}

/* Проверка контрольной суммы */
  function validSnilstest(val: any) {
    let value: any = formatSnils(val);
    if (!/^\d{3}-\d{3}-\d{3}\s\d{2}$/.test(val)) {
      return false;
    }
    value = value.replace(/\D/g, '');
    var checkSum = parseInt(value.slice(9), 10);
    value = value.substring(0, 9).split('');
    var sum = value.reduce(function (acc: any, next: any, index: any) {
      return acc + next * (9 - index);
    }, 0);
    return (sum < 100 && sum === checkSum)
      || ((sum === 100 || sum === 101) && checkSum === 0)
      || (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0)));
}

/* Форматирование строки снилс 000-000-000 00*/
function formatSnils(value: any): string {
  var res = '';
  if (typeof (value) === 'number') {
    res = '' + value;
  } else if (typeof (value) === 'string') {
    res = value.replace(/\D/g, '');
  } else {
    return '';
  }
  if (res.length !== 11) {
    return '';
  }
  res = res.slice(0, 3) + '-' + res.slice(3, 6) + '-' + res.slice(6, 9) + ' ' + res.slice(-2);
  return res;
}


