import {animate, state, style, transition, trigger} from "@angular/animations";

export const heightAnimation = trigger(
  'heightAnimation', [
    state('on', style({
      height: 'auto',
      opacity: 1,
    })),
    state('off', style({
      opacity: 0,
      height: '0px',
      visibility: 'collapse'
    })),
    transition('on <=> off', animate('0.5s')),
  ]);

export const expandedAnimation = trigger(
  'expandedAnimation', [
    state('on', style({
      height: '*',
      opacity: 1
    })),
    state('off', style({
      height: 0,
      opacity: 0,
      visibility: 'collapse'
    })),
    transition('on <=> off', animate('0.5s ease')),
  ]);

export const rightPanelAnimation = trigger(
  'rightPanelAnimation', [
    state('on', style({
      width: '430px',
      opacity: 1
    })),
    state('off', style({
      width: 0,
      opacity: 0,
      visibility: 'collapse'
    })),
    transition('on <=> off', animate('0.5s ease')),
  ]);
