<div class="app-grid">
    <app-menu-left class="sidebar"></app-menu-left>
    <app-header class="header"></app-header>

    <div class="cls-outlet" #ContentBloc>
        <router-outlet ></router-outlet>
    </div>

    <footer class="footer"></footer>
</div>
