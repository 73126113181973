import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Size} from "../../../services/size";

@Component({
  selector: 'app-line-lab-res-mobile',
  templateUrl: './line-lab-res.component.html',
  styleUrls: ['./line-lab-res.component.scss']
})
export class LineLabResMobileComponent implements OnInit {
  min: number = 0;   //
  max: number = 0;   //
  curent: number = 0;   // текущее значение

  @Input()
  set setMin(value: number | null | undefined) {
    if (value) {
      this.min = Number(value);
    } else {
      this.min = 0;
    }
  }

  @Input()
  set setMax(value: number | null | undefined) {
    if (value) {
      this.max = Number(value);
    } else {
      this.max = 0;
    }
  }

  @Input()
  set setCurent(value: number | null | undefined) {
    if (value) {
      this.curent = Number(value);
    } else {
      this.curent = 0;
    }
  }


  blockLinestyleLeft = {left: '0px'};

  @ViewChild('blockLine') blockLine: ElementRef|undefined;
  @ViewChild('line') line: ElementRef|undefined;
  @ViewChild('lineNorm') lineNorm: ElementRef|undefined;

  curentStyleLeft = 1;  //

  constructor(private size:Size) {
    this.size.onResize$().subscribe(val => {
      this.calcCurenLeft();
    });

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.calcCurenLeft();
    },0)
    
  }

  ngAfterViewInit(): void {
    this.calcCurenLeft();
  }

// Расчет отступа с лева
  calcCurenLeft() {
//    
    if (this.blockLine && this.line && this.lineNorm) {

// 
// 
// 

      let w1 = (this.line.nativeElement.offsetWidth - this.lineNorm.nativeElement.offsetWidth) / 2;  // Ширина до MIN

      if (this.curent < this.min) { // Значение меньше
        // this.curentStyleLeft = Math.trunc(w1 / this.min * this.curent);
        this.curentStyleLeft = Math.round(w1/2 - 11);
      };

      if (this.curent >= this.min && this.curent <= this.max) { // Значение НОРМ
// 
        let lineNormW = this.lineNorm.nativeElement.offsetWidth;
        let lineNormMarginLeft = Math.trunc((this.lineNorm.nativeElement.offsetWidth/100) * 10);
// 
        // let t = Math.trunc((lineNormW / (this.max - this.min)) * (this.curent-this.min));
        if(this.curent === 5)
        {
          
          
          
          
          
          
          
          
        }
        let t = Math.trunc(w1 + lineNormW*((this.curent - this.min)/(this.max - this.min))) - 11;


//        this.curentStyleLeft = Math.trunc((this.lineNorm.nativeElement.offsetWidth / (this.max - this.min)) * this.curent);
        this.curentStyleLeft = Math.trunc(t);
      };

// 

      if (this.curent > this.max) { // Значение больше МАКСИМУМ
// 

        // if (this.curent >= (this.max + this.min)) {  // Предел
        //   this.curentStyleLeft = this.line.nativeElement.offsetWidth - 30;
        // } else {
/*
          if (this.curent === 160){
            
            
            
          }
*/
        //   this.curentStyleLeft = this.lineNorm.nativeElement.offsetWidth + w1 + Math.trunc((w1 / this.min) * (this.curent - this.max)) - 10;
        // }

        this.curentStyleLeft = Math.round(this.line.nativeElement.offsetWidth - w1/2 - 11);
      };

      this.blockLinestyleLeft.left= this.curentStyleLeft +'px';
    }
  }

/*
  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
*/

  resize(ev: any) {
//    
    this.calcCurenLeft();
/*
    if (this.blockLine) {
      
      
    }
    if (this.line) {
      
      
      this.curentStyleLeft = Math.trunc(this.line.nativeElement.offsetWidth / this.max * this.curent);
    }
*/
  }

/*
  resNorm(): boolean {
    let f = false;
    f = this.curent >= this.min;
    if (this.curent === 2) {
      
      
      
    }
    return f;
  }
*/



}
