import { Pipe, PipeTransform } from '@angular/core';
import { ChipOption } from 'src/app/interfaces/chip';

@Pipe({
    name: 'isChipSelected'
})

export class IsChipSelectedPipe implements PipeTransform {
    transform(chip: ChipOption, values: ChipOption[]): any {
        return !!values.find(c => c.value === chip.value && c.selected);
    }
}