<!--
<div class="cls-pg-itogo">{{_countRec}} {{textItogo}}</div>
-->
<div class="cls-pg-itogo">Записей: {{ _countRec }}</div>

<ul class="cls-pagination" *ngIf="countPage > 0">
    <li class="cls-pag-first" id="page-prev" (click)="prevPage()"><</li>

    <ng-container *ngFor="let page of arrayPage; let i = index">
        <li
            [ngClass]="{ active: page.num == currentPage }"
            (click)="goPage(page.num)"
        >
            {{ page.name }}
        </li>
    </ng-container>

    <li class="cls-pag-next" id="page-next" (click)="nextPage()">></li>
</ul>
