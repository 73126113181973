export interface IdocType {
    keyid: number;
    text: string;
}

export interface IDocuments {
    id: number;
    dat_str: string;
    dat?: Date;
    text: string;
    file_name: string;
}

export interface IFileList {
    control_name: string;
    file?: FileList;
    filename?: string;
    fileBK?: string; // файл на бэке
}

export enum EUploadStatus {
    LOADING,
    SUCCESS,
    ERROR,
}

export interface IAttachedFile {
    file?: File;
    name: string;
    ext: string;
    status: EUploadStatus;
    id: number | null; // Id from database
    link_id: number | null; // Visit id file attached to
}

export interface IFilelistFromDB {
    id: number;
    name: string;
    file_type: string;
}

// Для отправки почты
/*
export interface IMailSend {
  birthDate?: string;
  email?: string;
  file?: IFileList[];
  firstName?: string;
  lastName?: string;
  note?: string;
  phone?: string
  secondName?: string
}
*/
