<div class='loader-wrapper' [ngClass]="{
  'fullscreen': isFullscreen,
  'bg-fill': bgFill
  }" [ngSwitch]="type" [ngStyle]="{'color': color}">

  <div *ngSwitchCase="'component'">
    <svg
      class="component-loader"
      width="24"
      height="24">
      <use
        xlink:href="assets/img/svg/loader.svg#img"
        href="assets/img/svg/loader.svg#img">
      </use>
    </svg>
  </div>
  <div class="page-loader" *ngSwitchCase="'page'">
    <img src="./assets/loading-rec.gif">
    <p class="message" *ngIf="message">{{message}}</p>
  </div>
</div>
