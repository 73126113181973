import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../../../services/application/config.service.';
import * as moment from 'moment';
import { showOpacityAnimation } from '../../../animations/show-left-right.animation';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { LoadService } from '../../../services/application/load.service';
import { Title } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';

@Component({
    selector: 'app-recovery',
    templateUrl: './recovery.component.html',
    styleUrls: ['./recovery.component.scss'],
    animations: [showOpacityAnimation],
})
export class RecoveryComponent implements OnInit {
    @Input() AccessReg: boolean = false;
    animState = 'on';
    loading = false;
    recoveryForm!: FormGroup;
    newPasswordForm!: FormGroup;
    captchaImage = '';
    maxDate!: string;
    private recoveryToken: string | null = null;
    isTokenRecovery: boolean = false;
    private pageSetting: any;

    @Output() onChanged = new EventEmitter<number>();
    @Output() onError = new EventEmitter<string>();

    constructor(
        private configService: ConfigService,
        private authService: AuthService,
        private alertService: AlertService,
        private loadService: LoadService,
        @Inject(APP_BASE_HREF) private baseHref: string,
        private titleService: Title
    ) {
        this.pageSetting = loadService.configData.pages.recovery;
        if (this.pageSetting && this.pageSetting.title) {
            this.titleService.setTitle(this.pageSetting.title);
        }

        const recoveryPath = `${baseHref}login/recovery`;
        this.recoveryToken = location.pathname.substring(recoveryPath.length);
        this.recoveryToken = this.recoveryToken.substring(1);
        if (!this.recoveryToken) {
            this.isTokenRecovery = true;
        }
    }

    ngOnInit(): void {
        this.maxDate = moment(new Date()).format('YYYY-MM-DD');
        this.initializeForms();
        this.refreshCaptcha();
    }

    private initializeForms(): void {
        this.recoveryForm = new FormGroup({
            captcha: new FormControl('', [Validators.minLength(4)]),
            captchaSolid: new FormControl('', [Validators.minLength(4)]),
            lastName: new FormControl('', [Validators.minLength(3)]),
            firstName: new FormControl('', [Validators.minLength(3)]),
            secondName: new FormControl('', [Validators.minLength(1)]),
            birthDate: new FormControl('', [Validators.minLength(1)]),
            email: new FormControl('', [Validators.email]),
        });

        this.newPasswordForm = new FormGroup({
            newPW: new FormControl('', [Validators.minLength(2)]),
            newPW2: new FormControl('', [Validators.minLength(2)]),
        });

        if (this.isTokenRecovery && this.recoveryToken) {
            this.authService.checktr(this.recoveryToken).subscribe(
                (resultToken) => {
                    if (
                        resultToken &&
                        resultToken[0] &&
                        resultToken[0].err_code == 0
                    ) {
                        this.alertService.info('Token is valid.');
                    } else {
                        this.recoveryToken = null;
                        this.isTokenRecovery = false;
                        const errorMessage =
                            resultToken &&
                            resultToken[0] &&
                            resultToken[0].err_text &&
                            resultToken[0].err_text.length > 0
                                ? resultToken[0].err_text
                                : 'Error validating token.';
                        this.alertService.error(errorMessage);
                        window.location.href = `${this.baseHref}login`;
                    }
                },
                (err) => {
                    console.error('resultToken=', err);
                }
            );
        }
    }

    public formatDate(event: any): boolean {
        let isValid = true;
        if (event.srcElement.value.length >= 10) {
            let year = moment(
                this.recoveryForm.controls['birthDate'].value
            ).format('YYYY');
            if (year && year[0] && Number(year[0]) > 0) {
                isValid = false;
            }
        }
        return isValid;
    }

    whenAnimate(event: any) {
        if (event.toState === 'off') {
            this.onChanged.emit(0);
        }
    }

    public refreshCaptcha() {
        let timestamp = new Date().getTime();
        this.recoveryForm.patchValue({ captchaSolid: timestamp });

        this.captchaImage =
            this.configService.getValue('hostBackend') +
            '/api/captcha.png?solid=' +
            new Date().getTime();
    }

    goAuth(): void {
        this.animState = 'off';
    }

    goRecovery() {
        this.loading = true;
        const formControls = this.recoveryForm.controls;

        this.authService
            .findpatientchangepw(
                formControls['lastName'].value,
                formControls['firstName'].value,
                formControls['secondName'].value,
                formControls['birthDate'].value,
                formControls['email'].value,
                formControls['captcha'].value,
                formControls['captchaSolid'].value
            )
            .subscribe(
                (res) => {
                    this.loading = false;

                    if (!res) {
                        this.handleError(
                            'Что-то пошло не так, попробуйте через 10 мин.'
                        );
                        return;
                    }

                    if (res.success === true && res.data?.identity > 0) {
                        this.handleSuccess();
                    } else {
                        this.handleError(
                            res.data?.[0]?.err_text ||
                                'Что-то пошло не так, попробуйте через 10 мин.'
                        );
                    }
                },
                (error) => {
                    this.loading = false;
                    this.handleError(
                        error.error?.msg ||
                            'Что-то пошло не так, попробуйте через 10 мин.'
                    );
                }
            );
    }

    private handleSuccess() {
        this.alertService.info(
            'Вам на почту отправлена ссылка для восстанавления пароля.',
            9000,
            true
        );
        setTimeout(() => {
            window.location.href = `${this.baseHref}login`;
        }, 9000);
        this.refreshCaptcha();
    }

    private handleError(errorMessage: string) {
        this.refreshCaptcha();
        this.alertService.error('Ошибка: ' + errorMessage);
    }

    goRecoveryNewPW() {
        this.loading = true;

        const { newPW, newPW2 } = this.newPasswordForm?.controls;
        const isNewPwReentered =
            newPW?.value && newPW2?.value && newPW2?.value === newPW?.value;
        const isChangeValid = this.recoveryToken && this.newPasswordForm;

        if (isChangeValid && isNewPwReentered) {
            this.authService
                .changepwtoken(
                    this.recoveryToken!,
                    this.newPasswordForm.controls['newPW'].value
                )
                .subscribe(
                    (res) => {
                        if (res[0] && res[0].err_code > 0) {
                            this.alertService.error(
                                'Error: ' + res[0].err_text
                            );
                            this.loading = false;
                        } else {
                            this.alertService.info(
                                'Пароль успешно изменен.',
                                9000
                            );
                            setTimeout(() => {
                                window.location.href = `${this.baseHref}login`;
                            }, 5000);
                        }
                    },
                    (err) => {
                        this.loading = false;
                        this.alertService.error(
                            'Ошибка изменения пароля, попробуйте позже.'
                        );
                    }
                );
        } else {
            this.alertService.error('Пароли не совпадают.');
            this.loading = false;
        }
    }

    isCopyPassword(): boolean {
        return (
            this.newPasswordForm &&
            this.newPasswordForm.controls['newPW'] &&
            this.newPasswordForm.controls['newPW'].value &&
            this.newPasswordForm.controls['newPW2'] &&
            this.newPasswordForm.controls['newPW2'].value &&
            this.newPasswordForm.controls['newPW'].value ===
                this.newPasswordForm.controls['newPW2'].value
        );
    }
}
