export enum ImgTypes {
    png = 'image/png',
    jpg = 'image/jpeg',
    jpeg = 'image/jpeg',
    avif = 'image/avif',
    webp = 'image/webp',
    gif = 'image/gif',
    ico = 'image/vnd.microsoft.icon',
    tiff = 'image/tiff',
    bmp = 'image/bmp',
    svg = 'image/svg+xml',
}

export enum DownloadTypes {
    html = 'text/html',
    jar = 'application/java-archive',
    xml = 'application/xml',
    pdf = 'application/pdf',
    arc = 'application/x-freearc',
    avi = 'video/x-msvideo',
    bz2 = 'application/x-bzip2',
    bz = 'application/x-bzip',
    bin = 'application/octet-stream',
    odt = 'application/vnd.oasis.opendocument.text',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt = 'application/vnd.ms-powerpoint',
    pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls = 'application/vnd.ms-excel',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    mp3 = 'audio/mpeg',
    wav = 'audio/wav',
    mp4 = 'video/mp4',
    webm = 'video/webm',
    zip = 'application/zip',
    rar = 'application/vnd.rar',
    '7z' = 'application/x-7z-compressed',
    tar = 'application/x-tar',
    gz = 'application/gzip',
    tar_gz = 'application/x-tar',
    tar_zst = 'application/x-tar',
    tar_lz4 = 'application/x-tar',
    tar_lzma = 'application/x-tar',
    tar_xz = 'application/x-tar',
    tar_bz2 = 'application/x-tar',
}
