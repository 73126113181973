import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {ICalendar, ICalendarInit, IMY} from "../../../interfaces/calendar";
import {CalendarService} from "../../../services/calendar.service";

@Component({
  selector: 'app-rec-calendar-mobile',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class RecCalendarComponentMobile implements OnInit {
 /* Параметры принимаются из сервиса */
 calendarArray: ICalendar[] = [];
 _params: ICalendarInit | null = null;
 disableTop: boolean = false;  // Отключить кнопку в верх
 disableBottom: boolean = false; // Отключить кнопку в низ
 @Output() onClickDate = new EventEmitter<string>(); // Событие -> выбрана Дата формат YYYY-MM-DD
 @Output() onCalendarClose = new EventEmitter<string>(); // Событие -> выбрана Дата формат YYYY-MM-DD
 selectedDate: string = '';   // выбранная дата
 
 selectrdDateInCalenadr: string = ''//дата в календаре
 
 constructor(private cs: CalendarService) {
     
   cs.onInit$().subscribe(n =>{
     this.initCalendary(n);
   });

   cs.onNotSelect$().subscribe(n =>{
     this.notSelectCalendary();
   });

   cs.onSelectedDate$().subscribe(n =>{
     this.setSelectedDate(n);
   });
   
 }

 ngOnInit(): void {
 };

 editM(my: IMY, countM: number, plus: boolean): IMY {
   let r: IMY = my;
   if (plus) { // +++
     r.m = r.m + countM;
     if (r.m > 12) {
       r.m = r.m - 12;
       r.y = r.y + 1;
     }
   } else {  // ---
     r.m = r.m - countM;
     if (r.m < 1) {
       r.m =  r.m + 12;
       r.y = r.y - 1;
     }
   }
   return  r;
 }
 
 

 notSelectCalendary(){ // Отмена выбора даты
   this.selectedDate = '';
   let disableClass = document.getElementById('disableBtn');
   disableClass?.classList.remove('disableBtn');
   if (this._params && this._params.selectDate){
     this._params.selectDate = '';
   }
   this.onClickDate.emit('');
 }

 // notCalendarClick - Не выполнять процедуру (выбор даты)
 initCalendary(params: ICalendarInit, notCalendarClick: boolean = false){

   this._params = params;
   this.calendarArray = [];
//    this.selectedDate = '';
// 
// 

   if (this._params && this._params.selectDate && this._params.selectDate.length>8 ){
     this.selectedDate = this._params.selectDate;
     
     if (notCalendarClick == false){
       this.calendarClick(this.selectedDate);
     }
   } else {
     this.selectedDate = '';
   }
   
   if (this._params.mounth_begin && this._params.year_begin) {
     let my: IMY = {m: this._params.mounth_begin, y: this._params.year_begin};
     for (var i = 0 ; i < this._params.count; i++) {
       if (this._params.orderByStart) {
         my = this.editM({m: this._params.mounth_begin, y: this._params.year_begin}, i, true);
       } else {
         my = this.editM({m: this._params.mounth_begin, y: this._params.year_begin}, i, false);
       }
       if (this._params.orderByStart) {
         this.calendarArray.push({month_begin: my.m, year_begin: my.y, date: this._params.cdActive});
       } else {
         this.calendarArray.unshift({month_begin: my.m, year_begin: my.y, date: this._params.cdActive});
       }

     };
     // Проверка блокировки
// 
     this.disableBottom = false;
     this.disableTop = false;
     if (this._params.MaxDate) {
       this.disableBottom = (this.calendarArray[this._params.count -1].month_begin == this._params.MaxDate.m && this.calendarArray[this._params.count -1].year_begin == this._params.MaxDate.y);
     }
     if (this._params.MinDate) {
       this.disableTop = (this.calendarArray[0].month_begin == this._params.MinDate.m && this.calendarArray[0].year_begin == this._params.MinDate.y);
     }
// 


   } else {
     
   }
 }

 // Установка даты
 
 setSelectedDate(dt: string){
   this.selectedDate = dt;

   if (this._params){
     this._params.selectDate = this.selectedDate;
     this.selectrdDateInCalenadr = moment(dt).format('DD MMMM');

   }


 }


 calendarClick(dt: string){
// 
   this.selectedDate = dt;
   
   let disableClass = document.getElementById('disableBtn');
   disableClass?.classList.add('disableBtn');
   if (this._params && this._params.selectDate){
     this._params.selectDate = this.selectedDate;
     disableClass?.classList.add('disableBtn');
   }
   this.onClickDate.emit(dt);
 }

 calendarClickGo(s: string) {  // Кнопка перемещения

   let m, y : number = 0;

   if (this._params) {
     let my: IMY = {m: this._params.mounth_begin, y: this._params.year_begin};
     if (s == 'top') { // В верх -1 месяц
       if (this.disableTop){ return}
       my = this.editM({m: my.m, y: my.y}, 1, false);
     } else {   // в низ +1
       if (this.disableBottom){ return}
       my = this.editM({m: my.m, y: my.y}, 1, true);
     }
     this._params.mounth_begin = my.m;
     this._params.year_begin = my.y;
     this.initCalendary(this._params, true);
   }
 }
    closeCalendar() {
        this.onCalendarClose.next();
    }
 

 }