<div class="mobile-period__wrapper">
    
    <div class="mobile-period__header">
        
        <div class="mobile-period__close">
            <button class="mobile-period__close--btn" (click)="close()">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0492 9.44691L18.1713 16.569C18.5956 16.9933 18.5956 17.6631 18.1713 18.0649C17.7471 18.4891 17.0773 18.4891 16.6755 18.0649L9.55336 10.9428L2.43124 18.0649C2.007 18.4891 1.3372 18.4891 0.935369 18.0649C0.511127 17.6407 0.511127 16.9709 0.935369 16.569L8.05749 9.44691L0.935369 2.32479C0.511127 1.90055 0.511127 1.23076 0.935369 0.828923C1.35961 0.404682 2.0294 0.404682 2.43124 0.828923L9.55336 7.95104L16.6755 0.828923C17.0997 0.404682 17.7695 0.404682 18.1713 0.828923C18.5956 1.25316 18.5956 1.92296 18.1713 2.32479L11.0492 9.44691Z" fill="#B7C9DF"/>
                </svg>    
            </button>
        </div>
        
        

       

        <div *ngIf="periodText && periodText != 'Платежи за всё время'" class="period mobile-period__period" [class.period--grey]="periodText == 'Выберите день или период'">
            <p class="period__text">{{periodText}}</p>
            <span *ngIf="periodText != 'Выберите день или период'" class="period__button" (click)="btnFilterByDay(null, null)">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5325 0L7 4.5325L2.4675 0L0 2.4675L4.5325 7L0 11.5325L2.4675 14L7 9.4675L11.5325 14L14 11.5325L9.4675 7L14 2.4675L11.5325 0Z" fill="#C5CDE9"/>
                </svg>    
            </span>
        </div>

        <div class="mobile-period__buttons">
            <!-- <button class="mobile-period__button btn cls-btn-second cls-btn-filter" (click)="btnFilterByDay(null, null)">
                За всё время
            </button> -->
            <button class="mobile-period__button btn cls-btn-second cls-btn-filter" (click)="btnFilterByDay(0)">
                Сегодня
            </button>
            <button class="mobile-period__button btn cls-btn-second cls-btn-filter" (click)="btnFilterByDay(3)">
                За 3 дня
            </button>
        </div>
    </div>

    <div class="mobile-period__body">
        
        <app-mobile-date-range [dtBegin]="newDate.dt_begin" [dtEnd]="newDate.dt_end"   (onChanged)="changePeriod($event)"></app-mobile-date-range>
    </div>

    <div class="mobile-period__footer">
        <button *ngIf="newDate" class="mobile-period__apply" (click)="apply()">Применить</button>
        
    </div>
</div>
