import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IInetuserLog,
    IInetuserLogSize,
    IPatient,
} from '../interfaces/patient';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { EventService } from './event/event.service';

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    firstname = '';
    lastname = '';
    secondname = '';
    birthdate = '';

    constructor(
        private httpNew: HttpNewService,
        private auth: AuthService,
        private eventService: EventService
    ) {
        this.getServerPatientInfo$().subscribe((res) => {
            this.firstname = res.firstname;
            this.lastname = res.lastname;
            this.secondname = res.secondname;
            this.birthdate = res.birthdatestr;
        });

        eventService.on('auth:logout', this.clearPatientInfo);
    }

    public getServerPatientInfo$(token?: string | null): Observable<IPatient> {
        //TomCat    return this.httpNew.get(`/api/patient/${this.auth.patientId}/info` , this.auth.token)
        if (token) {
            return this.httpNew.get(`/patient/info`, token);
        } else {
            return this.httpNew.get(`/patient/info`, this.auth.token);
        }
    }

    public get getFullName(): string {
        return [this.lastname, this.firstname, this.secondname].join(' ');
    }

    public get getBirthdate(): string {
        return this.birthdate;
    }

    /* Смена пароля*/
    public changepasswd$(
        pOldPassword: string,
        pNewPassword: string
    ): Observable<any> {
        return this.httpNew.post(
            `/api/changepasswd`,
            { oldPassword: pOldPassword, newPassword: pNewPassword },
            this.auth.token
        );
    }

    /* Количество активности пользователя*/
    public getInetuserLogSize$(): Observable<IInetuserLogSize> {
        // TomCat    return this.httpNew.get('/api/log/size' , this.auth.token);
        return this.httpNew.get('/log/size', this.auth.token);
    }
    // /api/log/size

    /* Список активности пользователя*/
    public getInetuserLog$(
        pStart?: number,
        pEnd?: number
    ): Observable<IInetuserLog[]> {
        let url = '/log/rec';
        if (pStart && pEnd) {
            url = url + `?start=${pStart}&end=${pEnd}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    // Список параметров заявлений
    public getStatementType$(): Observable<any> {
        return this.httpNew.get(`/statement/params`, this.auth.token);
    }

    /*
  // Список параметров заявлений
  public getStatementParam$(pTypeId: number): Observable<IStatementParam[]> {
//    return this.httpNew.get(`/api/patient/${this.auth.patientId}/statement/type` , this.auth.token)
    return this.httpNew.get(`/api/patient/${this.auth.patientId}/statement/type/${pTypeId}/param` , this.auth.token)
  }
*/

    // Запись параметров, формирование заявления и его отправка
    public getStatementSave$(pTypeId: number, pDate: string): Observable<any> {
        return this.httpNew.post(
            `/statement/create`,
            { statement_params: pDate },
            this.auth.token,
            true
        );
    }

    public create_pw$(): Observable<any> {
        return this.httpNew.get(`/patient/create_pw`, this.auth.token);
    }

    public clearPatientInfo(): void {
        this.lastname = '';
        this.secondname = '';
        this.firstname = '';
        this.birthdate = '';
    }
}
