import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPayment } from 'src/app/interfaces/payment.interface';
import { FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { SelectOption } from 'src/app/interfaces/select';
import {
    Refund,
    RefundService,
    RefundType,
    Srvdep,
} from 'src/app/services/refund.service';
import { AlertService } from 'src/lib/ariadna/alert';

interface IGenericForm {}

@Component({
    selector: 'refund-modal',
    templateUrl: 'refund.component.html',
    styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit {
    @ViewChild('closeModal') colseModalTemplate?: TemplateRef<any>;
    refundEnum = RefundType;
    closeModalRef?: MatDialogRef<any>;
    isLoading = false;
    refundCost = 0;
    selectedServices = new Set<Srvdep>();

    refundForm = new FormGroup<any>({});
    subform = new FormGroup<any>({});
    reasons: SelectOption[] = [
        { text: 'Причина 1', value: '1' },
        { text: 'Причина 2', value: '2' },
        { text: 'Причина 3', value: '3' },
        { text: 'Причина 4', value: '4' },
    ];
    services?: Srvdep[];

    constructor(
        private dialog: MatDialog,
        private refundService: RefundService,
        private alert: AlertService,
        public dialogRef: MatDialogRef<RefundComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPayment
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.init();
    }

    async init() {
        await this.getServices();

        if (this.services) {
            this.initForm();
            this.getRefundReasons();
            this.services.forEach((srv) => this.selectedServices.add(srv));
            this.calcRefundCost();
        }
    }

    initForm() {
        this.refundForm = new FormGroup<{}>({
            reason: new FormControl('', { validators: Validators.required }),
            comment: new FormControl(''),
            services: new FormControl(this.selectedServices, {
                validators: this.servicesValidator(),
            }),
            refundType: new FormControl(RefundType.cash, {
                validators: Validators.required,
            }),
            subform: new FormGroup({}),
        });
    }

    async getRefundReasons() {
        try {
            const reasons = await this.refundService.fetchRefundReasons();

            if (reasons) {
                this.reasons = [];

                for (const { keyid, text } of reasons) {
                    this.reasons.push({ text, value: keyid });
                }
            }
        } catch (error) {
            this.alert.warn('Ошибка при получении списка причин для возврата');
        }
    }

    async getServices() {
        try {
            const services = await this.refundService.fetchServices(
                this.data.pt_keyid
            );

            this.services = services ? services : [];
        } catch (error) {
            this.alert.warn('Ошибка при получении списка услуг');
        }
    }

    servicesValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            return control.value.size
                ? null
                : { services: 'Не выбрана ни одна услуга' };
        };
    }

    get refundType() {
        const typeField = this.refundForm.get('refundType');
        if (typeField !== null) {
            return typeField.value;
        }
        throw new Error();
    }

    set refundType(type: RefundType) {
        if (type !== this.refundForm.get('refundType')?.value) {
            this.refundForm.removeControl('subform');

            let subform = new FormGroup<any>({});

            if (type === RefundType.card) {
                subform = this.initCardSubformGroup();
            } else if (type === RefundType.requisits) {
                subform = this.initRequisitsSubformGroup();
            }

            this.subform = subform;
            this.refundForm.addControl('subform', subform);
            this.refundForm.get('refundType')?.setValue(type);
        }
    }

    onChangeService([value, checked]: [any, boolean]) {
        if (checked) {
            if (!this.selectedServices.has(value)) {
                this.selectedServices.add(value);
            }
        } else {
            if (this.selectedServices.has(value)) {
                this.selectedServices.delete(value);
            }
        }

        this.calcRefundCost();
        this.refundForm.get('services')?.updateValueAndValidity();
    }

    calcRefundCost() {
        let cost = 0;

        for (const srv of Array.from(this.selectedServices)) {
            cost += +srv.amount ?? 0;
        }

        this.refundCost = cost;
    }

    initCardSubformGroup() {
        return new FormGroup({
            number: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4),
                ],
            }),
            date: new FormControl('', {
                validators: [
                    Validators.required,
                    this.validateDateBeforeToday.bind(this),
                ],
            }),
        });
    }
    validateDateBeforeToday(
        control: AbstractControl
    ): { [key: string]: any } | null {
        const selectedDate = new Date(control.value);
        const currentDate = new Date();

        if (selectedDate <= currentDate) {
            return null;
        } else {
            return { invalidDate: true };
        }
    }
    getMaxDate(): string {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    initRequisitsSubformGroup() {
        return new FormGroup({
            bankName: new FormControl('', {
                validators: [Validators.required],
            }),
            inn: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(12),
                ],
            }),
            bik: new FormControl('', {
                validators: [Validators.required, Validators.minLength(9)],
            }),
            corr: new FormControl('', {
                validators: [Validators.required, Validators.minLength(20)],
            }),
            rsc: new FormControl('', {
                validators: [Validators.required, Validators.minLength(20)],
            }),
        });
    }

    openCloseModal() {
        if (this.colseModalTemplate && this.services) {
            this.closeModalRef = this.dialog.open(this.colseModalTemplate);
        } else {
            this.close();
        }
    }

    onCloseModal() {
        this.closeModalRef?.close();
    }

    close() {
        this.dialogRef.close();
        this.closeModalRef?.close();
    }

    async onSend(e: Event) {
        e.stopPropagation();

        try {
            const refund: Refund = {
                paymentId: this.data.pt_keyid,
                reasonId: this.f('reason')?.value.value,
                note: this.f('comment')?.value ?? null,
                refundType: this.refundType,
                lastCardNum: this.subf('number')?.value,
                payDate: this.subf('date')?.value,
                bankName: this.subf('bankName')?.value,
                inn: this.subf('inn')?.value,
                bik: this.subf('bik')?.value,
                corrBill: this.subf('corr')?.value,
                accBill: this.subf('rsc')?.value,
                srvdepIds: this.getSrvdepIds(
                    Array.from(this.f('services')?.value)
                ),
            };

            const result = (await this.refundService.sendRefund(refund))[0];

            if (result.error_code === 0) {
                this.alert.success(
                    'Заявка на возврат денежных средств успешно отправлена. Проверьте почту',
                    10000
                );
                this.dialogRef.close();
            } else {
                throw new Error(result.error_text);
            }
        } catch (error) {
            this.alert.error(
                'Ошибка при отправке заявки на возврат. Попробуйте ещё раз',
                10000
            );
        }
    }

    getSrvdepIds(options: Srvdep[]): string {
        return options.map((item: Srvdep) => item.keyid)?.join(';') ?? '';
    }

    f(controlName: string) {
        return this.refundForm.get(controlName);
    }

    subf(controlName: string) {
        return this.subform.get(controlName);
    }
}
