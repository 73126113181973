<p class="talon-header">{{ headerTxt }}</p>
<div *ngIf="loading" class="loader" style="text-align: center; width: 100%">
    <img src="./assets/loading.gif" />
</div>
<div class="row talon-list" *ngIf="!loading">
    <div
        *ngIf="talonListDay.length == 0"
        style="
            width: 100%;
            font-size: 18px;
            text-align: center;
            font-weight: 700;
        "
    >
        Нет свободных талонов
    </div>

    <ng-container *ngFor="let d of talonListDay; let i = index">
        <div
            class="col-sm-3 talon-item"
            [ngClass]="{ 'talon-activ': d == selectedTalon }"
        >
            <div
                (click)="clickTalon(d)"
                [ngClass]="{ 'talon-disable': talonDisable(d) }"
                [ngbTooltip]="talonDisable(d) ? tipContentRegDT : tipContentReg"
                container="body"
                placement="top"
                tooltipClass="tooltipPanel"
            >
                {{ formatTalon(d.dtBegin) }}
            </div>
            <ng-template #tipContentReg let-greeting="greeting">
                <span style="font-weight: 700">{{ d.depname }}</span>
            </ng-template>
            <ng-template #tipContentRegDT let-greeting="greeting">
                <span style="font-weight: 700">Для других услуг</span>
            </ng-template>
        </div>
    </ng-container>
</div>
