<div class="container">
    <div class="content">
        <h1 class="content__title">Личный кабинет</h1>
        <h2 class="content__subtitle">Вход для пациентов</h2>
        <img
            class="content__img"
            src="./assets/img/503.webp"
            alt="503_img"
            width="276"
            height="169"
        />
        <p class="content__info">Сервис недоступен</p>
        <button class="content__refresh-btn" (click)="refresh()">
            Обновить
        </button>
    </div>
</div>
