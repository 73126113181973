import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-mobile-feed-page-template',
    templateUrl: './mobile-feed-page-template.component.html',
    styleUrls: ['./mobile-feed-page-template.component.scss'],
})
export class MobileFeedPageTemplateComponent implements OnInit {
    @Input('loaded-pages') loadedPages: number = 0;
    @Input('view-page') viewPage: number = 1;
    @Input('count-of-pages') countOfPages: number = 0;
    @Input('is-feed-empty') isFeedEmpty = false;
    @Input('feed-empty-text') feedEmptyText = 'Записей нет';
    @Input('no-more-content-text') noMoreContentText: string =
        'Записей больше нет.';
    @Input('is-loading') isLoading: Boolean = false;
    @Input('root-url') url: string = '';
    @Input() pages: Array<any> = [];
    @Input('height-offset') offset = 0;
    @Output('load-next-page') loadNextPage = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    nextPage() {
        if (this.pages.length > this.viewPage) {
            this.viewPage++;
            this.scrollToPage(this.viewPage);
        } else {
            this.viewPage = this.pages.length;
            this.scrollToLoadMore();
        }
    }
    prevPage() {
        if (this.viewPage !== 1) {
            this.viewPage--;
            this.scrollToPage(this.viewPage);
        }
    }

    startLoading($event: any) {
        this.loadNextPage.emit(this.loadedPages);
        this.loadedPages++;
    }

    scrollToPage(page: number) {
        this.scrollTo(`[id=page${page}]`);
    }

    scrollToLoadMore() {
        this.scrollTo('[id="load-more"]');
    }

    scrollTo(selector: string) {
        const pageElement = document.querySelector(selector);

        if (pageElement) {
            pageElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
