<div class="mobile-modal__wrapper">
    <div class="mobile-modal__header">
        <div class="mobile-modal__title">Информация по услуге</div>
        <span class="mobile-modal__cross" (click)="close()">
            <img src="./assets/img/svg/krest-gray.svg" alt="Очистить" />
        </span>
    </div>
    <div class="mobile-modal__body">
        <div class="card__head">
            <span class="card__date"
                >{{ getTekDay(data.service.dtSort) }} в
                {{ getTime(data.service.dtSort) }}</span
            >
            <span class="card__day">{{
                getNameDay(data.service.dtSort) | titlecase
            }}</span>
        </div>
        <div class="card__body">
            <div class="card__content">
                <div class="card__doctor doctor" *ngIf="data.service.doctor_id">
                    <div class="doctor__avatar">
                        <img
                            [src]="doctorImg"
                            (error)="errorHandlerIMG($event, 'doc')"
                            alt="Фото врача"
                        />
                    </div>
                    <div class="doctor__info">
                        <div class="doctor__name">
                            {{ data.service.ps_docexec }}
                        </div>
                        <div class="doctor__speciality">
                            {{ data.service.spec_name }}
                        </div>
                    </div>
                </div>

                <div class="card__visit info-card">
                    <div class="info-card__title">Приём</div>
                    <div class="info-card__text">
                        <span class="card__day">{{
                            data.service.place
                                ? data.service.place
                                : "Место отутствует"
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__footer">
            <div class="card__subject">{{ data.service.sd_name }}</div>
            <div class="card__price">
                {{
                    data.service.ps_price
                        ? (data.service.ps_price | priceSpace : ".2") +
                          " рублей"
                        : "Бесплатно"
                }}
            </div>
        </div>
    </div>
    <div class="mobile-modal__footer">
        <button class="mobile-period__apply" (click)="close()">Закрыть</button>
    </div>
</div>
