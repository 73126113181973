<div *ngFor="let resultH of data;let i = index" style="margin-bottom: 30px">
  <div class="fm-header">{{resultH.name}}</div>
  <table class="w-100">
    <tr>
      <td class="w-50" rowspan="2" style="background-color: #F9F9F9; color: #A8A8A8; padding: 10px">Показатель</td>
      <td class="w-50" colspan="3" style="text-align: center; background-color: #EFF4F8; color: #A8A8A8">Результат заказа</td>
    </tr>

    <tr>
      <td class="result-header-tec">Текущий Ед.изм.</td>
      <td class="result-header-line">Референсное значение</td>
      <td class="result-header-pred">Предыдущий</td>
    </tr>

    <tr class="result-item-tr" *ngFor="let result of resultH.listResult ;let i = index">
      <td class="result-item-name">
        <span [ngbTooltip]="tipStatusInfo" placement="bottom" container="body" class="span-status-res">
          <img *ngIf="result.patLevel == '<' " src="./assets/img/lab/1.png" >
          <img *ngIf="result.patLevel == '!<' " src="./assets/img/lab/2.png" >
          <img *ngIf="result.patLevel == '<<!' " src="./assets/img/lab/3.png" >

          <img *ngIf="result.patLevel == '>' " src="./assets/img/lab/4.png" >
          <img *ngIf="result.patLevel == '>!' " src="./assets/img/lab/5.png" >
          <img *ngIf="result.patLevel == '!>>' " src="./assets/img/lab/6.png" >
        </span>

        <ng-template #tipStatusInfo let-greeting="greeting">
        <span *ngIf="result.patLevel == '<' ">
          <div>Значение</div>
          <span style="font-weight: 700;">Ниже</span>
        </span>
        <span *ngIf="result.patLevel == '!<' ">
          <div>Значение</div>
          <span style="font-weight: 700;">сильно ниже</span>
        </span>
          <span *ngIf="result.patLevel == '<<<' ">
          <div>Значение</div>
          <span style="font-weight: 700;">критически ниже</span>
        </span>

        <span *ngIf="result.patLevel == '>' ">
          <div>Значение</div>
          <span style="font-weight: 700;">Выше</span>
        </span>
        <span *ngIf="result.patLevel == '>!' ">
          <div>Значение</div>
          <span style="font-weight: 700;">сильно выше</span>
        </span>
        <span *ngIf="result.patLevel == '>>>' ">
          <div>Значение</div>
          <span style="font-weight: 700;">критически выше</span>
        </span>

        </ng-template>

          <span>{{result.name}}</span>
      </td>


      <td class="result-item-tec">{{result.val}}<span class="result-ed">{{result.valEd}}</span></td>
      <td class="result-item-line">
          <ng-container *ngIf="isShowLine(result?.val, result?.max, result?.min); then showLine else showTextNorm"></ng-container>

          <ng-template #showLine>
              <app-line-lab-res [setMin]="result?.min" [setMax]="result?.max" [setCurent]="result?.val"></app-line-lab-res>
          </ng-template>

          <ng-template #showTextNorm>
              <div style="color: #8499b4;padding-left: 5px;" *ngIf="result.norm">Норма: {{result?.norm}}</div>
          </ng-template>


      </td>
      <td class="result-item-pred">{{result.last_res}}</td>
    </tr>

  </table>

</div>

