/* Компонент вывода и выбора талонов раздела "Запись к врачу" мобильной версии */
import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    HostListener,
} from '@angular/core';
import {
    IRecTalon,
    IRecTalonInit,
    ITalonModal,
    ITalonsDaySetParams,
} from '../../../../../interfaces/record.interface';
import { RecordService } from '../../../../../services/record.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { NewTalonComponentMobile } from '../../../../../modals/new-talon-mobile/talon.component';
import { CalendarService } from '../../../../../services/calendar.service';
import { ICalendarDateActive, IMY } from '../../../../../interfaces/calendar';
import { AlertService } from 'src/lib/ariadna/alert';

@Component({
    selector: 'app-mobile-talons',
    //  changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './mobile-talons.component.html',
    styleUrls: ['./mobile-talons.component.scss'],
})
export class MobileTalonsComponent implements OnInit {
    //  @Input() talonList: IRecTalon[] = [];     // Массов дней - активный
    @Output() onClickTalon = new EventEmitter<string>(); // талон
    paramsData: IRecTalonInit | null = null; // паарметры инициализации
    talonList: IRecTalon[] = []; // Массов дней - активный
    talonListDay: IRecTalon[] = []; // Массов дней - активный
    selectedDate: Date | null = null; // Выбрана дата
    headerTxt = ''; // Заголовок
    selectedTalon: IRecTalon | null = null; // выбранный талон
    nextDate: Date | null | undefined = null; // Следующая дата
    prevDate: Date | null | undefined = null; // Предыдущая дата
    depId: number | 0 = 0;
    error: string | null = null;

    isNotDate: boolean = false; // Нет данных
    classApplied = false;
    blokCalendaryStyleHeight = { height: 'auto' };
    calendarEnabled: boolean = true;

    /* Создание компонента */
    constructor(
        private rs: RecordService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private cs: CalendarService //             ,private cdREF: ChangeDetectorRef
    ) {
        rs.TalonsOnInit$().subscribe((n) => {
            this.initTalons(n);
        });

        rs.TalonsDayOnInit$().subscribe((n) => {
            // Фильтровать по дню
            this.initDay(n);
        });
    }

    ngOnInit(): void {}

    /* ПОлучение списка талонов */
    initTalons(t: IRecTalonInit) {
        // Весь список талонов
        this.paramsData = t;
        this.talonList = this.paramsData.talons;

        // this.alertService.error('Запись невозможна, Тест');
    }

    /* Получение текущей даты в фильтре */
    initDay(data: ITalonsDaySetParams) {
        // Фильтр по дню
        this.selectedDate = data.dt;
        var depId = data.dep && data.dep.keyid > 0 ? data.dep.keyid : 0;
        this.loadDate(depId);
    }

    /* Фильтрация талонов по дате и филиалу */
    loadDate(depId: number) {
        let n = Number(moment(this.selectedDate).format('YYYYMMDD'));
        this.headerTxt = `${moment(this.selectedDate).format('DD MMMM')}`;
        this.talonListDay = [];
        if (depId) {
            let k = depId;
            this.depId = depId;
            this.talonListDay = this.talonList.filter(
                (item) => item.dtFilter === n && item.depid === k
            );
            var prevDateDay = this.talonList.filter(
                    (item) =>
                        Number(item.dtFilter) < Number(n) && item.depid === k
                ),
                nextDateDay = this.talonList.filter(
                    (item) =>
                        Number(item.dtFilter) > Number(n) && item.depid === k
                );
        } else {
            this.talonListDay = this.talonList.filter(
                (item) => item.dtFilter === n
            );
            var prevDateDay = this.talonList.filter(
                    (item) => Number(item.dtFilter) < Number(n)
                ),
                nextDateDay = this.talonList.filter(
                    (item) => Number(item.dtFilter) > Number(n)
                );
        }

        this.prevDate =
            prevDateDay[prevDateDay.length - 1] &&
            prevDateDay[prevDateDay.length - 1].dtBegin
                ? prevDateDay[prevDateDay.length - 1].dtBegin
                : null;
        this.nextDate =
            nextDateDay[0] && nextDateDay[0].dtBegin
                ? nextDateDay[0].dtBegin
                : null;

        var count = 1,
            nowDate = new Date(),
            cdYear = Number(moment(nowDate).format('YYYY')),
            cdMounth = Number(moment(nowDate).format('MM')),
            minDT = { m: cdMounth, y: cdYear },
            activeDate = moment(this.selectedDate).format('YYYY-MM-DD'),
            dates = new Array(),
            cdActive = new Array(),
            months = new Array();

        this.talonList.forEach(function (item) {
            if (!dates.includes(item.dtFilter)) {
                dates.push(item.dtFilter);
                cdActive.push({ date: item.dtFilter });
            }
            var monthCut = String(item.dtFilter).substr(0, 6);
            if (!months.includes(monthCut)) {
                months.push(monthCut);
            }
        });
        count = months.length > 0 ? months.length : 1;

        if (dates.length == 1) {
            this.calendarEnabled = false;
        }
        this.cs.setParams({
            year_begin: cdYear,
            mounth_begin: cdMounth,
            count: count,
            cdActive: cdActive,
            orderByStart: true,
            MinDate: minDT,
            selectDate: '',
        });
        this.cs.setSelectedDate(activeDate);
    }

    /* Форматирование даты талона */
    formatTalon(dt: Date | null | undefined): string {
        if (dt) {
            return moment(dt).format('HH:mm');
        } else {
            return '';
        }
    }

    /* Выбор даты в календаре талонов */
    clickDate(date: Date | null | undefined) {
        if (!date) {
            return;
        }
        this.selectedDate = date;
        this.loadDate(this.depId);
    }

    /* Выбор талона */
    clickTalon(talon: IRecTalon) {
        //
        if (!this.talonDisable(talon)) {
            let dt: ITalonModal = {
                rnumbID: talon.rnumbid,
                paramCansel: false,
            };
            this.rs.setRnumbBlStatus(talon.rnumbid).subscribe(
                (res) => {
                    if (res && res.err_code === 0) {
                        this.selectedTalon = talon;
                        if (this.paramsData && this.paramsData.srv) {
                            dt.srv = this.paramsData.srv;
                        }

                        const dialogRef = this.dialog.open(
                            NewTalonComponentMobile,
                            {
                                // const dialogRef = this.dialog.open(TalonComponentMobile, {
                                width: '100%',
                                height: '100%',
                                closeOnNavigation: true,
                                data: dt,
                            }
                        );

                        dialogRef.afterClosed().subscribe((result) => {
                            if (
                                result &&
                                result.unlockTalon &&
                                result.unlockTalon == true
                            ) {
                                // Отмена номерка
                                this.rs.getRnumbUnlock(talon.rnumbid).subscribe(
                                    (res) => {},
                                    (err) => {}
                                );
                            }
                        });
                    } else {
                        // this.alertService.error('Запись невозможна, ' + res.err_text);
                        this.showError('Запись невозможна, ' + res.err_text);
                    }
                },
                (err) => {}
            );
        }
    }

    /* Блокировка выбора талона */
    talonDisable(talon: IRecTalon): boolean {
        if (!this.paramsData?.srv) return false;

        if (!this.paramsData?.srv?.is_telemed) return false;

        return true;
    }

    /* Выбор даты на календаре */
    calendarClick(dt: string) {
        var date = new Date(dt);

        if (this.selectedDate != date) {
            this.selectedDate = date;

            this.selectedDate = date;
            this.cs.setSelectedDate(dt);
            this.loadDate(this.depId);
        }
        this.toggleClass();
    }

    /* Открытие/скрытие календаря */
    toggleClass() {
        if (!this.calendarEnabled) {
            return;
        }
        this.classApplied = !this.classApplied;
        var element = document.getElementsByClassName('calendar-container')[0];
        if (this.classApplied === true && element) {
            element.classList.add('calendar-active');
        } else if (element) {
            element.classList.remove('calendar-active');
        }
    }

    /* Вывод ошибки */
    showError(text: string) {
        this.error = text;
    }

    /* Скрытие ошибки */
    hideError() {
        this.error = null;
    }

    //свайп в мл=обиле
    defaultTouch = { x: 0, y: 0, time: 0 };

    @HostListener('touchstart', ['$event'])
    //@HostListener('touchmove', ['$event'])
    @HostListener('touchend', ['$event'])
    @HostListener('touchcancel', ['$event'])
    handleTouch(event: {
        touches: any[];
        changedTouches: any[];
        type: string;
        timeStamp: number;
    }) {
        let touch = event.touches[0] || event.changedTouches[0];

        // check the events
        if (event.type === 'touchstart') {
            this.defaultTouch.x = touch.pageX;
            this.defaultTouch.y = touch.pageY;
            this.defaultTouch.time = event.timeStamp;
        } else if (event.type === 'touchend') {
            let deltaX = touch.pageX - this.defaultTouch.x;
            let deltaY = touch.pageY - this.defaultTouch.y;
            let deltaTime = event.timeStamp - this.defaultTouch.time;

            // simulte a swipe -> less than 500 ms and more than 60 px
            if (deltaTime < 500) {
                // touch movement lasted less than 500 ms
                if (Math.abs(deltaX) > 60) {
                    // delta x is at least 60 pixels
                    if (deltaX > 0) {
                        this.doSwipeRight(event);
                    } else {
                        this.doSwipeLeft(event);
                    }
                }

                if (Math.abs(deltaY) > 60) {
                    // delta y is at least 60 pixels
                    if (deltaY > 0) {
                        this.doSwipeDown(event);
                    } else {
                        this.doSwipeUp(event);
                    }
                }
            }
        }
    }

    doSwipeLeft(event: any) {
        this.clickDate(this.nextDate);
    }

    doSwipeRight(event: any) {
        this.clickDate(this.prevDate);
    }

    doSwipeUp(event: any) {}

    doSwipeDown(event: any) {}
}
