import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IResearchFormBio} from "../../../../interfaces/lab.interface";
import {of} from "rxjs";
import {max} from "rxjs/operators";

@Component({
  selector: 'app-forma-biomaterial-mobile',
  templateUrl: './forma-biomaterial.component.html',
  styleUrls: ['./forma-biomaterial.component.scss']
})
export class FormaBiomaterialMobileComponent implements OnInit {
  @Input() data: IResearchFormBio[] = [];
  @Input() srvText: string | undefined | null = null; // Услуги для БИО
  @Input() srvRes: string | undefined | null = null;  // Результаты услуг для БИО
  @Input() note: string | undefined | null = null;   // Заключение для БИО
  @Input() statusRoot: string | undefined | null = null;   // Статус ROOT заказа

  BlocListAntibioticstyleHeight = {height:'auto'};

  selectedMicro: IResearchFormBio | null = null;

  constructor() { }

  ngOnInit(): void {
//     
    if (this.data && this.data.length > 0) {
      this.changeMicro(this.data[0]);
    }
    // Расчет высоты

    let lmax = 0;

    if (this.data && this.data.length>0) {
      this.data.forEach(item => {
        if (item.bactery && item.bactery.length > lmax){
          lmax = item.bactery.length;
        }
      });
    }
    lmax = Math.trunc((lmax + 1)/2);
    lmax = (lmax * 23) + 53;
    this.BlocListAntibioticstyleHeight.height = lmax +'px';
  }

  changeMicro(r: IResearchFormBio) {
    this.selectedMicro = r;
  }

  isShowListMicro(): boolean {
    if (this.data && this.data.length>0 && this.data[0].name && this.data[0].name.length>1) {
      return true;
    } else {
      return false;
    }
  }
}
