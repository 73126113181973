<div class="history-mobile">
    <div class="app__mobile-page page-wrapper">
        <div class="header">
            <div class="header__tabs">
                <button
                    class="btn cls-btn-second header__button header__tab"
                    [ngClass]="{ header__tab_active: statusTab == 0 }"
                    (click)="changeTab(0)"
                >
                    Предстоящие
                </button>
                <button
                    class="btn cls-btn-second header__button header__tab"
                    [ngClass]="{ header__tab_active: statusTab == 1 }"
                    (click)="changeTab(1)"
                >
                    Завершенные
                </button>

                <button class="btn cls-btn-rec" routerLink="/home/rec">
                    Записаться к врачу
                </button>
            </div>
        </div>
        <app-list-upcoming
            *ngIf="statusTab == 0"
            [rNumbs]="filteredRnumbList"
        ></app-list-upcoming>
        <app-list-completed *ngIf="statusTab == 1"></app-list-completed>
    </div>
</div>

<div class="history-desktop">
    <div class="history-desktop__header">
        <span class="header__title">Мои посещения</span>
        <div class="header__tabs">
            <button
                class="btn cls-btn-second cls-btn-filter cls-btn-rec-type"
                [ngClass]="{ 'active-type': statusTab == 0 }"
                (click)="changeTab(0)"
            >
                Предстоящие
            </button>
            <button
                class="btn cls-btn-second cls-btn-filter cls-btn-rec-type"
                [ngClass]="{ 'active-type': statusTab == 1 }"
                (click)="changeTab(1)"
            >
                Завершенные
            </button>
        </div>
        <button
            class="btn cls-btn-rec"
            *ngIf="isNotDate === false"
            routerLink="/home/rec"
        >
            Записаться к врачу
        </button>
    </div>

    <div class="history-desktop__body">
        <app-panel class="app-panel">
            <div class="panel-container">
                <app-list-upcoming
                    *ngIf="statusTab == 0"
                    (onDateGet)="dateGetMyRec($event)"
                    [selectDate]="sDate"
                    [rNumbs]="filteredRnumbList"
                ></app-list-upcoming>
                <app-list-completed
                    *ngIf="statusTab == 1"
                    (onDateGet)="dateGet($event)"
                    [CountRecPage]="countRecToHeigh"
                ></app-list-completed>

                <!-- <div class="block-not-data" *ngIf="isNotDate">
                    <div class="cls-not-rec">Нет посещений</div>
                    <button
                        class="btn cls-btn-rec no-data"
                        routerLink="/home/rec"
                    >
                        Записаться к врачу
                    </button>
                </div> -->

                <!-- <app-calendar
                    *ngIf="!isNotDate"
                    (onClickDate)="calendarClick($event)"
                ></app-calendar> -->
            </div>
        </app-panel>
    </div>
</div>
