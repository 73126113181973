<div class="visit-protocol-page">
  <div class="wrapper">
    <div class="header">
      <div class="title">
        <mat-icon
          class="close-btn"
          svgIcon="back"
          (click)="onClose()"
        ></mat-icon>
        Анкета
      </div>

      <div
        [ngClass]="{
          status: true,
          status_saved: protocolInfo.is_exists
        }"
        *ngIf="protocolInfo && !isLoading"
      >
        <mat-icon class="status__icon" svgIcon="tick"></mat-icon>
        <span class="status__text">
          {{ protocolStatus.fullText }}
        </span>
      </div>
    </div>

    <div class="content">
      <div class="protocol" *ngIf="!isLoading; else loader">
        <div class="protocol__message" *ngIf="!protocolInfo?.is_paid">
          Просьба оплатить в разделе "Финансы" в течение 70 минут. Иначе запись
          будет отменена
        </div>

        <protocol-form
          class="form"
          [protocol]="protocol"
          (onSubmit)="onSubmit($event)"
        >
        </protocol-form>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <loader type="page" color="#1A8DCC" message="Загрузка..."> </loader>
</ng-template>

<ng-template #closeModal>
  <div class="visit-modal">
    <div>
      <div class="visit-modal__title">Анкета не заполнена</div>
      <div class="visit-modal__text" *ngIf="!isRequired">
        Заполните, пожалуйста, все обязательные поля<span
          class="visit-modal__text_red"
          >&nbsp;*
        </span>
      </div>
      <div class="visit-modal__actions">
        <button
          class="button button_secondary"
          type="button"
          (click)="goBack()"
        >
          Пропустить
        </button>
        <button class="button" type="button" (click)="onCloseModal()">
          К анкете
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #saveModal>
  <div class="visit-modal">
    <div>
      <div class="visit-modal__title">Анкета отправлена</div>
      <div class="visit-modal__text" *ngIf="protocolStatus.time.length > 0">
        И доступна для редактирования в разделе “Мои посещения” до
        <span class="visit-modal__text_perpl">{{ protocolStatus.time }}</span>
      </div>
      <div class="visit-modal__actions">
        <button
          class="button button_secondary"
          type="button"
          (click)="onCloseModal()"
        >
          Пропустить
        </button>
        <button class="button" type="button" (click)="goMyrec()">
          В мои номерки
        </button>
      </div>
    </div>
  </div>
</ng-template>
