<div class="block-calendar-rnumb">
    <div style="width: 96%">
        <input
            type="text"
            class="form-control"
            id="testDep"
            placeholder="Выберите адрес"
            style="
                background-color: #ecf0f5;
                border-radius: 10px;
                cursor: pointer;
            "
            [(ngModel)]="depSelectName"
            (click)="searchClick()"
            (ngModelChange)="changeSearch($event)"
        />
        <mat-icon
            svgIcon="location"
            class="cls-btn-icon icon-location-input"
            *ngIf="depSelectName != ''"
        ></mat-icon>
        <mat-icon
            svgIcon="arrow_down"
            class="cls-btn-icon dropdown__icon"
        ></mat-icon>
    </div>

    <div
        class="select-list-adr"
        [@expandedAnimation]="searchAmine"
        (@expandedAnimation.done)="whenAnimateSearch($event)"
    >
        <div class="w-100" style="padding: 10px; margin-left: 3px">
            <div
                class="select-list-item"
                *ngFor="let dep of depList"
                (click)="selectDep(dep)"
            >
                <mat-icon svgIcon="location" class="cls-btn-icon"></mat-icon>
                <span>{{ dep.text }}</span>
                <div>{{ dep.addr! }}</div>
            </div>
        </div>
    </div>
</div>
