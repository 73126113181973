<div class="app-calendar-wrapper" >
  <div class="call-calendar-mob--wrap">
    <div class="dropdown-list__top">
      <button class="dropdown-list__cloce list-cloce reset" (click)="closeCalendar()">
        <img src="./assets/img/svg/cloce.svg" alt="Закрыть">
      </button>
    </div>
  </div>

  
  <div class="app-calendar-wrapper--div">
    <mat-icon svgIcon="top" class="cal-top" (click)="calendarClickGo('top')" [ngClass]="{ 'not-activ-btn': disableTop==true}"></mat-icon>
    <mat-icon svgIcon="bottom" class="cal-bottom" (click)="calendarClickGo('bottom')" [ngClass]="{ 'not-activ-btn': disableBottom==true}"></mat-icon>
  </div>
  <div *ngFor="let ca of calendarArray; let i = index;" class="cal-item-i">
    <app-item-calendar-mobile
      [in_year]="ca.year_begin"
      [in_month]="ca.month_begin"
      [daysActive]="ca.date"
      [selectedDate]="selectedDate"
      (onClickDate)="calendarClick($event)"
    >
    </app-item-calendar-mobile>
  </div>
</div>
