<div class="mobile-modal__wrapper">
    <div class="mobile-modal__header">
        <div class="mobile-modal__title">Информация по платежу</div>
        <button
            _ngcontent-oyn-c195=""
            class="mobile-period__close--btn"
            (click)="close()"
        >
            <svg
                _ngcontent-oyn-c195=""
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    _ngcontent-oyn-c195=""
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0492 9.44691L18.1713 16.569C18.5956 16.9933 18.5956 17.6631 18.1713 18.0649C17.7471 18.4891 17.0773 18.4891 16.6755 18.0649L9.55336 10.9428L2.43124 18.0649C2.007 18.4891 1.3372 18.4891 0.935369 18.0649C0.511127 17.6407 0.511127 16.9709 0.935369 16.569L8.05749 9.44691L0.935369 2.32479C0.511127 1.90055 0.511127 1.23076 0.935369 0.828923C1.35961 0.404682 2.0294 0.404682 2.43124 0.828923L9.55336 7.95104L16.6755 0.828923C17.0997 0.404682 17.7695 0.404682 18.1713 0.828923C18.5956 1.25316 18.5956 1.92296 18.1713 2.32479L11.0492 9.44691Z"
                    fill="#B7C9DF"
                ></path>
            </svg>
        </button>
    </div>
    <div class="mobile-modal__body">
        <div class="card__head">
            <span class="card__date"
                >{{ getDayWithYear(data.service.dtSort) }} в
                {{ getTime(data.service.dtSort) }}</span
            >

            <span class="card__day">{{
                getNameDay(data.service.dtSort) | titlecase
            }}</span>
        </div>
        <div class="card__body">
            <div class="card__content">
                <div class="card__visit info-card">
                    <div class="info-card__title">Тип платежа</div>
                    <div class="info-card__text">
                        <span class="card__day">{{
                            data.service.pay_type
                                ? data.service.pay_type
                                : "Место отутствует"
                        }}</span>
                    </div>
                </div>
                <div class="card__visit info-card">
                    <div class="info-card__title">Всего к оплате</div>
                    <div class="info-card__text">
                        <span
                            *ngIf="data.service.paystatus == 1"
                            class="card__day card__price"
                            style="
                                font-weight: 600;
                                font-size: 18px;
                                color: #648897;
                            "
                            >{{
                                data.service.all_amount
                                    ? data.service.all_amount
                                    : "Нет информации"
                            }}
                            <span> рублей</span></span
                        >

                        <span
                            *ngIf="
                                data.service.paystatus != 1 &&
                                data.service.confirmation_url != null
                            "
                            style="
                                font-weight: 600;
                                font-size: 18px;
                                color: #33c0ec;
                            "
                            >{{
                                data.service.all_amount
                                    ? data.service.all_amount
                                    : "Нет информации"
                            }}
                            <span> рублей</span></span
                        >
                        <span
                            *ngIf="
                                data.service.paystatus != 1 &&
                                data.service.confirmation_url == null
                            "
                            style="
                                font-weight: 600;
                                font-size: 18px;
                                color: #648897;
                            "
                            >{{
                                data.service.all_amount
                                    ? data.service.all_amount
                                    : "Нет информации"
                            }}
                            <span> рублей</span></span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-toggle">
            <div class="slide-toggle__head" (click)="slideMe()">
                <span>
                    {{ data.service.services.length }}
                    {{
                        sklonenie(data.service.services.length, [
                            "услуга",
                            "услуги",
                            "услуг"
                        ])
                    }}
                    в платеже</span
                >

                <svg
                    [ngClass]="{ slideToggleRotate: slideToggle }"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.6317 0.355291C13.5157 0.242666 13.3779 0.153312 13.2262 0.0923468C13.0745 0.0313812 12.9119 4.75609e-08 12.7477 5.47388e-08C12.5835 6.19168e-08 12.4209 0.0313813 12.2692 0.0923469C12.1175 0.153312 11.9798 0.242666 11.8638 0.355291L6.99891 5.06911L2.13404 0.355292C1.89961 0.128132 1.58164 0.000514971 1.2501 0.000514985C0.91855 0.000515 0.60058 0.128132 0.366142 0.355292C0.131704 0.582452 3.8927e-08 0.890546 5.29694e-08 1.2118C6.70118e-08 1.53305 0.131704 1.84115 0.366142 2.06831L6.12123 7.64471C6.23722 7.75734 6.37501 7.84669 6.52669 7.90766C6.67837 7.96862 6.84097 8 7.00518 8C7.16939 8 7.33199 7.96862 7.48367 7.90766C7.63535 7.84669 7.77313 7.75734 7.88913 7.64471L13.6442 2.0683C14.1207 1.60664 14.1207 0.829104 13.6317 0.355291Z"
                        fill="#9AACDB"
                    />
                </svg>
            </div>
            <div class="slide-toggle__content" *ngIf="this.slideToggle">
                <ng-container *ngFor="let item of uniqueDoctors">
                    <div class="card__doctor doctor ng-star-inserted">
                        <div *ngIf="item?.doc_id" class="doctor__avatar">
                            <img
                                alt="Фото врача"
                                [src]="getImgSrcDoc(item?.doc_id)"
                            />
                        </div>
                        <div class="doctor__info">
                            <div
                                class="doctor__name"
                                *ngIf="item.doc != ''; else noInfo"
                            >
                                {{ item.doc }}
                            </div>

                            <div
                                class="doctor__speciality"
                                *ngIf="item.spec_name != ''; else noInfo"
                            >
                                {{ item.spec_name }}
                            </div>
                            <ng-template #noInfo>
                                <div class="doctor__speciality"></div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="card__footer" *ngFor="let s of item.service">
                        <span class="card__subject">{{ s.name }}</span>
                        <span class="card__time"
                            >{{ strTodate(s.dtstr) }}
                        </span>
                        <p class="card__price">
                            {{ s.price }} <span>рублей</span>
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
        <button
            *ngIf="data.service.confirmation_url != null"
            class="cls-table-td-event cls-td-to-pay"
            (click)="toPay(data.service.confirmation_url)"
        >
            <mat-icon svgIcon="creditCard"></mat-icon>
            <span>Оплатить</span>
        </button>
    </div>
</div>
