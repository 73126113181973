import { Component, Input } from '@angular/core';

@Component({
    templateUrl: './template.html',
    styleUrls: ['./styles.scss'],
    selector: 'userpic',
})
export class UserpicComponent {
    @Input() placeholder: string = '';
    @Input() image: string = '';
    @Input() size: string = '3rem';

    public replaceWithPlaceholder = (event: Event) => {
        const target = event.target as HTMLImageElement;
        target.src = this.placeholder;
    };
}
