import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import * as dwv from 'src/lib/dwv';
import { ConfigService } from 'src/app/services/application/config.service.';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from 'src/app/services/patient.service';
import { AlertService } from 'src/lib/ariadna/alert';

@Component({
    selector: 'app-dicom-view-modal',
    templateUrl: './dicom-view-modal.component.html',
    styleUrls: ['./dicom-view-modal.component.scss'],
})
export class DicomViewModalComponent {
    @Input() accessionNumber = '';
    @Input() studyInstanceUID = '';
    @Input() studyDate = '';
    dwvApp!: dwv.App;
    layerDivID = 'layerGroup0';
    loadingApp = false;
    loadingSerie = false;
    isError = false;
    patient = {
        name: '',
        birthdate: '',
    };
    seriesInstanceUIDs: string[] = [];
    seriesPaths: string[] = []; // Path to static .dcm files
    currentIndex = 0; // Index of current serie
    currentDick = 0; // Index of current frame
    currentLayerIndex = 0; // dwv DOM viewLayer index
    @ViewChild('frameScrollInput')
    frameScrollInput!: ElementRef<HTMLInputElement>;
    inputFrameWidth = 0; // This is to force input width be equal to containers height after rotateZ(deg)
    seriesbarMenuOpened = false;
    @ViewChild('loaderSeriesProgress')
    loaderSeriesProgress!: ElementRef<HTMLSpanElement>;

    constructor(
        private http: HttpNewService,
        private configS: ConfigService,
        private patientS: PatientService,
        public activeModal: NgbActiveModal,
        private alert: AlertService
    ) {
        this.patient.name = patientS.getFullName;
        this.patient.birthdate = patientS.getBirthdate;
    }

    ngAfterViewInit() {
        this.loadingApp = true;

        // Timeout for dwv to initialize, has no event for it
        setTimeout(() => {
            this.initApp();
            this.getSeriesIUIDs();
            this.initTouchListeners();
        }, 300);
    }

    initApp() {
        this.dwvApp = new dwv.App();

        this.dwvApp.init({
            dataViewConfigs: { '*': [{ divId: this.layerDivID }] },
            tools: {
                Scroll: {},
            },
        });

        this.dwvApp.addEventListener('load', () => {
            this.dwvApp.setTool('Scroll');

            this.frameScrollInput.nativeElement.max = (
                this.seriesPaths.length - 1
            ).toString();

            this.loadingApp = false;
            this.loadingSerie = false;
            this.currentDick = 0;

            const framebar = document.querySelector(
                '.dicom__framebar'
            )! as HTMLDivElement;

            if (window.innerWidth > 768) {
                this.inputFrameWidth = framebar.getBoundingClientRect().height;
            } else {
                const framebarStyle = window.getComputedStyle(framebar);
                this.inputFrameWidth =
                    framebar.clientWidth -
                    parseFloat(framebarStyle.paddingLeft) * 2;
            }
        });

        this.dwvApp.addEventListener(
            'loadprogress',
            (e: any) =>
                (this.loaderSeriesProgress.nativeElement.innerText = e.loaded)
        );

        this.dwvApp.addEventListener('renderend', (e: Event) => {
            const avl = this.dwvApp
                .getLayerGroupByDivId(this.layerDivID)
                .getActiveViewLayer();
            const vc = avl.getViewController();
            const index = vc.getCurrentIndex();
            const values = index.getValues();
            this.frameScrollInput.nativeElement.value = values[2].toString();
        });
    }

    resetApp() {
        const layer = document.getElementById(this.layerDivID);
        layer?.querySelectorAll('*').forEach((el) => el.remove());
        this.dwvApp.reset();

        this.initApp();
    }

    private render(index: number) {
        const avl = this.dwvApp
            .getLayerGroupByDivId(this.layerDivID)
            .getActiveViewLayer();
        const vc = avl.getViewController();
        const values = vc.getCurrentIndex().getValues();

        values[2] = index;

        vc.setCurrentIndex(new dwv.Index(values));
    }

    getSeriesIUIDs() {
        this.loadingApp = true;

        this.http
            .get(
                `/history/visit/dicom/serieslist?studyInstanceUID=${this.studyInstanceUID}`,
                null
            )
            .subscribe(
                (res) => {
                    this.seriesInstanceUIDs = res;

                    this.loadingApp = false;

                    this.loadSerie(this.seriesInstanceUIDs[this.currentIndex]);
                },
                (err) => {
                    this.alert.error(err);
                    this.isError = true;
                }
            );
    }

    loadSerie(serieInstanceUID: string) {
        this.loadingSerie = true;
        this.http
            .get(
                `/history/visit/dicom/serie?accessionNumber=${this.accessionNumber}&studyInstanceUID=${this.studyInstanceUID}&serieInstanceUID=${serieInstanceUID}`,
                null
            )
            .subscribe((res: string[]) => {
                this.seriesPaths = res.map(
                    (path) =>
                        `${this.configS.getValue('hostBackend')}/dicom/${
                            this.accessionNumber
                        }/${serieInstanceUID}/${path}`
                );
                this.dwvApp.loadURLs(this.seriesPaths, {
                    withCredentials: true,
                });
            });
    }

    switchSerie(index: number, e?: MouseEvent) {
        e?.stopPropagation();

        this.dwvApp.abortLoad();

        this.currentDick = 0;
        this.currentIndex = index;
        this.seriesbarMenuOpened = false;

        document
            .getElementById(
                `${this.layerDivID}-layer-${this.currentLayerIndex++}`
            )
            ?.remove();

        this.resetApp();

        this.loadSerie(this.seriesInstanceUIDs[this.currentIndex]);
    }

    onSeriesScrollInputChange(event: any) {
        if (!this.dwvApp) return;

        this.currentIndex = +event.target.value;

        this.switchSerie(this.currentIndex);
    }

    onFrameInputChange(event: any) {
        if (!this.dwvApp) return;

        const inputValue = +event.target.value;
        this.currentDick = inputValue;

        this.render(inputValue);
    }

    onViewWheel(e: WheelEvent) {
        if (this.loadingSerie) return;

        e.deltaY > 0 ? ++this.currentDick : --this.currentDick;

        if (this.currentDick < 0) {
            this.currentIndex > 0
                ? this.switchSerie(this.currentIndex - 1)
                : this.switchSerie(this.seriesInstanceUIDs.length - 1);

            return;
        }

        if (this.currentDick > this.seriesPaths.length - 1) {
            this.currentIndex === this.seriesInstanceUIDs.length - 1
                ? this.switchSerie(0)
                : this.switchSerie(this.currentIndex + 1);

            return;
        }

        this.render(this.currentDick);
    }

    onMenuMobileButtonClick(e: MouseEvent) {
        e.stopPropagation();
        this.seriesbarMenuOpened = !this.seriesbarMenuOpened;
    }

    initTouchListeners() {
        const viewLayerDOM = document.querySelector(
            '.dicom__viewlayer'
        ) as HTMLDivElement;

        let touchStartX = 0;
        viewLayerDOM.addEventListener('touchstart', (e: TouchEvent) => {
            touchStartX = e.changedTouches[0].screenX;
        });

        viewLayerDOM.addEventListener('touchmove', (e: TouchEvent) => {
            if (this.loadingSerie) return;
            const { screenX } = e.changedTouches[0];
            const xMovement = touchStartX - screenX;
            const isForwardMove = xMovement > 0;
            const shouldMoveFire = xMovement % 15 === 0 && xMovement !== 0;

            if (!shouldMoveFire) return;

            this.currentDick = isForwardMove
                ? --this.currentDick
                : ++this.currentDick;
            // (touchStartX - e.changedTouches[0].screenX) % 10 === 0
            //     ? --this.currentDick
            //     : ++this.currentDick;

            if (this.currentDick < 0 && !this.loadingSerie) {
                this.currentIndex > 0
                    ? this.switchSerie(this.currentIndex - 1)
                    : this.switchSerie(this.seriesInstanceUIDs.length - 1);

                return;
            }

            if (
                this.currentDick > this.seriesPaths.length - 1 &&
                !this.loadingSerie
            ) {
                this.currentIndex === this.seriesInstanceUIDs.length - 1
                    ? this.switchSerie(0)
                    : this.switchSerie(this.currentIndex + 1);

                return;
            }

            this.render(this.currentDick);
        });
    }

    closeApp(event: MouseEvent) {
        event.stopPropagation();
        this.activeModal.close();
    }
}
