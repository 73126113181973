<ng-container *ngIf="filesSection">
    <div class="cls-block-header header">
        <h2 class="header__title">Документы</h2>
    </div>
    <div class="wrapper">
        <div class="left h-100">
            <span class="left__header-icon">
                <mat-icon
                    svgIcon="refresh"
                    class="cls-icon-refresh"
                    (click)="getDocumentsList()"
                ></mat-icon>
            </span>
            <div *ngIf="loading" class="loader">
                <div class="loader__img"></div>
            </div>
            <div *ngFor="let document of documentsList; let i = index">
                <div
                    [ngClass]="{
                        document_card: i !== selectedIndex,
                        document_card_active: i === selectedIndex
                    }"
                    (click)="
                        load_document
                            ? null
                            : getDocument(
                                  $event,
                                  document.id,
                                  document.file_name,
                                  i
                              )
                    "
                >
                    <span class="document_icon">
                        <mat-icon svgIcon="contract"></mat-icon>
                    </span>
                    <span class="document_title">
                        {{ document.file_name }}
                    </span>
                    <div class="document_date">
                        Дата файла: {{ document.dat_str }}
                    </div>
                </div>
            </div>
            <div
                *ngIf="documentsList.length < 1 && !loading"
                class="documents_not_found"
            >
                <h3>Документы не найдены.</h3>
            </div>
        </div>

        <div class="right h-100" *ngIf="documentsList.length > 0 && !loading">
            <div *ngIf="load_document" class="loader">
                <div class="loader__img"></div>
            </div>
            <div class="right_document">
                <img [src]="activeImgInBase64" *ngIf="activeImgInBase64" />
                <div *ngIf="downloadLink" class="download_link">
                    <a
                        [href]="downloadLink"
                        rel="noopener"
                        [download]="fileNameWithExt"
                        >Скачать {{ fileNameWithExt }}</a
                    >
                </div>
                <div
                    *ngIf="
                        !activeImgInBase64 && !downloadLink && !load_document
                    "
                    class="notSelectedDocument"
                >
                    <p>Выберите один из документов.</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
