import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appAlertRenderDirective]',
})
export class AlertRenderDirective {
    constructor(
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document
    ) {
        document.body.appendChild(el.nativeElement);
    }
}
