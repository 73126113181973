import { Injectable } from '@angular/core';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';

export interface Refund {
    paymentId: number;
    reasonId: number;
    note: string | null;
    refundType: RefundType;
    lastCardNum: number | string | null;
    payDate: string | null;
    bankName: string | null;
    inn: number | string | null;
    bik: number | string | null;
    corrBill: number | string | null;
    accBill: number | string | null;
    srvdepIds: string | null;
}

export interface Srvdep {
    keyid: number;
    text: string;
    amount: number | string;
}

export enum RefundType {
    cash = 1,
    card = 2,
    requisits = 3,
}

@Injectable({ providedIn: 'root' })
export class RefundService {
    constructor(public http: HttpNewService, public auth: AuthService) {}

    fetchRefundReasons(): Promise<{ keyid: number; text: string }[]> {
        return this.http.get('/refund/list', this.auth.token).toPromise();
    }

    sendRefund(refund: Refund) {
        const data = {
            p_payment_id: refund.paymentId,
            p_reason_id: refund.reasonId,
            p_note: refund.note,
            p_refund_type: refund.refundType,
            p_last_card_num: refund.lastCardNum,
            p_pay_dat: refund.payDate,
            p_bank_name: refund.bankName,
            p_inn: refund.inn,
            p_bik: refund.bik,
            p_corr_bill: refund.corrBill,
            p_acc_bill: refund.accBill,
            p_srvdep_ids: refund.srvdepIds,
        };

        return this.http
            .post('/refund/save', data, this.auth.token)
            .toPromise();
    }

    /** Получение списка услуг */
    fetchServices(paymentId: number): Promise<Srvdep[]> {
        return this.http
            .get('/refund/services?p_payment_id=' + paymentId, this.auth.token)
            .toPromise();
    }
}
