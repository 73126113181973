import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { randomCharString } from 'src/app/helpers/randomCharString';
const COMPONENT_NAME = 'barcode' as const;

@Component({
    selector: COMPONENT_NAME,
    templateUrl: `./${COMPONENT_NAME}.component.html`,
    styleUrls: [`./${COMPONENT_NAME}.component.scss`],
})
export class BarcodeComponent implements AfterViewChecked {
    @Input('value') value: string | number = '';
    @Input('id') id: string = randomCharString(10);
    ngAfterViewChecked(): void {
        JsBarcode(`#${this.id}`, this.value.toString());
    }
}
