import { Injectable } from '@angular/core';
import {HttpNewService} from "./application/http-new.service";
import {ICreatePatient} from "../interfaces/patient";
import {Observable} from "rxjs";
import {IHttpRequest} from "./application/IHttpRes.interface";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class EsiaService {

  constructor( private httpNew: HttpNewService) { }

  /* Получение URLl ГосУслуг для авторизации на ГосУслугах */
  public getInfo$(): Observable<IHttpRequest> {
    return new Observable((observer) => {
      return this.httpNew.get('/esia/info' ,  null)
        .subscribe(
          result => {
            observer.next(result);
          },
          err => {
            observer.error(err);
          }
        );
    });
  }

  /* Получение информации по коду с ГосУслуг */
  public getInfoState$(state: string): Observable<IHttpRequest> {
    return new Observable((observer) => {
      return this.httpNew.get('/esia/reg?state='+ state ,  null)
        .subscribe(
          result => {
            observer.next(result);
          },
          err => {
            observer.error(err);
          }
        );
    });
  }

}


