import {animate, group, query, state, style, transition, trigger} from "@angular/animations";

export const showOpacityAnimation = trigger(
  'showOpacityAnimation', [
    state('on', style({
      opacity: 1,
    })),
    state('off', style({
      opacity: 0,
    })),
    transition('on <=> off', animate('0.8s')),
  ]);

export const showOpacityDisplayAnimation = trigger(
  'showOpacityAnimation', [
    state('on', style({
      'opacity': 1,
      'display': 'block'
    })),
    state('off', style({
      'opacity': 0,
      'display': 'none'
    })),
    transition('on <=> off', animate('0.8s')),
  ]);

export const topButtonAnimation = trigger(
  'topButtonAnimation', [
    state('on', style({
      opacity: 0,
      bottom: '0px',
      height: '0px',
      'z-index': '0'
    })),
    state('off', style({
      opacity: 1,
      bottom: '-123px',
      height: '146px',
      'z-index': '50'
    })),
    transition('on <=> off', animate('500ms')),
  ]);

export const onIfAnimation = trigger('onIfAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('300ms', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate('300ms', style({ opacity: 0 }))
    ])
  ])

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('4000ms ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('4000ms ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('4000ms ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('4000ms ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

export const showLeftRightAnimation = trigger(
  'showLeftRightAnimation', [
  state('on', style({
    'display': 'block',
    'opacity': '0.85'
  })),
  state('off', style({
    'display': 'none',
    'opacity': '0'
  })),
/*
  transition('on => off', animate('400ms ease-in-out')),
  transition('off => on', animate('400ms ease-in-out'))
*/
    transition('on => off', right),
    transition('off => on', left)
]);

