import { Pipe, PipeTransform } from '@angular/core';
import { IPayment } from 'src/app/interfaces/payment.interface';

@Pipe({
    name: 'isRefund'
})

export class IsRefundPipe implements PipeTransform {
    transform(value: IPayment): any {
        return value.paystatus == 1;
    }
}