import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
    HostListener,
} from '@angular/core';

@Component({
    selector: 'scroll-view',
    templateUrl: 'scroll-view.component.html',
    styleUrls: ['./scroll-view.component.scss'],
})
export class ScrollViewComponent implements OnInit, AfterViewInit {
    @ViewChild('scrollWrapper') scrollWrapper?: ElementRef;
    scrollViewHeight = 0;
    scrollViewWidth = 0;

    constructor() {}
    ngAfterViewInit(): void {
        //setTimeout нужен для того, чтобы ангуляр не выбрасывал ошибку,
        //что значение типа было изменено. Можно поправить, но вроде это не критично
        setTimeout(() => {
            this.updateScrollSize();
        }, 0);
    }

    ngOnInit() {}
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updateScrollSize();
    }

    updateScrollSize() {
        if (this.scrollWrapper) {
            this.scrollViewHeight =
                this.scrollWrapper.nativeElement.offsetHeight;
            this.scrollViewWidth = this.scrollWrapper.nativeElement.offsetWidth;
        }
    }
}
