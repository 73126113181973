<ng-container *ngIf="isShowListMicro(); then showListMicro else NotListMicro"></ng-container>

<ng-template #NotListMicro>
  <div class="toJob cls-color-status-order4" *ngIf="statusRoot !='N'">в работе</div>
  <div class="toJob cls-color-status-order1" *ngIf="statusRoot =='N'">ожидает обработки</div>
</ng-template>

<ng-template #showListMicro>

  <div class="row">
  <div class="col-md-4 block-micro">
    <table class="w-100" style="margin-left: 3px;">
      <thead>
        <tr>
          <th colspan="2">Микроорганизм</th>
        </tr>
      </thead>
      <tr *ngFor="let result of data;let i = index" (click)="changeMicro(result)" [ngClass]="{ 'active': result == selectedMicro}">
<!--
        <td><mat-icon svgIcon="point" style="color: #9277ff; width: 11px;" *ngIf="result == selectedMicro"></mat-icon></td>
-->
        <td style="min-width: 10px;"></td>
        <td class="micro-res">{{result.name}}</td>
      </tr>
    </table>
  </div>

  <div class="col-md-8 block-antibiotic">
    <app-panel>
      <div class="row" [ngStyle]=BlocListAntibioticstyleHeight>
        <div class="col-6">
          <table class="antibiotic-table">
          <tbody>
          <tr>
            <th scope="col" style="width: 75%;">Антибиотик</th>
            <th scope="col" style="width: 25px;">SIR</th>
            <th scope="col" style="width: 25px;">MIC</th>
            <th scope="col" style="width: 25px;">DIA</th>
          </tr>

          <tr *ngFor="let resA of selectedMicro?.bactery | evenodd:'odd';let i = index;">
            <td>{{resA.name}}</td>
            <td class="cls-td-center">{{resA.sir}}</td>
            <td class="cls-td-center">{{resA.mic}}</td>
            <td class="cls-td-center">{{resA.dia}}</td>
          </tr>
          </tbody>
        </table>
        </div>

        <div class="col-6">
          <table class="antibiotic-table">
          <tbody>
          <tr>
            <th scope="col" style="width: 75%;">Антибиотик</th>
            <th scope="col" style="width: 25px;">SIR</th>
            <th scope="col" style="width: 25px;">MIC</th>
            <th scope="col" style="width: 25px;">DIA</th>
          </tr>
          <tr *ngFor="let resA of selectedMicro?.bactery | evenodd:'even';let i = index;">
            <td>{{resA.name}}</td>
            <td class="cls-td-center">{{resA.sir}}</td>
            <td class="cls-td-center">{{resA.mic}}</td>
            <td class="cls-td-center">{{resA.dia}}</td>
          </tr>
          </tbody>

        </table>
        </div>
      </div>

    </app-panel>
    <div class="row mat-bio-footer">
      <div class="col-6">
        <div>Услуга:</div>
        <div class="mat-bio-footer-res">{{srvText}}</div>
        <div>Результат услуги:</div>
        <div class="mat-bio-footer-res">{{srvRes}}</div>
      </div>

      <div class="col-6">
        <div>Заключение по антибиотикам:</div>
        <div class="mat-bio-footer-res">{{note}}</div>
      </div>

    </div>

  </div>

  </div>

</ng-template>
