<!-- Файл представления компонента раздела "Лаборатория" мобильной версии -->
<app-panel
    class="panel--fixed"
    *ngIf="toggleOrder == true"
    classSrt="h-100 loading-group"
    style="padding-left: 0px"
>
    <div class="panel--fixed__closeWrap">
        <span class="text">Информация по заказу</span>
        <button class="panel--fixed__close" (click)="closeFakePopup()">
            <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0492 9.66454L18.1713 17.2598C18.5956 17.7122 18.5956 18.4265 18.1713 18.855C17.7471 19.3074 17.0773 19.3074 16.6755 18.855L9.55336 11.2598L2.43124 18.855C2.007 19.3074 1.3372 19.3074 0.935369 18.855C0.511127 18.4026 0.511127 17.6883 0.935369 17.2598L8.05749 9.66454L0.935369 2.06932C0.511127 1.6169 0.511127 0.902611 0.935369 0.474083C1.35961 0.02166 2.0294 0.02166 2.43124 0.474083L9.55336 8.06931L16.6755 0.474083C17.0997 0.02166 17.7695 0.02166 18.1713 0.474083C18.5956 0.926505 18.5956 1.64079 18.1713 2.06932L11.0492 9.66454Z"
                    fill="#B7C9DF"
                />
            </svg>
        </button>
    </div>

    <div
        *ngIf="!loadingResearchList; else modalLoader"
        class="panel--fixed__content"
    >
        <div class="panel--fixed__time">
            <span class="date">{{ getDateOrder(selectedOrder?.dtSort) }} </span>
            <span class="day">{{ getTekDay(selectedOrder?.dtSort) }}</span>
        </div>
        <div class="panel--fixed__order">
            <span class="name-research" *ngIf="selectedOrder">
                Заказ {{ selectedOrder.material_id }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'выполнен'"
                class="order--status order--status__green"
            >
                {{ selectedOrder?.text_status }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'частично выполнен'"
                class="order--status order--status__turq"
            >
                {{ selectedOrder?.text_status }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'ожидает обработки'"
                class="order--status order--status__violent"
            >
                {{ selectedOrder?.text_status }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'в работе'"
                class="order--status order--status__blue"
            >
                {{ selectedOrder?.text_status }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'не сдан'"
                class="order--status order--status__red"
            >
                {{ selectedOrder?.text_status }}
            </span>
            <span
                *ngIf="selectedOrder?.text_status == 'отменён'"
                class="order--status order--status__red"
            >
                {{ selectedOrder?.text_status }}
            </span>
        </div>
        <button
            class="panel--fixed__pdf"
            *ngIf="
                selectedOrder?.text_status == 'выполнен' ||
                selectedOrder?.text_status == 'частично выполнен'
            "
            (click)="goPdf(selectedOrder?.research_id)"
        >
            <mat-icon
                *ngIf="isPrint(labResult)"
                svgIcon="print"
                class="cls-btn-icon cls-btn-print"
            >
            </mat-icon>
        </button>

        <div
            *ngFor="let result of labResult; let i = index"
            class="block-material-item"
        >
            <div *ngIf="!isNotShowResult" class="panel--fixed__slide">
                <div
                    class="panel--fixed__slide-head"
                    (click)="slideMe('A' + i)"
                    [ngClass]="{
                        'fixed--disabled':
                            selectedOrder?.text_status == 'не сдан' &&
                            result.res != null,
                        toggleClass: currDiv == 'A' + i && slideToggle
                    }"
                >
                    <span>{{ result.name }}</span>
                    <svg
                        class="panel--fixed__slide--svg"
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.6317 0.355291C13.5157 0.242666 13.3779 0.153312 13.2262 0.0923468C13.0745 0.0313812 12.9119 4.75609e-08 12.7477 5.47388e-08C12.5835 6.19168e-08 12.4209 0.0313813 12.2692 0.0923469C12.1175 0.153312 11.9798 0.242666 11.8638 0.355291L6.99891 5.06911L2.13404 0.355292C1.89961 0.128132 1.58164 0.000514971 1.2501 0.000514985C0.918549 0.000515 0.600581 0.128132 0.366142 0.355292C0.131704 0.582452 1.29767e-07 0.890546 1.43809e-07 1.2118C1.57852e-07 1.53305 0.131704 1.84115 0.366142 2.06831L6.12123 7.64471C6.23722 7.75734 6.37501 7.84669 6.52669 7.90766C6.67837 7.96862 6.84097 8 7.00518 8C7.16939 8 7.33199 7.96862 7.48367 7.90765C7.63535 7.84669 7.77313 7.75734 7.88913 7.64471L13.6442 2.0683C14.1207 1.60664 14.1207 0.829104 13.6317 0.355291Z"
                            fill="#9AACDB"
                        />
                    </svg>
                </div>
                <!-- <div *ngIf="isNotShowResult" class="res-in-work">
                    {{
                        selectedOrder?.text_status ?? "Результат обрабатывается"
                    }}
                </div> -->
                <div
                    class="panel--fixed__slide-body"
                    *ngIf="
                        currDiv == 'A' + i && slideToggle && !isNotShowResult
                    "
                >
                    <ng-container
                        *ngIf="result.formBio; then showFormBio; else showForm"
                    ></ng-container>
                    <ng-template #showFormBio>
                        <app-forma-biomaterial-mobile
                            [data]="resForFormBio(result.res)"
                            [srvText]="result.srvText"
                            [srvRes]="result.srvRes"
                            [note]="result.note"
                            [statusRoot]="selectedOrder?.status"
                        >
                        </app-forma-biomaterial-mobile>
                    </ng-template>
                    <ng-template #showForm>
                        <app-forma-material-mobile
                            [data]="resForForm(result.res)"
                        ></app-forma-material-mobile>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</app-panel>

<div class="app__mobile-page lab-page">
    <app-mobile-feed-page-template
        [count-of-pages]="countOfPages"
        [loaded-pages]="loadedPages"
        [is-loading]="loadingMoreContent"
        [root-url]="'home/labs'"
        [view-page]="viewPage"
        [pages]="this.servicesPages"
        [no-more-content-text]="'Лабораторных исследований больше нет.'"
        [feed-empty-text]="'На выбранные дни лабораторных исследований нет'"
        [is-feed-empty]="servicesListLength === 0"
        (load-next-page)="loadNextPage()"
        *ngIf="!isLoading; else loader"
    >
        <feed-header-period
            header
            [periodText]="periodText"
            (onClick)="showPeriodDialog()"
            [canBeClear]="
                periodText != 'Лабораторные исследования за всё время'
            "
            (onClear)="btnFilterByDate(null)"
        ></feed-header-period>

        <section
            page
            [id]="'page' + (pageIndex + 1)"
            class="services-mobile__page"
            *ngFor="let servicesPage of servicesPages; let pageIndex = index"
        >
            <app-feed-page-anchor
                [is-hidden]="pageIndex === 0"
                (intersected)="viewPage = pageIndex + 1"
            >
                {{ pageIndex + 1 }} из {{ countOfPages }}
            </app-feed-page-anchor>

            <section
                class="card lab--card"
                *ngFor="let serv of servicesPage; let i = index"
                [ngClass]="{ active: serv == selectedOrder }"
            >
                <ng-container *ngIf="isBlockYeer(i)">
                    <div
                        class="finanse-content__year"
                        [class.hidden]="i < 1"
                        (intersected)="viewPage = i + 1"
                        *ngIf="isBlockYeer(i)"
                    >
                        <span>{{ getYeer(i) }}</span>
                    </div>
                </ng-container>
                <div
                    class="lab--card--wrap"
                    (click)="selectOrder(serv); toggleOrder = true"
                >
                    <div class="card__head">
                        <span class="card__day">{{
                            getNameDay(serv.dtSort) | titlecase
                        }}</span>
                        <span class="card__date">{{
                            getDateOrder(serv.dtSort)
                        }}</span>
                    </div>

                    <div class="card__body">
                        <div class="card__content">
                            <span class="order--numb">
                                Заказ {{ serv.material_id }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'выполнен'"
                                class="order--status order--status__green"
                            >
                                {{ serv.text_status }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'частично выполнен'"
                                class="order--status order--status__turq"
                            >
                                {{ serv.text_status }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'ожидает обработки'"
                                class="order--status order--status__violent"
                            >
                                {{ serv.text_status }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'в работе'"
                                class="order--status order--status__blue"
                            >
                                {{ serv.text_status }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'не сдан'"
                                class="order--status order--status__red"
                            >
                                {{ serv.text_status }}
                            </span>
                            <span
                                *ngIf="serv.text_status == 'отменён'"
                                class="order--status order--status__red"
                            >
                                {{ serv.text_status }}
                            </span>
                        </div>
                    </div>
                </div>

                <!-- <button
                    class="pdf-onCard"
                    *ngIf="serv.text_status == 'выполнен'"
                    (click)="goPdf(serv.research_id)"
                >
                    <mat-icon
                        *ngIf="!pdfLoading"
                        svgIcon="print"
                        class="cls-btn-icon cls-btn-print"
                    >
                    </mat-icon>
                    <img
                        *ngIf="pdfLoading"
                        class="pdf-loader loader-rotate"
                        src="./assets/img/svg/loader-btn.svg"
                        alt=""
                    />
                    <span class="title">PDF</span>
                </button> -->
            </section>
        </section>

        <div fab class="calendar-btn" (click)="showPeriodDialog()">
            <mat-icon svgIcon="calendar"></mat-icon>
        </div>
    </app-mobile-feed-page-template>
</div>

<ng-template #loader>
    <div class="loader">
        <loader type="page" message="Загрузка списка платежей"> </loader>
    </div>
</ng-template>

<ng-template #modalLoader>
    <div class="loader">
        <loader type="page" message="Загрузка информации по заказу"></loader>
    </div>
</ng-template>
