import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpNewService } from './application/http-new.service';
import { Observable } from 'rxjs';
import {
    IAbonement,
    IAbonementBalanse,
    ICardAbonement,
    IGroupServiceAbonement,
    IPaidAbonement,
    IServiceAbonement,
    IServicesAbonement,
} from '../interfaces/abonement';

@Injectable({
    providedIn: 'root',
})
export class AbonementService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}

    /**
     * Список абонементов
     * @param parameters
     * @returns {Observable<IAbonement[]>}
     */
    public getAbonementList(parameters: {
        type: number;
    }): Observable<IAbonement[]> {
        const type = parameters.type;
        //TomCat    return this.httpNew.get(`/api/patient/${this.auth.patientId}/status/${type}/abonements` ,  this.auth.token)
        return this.httpNew.get(
            `/abonements/patient?status=${type}`,
            this.auth.token
        );
    }

    /**
     * Карта указанного абонемента
     * @param parameters
     * @returns {Observable<ICardAbonement[]>}
     */
    public getAbonementServices(parameters: {
        abonementId: number;
    }): Observable<IServicesAbonement[]> {
        const abonementId = parameters.abonementId;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/services`,
            this.auth.token
        );
    }

    /**
     * Карта указанного абонемента
     * @param parameters
     * @returns {Observable<ICardAbonement[]>}
     */
    public getAbonementCard(parameters: {
        abonementId: number;
    }): Observable<ICardAbonement[]> {
        const abonementId = parameters.abonementId;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/card`,
            this.auth.token
        );
    }
    /**
     * Карта указанного абонемента
     * @param parameters
     * @returns {Observable<ICardAbonement[]>}
     */
    public getAbonementBalans(
        abonementId: number
    ): Observable<IAbonementBalanse> {
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/balance`,
            this.auth.token
        );
    }
    /*
  Получить шапку по Абонементу
   */
    public getAbonementName(parameters: {
        abonementId: number;
    }): Observable<string> {
        const abonementId = parameters.abonementId;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/name`,
            this.auth.token
        );
    }

    /**
     * График платежей указанного абонемента
     * @param parameters
     * @returns {Observable<ICardAbonement>}
     */
    public getAbonementPaid(parameters: {
        abonementId: number;
    }): Observable<IPaidAbonement[]> {
        const abonementId = parameters.abonementId;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/paidplan`,
            this.auth.token
        );
    }

    /**
     * Cписок групп услуг указанного абонемента
     * @param parameters
     * @returns {Observable<IGroupServiceAbonement[]>}
     */
    public getGroupServiceAbonement(parameters: {
        abonementId: number;
    }): Observable<IGroupServiceAbonement[]> {
        const abonementId = parameters.abonementId;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/groups`,
            this.auth.token
        );
    }

    /**
     * Перечень услуг входящих в переданную группу указанного абонемента
     * @param parameters
     * @returns {Observable<IServiceAbonement[]>}
     */
    public getServiceAbonement(parameters: {
        abonementId: number;
        groupId: number;
    }): Observable<IServiceAbonement[]> {
        const { abonementId, groupId } = parameters;
        return this.httpNew.get(
            `/api/patient/${this.auth.patientId}/abonement/${abonementId}/group/${groupId}/services`,
            this.auth.token
        );
    }
}
