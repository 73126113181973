import { Component, OnInit } from '@angular/core';
import {Size} from "../../services/size";

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss']
})
export class LabsComponent implements OnInit {
  public deviceType:string = '';

  constructor(public size: Size) {
    this.deviceType = this.size.getDeviceType();
  }

  ngOnInit(): void {
    this.deviceType = this.size.getDeviceType();
  }

}
