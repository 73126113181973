<ng-container [ngSwitch]="deviceType">
    <ng-template [ngSwitchCase]="size.pc">
        <app-rec-pc></app-rec-pc>
    </ng-template>

    <ng-template [ngSwitchCase]="size.mobile">
        <app-rec-mobile></app-rec-mobile>
    </ng-template>

    <ng-template ngSwitchDefault>
        <app-rec-mobile></app-rec-mobile>
    </ng-template>
</ng-container>


