<!-- Файл представления компонента записей пользователя на главной странице мобильной версии -->
<p class="cls-blok-talon-header">Мои ближайшие записи</p>
<div class="talons-body near-records">
    <div
        *ngIf="talonsTime.length > 0; then showListTalon; else showNotTalon"
    ></div>

    <ng-template #showListTalon>
        <div
            *ngFor="let talon of talonsTime; let i = index"
            class="cls-blok-talon"
            [ngClass]="{ 'cls-blok-day-not': i > 0 }"
            (click)="navigateToHistory()"
        >
            <div>
                <span class="cls-blok-talon-txt">{{ talon.tekDay }}, </span>
                <span class="cls-blok-talon-dn">{{ talon.Weekday }}</span>
            </div>
            <div>
                <span
                    *ngFor="let time of talon.times"
                    class="cls-blok-talon-time"
                    >{{ time.time }}</span
                >
            </div>
        </div>
    </ng-template>

    <ng-template #showNotTalon>
        <div class="cls-blok-talon">
            <div>
                <span class="cls-blok-talon-txt">Нет предстоящих записей</span>
                <span class="cls-blok-talon-dn"></span>
            </div>
        </div>
    </ng-template>
</div>
