<div
    class="loading-group"
    [@showOpacityAnimation]="animState"
    (@showOpacityAnimation.done)="whenAnimate($event)"
>
    <div *ngIf="loading" class="loading loading-auth">
        <img src="./assets/loading.gif" />
    </div>

    <form class="form-signin auth-form" [formGroup]="frm">
        <div *ngIf="showDoglist; then showListdog; else showAuthForm"></div>

        <ng-template #showListdog>
            <app-dog-list
                (onError)="onErrorDog($event)"
                [tmpToken]="tmpToken"
                [dogPREVIEW_REQUIRED]="dogPREVIEW_REQUIRED"
                (onEvent)="onEventDog($event)"
            ></app-dog-list>
        </ng-template>

        <ng-template #showAuthForm>
            <mat-form-field class="w-100">
                <input
                    id="asd"
                    [type]="'text'"
                    matInput
                    formControlName="username"
                    [readonly]="loading"
                    autofocus
                    (keyup.enter)="inputPassword.focus()"
                />
                <mat-label>Логин</mat-label>
                <mat-hint *ngIf="thisTest">Например, 001</mat-hint>
            </mat-form-field>

            <mat-form-field class="w-100" style="padding-top: 10px">
                <mat-label>Пароль</mat-label>
                <input
                    id="password1"
                    #inputPassword
                    [type]="hidePassword ? 'password' : 'text'"
                    matInput
                    formControlName="password"
                />
                <span
                    *ngIf="!hidePassword"
                    matSuffix
                    (click)="hidePassword = !hidePassword"
                >
                    <mat-icon svgIcon="visibility"></mat-icon>
                </span>

                <span
                    *ngIf="hidePassword"
                    matSuffix
                    (click)="hidePassword = !hidePassword"
                >
                    <mat-icon svgIcon="visibility_off"></mat-icon>
                </span>
                <mat-hint *ngIf="thisTest">Например, 001</mat-hint>
            </mat-form-field>

            <div class="cls-block-rec-save">
                <mat-checkbox class="cls-save-my" color="primary"
                    >Запомнить меня</mat-checkbox
                >
                <div *ngIf="isRecovery; then showRecovery"></div>
                <ng-template #showRecovery>
                    <a
                        href="./login/recovery"
                        class="cls-go-recovery"
                        title="Восстановление доступа к личному кабинету"
                        >Не помню пароль</a
                    >
                </ng-template>
            </div>

            <button
                class="w-100 btn btn-lg cls-btn cls-btn-auth"
                type="submit"
                [disabled]="loading || !frm.valid"
                (click)="login()"
            >
                Войти
            </button>
            <button
                class="w-100 btn btn-lg cls-btn cls-btn-esia"
                type="submit"
                [disabled]="loading"
                *ngIf="esia_link"
                (click)="loginEsia()"
            >
                <img src="./assets/img/svg/esia.svg" class="icon" />
                Войти через Госуслуги
            </button>

            <div class="cls-footer1" *ngIf="AccessReg">
                Первый раз в личном кабинете?
            </div>
            <div
                class="cls-footer2"
                (click)="goReg(1)"
                placeholder="Регистрацииия"
                *ngIf="AccessReg"
            >
                Регистрация
                <!-- <mat-icon
                    class="cls-icon-arrow-right"
                    svgIcon="arrow_right"
                ></mat-icon> -->
            </div>

            <div
                *ngIf="Auth_type == 2"
                class="cls-footer2"
                (click)="goReg(3)"
                placeholder="Регистрацииия"
            >
                Вход по телефону
            </div>
        </ng-template>
    </form>
</div>
