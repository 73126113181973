import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PayDetailsDialogComponent} from "../pays-details-dialog/pay-details-dialog.component";
import {IDateRange} from "../../../../interfaces/date-range.interface";
import * as moment from "moment";

@Component({
    selector: 'app-pay-period-dialog',
    templateUrl: './pay-period-dialog.component.html',
    styleUrls: ['./pay-period-dialog.component.scss']
})
export class PayPeriodDialogComponent implements OnInit {

    periodText = '';
    newDate: IDateRange;

    constructor(
        public dialogRef: MatDialogRef<PayDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { dateRange: IDateRange }
    ) {
        this.newDate = data.dateRange;
        this.changePeriod(this.newDate);
        
        this.periodText = this.getPeriodText(this.newDate);  
    }

    ngOnInit(): void {

    }

    /** Изменение периода для вывода записей*/
    changePeriod(dt: IDateRange): void {
        this.newDate = dt;
        this.formatDateToString(this.newDate);
        this.periodText = this.getPeriodText(this.newDate);
    }

    /** Сброс выбранного периода */
    resetPeriod() {
        this.newDate = this.data.dateRange;
        this.periodText = this.getPeriodText(this.newDate);
    }

    /** Получнеи текста из периода */
    getPeriodText(dt: IDateRange): string {
        let periodText = '';
        let isSelectedOneDay = dt.str_begin == dt.str_end;
        let today = new Date();
        let oneYear = new Date(today);
        oneYear.setDate(today.getDate()- 365);
        oneYear.toLocaleDateString();
        if (isSelectedOneDay) {
            if(dt.dt_begin == null && dt.dt_end == null)
                periodText = 'Выберите день или период';
            else if (dt.dt_begin?.toDateString() == new Date().toDateString())
                periodText = moment(new Date(dt.dt_begin)).format('D MMMM YYYY');
            else if (dt.str_begin)
                periodText = dt.str_begin;
        } else if (dt.str_begin && dt.str_end) {
            periodText = `${dt.str_begin} - ${dt.str_end}`;
        } else if (dt.str_begin) {
            periodText = dt.str_begin;
        } else if (dt.str_end) {
            periodText = dt.str_end;
        } else if (dt.dt_begin == oneYear && dt.str_end == today.toLocaleDateString()) {
            
        }
        return periodText;
    }

    /** Создание строковйх представлений для переданного значения */
    formatDateToString(dt: IDateRange) {
        if (dt.dt_begin) {
            dt.str_begin = moment(new Date(dt.dt_begin)).format('D MMMM YYYY');
        }
        if (dt.dt_end) {
            dt.str_end = moment(new Date(dt.dt_end)).format('D MMMM YYYY');
        }
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */

    
    btnFilterByDay(startOffset: number | null = 0, endOffset: number | null = 0) {
        if(startOffset !== null && endOffset !== null){
            this.newDate.dt_begin = new Date();
            this.newDate.dt_begin.setDate(this.newDate.dt_begin.getDate() - startOffset);
            this.newDate.dt_end = new Date();
            this.newDate.dt_end.setDate(this.newDate.dt_end.getDate() - endOffset);
            this.changePeriod({dt_begin: this.newDate.dt_begin, dt_end: this.newDate.dt_end});
        }
        else{
            this.changePeriod({dt_begin: null, dt_end: null});
        }

       
        // this.apply();
       
    }
    
    
    /** Закрытие окна с передачей результата */
    apply() {


        if (this.newDate) {
            this.dialogRef.close({date: this.newDate})
        }
    }

    /** Закрытие окна */
    close() {
        this.dialogRef.close()
    }
}
