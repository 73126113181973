import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { AlertService } from 'src/lib/ariadna/alert';

@Component({
    selector: 'app-change-pw',
    templateUrl: './change-pw.component.html',
    styleUrls: ['./change-pw.component.scss'],
})
export class ChangePwComponent implements OnInit {
    public frm!: FormGroup;
    public loading = false;

    hidePasswordOLD = true; // Показывать пароль старый
    hidePasswordNEW = true; // Показывать пароль новый
    hidePasswordNEW2 = true; // Показывать пароль новый2
    exitForm = false; // Форма выхода

    constructor(
        public dialogRef: MatDialogRef<ChangePwComponent>,
        private auth: AuthService,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true; // запрет закрытия окна (только командами)
    }

    ngOnInit(): void {
        this.initialize();
    }

    /* Инициализация при создании компанента */
    private initialize(): void {
        this.frm = new FormGroup({
            oldPW: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            newPW: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            new2PW: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
        });
        this.exitForm = false;
    }

    /* Проверка возможности отправки */
    testChangePW(): boolean {
        let f = false;
        let newPW = this.frm.get('newPW');
        let newPW2 = this.frm.get('new2PW');
        /*
    if (newPW && newPW2){
      
    }
*/
        if (this.loading == false) {
            if (this.frm.valid == true) {
                if (newPW && newPW2) {
                    if (newPW.value == newPW2.value) {
                        f = true;
                    }
                }
            }
        }
        return f;
    }

    exit() {
        this.auth.logout();
        this.dialogRef.close();
    }

    goToChange() {
        this.loading = true;
        let oldPW = this.frm.get('oldPW')?.value;
        let newPW = this.frm.get('newPW')?.value;

        if (oldPW === newPW) {
            this.alertService.error(
                'Новый пароль должен отличаться от предыдущего!',
                5000
            );
            this.loading = false;
            return;
        }

        if (oldPW && newPW) {
            this.auth.changepw(oldPW, newPW).subscribe(
                (info) => {
                    this.loading = false;
                    if (!info)
                        this.alertService.error(
                            'Неверно указан действующий пароль'
                        );

                    if (info.err_text) this.alertService.error(info.err_text);

                    if (info.err_code === 0) {
                        this.exitForm = true;
                        this.alertService.info(
                            'Пароль успешно изменен. Пожалуйста, авторизуйтесь с новым паролем.',
                            6000
                        );
                        this.dialogRef.close();
                        this.exit();
                    }
                },
                (err) => {
                    this.alertService.error(
                        'Неверно указан действующий пароль'
                    );
                    this.dialogRef.close();
                    this.loading = false;
                }
            );
        }
    }
}
