<div *ngIf="control; else controlDoseNotExist" class="protocol-control">
    <div [ngSwitch]="control.type">
        <div *ngSwitchCase="'text'">
            <mat-form-field>
                <mat-label>{{ control.label }}</mat-label>
                <input
                    matInput
                    [type]="
                        control.data_type === 5 ||
                        control.data_type === 6 ||
                        control.data_type === 7
                            ? 'number'
                            : 'text'
                    "
                    [value]="textValue"
                    [required]="control.isRequired"
                    (input)="onChangeHandler($event)"
                    (focus)="onTouchHandler()"
                />
            </mat-form-field>
        </div>
        <div *ngSwitchCase="'select'">
            <app-select
                class="select"
                theme="flat"
                [label]="control.label"
                [value]="selectValue"
                [required]="control.isRequired"
                [options]="selectOptions"
                (onSelectionChange)="onListSelectionHandler($event)"
            >
            </app-select>
        </div>
        <div *ngSwitchCase="'multi-select'">
            <chip-list
                [label]="control.label"
                [options]="chipsOptions"
                [value]="chipValue"
                [required]="control.isRequired"
                [multi-select]="control.isMultiSelect"
                (onSelectionChange)="onEnumSelectionHandler($event)"
            >
            </chip-list>
        </div>
        <div *ngSwitchCase="'date'">
            <mat-form-field>
                <mat-label>{{ control.label }}</mat-label>
                <input
                    matInput
                    type="date"
                    [value]="textValue"
                    [required]="control.isRequired"
                    (input)="onChangeHandler($event)"
                    (focus)="onTouchHandler()"
                />
            </mat-form-field>
        </div>
        <div *ngSwitchCase="'delimiter'">
            <text theme="delimiter">{{ control.label }}</text>
        </div>
        <div *ngSwitchDefault>Тип отсутствует</div>
    </div>
</div>

<ng-template #controlDoseNotExist>
    <div class="controlDoseNotExist">Поле отсутствует.</div>
</ng-template>
