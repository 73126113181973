import { Injectable } from '@angular/core';
import {HttpNewService} from "./application/http-new.service";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {IHistoryDiag, IHistoryEventList, IHistoryVisit} from "../interfaces/history.interface";
import {IService, IServiceSize} from "../interfaces/services";
import {dateToText} from "../components/application/global.function";
import {IResearchFileList} from "../interfaces/laboratory-services.interface";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private httpNew: HttpNewService, private auth: AuthService) { }

  /**
   * Список событий истории за указанный промежуток времени
   * @param parameters
   * @returns {Observable<IHistoryEventList[]>}
   */
  public getHistoryEventList(parameters: { begin: string, end: string }): Observable<IHistoryEventList[]> {
    const {begin, end} = parameters;
    const url = '/api/patient/' + this.auth.patientId + '/history/event?beginDate=' + begin + '&endDate=' + end;
    return this.httpNew.get(url, this.auth.token);
  }

  /**
   * Получить событие по его типу и идентификатору
   * @param parameters
   * @returns {Observable<IHistoryVisit[] | IHistoryDiag[]>}
   */
  public getHistoryItem(parameters: { id: number, type: string }): Observable<IHistoryVisit | IHistoryDiag> {
    const {id, type} = parameters;
    const url = '/api/patient/' + this.auth.patientId + '/history/event/' + id + '/record?type=' + type;
    return this.httpNew.get(url, this.auth.token);
  }


  /**
   * Получить список услуг
   * @param orderBy = acs / desc
   */

  public getServices( beginDate: Date | null, endDate: Date | null, pStart?: number , pEnd?: number, orderBy: string = 'acs'): Observable<IService[]> {
    const param = new Array();

    param.push('orderby=' + orderBy);

    if (beginDate) {
      param.push('beginDate=' + dateToText(new Date(beginDate)));
    }

    if (endDate) {
      param.push('endDate=' + dateToText(new Date(endDate)));
    }
    if (pStart && pEnd) {
      param.push('start=' + pStart);
      param.push('end=' + pEnd);
    }

    const pr = param.join('&');
//        8 16:26 http://localhost:8090/pa-web/api/service/patient/262535?beginDate=2008-01-01&endDate=2019-04-30"
//TomCat    const url = '/api/service/patient/' + this.auth.patientId + '?' + pr;
    const url = '/service/patient?' + pr;
    return this.httpNew.get(url, this.auth.token);
  }

  /**
   * Получить список услуг - количество записей
   */

  public getServicesSize( beginDate: Date | null, endDate: Date | null ): Observable<IServiceSize> {
    const param = new Array();

    if (beginDate) {
      param.push('beginDate=' + dateToText(new Date(beginDate)));
    }

    if (endDate) {
      param.push('endDate=' + dateToText(new Date(endDate)));
    }

    const pr = param.join('&');
//        8 16:26 http://localhost:8090/pa-web/api/service/patient/262535?beginDate=2008-01-01&endDate=2019-04-30"
//TomCat    const url = '/api/service/patient/' + this.auth.patientId + '?' + pr;
    const url = '/service/patient/size?' + pr;
    return this.httpNew.get(url, this.auth.token);
  }

  /**
   * Получить по событию список файлов
   */
  public getHistoryFileList( eventId: number, eventType: string ): Observable<IResearchFileList[]> {
    return this.httpNew.get(`/api/history/event/${eventId}/type/${eventType}/files`, this.auth.token);
  }

}
