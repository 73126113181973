<div>
    <h1 mat-dialog-title class="talon-block-title talon-m">
        <div class="title-spec">Добавление документа</div>
        <mat-icon svgIcon="krest" (click)="closeExit()"></mat-icon>
    </h1>

    <div mat-dialog-content class="talon-content container-fluid loading-group">
        <div *ngIf="loadingDoc" class="loading">
            <img src="./assets/loading.gif" />
        </div>

        <form
            class="cls-change-form"
            [formGroup]="frm"
            [ngClass]="{ loader: loadingDoc }"
        >
            <mat-form-field appearance="fill">
                <mat-label>Тип документа</mat-label>
                <mat-select
                    (selectionChange)="changeTypeDoc($event)"
                    formControlName="selectType"
                >
                    <mat-option
                        *ngFor="let typeI of listTypeDoc"
                        [value]="typeI.keyid"
                    >
                        {{ typeI.text }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="custom-file mb-3">
                <!--        <input type="file" class="custom-file-input" id="validatedCustomFile" formControlName="file" (change)="upload(frm.controls['file'].value)" name="photo">-->
                <input
                    type="file"
                    accept="image/*"
                    class="custom-file-input"
                    id="validatedCustomFile"
                    formControlName="file"
                    name="photo"
                    (change)="selectFiles($event)"
                />
                <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    *ngIf="frm.controls['file'].value"
                    >{{ frm.controls["file"].value }}</label
                >
                <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    *ngIf="!frm.controls['file'].value"
                    >Выберите файл</label
                >

                <!--        <div class="invalid-feedback">Example invalid custom file feedback</div>-->
            </div>

            <div class="cls-add-doc-footer">
                <button
                    class="btn cls-btn-second cls-btn-filter"
                    [disabled]="!frm.valid"
                >
                    Добавить
                </button>
            </div>
        </form>

        <!--
    <form action="http://10.0.0.204:4005/file/upload" method="POST" enctype="multipart/form-data">
      <input type="file" name="photo">
      <input type="submit">
    </form>
-->
    </div>
</div>
