import {Injectable} from '@angular/core';
import {ITerritory} from './Territory.interface';
import {Observable} from 'rxjs';
import {ITalon, ITalonGroup} from './Talon.interface';
import {ISpeciality} from './Speciality.interface';
import {IDoctor, IDoctorInfos} from './Doctor.interface';
import {ICityFilial, IFilial} from './CityToFilial.interface';
import {HttpNewService} from '../application/http-new.service';
import {Ihdoctor} from './hdoctor.interface';
import {ICountDay} from './DocTalonsDay.interface';
import {IHttpRes} from '../application/IHttpRes.interface';
import {ISrv, ISrvInfoParam} from './srv.interface';
import {AuthService} from "../auth.service";

@Injectable()
export class RecDoctorService {
    private listFilials: IFilial[] | null;  // Список филиалов - КЕШ
    private listDoctors: IDoctor[] | null;  // Список докторов - КЕШ

    constructor(private httpNew: HttpNewService, private auth: AuthService) {
        this.listFilials = null;
        this.listDoctors = null;
    }

    /* Список городов */
    public getCity(): Observable<ICityFilial[]> {
        return this.httpNew.get('/api/dict/city', this.auth.token);
    };

    /* Список Филиалов  */
    public getFilial(): Observable<IFilial[]> {
        if (!this.listFilials) {
            return new Observable((observer) => {
                this.httpNew.get('/api/filial', this.auth.token)
                    .subscribe(
                        result => {
                            this.listFilials = result;
                            observer.next(result);
                            observer.complete();
                        },
                        err => {
                            observer.error(err);
                        }
                    );
            });
        } else {
            return new Observable((observer) => {
                // @ts-ignore
               observer.next(this.listFilials);
               observer.complete();
            })
        }
    };

// /api/apptdoctor/{doctorid}/info
    /* Информация о враче (вопрос, ответ)
    *  doctorid - идентификатор доктора;
    **/
    public getDoctorInfo(doctorid: number): Observable<IDoctorInfos[]> {
        const url = `/api/apptdoctor/${doctorid}/info`;
        return new Observable((observer) => {
            this.httpNew.get(url, this.auth.token)
                .subscribe(
                    result => {
//                        this.listDoctors = result;
                        observer.next(result);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                    }
                );
        });
    }

    /* Список докторов
    *  filialid - идентификатор или идентификаторы филиалов;
    **/
    public getDoctors(filialid: string | null, specid: number | null): Observable<IDoctor[]> {
        if (!this.listDoctors) {
            let _filialid = '';
            if ((filialid) && (filialid.length > 0)) { // Есть филиал
                _filialid = '?filialid=' + filialid;
            }

            let _specid = '';
            if (specid) {
                _specid = 'specid=' + specid;
            }

            const url = '/api/apptdoctor' + _filialid; // /api/apptdoctor?filialid=1,7,14&specid=26180
            return new Observable((observer) => {
                this.httpNew.get(url, this.auth.token)
                    .subscribe(
                        result => {
                            this.listDoctors = result;
                            observer.next(result);
                            observer.complete();
                        },
                        err => {
                            observer.error(err);
                        }
                    );
            });
        } else {
            return new Observable((observer) => {
                // @ts-ignore
              observer.next(this.listDoctors);
              observer.complete();
            })
        }

    }

    /* Количество талонов в каждый день
    *  filialid - идентификатор или идентификаторы филиалов;
    * specid - идентификатор специальности;
    * doctorid - идентификатор врача;
    * srvid - идентификатор или идентификаторы услуги;
    * -- paidmode - платный прием (1 - платный прием, (0, null) - бесплатный прием);
    * begin_date - дата и время начала поиска талонов (формат yyyy-MM-dd’T’HH:mm:ss);
    * end_date - дата и время конца поиска талонов (формат yyyy-MM-dd’T’HH:mm:ss).
    **/
    public getСountToDay$(filialid: string | null
        , specid: number | null
        , doctorid: number | null
        , beginDate: string | null
        , endDate: string | null): Observable<ICountDay[]> {
        const param = new Array();
        if (filialid != null) {
            param.push('filialid=' + filialid);
        }
        if (specid != null) {
            param.push('specid=' + specid);
        }
        if (doctorid != null) {
            param.push('doctorid=' + doctorid);
        }
        if (beginDate != null) {
            param.push('begin_date=' + beginDate);
        }
        if (endDate != null) {
            param.push('end_date=' + endDate);
        }

        let url = '';
        if (param.length > 0) {
            const ss = param.join('&');
            url = '/api/rnumb/count?' + ss;
        } else {
            url = '/api/rnumb/count';
        }
        return this.httpNew.get(url, this.auth.token);
    }

/* Список услуг врача */
    public getSrvList(doctorid: number | null, specid: number | null , filialid: string | null): Observable<ISrv[]> {
        const param = new Array();
        if (filialid != null) {
            param.push('filialid=' + filialid);
        }
        if (specid != null) {
            param.push('specid=' + specid);
        }
        if (doctorid != null) {
            param.push('doctorid=' + doctorid);
        }

        let url = '';
        if (param.length > 0) {
            const ss = param.join('&');
            url = '/api/srv?' + ss;
        } else {
            url = '/api/srv';
        }
        return this.httpNew.get(url, this.auth.token);
    }

/* Информация по услуге */
    public getSrvDesc(params: ISrvInfoParam): Observable<any> {
        const param = new Array();
        if (params.srvid != null) {
            param.push('srvid=' + params.srvid);
        }
        if (params.depid != null) {
            param.push('depid=' + params.depid);
        }
        if (params.specid != null) {
            param.push('specid=' + params.specid);
        }
        if (params.doctorid != null) {
            param.push('doctorid=' + params.doctorid);
        }

        let url = '';
        if (param.length > 0) {
            const ss = param.join('&');
            url = '/api/srv/desc?' + ss;
        } else {
            url = '/api/srv';
        }
        return this.httpNew.get(url, this.auth.token);
    }

/* Захват талона  */
/*
    public lockTalon(numTalon: number): Observable<any> {
*/
    public lockTalon(talon: ITalon): Observable<any> {
// 
        if (talon.isInterval && talon.isInterval === 1 ) { // Интервальный, нужно создать
            return new Observable((observer) => {
                // @ts-ignore
              this.create_interval_rnumb(talon.intervalId, talon.beginDate, talon.endDate)
                    .subscribe(
                      // @ts-ignore
                        result => {
// 
// 
                            if (result.id && result.id > 0 ) {
                                return this.httpNew.post('/api/rnumb/' + result.id + '/lock', {id: result.id} , this.auth.token)
                                    .subscribe(
                                        resultLock => {
//                                            resultLock.id = result.id; // подставляем созданный
                                            observer.next({id: result.id});
                                            observer.complete();
                                        },
                                        errLock => {observer.error(errLock)}
                                    );
                            } else {
                                observer.error(result);
                                observer.complete();
                            }
                        },
                        err => {
                            observer.error(err);
                        }
                    );
            });

        } else {
            return this.httpNew.post('/api/rnumb/' + talon.id + '/lock'
                , {id: talon.id}
                , this.auth.token);
        };
    };

/* Освобождение талона  */
    public unLockTalon(numTalon: number): Observable<any> {
        return this.httpNew.post('/api/rnumb/' + numTalon + '/unlock'
            , {id: numTalon}
            , this.auth.token);
    };

/* Запись на номерок
* numTalon - id талона
* srvIds - список услуг */
    public appointment(numTalon: number, srvIds: number[] | null): Observable<any> {
        if (srvIds && srvIds.length > 0) { // с услугой
            return this.httpNew.post('/api/rnumb/' + numTalon + '/appointment'
                , {rnumbId: numTalon, srvIds: srvIds}
                , this.auth.token);
        } else { // Без услуг
            return this.httpNew.post('/api/rnumb/' + numTalon + '/appointment'
                , {rnumbId: numTalon}
                , this.auth.token);
        }
    }

/* Создание платежа */
    public createPayment(numTalon: number, srvIds: number[] | null): Observable<any> {
        if (srvIds && srvIds.length > 0) { // с услугой
            return this.httpNew.post('/api/rnumb/' + numTalon + '/payment'
                , {rnumbId: numTalon, srvIds: srvIds}
                , this.auth.token);
        } else { // Без услуг
            return this.httpNew.post('/api/rnumb/' + numTalon + '/payment'
                , {rnumbId: numTalon}
                , this.auth.token);
        }
    }

/* Отмена азаписи */
    public cancellAppointment(numTalon: number): Observable<any> {
        return this.httpNew.post('/api/rnumb/' + numTalon + '/cancellappointment'
            , {rnumbId: numTalon}
            , this.auth.token);
    }


    /*
    Список талонов сгруппированных
        filialid - список филиалов
        specid - специальность
        begin_date - дата начала
        end_date - дата окончания

     */
    public getTalonGroup(filialid: string | null
        , specid: number | null
        , doctorid: number | null
        , beginDate: string | null
        , endDate: string | null): Observable<ITalonGroup[]> {
        const param = new Array();

        if (filialid != null) {
            param.push('filialid=' + filialid);
        }
        if (specid != null) {
            param.push('specid=' + specid);
        }
        if (doctorid != null) {
            param.push('doctorid=' + doctorid);
        }
        if (beginDate != null) {
            param.push('begin_date=' + beginDate);
        }
        if (endDate != null) {
            param.push('end_date=' + endDate);
        }

        let url = '';
        if (param.length > 0) {
            const ss = param.join('&');
            url = '/api/rnumb/group/count?' + ss;
        } else {
            url = '/api/rnumb/group/count';
        }
        return this.httpNew.get(url, this.auth.token);
    }

/*
Список талонов
    filialid - список филиалов
    specid - специальность
    begin_date - дата начала
    end_date - дата окончания

 */
    public getTalon(filialid: string | null
                    , specid: number | null
                    , doctorid: number | null
                    , beginDate: string | null
                    , endDate: string | null): Observable<ITalon[]> {
        const param = new Array();

        if (filialid != null) {
            param.push('filialid=' + filialid);
        }
        if (specid != null) {
            param.push('specid=' + specid);
        }
        if (doctorid != null) {
            param.push('doctorid=' + doctorid);
        }
        if (beginDate != null) {
            param.push('begin_date=' + beginDate);
        }
        if (endDate != null) {
            param.push('end_date=' + endDate);
        }

        let url = '';
        if (param.length > 0) {
            const ss = param.join('&');
            url = '/api/rnumb?' + ss;
        } else {
            url = '/api/rnumb';
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /**
     * Создание интервального номерка
     * тело запроса:
     * {
     * "intervalId":123,
     * "beginDate":"2020-04-03T15:00:00",
     * "endDate":"2020-04-03T16:00:00"
     * }
     * @returns {Observable<Object>}
     public create_interval_rnumb(pintervalId: number, pbeginDate: string, pendDate: string): Observable<IHttpRes> {

     */
    public create_interval_rnumb(pintervalId: number | null, pbeginDate: string | null, pendDate: string | null): Observable<IHttpRes> {
        return this.httpNew.post(`/api/interval/${pintervalId}/rnumb/`
            , {intervalId: pintervalId
                , beginDate : pbeginDate
                , endDate: pendDate}
            , this.auth.token);

    }


    /* Список специальностей
    filialid - идентификатор или идентификаторы филиалов;
     */
    public getSpeciality(filialid: string | null): Observable<ISpeciality[]> {
        let _filialid = '';
        if ((filialid) && (filialid.length > 0)) { // Есть филиал
            _filialid = '?filialid=' + filialid;
        }

        const url = '/api/speciality' + _filialid; // api/speciality?filialid=1,7,14
        return this.httpNew.get(url, this.auth.token);
    }

    /*
    Пролучение истории талонов по клиенту
     */
     public getDateHistory(): Observable<Ihdoctor[]> {
//TomCat       const url = '/api/rnumb/info';
         const url = '/rnumb/info';
         return this.httpNew.get(url, this.auth.token);
    }

    /*  Методы записи     ITerritory*/

    public getTerritory(): Observable<ITerritory[]> {
        const url = '/api/dict/territory';
        return this.httpNew.get(url, this.auth.token);
    }


}

