<div class="wrapper">
    <text
        class="label"
        *ngIf="label"
        theme="label"
        [required]="required">{{label}}</text>
    <div
        name="chip-list"
        class="chip-list"
        *ngIf="options">

        <chip
            class="chip"
            *ngFor="let opt of options"
            [value]="opt.value"
            [text]="opt.text"
            [selected]="opt | isChipSelected : value"
            (onSelectionChange)="onSelectionChangeHendler($event)">
        </chip>
    </div>
</div>