<div
    class="text-input"
    [ngClass]="{
        'text-input_not-empty': value,
        'text-input_no-placeholder': placeholder.length == 0,
        'text-input_error': errorMsg.length !== 0,
        'text-input_focused': inputFocused
    }"
>
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'text'">
            <input
                class="text-input__input"
                [(ngModel)]="value"
                [type]="'text'"
                [disabled]="isDisabled"
                [placeholder]="placeholder"
                [maxlength]="maxLength"
                [mask]="mask ?? ''"
                [prefix]="prefix"
                [dropSpecialCharacters]="dropSpecialCharacters"
                [clearIfNotMatch]="clearIfNotMatch"
                (focus)="focusInput()"
                (change)="changeInput($event)"
                (blur)="blurInput()"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
            <div class="date-input">
                <input
                    class="text-input__input"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    mask="00.00.0000"
                    [placeholder]="placeholder"
                    [value]="value | date : 'dd.MM.yyyy'"
                    [disabled]="isDisabled"
                    (focus)="focusInput()"
                    (change)="changeDateInput($event)"
                    (blur)="blurInput()"
                    maxlength="10"
                />

                <div class="date-input__calendar-button calendar-button">
                    <mat-icon
                        class="calendar-button__icon"
                        svgIcon="calendar"
                    ></mat-icon>
                    <input
                        type="date"
                        class="calendar-button__input"
                        [(ngModel)]="value"
                        [max]="maxDate"
                        [min]="minDate"
                        (change)="changeInput($event)"
                    />
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'numeric'">
            <input
                class="text-input__input"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="value"
                [type]="'text'"
                [disabled]="isDisabled"
                [placeholder]="placeholder"
                (change)="changeInput($event)"
                (focus)="focusInput()"
                (blur)="blurInput()"
            />
        </ng-container>
    </ng-container>
</div>
