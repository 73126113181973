<h1
    mat-dialog-title
    style="
        min-height: 61px;
        min-width: 300px;
        box-shadow: 0px 7px 7px 0px rgb(173 189 209);
        padding-top: 8px;
        font-size: 16px;
        margin: 0px;
    "
>
    <div class="cls-icon-krest" *ngIf="!exitForm">
        <mat-icon svgIcon="krest" [mat-dialog-close]="false"></mat-icon>
    </div>

    <span class="cls-modal-block-header">Создание пароля</span>
</h1>

<div mat-dialog-content>
    <div class="cls-create" [ngClass]="{ loader: loading }">
        <ng-container
            *ngIf="!exitForm; then showForm; else showFormExit"
        ></ng-container>

        <ng-template #showForm>
            <div>
                Логин и пароль будут отправлены вам на почту
                <h1>{{ patient.email }}</h1>
            </div>
        </ng-template>

        <ng-template #showFormExit>
            <div class="contentExit">Пароль успешно изменен</div>
        </ng-template>

        <button
            class="btn btn-lg w-100 cls-btn-second"
            *ngIf="!exitForm"
            type="submit"
            (click)="goToCreate()"
            style="margin-bottom: 20px; margin-top: 50px"
        >
            Отправить
        </button>

        <button
            class="btn btn-lg w-100 cls-btn-second cls-btn-send"
            *ngIf="exitForm"
            type="submit"
            (click)="exit()"
            style="margin-bottom: 20px; margin-top: 50px"
        >
            Войти
        </button>
    </div>
</div>
