<!-- Файл представления компонента главной страницы мобильной версии -->
<div class="cls-home">
    <div class="cls-block">
        <app-block-talons class="app-block-talons"></app-block-talons>
        <app-block-promo class="app-block-promo"></app-block-promo>
    </div>

    <div class="cls-panels">
        <div
            *ngFor="let panel of panelsMenu"
            class="cls-panel-menu"
            [routerLink]="panel.url"
        >
            <img
                class="panel__image"
                src="./assets/img/svg/home/{{ panel.img }}"
                alt=""
            />
            <span class="panel__text">{{ panel.name }}</span>
        </div>
    </div>
</div>
