import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpNewService} from "./application/http-new.service";

@Injectable({
  providedIn: 'root'
})
export class StatService {
/* Сервис статистики */
  constructor(private httpNew: HttpNewService) { }

  /**
   * Фиксируем статистику
   * @param parameters
   */

  public setStatLog(param: string): Observable<any> {
    let url = '/stat?key=';
    return this.httpNew.get(url + param, null);
  }

}
