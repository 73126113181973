import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Protocol } from 'src/app/interfaces/protocol';

@Component({
    selector: 'protocol-form',
    templateUrl: 'protocol-form.component.html',
    styleUrls: ['./protocol-form.component.scss']
})

export class ProtocolFormComponent implements OnInit {

    @Input() protocol: Protocol = new Protocol([]);
    @Output('onSubmit') onSubmitEmitter = new EventEmitter();
    constructor() { }

    ngOnInit() { }

    onSubmit(e: Event) {
        this.onSubmitEmitter.emit(e);
    }
}