import { Injectable, Inject, isDevMode } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { ConfigService } from '../application/config.service.';
import { Observable, Observer } from 'rxjs';
import { EventService } from '../event/event.service';
import { APP_BASE_HREF, Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private socket!: Socket;
    private observer!: Observer<any>;
    public baseURL: string;
    private path: string;
    private isExplicitUrl: boolean;

    constructor(
        private configS: ConfigService,
        private eventService: EventService,
        @Inject(APP_BASE_HREF) private baseHref: string
    ) {
        const hostBackend = configS.getValue('hostBackend') as string;
        this.isExplicitUrl = hostBackend.match(/http(s?):\/\//) ? true : false;
        this.baseURL = this.isExplicitUrl ? hostBackend : window.location.host;
        this.path = this.isExplicitUrl ? `api/v2/socket` : `api/api/v2/socket`;

        this._addDefaultListeners();
    }

    connect() {
        this.socket = io(this.baseURL, {
            path: this.baseHref + this.path,
            transports: ['websocket'],
            withCredentials: true,
        });

        console.log('socket url: ', this.baseURL + this.baseHref + this.path);

        this.socket.on('connect', () => console.log('socket connect'));
        this.socket.on('disconnect', () => console.log('socket disconnect'));
    }

    disconnect() {
        if (!this.socket) return;
        this.socket.disconnect();
    }

    on(ev: string, handler: ({ ...any }: any) => void) {
        this.socket.on(ev, handler);
    }

    public unlockTalon(rnumbID: number) {
        return this.socket.emit('unlock:talon', rnumbID);
    }

    private _addDefaultListeners() {
        this.eventService.on('auth:login', () => this.connect());
        this.eventService.on('auth:logout', () => this.disconnect());
    }
}
