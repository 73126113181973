
<div *ngIf="data.length>0; else waitBlock">

<div *ngFor="let resultH of data;let i = index" >
  <div class="fm-header">{{resultH.name}}</div>
  <div class="w-100 material-block">
    

    

    <div class="result-item-tr" *ngFor="let result of resultH.listResult ;let i = index">
      <div class="result-item-name">
        <span [ngbTooltip]="tipStatusInfo" placement="bottom" class="span-status-res">
          <img *ngIf="result.patLevel == '<' " src="./assets/img/lab/1.png" >
          <img *ngIf="result.patLevel == '!<' " src="./assets/img/lab/2.png" >
          <img *ngIf="result.patLevel == '<<!' " src="./assets/img/lab/3.png" >

          <img *ngIf="result.patLevel == '>' " src="./assets/img/lab/4.png" >
          <img *ngIf="result.patLevel == '>!' " src="./assets/img/lab/5.png" >
          <img *ngIf="result.patLevel == '!>>' " src="./assets/img/lab/6.png" >
        </span>

        <ng-template #tipStatusInfo let-greeting="greeting">
          <span *ngIf="result.patLevel == '<' ">
            <div>Результат ниже нормы</div>
          </span>
          <span *ngIf="result.patLevel == '!<' ">
            <div>Результат ниже нормы</div>
          </span>
          <span *ngIf="result.patLevel == '<<!' ">
            <div>Результат ниже нормы</div>
          </span>

          <span *ngIf="result.patLevel == '>' ">
            <div>Результат выше нормы</div>
          </span>
          <span *ngIf="result.patLevel == '>!' ">
            <div>Результат выше нормы</div>
          </span>
          <span *ngIf="result.patLevel == '!>>' ">
            <div>Результат выше нормы</div>
          </span>
        </ng-template>

          <span>{{result.name}}</span>
      </div>

      <div class="result-item__wrap">
        <div class="result-item-tec result-item__block">
          <span class="title">Текущий результат</span>
          <div class="result-item-tec--val">
          <span class="result-item-tec__val">
            {{result.val}} 
          </span>
          <span class="result-ed">{{result.valEd}}</span>
          </div>
        </div>
        <!-- <div class="result-item-tec">{{result.val}}<span class="result-ed">{{result.valEd}}</span></div> -->
        <div class="result-item-pred result-item__block">
          <span class="title">Предыдущий результат</span>
          <span class="result-last_res" *ngIf="result.last_res!=null;else emptyBlock">{{result.last_res}} <b>{{result.valEd}}</b></span>  
          <ng-template #emptyBlock>
            <span class="result-last_res-empty">—</span>
          </ng-template>
        </div>
      </div>

      
      <div class="result-item-line">
          <ng-container *ngIf="isShowLine(result?.val, result?.max, result?.min); then showLine else showTextNorm"></ng-container>

          <ng-template #showLine>
              <app-line-lab-res-mobile [setMin]="result?.min" [setMax]="result?.max" [setCurent]="result?.val"></app-line-lab-res-mobile>
          </ng-template>

          <ng-template #showTextNorm>
              <div style="color: #8499b4;padding-left: 5px;" *ngIf="result.norm">Норма: {{result?.norm}}</div>
          </ng-template>


      </div>
      
    </div>

  </div>

</div>
</div>

<ng-template #waitBlock>
  <div class="toJob-text">Ожидает обработки...</div>
</ng-template>

