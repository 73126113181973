import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'feed-header-period',
    templateUrl: 'feed-header-period.component.html',
    styleUrls: ['./feed-header-period.component.scss'],
})
export class FeedHeaderPeriodComponent implements OnInit {
    constructor() {}
    @Input() periodText = 'Выберите период';
    @Input() canBeClear = false;
    @Output() onClear = new EventEmitter();
    @Output() onClick = new EventEmitter();

    ngOnInit() {}

    onClickHandler() {
        this.onClick.emit();
    }

    onClearHandler() {
        this.onClear.emit();
    }
}
