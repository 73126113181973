import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ChipOption } from 'src/app/interfaces/chip';
import { SelectOption } from 'src/app/interfaces/select';
import { ProtocolControl } from 'src/app/interfaces/protocol';
import { relativeTimeThreshold } from 'moment';

@Component({
    selector: 'protocol-control',
    templateUrl: 'protocol-control.component.html',
    styleUrls: ['./protocol-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ProtocolControlComponent),
        },
    ],
})
export class ProtocolControlComponent implements OnInit, ControlValueAccessor {
    @Input() control?: ProtocolControl;
    // Устанавливает корректное значение в зависимости от типа данных
    @Input() set value(value: any) {
        this.textValue = typeof value === 'string' ? value : '';
        this.selectValue = typeof value === 'object' ? value : undefined;
        this.chipValue = Array.isArray(value) ? value : [];
    }
    @Input() set options(
        options: ChipOption[] | SelectOption[] | undefined | null
    ) {
        if (options) {
            this.chipsOptions =
                this.control?.type === 'multi-select'
                    ? (options as ChipOption[])
                    : [];
            this.selectOptions =
                this.control?.type === 'select'
                    ? (options as SelectOption[])
                    : [];
        }
    }
    @Input() required = false;

    onChange: any = () => {};

    chipsOptions: ChipOption[] = [];
    selectOptions: SelectOption[] = [];

    selectValue?: SelectOption;
    textValue = '';
    chipValue: ChipOption[] = [];

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    onTouched = (value: boolean) => {};
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.control!.item.form_item_val_text = value;
    }
    setDisabledState?(isDisabled: boolean): void {}

    constructor() {}

    ngOnInit() {}

    onTouchHandler() {
        this.onTouched(true);
    }

    onChangeHandler(e: Event) {
        const target = e.target as HTMLInputElement;
        this.control!.item.form_item_val_text = target.value;
        this.onChange(target.value);
    }

    onListSelectionHandler(e: SelectOption) {
        this.control!.item.form_item_val_id = Number(e.value);
        this.control!.item.form_result_value_text = e.text;
        this.onChange(e);
    }

    onEnumSelectionHandler(e: ChipOption[]) {
        this.control!.item.form_item_val_id = Number(e[0].value);
        this.onChange(e);
    }
}
