import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent implements OnInit {
    @Input() disabled = false;
    @Input() checked = false;
    @Input() text = '';
    @Input() note = '';
    @Input() value: any = '';

    @Output() onChangeEmitter = new EventEmitter<any>();
    constructor() { }

    ngOnInit() { }

    onChangeHandler(e: Event){
        const target = e.target as HTMLInputElement;
        this.onChangeEmitter.emit([this.value, target.checked]);
    }
}