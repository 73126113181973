import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ElementRef,
    ViewChild,
} from '@angular/core';
import {
    IDateRange,
    IDateRangeHeader,
    ImonthList,
} from '../../../../interfaces/date-range.interface';
import * as moment from 'moment';
import {
    expandedAnimation,
    rightPanelAnimation,
} from '../../../../animations/height.animation';
import { DateRangeService } from '../../../../services/date-range.service';
import { IPeriod } from '../../../../interfaces/period.interface';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

const tableLeftPrefix = 'dtLeft';
const tableRightPrefix = 'dtRight';

@Component({
    selector: 'app-mobile-date-range',
    templateUrl: './mobile-date-range.component.html',
    styleUrls: ['./mobile-date-range.component.scss'],
    animations: [expandedAnimation, rightPanelAnimation],
})
export class MobileDateRangeComponent implements OnInit {
    public dt: IDateRange;

    @Output() onChanged = new EventEmitter<IDateRange>(); // Смена даты
    @Input() // Дата начала
    set dtBegin(value: Date | null) {
        this.dt.dt_begin = value;
        this.setHeaderBtn();
        this.drs.CalendarySetDateRange(this.dt);
    }

    get dtBegin(): Date | null {
        return this.dt.dt_begin;
    }

    @Input() // Дата окончания
    set dtEnd(value: Date | null) {
        this.dt.dt_end = value;
        this.setHeaderBtn();
        this.drs.CalendarySetDateRange(this.dt);
    }

    get dtEnd(): Date | null {
        return this.dt.dt_end;
    }

    calendearyAnime = 'off'; // Показ календаря
    headerLeft: IDateRangeHeader = {
        mount: '',
        year: 0,
        dt: new Date(),
        left: true,
        right: true,
        prefix: tableLeftPrefix,
    };
    headerRight: IDateRangeHeader = {
        mount: '',
        year: 0,
        dt: new Date(),
        left: true,
        right: true,
        prefix: tableRightPrefix,
    };
    // быстрая дата
    fastMonths = moment.months();
    monthList: ImonthList[] = [];
    arrIndex: any | undefined;
    fastYears = Array.from({ length: 40 }).map((_, i) =>
        Number(
            moment()
                .subtract(20 - i, 'years')
                .format('YYYY')
        )
    );
    fastDateState: any = { year: null, month: null };

    paramPic: Date = new Date();

    dt_end_set: boolean = true;

    constructor(private drs: DateRangeService) {
        moment.locale('ru');
        //// быстрая дата
        let i = 0;
        this.fastMonths.forEach((item) => {
            i = i + 1;
            this.monthList.push({ id: i, name: item });
        });
        i = 0;
        this.fastMonths.forEach((item) => {
            i = i + 1;
            this.monthList.push({ id: i, name: item });
        });
        i = 0;
        this.fastMonths.forEach((item) => {
            i = i + 1;
            this.monthList.push({ id: i, name: item });
        });
        // this.monthList.push({id: 13, name: ''});

        //
        this.dt = { str_begin: '', str_end: '', dt_begin: null, dt_end: null };
        // Событие изменения даты
        drs.CalendaryOnSelectDay$().subscribe((n) => {
            // При заполненных обеих дат, скидываем и устанавливаем начальную
            if (this.dt.dt_begin && this.dt.dt_end) {
                // this.dt.dt_begin = n;
                // this.dt.dt_end = null;

                if (this.dt_end_set == true) {
                    this.dt.dt_begin = n;
                    this.dt.dt_end = null;
                    this.dt_end_set = false;
                } else {
                    if (this.dt.dt_begin > n) {
                        this.dt.dt_begin = n;
                        this.dt.dt_end = null;
                        this.dt_end_set = false;
                    } else {
                        this.dt.dt_end = n;
                        this.dt_end_set = true;
                    }
                }
            } else {
                // По идее, сюда теперь условие не будет уходить
                // а, всё-таки уходит....

                if (this.dt_end_set == true) {
                    this.dt.dt_begin = n;
                    this.dt.dt_end = null;
                    this.dt_end_set = false;
                } else {
                }
                /*if (this.dt.dt_begin == null) {
          this.dt.dt_begin = n;
        } else {
          if (this.dt.dt_begin > n) {
            this.dt.dt_begin = n;
            this.dt.dt_end = null;
            this.dt_end_set = false;
          } else {
            this.dt.dt_end = n;
            this.dt_end_set = true;
          }
        }*/
            }
            if (this.dt.dt_end == null && this.dt_end_set == false) {
                this.dt.dt_end = n;
            }

            this.setHeaderBtn();
            if (this.dt.dt_begin && this.dt.dt_end) {
                this.onChanged.emit({
                    dt_begin: this.dt.dt_begin,
                    dt_end: this.dt.dt_end,
                });
                this.calendearyAnime = 'off';
            }

            drs.CalendarySetDateRange(this.dt);
            return true;
        });
    }

    ngOnInit(): void {
        moment.locale('ru');
        const minDt = new Date();
        this.drs.CalendarySetParams({
            prefix: tableLeftPrefix,
            curentDate: minDt,
            startDate: this.dtBegin,
            endDate: this.dtEnd,
        });

        var lastDay = new Date(minDt.getFullYear(), minDt.getMonth() + 1, 1);

        this.drs.CalendarySetParams({
            prefix: tableRightPrefix,
            curentDate: lastDay,
            startDate: this.dtBegin,
            endDate: this.dtEnd,
        });
        this.setDateHeader(this.headerLeft, minDt, true, false);
        this.setDateHeader(this.headerRight, lastDay, false, true);

        // быстрая дата
        this.hhScroll$
            .asObservable()
            .pipe(debounceTime(100))
            .subscribe((v: any) => {
                let index = this.monthList.findIndex(({ id }) => id === v.id),
                    currScroll = this.hhContainerRef.nativeElement.scrollTop,
                    newScroll = currScroll,
                    diff = currScroll % 56;
                if (diff >= 28) {
                    newScroll = currScroll + diff;
                } else {
                    newScroll = currScroll - diff;
                }

                this.hhContainerRef.nativeElement.scrollTo({
                    // top: index * 56,
                    // top: 56*12 + index * 56,
                    top: newScroll,
                    behavior: 'smooth',
                });
                this.hhContainerRef.nativeElement.scrollTop =
                    56 * 12 + index * 56;
            });

        this.mmScroll$
            .asObservable()
            .pipe(debounceTime(100))
            .subscribe((v) => {
                let index = this.fastYears.indexOf(v);
                this.arrIndex = v;
                this.mmContainerRef.nativeElement.scrollTo({
                    top: index * 56,
                    behavior: 'smooth',
                });
            });
    }

    // Установка текста на кнопке
    setHeaderBtn() {
        if (this.dt.dt_begin) {
            this.dt.str_begin = moment(new Date(this.dt.dt_begin)).format(
                'D MMM YYYY'
            );
        } else {
            this.dt.str_begin = '';
        }

        if (this.dt.dt_end) {
            this.dt.str_end = moment(new Date(this.dt.dt_end)).format(
                'D MMM YYYY'
            );
        } else {
            this.dt.str_end = '';
        }
    }

    /* только числа */
    public numberOnly(event: any): boolean {
        const charCode = event.keyCode;
        // Проверка на ввод числа
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    test(ev: any, isBegin: boolean) {
        //формат строки
        let str: string = '';
        let dt: Date | null = null;
        if (isBegin) {
            str = this.dt.str_begin ? this.dt.str_begin : '';
        } else {
            str = this.dt.str_end ? this.dt.str_end : '';
        }
        if (str.length == 2 || str.length == 5) {
            str = str + '.';
        }

        if (str.length == 10) {
            // Возможно уже Date
            try {
                dt = new Date(
                    Number(str.substr(6, 4)),
                    Number(str.substr(3, 2)) - 1,
                    Number(str.substr(0, 2))
                );
            } catch (e) {
                console.error('Err convert DT=', e);
            }
        }

        if (dt) {
            // Проверка на год (кол месяцев иногда изменяют год)
            if (moment(dt).format('DD.MM.YYYY') != str) {
                dt = null;
            }
        }
        //
        if (isBegin) {
            this.dt.str_begin = str;
            this.dt.dt_begin = dt ? dt : null;
            if (dt) {
                // @ts-ignore
                document.getElementById('dr-end').focus();
            }
        } else {
            this.dt.str_end = str;
            this.dt.dt_end = dt ? dt : null;
        }
    }

    whenAnimateSearch(event: any) {
        // Окончание анимации
    }

    calendar() {
        if (this.calendearyAnime == 'on') {
            this.calendearyAnime = 'off';
        } else {
            this.calendearyAnime = 'on';
        }
    }

    setDateHeader(h: IDateRangeHeader, dt: Date, l: boolean, r: boolean) {
        h.dt = dt;
        h.mount = moment(h.dt).format('MMMM');
        h.year = h.dt.getFullYear();
        h.left = l;
        h.right = r;
    }

    mountPlusMinus(pr: IDateRangeHeader, znak: string) {
        // Добавить или уменьшить месяц

        switch (znak) {
            case '-': {
                if (pr.left) {
                    let lastDay = new Date(
                        pr.dt.getFullYear(),
                        pr.dt.getMonth() - 1,
                        1
                    ); // Уменьшаем на 1 месяц
                    this.setDateHeader(pr, lastDay, true, false);
                    this.drs.CalendarySetParams({
                        prefix: pr.prefix,
                        curentDate: pr.dt,
                        startDate: this.dt.dt_begin,
                        endDate: this.dt.dt_end,
                    });
                }
                break;
            }
            case '+': {
                if (pr.right) {
                    let lastDay = new Date(
                        pr.dt.getFullYear(),
                        pr.dt.getMonth() + 1,
                        1
                    ); // Увеличиваем на 1 месяц
                    this.setDateHeader(pr, lastDay, true, true);
                    this.drs.CalendarySetParams({
                        prefix: pr.prefix,
                        curentDate: pr.dt,
                        startDate: this.dt.dt_begin,
                        endDate: this.dt.dt_end,
                    });
                }
                break;
            }
        }

        // Общая проверка и блокировка
        // Проверка для левого
        let NextDay = new Date(
            this.headerLeft.dt.getFullYear(),
            this.headerLeft.dt.getMonth() + 1,
            1
        ); // + на 1 месяц
        if (
            this.headerRight.dt.getFullYear() == NextDay.getFullYear() &&
            this.headerRight.dt.getMonth() == NextDay.getMonth()
        ) {
            this.headerLeft.right = true;
            this.headerLeft.left = true;
        } else {
            this.headerLeft.right = true;
        }

        // Проверка для правого
        let PrevDay = new Date(
            this.headerRight.dt.getFullYear(),
            this.headerRight.dt.getMonth() - 1,
            1
        ); // + на 1 месяц
        if (
            this.headerLeft.dt.getFullYear() == PrevDay.getFullYear() &&
            this.headerLeft.dt.getMonth() == PrevDay.getMonth()
        ) {
            //      this.setDateHeader(this.headerRight, this.headerRight.dt, false, true);
            this.headerRight.left = true;
            this.headerRight.right = true;
        } else {
            this.headerRight.left = true;
        }

        var d = new Date(this.headerLeft.dt);
        this.mmValue = d.getFullYear();
        this.hhValue = this.monthList[d.getMonth()];
    }

    loadParamsDate() {
        var d = this.dtEnd ? new Date(this.dtEnd) : new Date();
        this.mmValue = this.mmValue ? this.mmValue : d.getFullYear();
        this.hhValue = this.hhValue
            ? this.hhValue
            : this.monthList[d.getMonth()];
        this.scrollToDate();
    }

    scrollToDate() {
        var h_name = this.hhValue.name,
            h_index = Number(this.getKeyByValue(this.fastMonths, h_name)),
            m_name = this.mmValue,
            m_index = Number(this.getKeyByValue(this.fastYears, m_name));

        var h_interval = setInterval(function () {
            var hElement = document.getElementById('h_container');
            if (hElement) {
                hElement.scrollTop = 56 * 12 + h_index * 56;
                // hElement.scrollTop = h_index * 56;
                clearInterval(h_interval);
            }
        }, 100);
        var m_interval = setInterval(function () {
            var mElement = document.getElementById('m_container');
            if (mElement) {
                mElement.scrollTop = m_index * 56;
                clearInterval(m_interval);
            }
        }, 100);
    }

    toggle: boolean = false;
    setDateAndMonth() {
        this.toggle = !this.toggle;
        this.loadParamsDate();
    }

    setFastDate() {
        const { month, year } = this.fastDateState;
        const date = moment(`${month} ${year}`, 'MMMM YYYY').toDate();
        this.toggle = false;
        this.headerLeft.mount = month;
        this.headerLeft.year = year;
        let dtR: IDateRange = {
            str_begin: '',
            str_end: '',
            dt_begin: null,
            dt_end: null,
        };
        dtR.dt_begin = this.paramPic;
        dtR.dt_end = this.paramPic;
        this.drs.CalendarySetDateRange(dtR);

        this.setDateHeader(this.headerLeft, this.paramPic, true, true);
        this.drs.CalendarySetParams({
            prefix: 'dtLeft',
            curentDate: this.paramPic,
            startDate: this.dt.dt_begin,
            endDate: this.dt.dt_end,
        });
    }

    changeMySelect(e: any, idM: number | null, idYear: number | null) {
        let dtR: IDateRange = {
            str_begin: '',
            str_end: '',
            dt_begin: null,
            dt_end: null,
        };
        if (idM) {
            this.paramPic.setMonth(idM - 1);
        }

        if (idYear) {
            this.paramPic.setFullYear(idYear);
        }
    }

    //свайп в мл=обиле
    defaultTouch = { x: 0, y: 0, time: 0 };

    @HostListener('touchstart', ['$event'])
    //@HostListener('touchmove', ['$event'])
    @HostListener('touchend', ['$event'])
    @HostListener('touchcancel', ['$event'])
    handleTouch(event: {
        touches: any[];
        changedTouches: any[];
        type: string;
        timeStamp: number;
    }) {
        let touch = event.touches[0] || event.changedTouches[0];

        // check the events
        if (event.type === 'touchstart') {
            this.defaultTouch.x = touch.pageX;
            this.defaultTouch.y = touch.pageY;
            this.defaultTouch.time = event.timeStamp;
        } else if (event.type === 'touchend') {
            let deltaX = touch.pageX - this.defaultTouch.x;
            let deltaY = touch.pageY - this.defaultTouch.y;
            let deltaTime = event.timeStamp - this.defaultTouch.time;

            // simulte a swipe -> less than 500 ms and more than 60 px
            if (deltaTime < 500) {
                // touch movement lasted less than 500 ms
                if (Math.abs(deltaX) > 60) {
                    // delta x is at least 60 pixels
                    if (deltaX > 0) {
                        this.doSwipeRight(event);
                    } else {
                        this.doSwipeLeft(event);
                    }
                }

                if (Math.abs(deltaY) > 60) {
                    // delta y is at least 60 pixels
                    if (deltaY > 0) {
                        this.doSwipeDown(event);
                    } else {
                        this.doSwipeUp(event);
                    }
                }
            }
        }
    }

    doSwipeLeft(event: any) {
        this.mountPlusMinus(this.headerLeft, '+');
    }

    doSwipeRight(event: any) {
        this.mountPlusMinus(this.headerLeft, '-');
    }

    doSwipeUp(event: any) {}

    doSwipeDown(event: any) {}

    getKeyByValue(object: any, value: any) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    // быстрая дата
    hhArray: any = this.monthList;

    @ViewChild('hContainer')
    hhContainerRef!: ElementRef;
    @ViewChild('mContainer')
    mmContainerRef!: ElementRef;

    hhScroll$ = new Subject<number>();
    mmScroll$ = new Subject<number>();
    hhValue: any | undefined;
    mmValue: any | undefined;

    getValueByPosition(y: number | 0, type: string | 'hh') {
        let arr = type === 'hh' ? this.monthList : this.fastYears;
        let index = Math.floor((y + 10) / 56);
        if (index < 0) index = 0;
        if (index >= arr.length) index = arr.length + 1;
        return arr[index];
    }

    scrollTo(index: number, event: any, type: string) {
        let containerRef =
            type === 'hh' ? this.hhContainerRef : this.mmContainerRef;
        containerRef.nativeElement.scrollTo({
            top: index * 56,
            behavior: 'smooth',
        });
    }

    @HostListener('window:scroll', ['$event'])
    handleHHScroll(event: any) {
        //
        this.hhValue = this.getValueByPosition(event.target.scrollTop, 'hh');

        this.hhScroll$.next(this.hhValue);
        this.changeMySelect(event, this.hhValue.id, null);
    }

    @HostListener('window:scroll', ['$event'])
    handleMMScroll(event: any) {
        //
        this.mmValue = this.getValueByPosition(event.target.scrollTop, 'mm');
        this.mmScroll$.next(this.mmValue);

        this.changeMySelect(event, null, this.mmValue);
    }
}
