<button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    aria-label="icon-button with a menu"
    class="cls-btn-menu cls-dev-phone fixed"
>
    <div class="svg-menu" *ngIf="!menuTrigger.menuOpen">
        <svg
            width="27"
            height="17"
            viewBox="0 0 27 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                y1="1.75"
                x2="27"
                y2="1.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
            <line
                y1="8.75"
                x2="27"
                y2="8.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
            <line
                y1="15.75"
                x2="27"
                y2="15.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
        </svg>
    </div>
    <!-- <mat-icon class="svg-menu" *ngIf="!menuTrigger.menuOpen" svgIcon="menu"></mat-icon> -->
    <div class="svg-menu-close" *ngIf="menuTrigger.menuOpen">
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.6739 10L19.6439 17.97C20.1187 18.4447 20.1187 19.1943 19.6439 19.6439C19.1692 20.1187 18.4197 20.1187 17.97 19.6439L10 11.6739L2.03001 19.6439C1.55526 20.1187 0.805733 20.1187 0.35606 19.6439C-0.118687 19.1692 -0.118687 18.4197 0.35606 17.97L8.32605 10L0.35606 2.03001C-0.118687 1.55526 -0.118687 0.805733 0.35606 0.35606C0.830806 -0.118687 1.58034 -0.118687 2.03001 0.35606L10 8.32605L17.97 0.35606C18.4447 -0.118687 19.1943 -0.118687 19.6439 0.35606C20.1187 0.830806 20.1187 1.58034 19.6439 2.03001L11.6739 10Z"
                fill="#B7C9DF"
            />
        </svg>
    </div>
    <!-- {{menuTrigger.menuOpen ? 'expand_less' : 'expand_more'}} -->
</button>

<mat-menu #menu="matMenu" class="cls-menu-list cls-dev-phone">
    <button
        mat-icon-button
        aria-label="icon-button with a menu"
        class="cls-btn-menu cls-dev-phone cls-btn-menu--inOpenMenu"
    >
        <!-- <mat-icon class="svg-menu" *ngIf="!menuTrigger.menuOpen" svgIcon="menu"></mat-icon> -->
        <div class="svg-menu-close" *ngIf="menuTrigger.menuOpen">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.6739 10L19.6439 17.97C20.1187 18.4447 20.1187 19.1943 19.6439 19.6439C19.1692 20.1187 18.4197 20.1187 17.97 19.6439L10 11.6739L2.03001 19.6439C1.55526 20.1187 0.805733 20.1187 0.35606 19.6439C-0.118687 19.1692 -0.118687 18.4197 0.35606 17.97L8.32605 10L0.35606 2.03001C-0.118687 1.55526 -0.118687 0.805733 0.35606 0.35606C0.830806 -0.118687 1.58034 -0.118687 2.03001 0.35606L10 8.32605L17.97 0.35606C18.4447 -0.118687 19.1943 -0.118687 19.6439 0.35606C20.1187 0.830806 20.1187 1.58034 19.6439 2.03001L11.6739 10Z"
                    fill="#B7C9DF"
                />
            </svg>
        </div>
        <!-- {{menuTrigger.menuOpen ? 'expand_less' : 'expand_more'}} -->
    </button>
    <button
        mat-menu-item
        *ngFor="let menu of menus"
        [ngClass]="{
            active: menu.active,
            'cls-menu-btn-rec': menu.img == 'm_rec'
        }"
        [routerLink]="menu.url"
        (click)="closeAllModals(menuTrigger)"
    >
        <div class="matMenu-wrapper">
            <mat-icon [svgIcon]="menu.img"></mat-icon>
            <span>{{ menu.name }}</span>
        </div>
    </button>
</mat-menu>

<!-- Комп -->
<div class="flex-column flex-shrink-0 text-white cls-menu cls-dev-comp">
    <a
        routerLink="/"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none cls-menu-a"
    >
        <img
            src="./assets/img/header_logo.png"
            alt=""
            width="190px"
            class="cls-menu-a-img"
        />
    </a>
    <ul class="nav nav-pills flex-column mb-auto cls-menu-ul">
        <li
            class="cls-menu-li"
            *ngFor="let menu of menus"
            [ngClass]="{
                active: menu.active,
                'cls-menu-li-rec': menu.img == 'm_rec'
            }"
            [routerLink]="menu.url"
            (click)="setActiveMenu(menu)"
        >
            <mat-icon [svgIcon]="menu.img"></mat-icon>
            <span>{{ menu.name }}</span>
        </li>
    </ul>

    <!--
  <mat-icon svgIcon='lk' style="max-width: 45px; min-width: 30px;"></mat-icon>
-->
    <div>
        <ul class="nav nav-pills flex-column mb-auto cls-menu-ul">
            <li
                class="cls-menu-li"
                routerLink="/home/account"
                [ngClass]="{ active: lkActive }"
            >
                <mat-icon svgIcon="lk"></mat-icon>
                <span>Личные данные</span>
            </li>
            <li class="cls-menu-li" (click)="onExit()">
                <mat-icon svgIcon="exit"></mat-icon>
                <span>Выход</span>
            </li>
        </ul>
    </div>
</div>
