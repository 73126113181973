/* Компонент вывода и выбора филиалов раздела "Запись к врачу" мобильной версии */
import { Component, OnInit } from '@angular/core';
import { expandedAnimation } from '../../../../../animations/height.animation';
import { RecordService } from '../../../../../services/record.service';
import { IDep } from '../../../../../interfaces/record.interface';

@Component({
    selector: 'app-rec-mobile-dep-list',
    templateUrl: './rec-mobile-dep-list.component.html',
    styleUrls: ['./rec-mobile-dep-list.component.scss'],
    animations: [expandedAnimation],
})
export class RecMobileDepListComponent implements OnInit {
    depList: IDep[] = []; // Список филиалов
    depSelect: IDep | null = null; // Выбранный филиал
    depSelectName: string = ''; // Выбранный адрес текст

    searchAmine = 'off';
    shownDeps = false;
    shownTalons = false;

    /* Иниицализация компонента */
    constructor(private rs: RecordService) {
        rs.DepOnInit$().subscribe((n) => {
            this.initDep(n);
        });
    }

    ngOnInit(): void {}

    btnFiltrAdr(num: number) {}

    /* Получение списка филиалов */
    initDep(deps: IDep[]) {
        let depsNew = [{ keyid: -1, text: 'Все' }];

        deps.forEach((item) => {
            depsNew.push(item);
        });

        this.depList = depsNew;
        //this.depList.unshift({ keyid: -1, text: 'Все' });
        this.depSelect = null;
        this.depSelectName = '';
    }

    /* Поиск филиалов (не используется) */
    searchClick() {
        if (this.searchAmine === 'off') {
            this.searchAmine = 'on';
        } else {
            this.searchAmine = 'off';
        }
    }

    /* Изменение строки поиска (не используется */
    changeSearch(event: any) {
        //    this.rs.DepSelect()
    }

    /* После раскрытия панели поиска (не используется */
    whenAnimateSearch(event: any) {
        //
    }

    /* Выбор филиала */
    selectDep(dep: IDep) {
        this.searchAmine = 'off';
        this.depSelect = dep;
        this.depSelectName = '   ' + this.depSelect.text;
        this.shownDeps = false;
        this.shownTalons = true;

        this.rs.DepSelect(dep);
    }

    /* Показ списка филиалов */
    showDeps() {
        this.shownDeps = true;
    }

    /* Скрытие списка филиалов */
    hideDeps() {
        this.shownDeps = false;
    }

    /* Скрытие/показ списка филиалов */
    toggleShowDeps() {
        this.shownDeps = !this.shownDeps;
    }
}
