<div
    class="visit"
    [ngStyle]="{
        'grid-template-columns': gridTemplateColumns,
        cursor: visit.cnt_prot ? 'pointer' : 'auto',
        'pointer-events': visit.cnt_prot ? 'auto' : 'none'
    }"
    (click)="historyItem(visit.keyid, visit.typehistory)"
>
    <div class="visit__date">
        {{ getTekDay(visit.dtSort) }} в
        {{ getTime(visit.dtSort) }}
        <div style="color: #c5c5c5">
            {{ getNameDay(visit.dtSort) }}
        </div>
    </div>

    <div class="visit__doctor">
        {{ visit.spec }} <br />
        <span class="name-capitalize">{{ visit.doctor }}</span>
    </div>

    <div class="visit__type">{{ visit.typetext }}</div>

    <div class="vist__deproot" *ngIf="showFilials">
        {{ visit.dep_root_name }}
    </div>

    <div class="visit_department">
        {{ visit.dep_name }}
    </div>

    <div class="visit__record">
        <ng-container
            *ngIf="
                visit.sched_exists_for_dd_on_visit == 1;
                then online;
                else offline
            "
        >
        </ng-container>
        <ng-template #online>
            <ng-template #tipContentOnline let-greeting="greeting"
                >Записаться на прием.</ng-template
            >
            <p
                class="cls-label-OnLine"
                routerLink="/home/rec"
                placement="left"
                [ngbTooltip]="tipContentOnline"
                tooltipClass="tooltipPanel"
            >
                Онлайн
            </p>
        </ng-template>
        <ng-template #offline
            ><span [ngbTooltip]="tipContentOffline">В регистратуре</span>
            <ng-template #tipContentOffline let-greeting="greeting">
                <mat-icon
                    svgIcon="phone-notColor"
                    style="height: 14px"
                ></mat-icon>
                <span
                    [innerHTML]="txtPhone"
                    class="txtPhone"
                ></span> </ng-template
        ></ng-template>
    </div>

    <div class="visit__result">
        <button
            class="result-item"
            *ngIf="visit.cnt_prot !== 0"
            (click)="gotToDownload(visit.keyid, visit.typehistory, $event)"
        >
            <img
                *ngIf="!pdfLoading"
                class="result-item__img"
                [src]="pdfImageURL"
            />
            <img
                *ngIf="pdfLoading"
                class="loader-rotate"
                src="./assets/img/svg/loader-btn.svg"
            />
        </button>

        <ng-container *ngIf="visit.accession_no; then dicom"></ng-container>
        <ng-template #dicom>
            <button
                class="result-item"
                (click)="
                    openDicomModal(
                        visit.accession_no,
                        visit.study_date,
                        visit.study_iuid,
                        $event
                    )
                "
            >
                <img
                    class="result-item__img"
                    src="./assets/img/svg/dicom-view.svg"
                />
            </button>
            <a
                class="result-item"
                href="{{ getDownloadUrl(visit.study_iuid) }}"
                (click)="stopPropagation($event)"
                download="sos"
                ><img
                    class="result-item__img"
                    src="./assets/img/svg/dicom-download.svg"
            /></a>
        </ng-template>
    </div>
</div>
