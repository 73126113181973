import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Size } from './services/size';
import { Meta } from '@angular/platform-browser';
import packageJson from 'package.json';
const { version } = packageJson;
import { SocketService } from './services/socket/socket.service';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event/event.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'pa-app';
    constructor(
        private size: Size,
        private meta: Meta,
        private socketService: SocketService,
        private eventService: EventService
    ) {
        // console.log('no cookie');
        // Установка типа девайса
        size.setW(window.innerWidth);
        // console.log(document.cookie, document.cookie.split(';'));
        // const cookieValue = document.cookie
        //     .split(';')
        //     .find((cookie) => cookie.trim().startsWith(' pa-web_Patient='))
        //     ?.split('=')[1];
        if (!document.cookie.replace('pa-web_Patient=', '')) {
            // console.log('yes cookie');
            eventService.emit('auth:login');
            const talonBlock = localStorage.getItem('talonBlock');
            // console.log(talonBlock);
            const { rnumbID, f_appoitment } = JSON.parse(talonBlock!) || {};
            // console.log(rnumbID, f_appoitment);
            if (rnumbID && !f_appoitment) {
                // console.log('emit');
                setTimeout(() => {
                    socketService.unlockTalon(Number(rnumbID));
                }, 200);
            }
            localStorage.removeItem('talonBlock');
        }
    }

    ngAfterViewInit(): void {
        this.updateVhUnits();
    }

    ngOnInit(): void {
        this.meta.updateTag({
            name: 'product_version',
            value: version,
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updateVhUnits();
    }

    updateVhUnits() {
        const vh = window.innerHeight * 0.01;
        document.body.style.setProperty('--vh', `${vh}px`);
    }
}
