import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpNewService } from '../application/http-new.service';
import { AuthService } from '../auth.service';

export interface ICaptcha {
    id: number;
    svg: string;
    value?: string;
}

interface CaptchaVerifyResult {
    valid: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {
    constructor(
        private http: HttpNewService,
        private auth: AuthService,
    ) {
    }

    /**
     * Создать и получить капчу 
     * @param parameters
     * @returns {Observable<IAbonement[]>}
    **/
    public fetchCaptcha(): Promise<ICaptcha> {
        return this.http.get('/api/captcha/create', this.auth.token)
            .pipe(first())
            .toPromise<ICaptcha>();
    }

    /**
     * Создать и получить капчу 
     * @param parameters
     * @returns {Observable<IAbonement[]>}
    **/
    public verifyCaptcha(id: number, value: string): Promise<CaptchaVerifyResult> {
        const data = {
            id,
            value,
        }

        return this.http.post('/api/captcha/verify', data, this.auth.token)
            .pipe(first())
            .toPromise<CaptchaVerifyResult>();
    }
}
