<button
  [ngClass]="{
    chip: true,
    selected: selected,
    stratch: stratch
  }"
  [ngStyle]="{ 'text-align': textAlign }"
  type="button"
  role="option"
  [disabled]="disabled"
  [attr.aria-selected]="selected"
  [attr.selected]="selected"
  (click)="onSelectionHendler($event)"
>
  <div class="text">{{ text ?? value }}</div>
  <div class="hint" *ngIf="hint">{{ hint }}</div>
</button>
