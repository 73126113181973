import { Pipe, PipeTransform } from '@angular/core';
import {
    IHistoryFilter,
    IHistoryFilterItem,
} from '../interfaces/history.interface';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], searchFilter: string): any[] {
        if (!items) return [];
        if (!searchFilter) return items;

        searchFilter = searchFilter.toLocaleLowerCase();

        return items.filter((item) =>
            item.text.toLocaleLowerCase().includes(searchFilter)
        );
    }
}
