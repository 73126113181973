<div class="rec-calendary">
  <div class="cal-header">
    <span class="cal-header-mount">{{headerText.mount}}</span>
    <span class="cal-header-year">{{headerText.year}}</span>
  </div>

  <table class="cal-table">
    <tr style="height: 3em;">
      <th class="cal-h">пн</th>
      <th class="cal-h">вт</th>
      <th class="cal-h">ср</th>
      <th class="cal-h">чт</th>
      <th class="cal-h">пт</th>
      <th class="cal-h">сб</th>
      <th class="cal-h">вс</th>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:0:7; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:7:14; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:14:21; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:21:28; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:28:35; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
    <tr>
      <td class="cal-row" *ngFor="let dt of dayArray | slice:35:42; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false, 'day-activ': dt.isAction==true, 'day-selected': dt.isSelected==true}"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>
  </table>

</div>
