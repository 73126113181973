/* Компонент выбора талона раздела "Запись к врачу" мобильной версии */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { RecordService } from '../../../../services/record.service';
import {
    ICalendarData,
    IDep,
    IRecTalon,
    IRnumbDate,
    IStaticFilter,
} from '../../../../interfaces/record.interface';
import { ISrv } from '../../../../services/to-doctor/srv.interface';
import { strToDate } from '../../../application/global.function';
import { from, of, zip } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
// import {MobileTalonsComponent } from '/../../../rnumb-mobile-selected/mobile-talons/mobile-talons.component';

@Component({
    selector: 'app-rnumb-mobile-selected',
    templateUrl: './rnumb-mobile-selected.component.html',
    styleUrls: ['./rnumb-mobile-selected.component.scss'],
})
export class RnumbMobileSelectedComponent implements OnInit {
    //  daysActive: ICalendarDateActive[] = []; // Спсок талонов
    /*
  @Input() doctorId: number | null = null;
  @Input() specId: number | null = null;
  @Input() periodStart: Date = new Date();
  @Input() periodEnd: Date = new Date();
  @Input() staticFilterSelected: IStaticFilter | null = null;  // выбраные фильтр
*/
    @Output() onClose = new EventEmitter<number>(); // закрыть
    paramsData: IRnumbDate;

    talonList: IRecTalon[] = []; // Спсок талонов

    constructor(private rs: RecordService) {
        rs.RnumbDateOnInit$().subscribe((n) => {
            this.dateInit(n);
        });
        this.paramsData = {
            doctorId: 0,
            specId: 0,
            periodStart: new Date(),
            periodEnd: new Date(),
        };
    }

    ngOnInit(): void {}

    /* инициализация данных */
    dateInit(data: IRnumbDate) {
        this.paramsData = data;
        this.getRnumbList();
    }

    getRnumbList() {
        this.talonList = [];
        let staticId: number | null = null;
        if (
            this.paramsData.staticFilterSelected &&
            this.paramsData.staticFilterSelected.keyid
        ) {
            staticId = this.paramsData.staticFilterSelected.keyid;
        }
        let specId: number | null = null;
        if (this.paramsData.specId) {
            specId = this.paramsData.specId;
        }

        let deps: IDep[];
        /*
    this.rs.getDepList(this.paramsData.doctorId, specId, null)
      .subscribe(resDep => {
          this.rs.DepSetParams(resDep);
*/
        this.rs
            .getRnumbList(
                this.paramsData.doctorId,
                specId,
                this.paramsData.periodStart,
                this.paramsData.periodEnd,
                staticId,
                null
            )
            .subscribe(
                (res) => {
                    this.talonList = res;

                    let depListTalon: number[] = [];
                    this.talonList.forEach((item) => {
                        if (depListTalon.indexOf(item.depid) == -1) {
                            depListTalon.push(item.depid);
                        }

                        item.dtBegin = strToDate(item.dat_begin_str);
                        if (item.dat_end_str && item.dat_end_str.length > 0) {
                            item.dtEnd = strToDate(item.dat_end_str);
                        } else {
                            item.dtEnd = strToDate(item.dat_begin_str);
                        }
                        item.dtFilter = Number(
                            moment(item.dtBegin).format('YYYYMMDD')
                        );
                    });

                    this.rs
                        .getDepList(this.paramsData.doctorId, specId, null)
                        .subscribe(
                            (resDep) => {
                                let tmp: IDep[] = [];
                                resDep.forEach((itemDep) => {
                                    if (
                                        depListTalon.indexOf(itemDep.keyid) !=
                                        -1
                                    ) {
                                        tmp.push(itemDep);
                                    }
                                });
                                this.rs.DepSetParams(tmp);
                            },
                            (error) => {
                                console.error(error);
                            }
                        );

                    this.rs.TalonsSetParams({
                        talons: this.talonList,
                        srv: this.paramsData.srv,
                    }); // Кидаем в талоны
                    this.rs.CalendarysetParams({
                        periodStart: this.paramsData.periodStart,
                        periodEnd: this.paramsData.periodEnd,
                        talonList: this.talonList,
                    }); // Кидаем на календарь
                },
                (error) => {
                    console.error(error);
                }
            );

        /*
        },
        errorDep => {
          console.error(errorDep);
        });
*/
    }

    close() {
        this.onClose.emit(1);
    }
}
