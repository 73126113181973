<!-- Файл представления компонента раздела "Запись к врачу" мобильной версии -->
<div class="page-content" *ngIf="!isPageLoading; else loader">
    <ng-template [ngIf]="!selectedSrv">
        <div class="header__row">
            <div class="header__box search">
                <ng-template [ngIf]="selectedSpec">
                    <input
                        class="header__saerch search__input"
                        placeholder="Введите специальность"
                        type="search"
                        name=""
                        id="search-input"
                        value="{{ selectedSpec.text }}"
                        (keyup)="specSearch($event)"
                    />
                    <span class="search-reset" (click)="resetSearch()"
                        ><img src="./assets/img/svg/close-icon.svg"
                    /></span>
                </ng-template>
                <ng-template [ngIf]="!selectedSpec">
                    <input
                        class="header__saerch search__input"
                        placeholder="Введите специальность"
                        type="search"
                        name=""
                        id="search-input"
                        (keyup)="specSearch($event)"
                    />
                    <span
                        *ngIf="searchString !== ''"
                        class="search-reset"
                        (click)="resetSearch()"
                        ><img src="./assets/img/svg/close-icon.svg"
                    /></span>
                </ng-template>
                <label class="search__karet" for="search-input"></label>
                <button class="search__btn reset">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15.7732 14.442L11.8947 10.5635C13.2416 8.76381 13.5809 6.40276 12.7952 4.29704C12.0095 2.19084 10.2066 0.628776 8.01004 0.151012C5.81349 -0.326719 3.52462 0.345434 1.93493 1.93492C0.345404 3.52442 -0.326707 5.81321 0.151008 8.00996C0.628723 10.2067 2.19085 12.0094 4.29687 12.795C6.40309 13.5807 8.76417 13.2416 10.5639 11.8946L14.4425 15.7731C14.6854 15.9812 15.0171 16.052 15.3241 15.9612C15.6307 15.8706 15.8707 15.6307 15.9612 15.324C16.052 15.0173 15.981 14.6856 15.7731 14.4425L15.7732 14.442ZM1.89152 6.61932C1.8911 5.04394 2.6772 3.5722 3.98659 2.69637C5.29614 1.82054 6.95641 1.65602 8.41236 2.25782C9.86832 2.85961 10.9278 4.14833 11.2366 5.69314C11.5457 7.23794 11.0631 8.83505 9.95045 9.95036C9.06636 10.8368 7.86617 11.3355 6.6141 11.3366C5.36225 11.3378 4.16119 10.8414 3.27543 9.95645C2.38967 9.07178 1.89174 7.87133 1.89152 6.61928V6.61932Z"
                            fill="#B6B6B6"
                        />
                    </svg>
                </button>
            </div>
            <button class="header__filter filter reset" (click)="searchClick()">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 3.44434H5.88889M23 3.44434H10.7778M1 11.9999H15.6667M23 11.9999H20.5556M1 20.5554H3.44444M23 20.5554H8.33333"
                        stroke="#C6C6C6"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M8.33507 5.88889C9.6851 5.88889 10.7795 4.79447 10.7795 3.44444C10.7795 2.09441 9.6851 1 8.33507 1C6.98504 1 5.89062 2.09441 5.89062 3.44444C5.89062 4.79447 6.98504 5.88889 8.33507 5.88889Z"
                        stroke="#C6C6C6"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M18.1124 14.4446C19.4624 14.4446 20.5569 13.3501 20.5569 12.0001C20.5569 10.6501 19.4624 9.55566 18.1124 9.55566C16.7624 9.55566 15.668 10.6501 15.668 12.0001C15.668 13.3501 16.7624 14.4446 18.1124 14.4446Z"
                        stroke="#C6C6C6"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M5.88976 23.0002C7.23979 23.0002 8.3342 21.9058 8.3342 20.5558C8.3342 19.2057 7.23979 18.1113 5.88976 18.1113C4.53973 18.1113 3.44531 19.2057 3.44531 20.5558C3.44531 21.9058 4.53973 23.0002 5.88976 23.0002Z"
                        stroke="#C6C6C6"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </button>
        </div>
    </ng-template>

    <div class="filters white record" [@expandedAnimation]="searchAmine">
        <div class="record-filter">
            <div class="record-filter__wrapper">
                <div class="record-filter__top record-top">
                    <div class="record-top__left">
                        <div class="record-top__title record-title">
                            Фильтры
                        </div>
                    </div>
                    <button
                        class="record-top__cloce list-cloce reset"
                        (click)="searchClick()"
                    >
                        <img
                            src="./assets/img/svg/cloce.svg"
                            alt="Закрыть фильтр"
                        />
                    </button>
                </div>
                <div class="record-filter__box">
                    <div
                        class="checkbox"
                        *ngFor="let stBTN of staticFilterBTNs"
                    >
                        <ng-template
                            [ngIf]="stBTN.keyid == staticFilterSelected?.keyid"
                        >
                            <input
                                id="consultation_{{ stBTN.keyid }}"
                                type="radio"
                                name="consultation"
                                class="checkbox__input"
                                checked
                            />
                        </ng-template>
                        <ng-template
                            [ngIf]="stBTN.keyid != staticFilterSelected?.keyid"
                        >
                            <input
                                id="consultation_{{ stBTN.keyid }}"
                                type="radio"
                                name="consultation"
                                class="checkbox__input"
                            />
                        </ng-template>
                        <label
                            for="consultation_{{ stBTN.keyid }}"
                            class="checkbox__label"
                            (click)="btnStaticFiltr(stBTN)"
                        >
                            {{ stBTN.text }}
                        </label>
                    </div>
                    <!--<button class="record-filter__btn list-btn reset">Выберите филиал</button>-->
                </div>
            </div>
            <div class="record-filter__bottom bottom-apply">
                <button
                    class="record-filter__lose bottom-apply__btn lose reset"
                    (click)="clearFilter()"
                >
                    Сбросить
                </button>
                <button
                    class="record-filter__use bottom-apply__btn use reset"
                    (click)="searchClick()"
                >
                    Применить
                </button>
            </div>
        </div>
    </div>

    <!-- Directions list -->
    <ng-template
        [ngIf]="!loadingSpecList"
        [ngIfElse]="specListLoader"
    ></ng-template>
    <ng-template [ngIf]="!selectedSpec">
        <div class="record">
            <div class="record-grid">
                <button
                    class="record-grid__btn reset"
                    *ngFor="let sl of specList"
                    (click)="selectSpec(sl.keyid)"
                >
                    <img
                        [src]="getImgSrcSpec(sl.keyid)"
                        (error)="errorHandlerIMG($event, 'spec')"
                        alt="{{ sl.text }}"
                    />
                    <span>{{ sl.text }}</span>
                </button>
            </div>
        </div>
    </ng-template>
    <!-- End of Directions list -->

    <!-- Doctors list -->
    <ng-template [ngIf]="selectedSpec && !selectedSrv">
        <div
            class="record"
            [ngClass]="{ 'srv-choice': shownServicesDoc !== null }"
        >
            <div class="record-list custom">
                <div
                    class="record-list__item list-item --active"
                    *ngFor="let doc of recDoctors"
                >
                    <div class="list-item__box" (click)="selectedDoc(doc)">
                        <!-- <div class="list-item__image">
                            <img
                                [src]="getImgSrcDoc(doc.doctorid)"
                                (error)="errorHandlerIMG($event, 'doc')"
                                alt="Доктор {{ getFIODoc(doc) }}"
                            />
                        </div> -->
                        <userpic
                            [placeholder]="getImgSrcErr()"
                            [image]="getImgSrcDoc(doc.doctorid)"
                        ></userpic>
                        <div class="list-item__info">
                            <span class="list-item__name">{{
                                getFIODoc(doc)
                            }}</span>
                            <span class="list-item__number"
                                >Осталось {{ doc.rcount }} номерков</span
                            >
                        </div>
                    </div>

                    <ng-container
                        *ngIf="isShowSrvList(doc); then showListService"
                    ></ng-container>

                    <ng-template #showListService>
                        <div class="record-list__dropdown dropdown-list">
                            <button
                                class="dropdown-list__btn list-btn reset"
                                [ngClass]="{
                                    '--active': shownServicesDoc == doc
                                }"
                                (click)="showServices(doc)"
                            >
                                Выберите услугу
                            </button>
                            <div
                                class="dropdown-list__wrapper"
                                [ngClass]="{
                                    '--visible': shownServicesDoc == doc
                                }"
                            >
                                <div class="dropdown-list__top">
                                    <span>Выбор услуги</span>
                                    <button
                                        class="dropdown-list__cloce list-cloce reset"
                                        (click)="hideServices()"
                                    >
                                        <img
                                            src="./assets/img/svg/cloce.svg"
                                            alt="Закрыть выбор услуги"
                                        />
                                    </button>
                                </div>
                                <div class="dropdown-list__contant custom">
                                    <button
                                        class="dropdown-list__choise reset"
                                        *ngFor="let docSrv of doc.srvlist"
                                        (click)="selectedServise(doc, docSrv)"
                                    >
                                        <ng-template
                                            [ngIf]="docSrv.is_online_pay == 1"
                                        >
                                            <span>{{ docSrv.text }}</span>
                                        </ng-template>
                                        <ng-template
                                            [ngIf]="docSrv.is_online_pay != 1"
                                        >
                                            {{ docSrv.text }}
                                        </ng-template>
                                        <img
                                            *ngIf="docSrv.is_online_pay == 1"
                                            src="~/assets/img/svg/rub.svg"
                                            alt="рубль"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- End of Doctors list -->
</div>

<div
    class="h-100 panel--fixed custom"
    [ngClass]="selectedSpec && selectDoc ? '--visible' : ''"
>
    <ng-template [ngIf]="selectedSpec && selectDoc">
        <div class="staps record">
            <div class="staps__top record-top">
                <div class="record-top__left">
                    <button class="record-back reset">
                        <img
                            src="./assets/img/svg/back.svg"
                            alt="Назад"
                            (click)="resetChoice()"
                        />
                    </button>
                    <div class="record-top__title record-title">
                        Выбор дня и времени
                    </div>
                </div>
                <button
                    class="record-top__cloce list-cloce reset"
                    (click)="resetChoice(true)"
                >
                    <img
                        src="./assets/img/svg/cloce.svg"
                        alt="Закрыть фильтр"
                    />
                </button>
            </div>
            <div class="list-item">
                <div class="list-item__box">
                    <div class="list-item__image">
                        <img
                            [src]="getImgSrcDoc(selectDoc.doctorid)"
                            (error)="errorHandlerIMG($event, 'doc')"
                            alt="Доктор {{ getFIODoc(selectDoc) }}"
                        />
                    </div>
                    <div class="list-item__info">
                        <span class="list-item__name">{{
                            getFIODoc(selectDoc)
                        }}</span>
                        <span class="list-item__number"
                            >{{ selectedSpec.text
                            }}<img
                                src="./assets/img/svg/info.svg"
                                aria-hidden="true"
                                alt="info"
                        /></span>
                    </div>
                </div>
            </div>
            <div class="staps__box" *ngIf="selectedSrv">
                <div class="staps__rezult filter-rezult">
                    <div class="filter-rezult__name">
                        {{ selectedSrv.text }}
                    </div>
                    <div class="filter-rezult__price">
                        {{ selectedSrv.price | priceSpace : ".2" }} рублей
                    </div>
                    <span class="filter-rezult__image"
                        ><img
                            *ngIf="selectedSrv.is_online_pay == 1"
                            src="~/assets/img/svg/rub.svg"
                            aria-hidden="true"
                            alt="Рубль"
                    /></span>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="staps record" [@rightPanelAnimation]="panelRnumbAmine">
        <div class="staps__box">
            <app-rnumb-mobile-selected
                (onClose)="onClosePanelTalon($event)"
            ></app-rnumb-mobile-selected>
        </div>
    </div>
</div>

<ng-template #loader>
    <div class="page-loader">
        <loader [isFullscreen]="true" type="page"></loader>
    </div>
</ng-template>

<ng-template #specListLoader>
    <div class="spec-list-loader">
        <loader type="page"></loader>
    </div>
</ng-template>
