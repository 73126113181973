import { LoadService } from 'src/app/services/application/load.service';
import { Imenu } from './../../../interfaces/menu.interface';
import { Component, OnInit } from '@angular/core';
import { Size } from '../../../services/size';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
    private _setting: any;
    private settingMenu: String[];
    panelsMenu: Imenu[] = []; // Список блоков меню

    /* Создание компонента и загрузка меню */
    constructor(private load: LoadService, private title: Title) {
        this._setting = load.configData.pages.home;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        if (
            load.configData.menu.items &&
            load.configData.menu.items.length > 5
        ) {
            this.settingMenu = load.configData.menu.items.split(',');
        } else {
            this.settingMenu = [];
        }
    }

    ngOnInit(): void {
        this.getMenu();
    }

    /* Получение меню */
    getMenu(): void {
        /* Параметры
    1) Запись к врачу  - to-doctor
    2) Лаборатория     -  laboratory
    3) Финансы             - payments
    4) Мои посещения  -  h-doctor && history
    5) Услуги                - services

     */
        this.panelsMenu = [];
        if (this.settingMenu.indexOf('to-doctor') > -1) {
            this.panelsMenu.push({
                img: 'm_rec_panel.png',
                name: 'Запись к врачу',
                url: '/home/rec',
                order: 0,
            });
        }

        if (this.settingMenu.indexOf('record-services') > -1) {
            this.panelsMenu.push({
                img: 'registrationServices.svg',
                name: 'Запись на услугу',
                url: '/home/record-services',
                order: 1,
            });
        }

        if (this.settingMenu.indexOf('laboratory') > -1) {
            this.panelsMenu.push({
                img: 'm_lab_panel.png',
                name: 'Лаборатория',
                url: '/home/labs',
                order: 1,
            });
        }
        if (this.settingMenu.indexOf('payments') > -1) {
            this.panelsMenu.push({
                img: 'm_fin_panel.png',
                name: 'Финансы',
                url: '/home/payments',
                order: 1,
            });
        }
        //    this.panelsMenu.push({img: 'm_abon_panel.png', name:'Абонементы', url:'/test5', order:1});
        if (
            this.settingMenu.indexOf('h-doctor') > -1 ||
            this.settingMenu.indexOf('history') > -1
        ) {
            this.panelsMenu.push({
                img: 'm_history_panel.png',
                name: 'Мои посещения',
                url: '/home/history',
                order: 1,
            });
        }
        if (this.settingMenu.indexOf('services') > -1) {
            this.panelsMenu.push({
                img: 'm_serv_panel.png',
                name: 'Услуги',
                url: '/home/services',
                order: 1,
            });
        }
        if (this.settingMenu.indexOf('documents') > -1) {
            this.panelsMenu.push({
                img: 'm_doc_panel.png',
                name: 'Документы',
                url: '/home/patient-files',
                order: 1,
            });
        }

        //legacy govna(полного)
    }
}
