import { EventService } from './../../../../../services/event/event.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    IRecTalon,
    IRecTalonInit,
    ITalonModal,
    ITalonsDaySetParams,
} from '../../../../../interfaces/record.interface';
import { RecordService } from '../../../../../services/record.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { TalonComponent } from '../../../../../modals/talon/talon.component';
import { AlertService } from 'src/lib/ariadna/alert';
import { NewTalonComponentMobile } from 'src/app/modals/new-talon-mobile/talon.component';

@Component({
    selector: 'app-talons',
    //  changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './talons.component.html',
    styleUrls: ['./talons.component.scss'],
})
export class TalonsComponent implements OnInit {
    //  @Input() talonList: IRecTalon[] = [];     // Массов дней - активный
    @Output() onClickTalon = new EventEmitter<string>(); // талон
    paramsData: IRecTalonInit | null = null; // паарметры инициализации
    talonList: IRecTalon[] = []; // Массов дней - активный
    talonListDay: IRecTalon[] = []; // Массов дней - активный
    selectedDate: Date | null = null; // Выбрана дата
    headerTxt = ''; // Заголовок
    selectedTalon: IRecTalon | null = null; // выбранный талон
    loading = false;

    constructor(
        private rs: RecordService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private eventService: EventService
    ) {
        eventService.on('calendar:loading', () => {
            this.loading = true;
        });

        rs.TalonsOnInit$().subscribe((n) => {
            this.initTalons(n);
        });

        rs.TalonsDayOnInit$().subscribe((n) => {
            // Фильтровать по дню
            this.initDay(n);
        });
    }

    ngOnInit(): void {}

    initTalons(t: IRecTalonInit) {
        // Весь список талонов
        this.paramsData = t;
        this.talonList = this.paramsData.talons;
        if (this.talonList.length === 0) this.talonListDay = [];
        this.loading = false;
        //
        //
    }

    //  initDay(dt: Date) { // Фильтр по дню
    initDay(data: ITalonsDaySetParams) {
        // Фильтр по дню
        this.selectedDate = data.dt;
        //
        this.headerTxt = `Свободно на ${moment(this.selectedDate).format(
            'DD MMMM'
        )}`;
        let n = Number(moment(this.selectedDate).format('YYYYMMDD'));
        this.talonListDay = [];
        //
        if (data.dep && data.dep.keyid > 0) {
            let k = data.dep.keyid;
            this.talonListDay = this.talonList.filter(
                (item) => item.dtFilter === n && item.depid === k
            );
            //      this.selectedDep(data.dep);
        } else {
            this.talonListDay = this.talonList.filter(
                (item) => item.dtFilter === n
            );
        }

        this.loading = false;
    }

    formatTalon(dt: Date | null | undefined): string {
        if (dt) {
            return moment(dt).format('HH:mm');
        } else {
            return '';
        }
    }

    FsetRnumbBlStatus(talon: IRecTalon) {
        let dt: ITalonModal = {
            rnumbID: talon.rnumbid,
            paramCansel: false,
        };
        this.rs.setRnumbBlStatus(talon.rnumbid).subscribe(
            (res) => {
                if (res && res.err_code === 0) {
                    this.selectedTalon = talon;
                    if (this.paramsData && this.paramsData.srv) {
                        dt.srv = this.paramsData.srv;
                    }

                    const dialogRef = this.dialog.open(
                        NewTalonComponentMobile,
                        {
                            width: 'auto',
                            height: 'auto',
                            closeOnNavigation: true,
                            data: dt,
                        }
                    );

                    dialogRef.afterClosed().subscribe((result) => {
                        //
                        if (
                            result &&
                            result.unlockTalon &&
                            result.unlockTalon == true
                        ) {
                            // Отмена номерка
                            this.rs.getRnumbUnlock(talon.rnumbid).subscribe(
                                (res) => {
                                    //
                                },
                                (err) => {
                                    console.error('getRnumbUnlock err=', err);
                                }
                            );
                        }
                    });
                } else {
                    //
                    this.alertService.error(
                        'Запись не возможна, ' + res.err_text
                    );
                }
            },
            (err) => {
                console.error('setRnumbBlStatus ERRROr=', err);
            }
        );
    }

    clickTalon(talon: IRecTalon) {
        if (!this.talonDisable(talon)) {
            // Интервальное расписание
            if (talon.interval_id && talon.interval_id > -1) {
                this.rs
                    .getRnumbCreateInterval(
                        talon.interval_id,
                        moment(talon.dtBegin).format('DD.MM.YYYY HH:mm'),
                        moment(talon.dtEnd).format('DD.MM.YYYY HH:mm')
                    )
                    .subscribe(
                        (res) => {
                            if (res && res.guid && res.guid > -1) {
                                talon.rnumbid = res.guid;
                                this.FsetRnumbBlStatus(talon);
                            } else {
                                console.error('Ошибка id интервал');
                            }
                        },
                        (err) => {}
                    );
            } else {
                this.FsetRnumbBlStatus(talon);
            }
        }
    }

    talonDisable(talon: IRecTalon): boolean {
        let f: boolean = false;
        if (this.paramsData && this.paramsData.srv) {
            f = this.paramsData.srv.is_telemed != talon.is_telemed;
        }
        return f;
    }
}
