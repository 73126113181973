import { Component, Input, OnInit } from '@angular/core';

export type TextTheme = 
    'title' | 
    'header' | 
    'paragraph' | 
    'label' |
    'delimiter';


@Component({
    selector: 'text',
    templateUrl: 'text.component.html',
    styleUrls: ['./text.component.scss'],
})

export class TextComponent implements OnInit {
    @Input() theme: TextTheme = 'paragraph';
    @Input() required = false;

    constructor() { }

    ngOnInit() { }
}