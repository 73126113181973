import { EventService } from './event/event.service';
import { Injectable } from '@angular/core';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { Observable, Subject } from 'rxjs';
import {
    ISpec,
    IDoctor,
    IRecTalon,
    ICalendarData,
    IStaticFilter,
    IRnumbDate,
    IDep,
    ITalonInfo,
    IRecTalonInit,
    ITalonResBlStatus,
    ITalonResAppointment,
    ITalonResAttrs,
    ITalonResCPbyR,
    ITalonResPaymentTemp,
    ITalonResCansel,
    ITalonsDaySetParams,
} from '../interfaces/record.interface';
import { dateToText } from '../components/application/global.function';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class RecordService {
    /* События календаря */
    CalendaryinitSubject = new Subject<ICalendarData>();
    CalendarysetParams(param: ICalendarData) {
        // Установить параметры для календаря
        if (this.CalendaryinitSubject) {
            this.CalendaryinitSubject.next(param);
        }
    }
    // Событие инициализации
    CalendaryonInit$(): Observable<ICalendarData> {
        return this.CalendaryinitSubject.asObservable();
    }
    // ******************************************************
    /* События талонов */
    TalonsInitSubject = new Subject<IRecTalonInit>();
    TalonsSetParams(param: IRecTalonInit) {
        // Передать список талонов
        if (this.TalonsInitSubject) {
            this.TalonsInitSubject.next(param);
        }
    }
    // Событие инициализации списка талонов
    TalonsOnInit$(): Observable<IRecTalonInit> {
        return this.TalonsInitSubject.asObservable();
    }

    TalonsDayInitSubject = new Subject<ITalonsDaySetParams>();
    TalonsDaySetParams(param: ITalonsDaySetParams) {
        // Передать выбранные день
        if (this.TalonsDayInitSubject) {
            this.TalonsDayInitSubject.next(param);
        }
    }
    // Событие инициализации списка талонов
    TalonsDayOnInit$(): Observable<ITalonsDaySetParams> {
        return this.TalonsDayInitSubject.asObservable();
    }
    // *****************************************************
    /* События выбор талонов*/
    RnumbDateinitSubject = new Subject<IRnumbDate>();
    RnumbDateSetParams(param: IRnumbDate) {
        // Установить параметры для Блок Rnumb
        if (this.RnumbDateinitSubject) {
            this.RnumbDateinitSubject.next(param);
            this.eventService.emit('calendar:loading');
        }
    }
    // Событие инициализации
    RnumbDateOnInit$(): Observable<IRnumbDate> {
        return this.RnumbDateinitSubject.asObservable();
    }

    /* События списка подразделений */
    DepInitSubject = new Subject<IDep[]>();
    DepSetParams(param: IDep[]) {
        // Установить параметры для Блок Rnumb
        if (this.DepInitSubject) {
            this.DepInitSubject.next(param);
        }
    }
    // Событие инициализации
    DepOnInit$(): Observable<IDep[]> {
        return this.DepInitSubject.asObservable();
    }

    // Событие Выбора Dep
    DepSelectSubject = new Subject<IDep>();
    DepSelect(param: IDep) {
        // Установить параметры для Блок Rnumb
        if (this.DepSelectSubject) {
            this.DepSelectSubject.next(param);
        }
    }
    // Событие Выбора
    DepOnSelect$(): Observable<IDep> {
        return this.DepSelectSubject.asObservable();
    }

    // *****************************************

    constructor(
        private httpNew: HttpNewService,
        private auth: AuthService,
        private eventService: EventService
    ) {}

    /**
     * Список специальностей
     * @param parameters
     * beginDate: Date,
     * endDate: Date
     * staticId - id фильтра
     */
    public getSpecList(
        beginDate: Date,
        endDate: Date,
        staticId: number | null
    ): Observable<ISpec[]> {
        let url = '/record/spec/list';
        if (beginDate && endDate) {
            url =
                url +
                `?beginDate=${dateToText(beginDate)}&endDate=${dateToText(
                    endDate
                )}`;
        }
        if (staticId) {
            url = url + `&stacId=${staticId}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /**
     * Список докторов
     * @param parameters
     */
    /*
  public getHistoryEvents(pStart?: number , pEnd?: number): Observable<IHistoryEvents[]> {
    let url = '/history/events';
    if (pStart && pEnd) {
      url = url + `?start=${pStart}&end=${pEnd}`;
    }
*/

    public getDocList(
        pSpecId: number,
        beginDate: Date,
        endDate: Date,
        stacId: number | null
    ): Observable<IDoctor[]> {
        let url = '/record/doc/list';
        if (pSpecId && beginDate && endDate) {
            url =
                url +
                `?specID=${pSpecId}&beginDate=${dateToText(
                    beginDate
                )}&endDate=${dateToText(endDate)}`;
        }
        if (stacId) {
            url = url + `&stacId=${stacId}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    public getRnumbList(
        pDoctorId: number,
        pSpecId: number | null,
        beginDate: Date,
        endDate: Date,
        stacId: number | null,
        srvIds: string | null
    ): Observable<IRecTalon[]> {
        let url = '/record/rnumb/list';
        if (pDoctorId && pSpecId && beginDate && endDate) {
            url =
                url +
                `?specID=${pSpecId}&doctorID=${pDoctorId}&beginDate=${dateToText(
                    beginDate
                )}&endDate=${dateToText(endDate)}`;
            if (stacId) {
                url = url + `&stacId=${stacId}`;
            }
            if (srvIds) {
                url = url + `&srvIds=${srvIds}`;
            }
        }
        return this.httpNew.get(url, this.auth.token);
    }
    /* Список фильтров кнопок (метки на талонах) */
    public getStacfilterList(): Observable<IStaticFilter[]> {
        let url = '/record/stacfilter/list';
        return this.httpNew.get(url, this.auth.token);
    }

    /* Список подразделений */
    public getDepList(
        pDoctorId: number,
        pSpecId: number | null,
        servId: number | null
    ): Observable<IDep[]> {
        let url = '/record/dep/list';
        if (pDoctorId && pSpecId) {
            url = url + `?doctorID=${pDoctorId}&specID=${pSpecId}`;
        }
        if (servId) {
            url = url + `&servID=${servId}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Информация о талоне */
    public getRnumbInfo(rnumbID: number): Observable<ITalonInfo[]> {
        let url = '/record/rnumb/info';
        if (rnumbID) {
            url = url + `?rnumbID=${rnumbID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Блокировка талона */
    public setRnumbBlStatus(rnumbID: number): Observable<ITalonResBlStatus> {
        let url = '/record/rnumb/blstatus';
        if (rnumbID) {
            url = url + `?rnumbID=${rnumbID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Запись талона */
    public setAppointment(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResAppointment> {
        let url = '/record/rnumb/appointment';
        if (rnumbID) {
            url = url + `?rnumbID=${rnumbID}`;
        }
        if (srvID) {
            url = url + `&srvID=${srvID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Проверка статусов талона */
    public getAttrs(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResAttrs> {
        let url = '/record/rnumb/attrs';
        url = url + `?rnumbID=${rnumbID}`;

        if (srvID) {
            url = url + `&srvID=${srvID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Создание платежа после записи на номерок */
    public getCPbyR(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResCPbyR> {
        let url = '/record/rnumb/cpbyr';
        url = url + `?rnumbID=${rnumbID}`;
        if (srvID) {
            url = url + `&srvID=${srvID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Создание Видео-конференции после записи на номерок */
    public getСreateConference(rnumbID: number): Observable<any> {
        let url = '/trueconf/createConference';
        url = url + `?rnumbID=${rnumbID}`;
        return this.httpNew.get(url, this.auth.token);
    }

    /* Оплата отложенного платежа */
    public getPaymentTemp(
        paymentID: number,
        email: string | null,
        phone: string | null
    ): Observable<ITalonResPaymentTemp> {
        let url = '/record/rnumb/paymenttemp';
        url = url + `?paymentID=${paymentID}`;
        if (email) {
            url = url + `&email=${email}`;
        }
        if (phone) {
            url = url + `&phone=${phone}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Отмена талона */
    public getRnumbCancel(rnumbID: number): Observable<ITalonResCansel> {
        let url = '/record/rnumb/cancel';
        if (rnumbID) {
            url = url + `?rnumbID=${rnumbID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* Разблокировка талона талона */
    public getRnumbUnlock(rnumbID: number): Observable<ITalonResCansel> {
        let url = '/record/rnumb/unlock';
        if (rnumbID) {
            url = url + `?rnumbID=${rnumbID}`;
        }
        return this.httpNew.get(url, this.auth.token);
    }

    /* создание интервального расписания */
    public getRnumbCreateInterval(
        intervalID: number,
        dat_bgn: string,
        dat_end: string
    ): Observable<ITalonResCansel> {
        let url = '/record/rnumb/create/interval';
        let params = {
            interval_id: intervalID,
            dat_bgn: dat_bgn,
            dat_end: dat_end,
        };
        return this.httpNew.post(url, params, this.auth.token);
    }
}
