export const VISIT_ALLOWED_EXTENSIONS = [
    'zip',
    'rar',
    'iso',
    'pdf',
    'doc',
    'docx',
    'jpg',
    'jpeg',
    'png',
    'xls',
    'xlsx',
];

export const MAX_FILE_SIZE = 1_073_741_822 as const;
