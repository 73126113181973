import { Component, OnInit } from '@angular/core';
import {
    IBactery,
    ILabOrder,
    ILabResearch,
    ILabResearchForm,
    IPakaz,
    IResearchForm,
    IResearchFormBio,
} from '../../../interfaces/lab.interface';
import { LabService } from '../../../services/lab.service';
import { Size } from '../../../services/size';
import { compToPagination } from '../../../services/compToPagination';
import { ConfigService } from '../../../services/application/config.service.';
import { LoadService } from '../../../services/application/load.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { IPeriod } from '../../../interfaces/period.interface';
import { of } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import {
    getStrEnd,
    getTekDayDATE,
    getTekDay,
    getNameDay,
    getTime,
} from '../../application/global.function';
import { IResearchFileList } from '../../../interfaces/laboratory-services.interface';
import { AuthService } from '../../../services/auth.service';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/lib/ariadna/alert';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-labs-pc',
    templateUrl: './labs-pc.component.html',
    styleUrls: ['./labs-pc.component.scss'],
})
export class LabsPcComponent implements OnInit {
    //    blockBodystyleHeight = {height:'auto'};
    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';
    isCovid = false; // КОВИД значение

    orderList: ILabOrder[] | null = null;
    selectedOrder: ILabOrder | null = null; // Выбранный заказ
    labResearchs: ILabResearch[] | null = null;

    labResult: ILabResearchForm[] = []; // Итоговый результат

    orderListLength: number = 0; // Количество записей
    countRectoPage = 6; // Количетсво записей на странице

    loadingOrderList = false; // Загрузка заказов
    loadingResearchList = false; // Загрузка результатов
    private _setting: any;
    public onlyPdf = false; // Только документы и PDF (// Отображать результаты только в PDF)
    public pdfHtml: SafeHtml | null = null; // HTML pdf файла
    buttonNameEnabled: string = '';
    isNotSecondClick: boolean = false;
    ButtonActiveColorOneDay: boolean = false;
    isNotShowResult = false;

    pdfLoading = false;

    constructor(
        private lab: LabService,
        private size: Size,
        private cToP: compToPagination,
        private configS: ConfigService,
        private load: LoadService,
        private title: Title,
        private sanitizer: DomSanitizer,
        private auth: AuthService,
        private alert: AlertService,
        private http: HttpNewService
    ) {
        moment.locale('ru');
        this._setting = load.configData.pages.laboratory;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        if (this._setting.WEB_LK_LAB_SHOW_RES_ONLY_PDF) {
            this.onlyPdf = this._setting.WEB_LK_LAB_SHOW_RES_ONLY_PDF == 1;
        }
        //

        /*
        this.size.onResize$().subscribe(val => {
            let h = val.outletH;
            if (val.blockHeaderH && val.blockHeaderH>0){
                h = h- val.blockHeaderH;
            }
            this.blockBodystyleHeight.height = h +'px';
        });
*/
    }

    ngOnInit(): void {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 365);
        this.dtEnd = new Date();
        // test
        //        this.dtBegin = new Date('2019.09.2');
        //        this.dtEnd = new Date('2019.09.30');

        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    // Количество записей
    getOrdersSize() {
        this.loadingOrderList = true;
        this.orderList = null;
        this.lab
            .getLaboratoryOrderSize(this.dtBegin, this.dtEnd)
            //    this.lab.getLaboratoryOrderSize(new Date(2015, 1, 1), new Date(2021, 18, 1))
            .subscribe(
                (res) => {
                    if (res.size) {
                        this.orderListLength = res.size;
                        this.getOrders(1, this.countRectoPage);
                    } else {
                        this.orderListLength = 0;
                        this.loadingOrderList = false;
                    }
                },
                (err) => {
                    console.error('ERRROr=', err);
                    this.loadingOrderList = false;
                }
            );
    }

    getOrders(pStart?: number, pEnd?: number) {
        this.loadingOrderList = true;
        this.orderList = null;
        let order = 'acs';
        if (this.orderUP) {
            order = 'desc';
        } else {
            order = 'acs';
        }
        this.lab
            .getLaboratoryOrderList(
                this.dtBegin,
                this.dtEnd,
                pStart,
                pEnd,
                order
            )
            //    this.lab.getLaboratoryOrderList(new Date(2015, 1, 1), new Date(2021, 18, 1), pStart, pEnd, order)
            .subscribe(
                (res) => {
                    //
                    this.orderList = res;
                    this.orderList.forEach((item) => {
                        item.dtSort = new Date(
                            item.regdate.replace(
                                /(\d+).(\d+).(\d+)/,
                                '$3/$2/$1'
                            )
                        );
                    });
                    //
                    if (this.orderList && this.orderList.length > 0) {
                        this.selectOrder(this.orderList[0]);
                    }
                    this.loadingOrderList = false;
                },
                (err) => {
                    console.error('ERRROr=', err);
                    this.loadingOrderList = false;
                }
            );
    }
    checkEnabledButton(buttonName: string) {
        if (this.buttonNameEnabled === '') {
            this.buttonNameEnabled = buttonName;
            return true;
        } else {
            if (this.buttonNameEnabled === buttonName) {
                this.ngOnInit();
                this.buttonNameEnabled = '';
                return false;
            } else {
                this.buttonNameEnabled = buttonName;
                return true;
            }
        }
    }

    // За сегодня
    btnFiltrTek() {
        this.isNotSecondClick = this.checkEnabledButton('oneDay');

        if (this.isNotSecondClick) {
            this.dtBegin = new Date();
            this.dtEnd = new Date();
            this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
        }
    }
    // За 3 дня
    btnFiltr3Day() {
        this.isNotSecondClick = this.checkEnabledButton('threeDays');
        if (this.isNotSecondClick) {
            this.dtBegin = new Date();
            this.dtBegin.setDate(this.dtBegin.getDate() - 3);
            this.dtEnd = new Date();
            this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
        }
    }

    sortUp() {
        this.cToP.setPage(1); // Переходим на первую страницу
        /*
            if (this.orderList) {
              this.orderList.sort((a, b) => a.dtSort < b.dtSort ? 1 : -1);
            }
        */
    }

    sortAp() {
        this.cToP.setPage(1); // Переходим на первую страницу
        /*
            if (this.orderList) {
              this.orderList.sort((a, b) => a.dtSort > b.dtSort ? 1 : -1);
            }
        */
    }

    // С начала последние
    btnFiltrOrderUp() {
        if (this.orderList && this.orderList.length > 0) {
            this.orderUP = !this.orderUP;
            if (this.orderUP === true) {
                this.orderUPText = 'Сначала последние';
                this.sortUp();
            } else {
                this.orderUPText = 'Сначала первые';
                this.sortAp();
            }
        }
    }

    getTekDay(dt: Date | null | undefined): string {
        if (dt) {
            return getTekDay(dt);
        } else {
            return '!';
        }
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    getTime(dt: Date): string {
        return getTime(dt);
    }

    changePeriod(dt: IPeriod) {
        //
        this.dtBegin = dt.begin;
        this.dtEnd = dt.end;
        this.getOrdersSize();
    }

    /* Событие выбора страницы */
    changedPage(page: any) {
        //

        if (page === 1) {
            this.getOrders(1, this.countRectoPage);
        } else {
            this.getOrders(
                page * this.countRectoPage - this.countRectoPage + 1,
                page * this.countRectoPage
            );
        }
    }

    isBlockYeer(index: number): boolean {
        let f = false;
        if (
            this.orderList &&
            this.orderList[index] &&
            this.orderList[index - 1]
        ) {
            if (
                this.orderList[index].dtSort.getFullYear() !==
                this.orderList[index - 1].dtSort.getFullYear()
            ) {
                f = true;
            }
        }
        if (index === 0) {
            f = true;
        }
        return f;
    }

    getYeer(index: number): string {
        if (this.orderList && this.orderList[index]) {
            return this.orderList[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }

    selectOrder(order: ILabOrder) {
        this.selectedOrder = order;
        // console.log(order.text_status);
        this.loadingResearchList = true;
        if (
            order.text_status === 'выполнен' ||
            order.text_status === 'частично выполнен'
        ) {
            this.isNotShowResult = false;
            //  selectOrder(this.selectedOrder.research_id: number) {
            // PDF
            if (this.onlyPdf) {
                this.getResearchFileList(order.research_id);
                this.loadingResearchList = false;
            } else {
                this.labResearchs = [];
                // this.loadingResearchList = true;
                this.labResult = [];
                let resFormaBio: ILabResearch[] = [];
                let resForma: ILabResearch[] = [];

                let formBio: IResearchFormBio[] = [];
                let form: IResearchForm[] = [];
                this.isCovid = false;

                //
                /*
              let tempId = 0;
              if (order.research_id == 441074526497) {
                tempId = 441074522290;
              } else {
                tempId = order.research_id;
              }
        */
                this.lab.getLaboratoryResearch(order.research_id).subscribe(
                    (res) => {
                        this.labResearchs = res;
                        if (res && res.length > 0) {
                            res.forEach((item, index) => {
                                if (
                                    !item.test_name &&
                                    !item.res_display_order
                                ) {
                                    // форма BIO
                                    resFormaBio.push(item);
                                } else {
                                    resForma.push(item);
                                }
                            });
                        }

                        //
                        //
                        //          let resultBio: IResearchFormBio[] = [];

                        if (resFormaBio.length > 0) {
                            of(resFormaBio)
                                .pipe(
                                    mergeMap((res) => res),
                                    groupBy((person) => person.ids), // Группируем по IDS
                                    mergeMap((group) => group.pipe(toArray())) // Возврат массива по группам
                                )
                                .subscribe((val) => {
                                    //
                                    // Группируем по микроорганизмам
                                    of(val)
                                        .pipe(
                                            mergeMap((res) => res),
                                            groupBy(
                                                (micro) =>
                                                    micro.abio_bactery_text
                                            ), // Группируем
                                            mergeMap((group) =>
                                                group.pipe(toArray())
                                            ) // Возврат массива по группам
                                        )
                                        .subscribe((valMicro) => {
                                            //
                                            let tmp: IBactery[] = [];
                                            tmp = <IBactery[]>valMicro.map(
                                                (itemMicro) => {
                                                    return {
                                                        name: itemMicro.res_antibiotic_text,
                                                        sir: itemMicro.res_sir,
                                                        mic: itemMicro.res_mic,
                                                        dia: itemMicro.res_dia,
                                                    };
                                                }
                                            );
                                            //

                                            formBio.push({
                                                name: valMicro[0]
                                                    .abio_bactery_text,
                                                bactery: tmp,
                                            });
                                        });

                                    //
                                    this.labResult.push({
                                        formBio: true,
                                        name: val[0].specimen_text,
                                        ids: val[0].ids,
                                        res: <IResearchFormBio[]>formBio,
                                        srvText: val[0].srvtext,
                                        srvRes: val[0].abio_conclusion,
                                        note: val[0].abio_note,
                                    });
                                    formBio = [];
                                });
                        }

                        //
                        // Разбираем стандартную
                        if (resForma.length > 0) {
                            of(resForma)
                                .pipe(
                                    mergeMap((res) => res),
                                    groupBy((frm) => frm.ids), // Группируем по IDS title_id
                                    mergeMap((group) => group.pipe(toArray())) // Возврат массива по группам
                                )
                                .subscribe((val) => {
                                    //
                                    let resPokaz: IPakaz[] = [];
                                    form = [];
                                    // Группировка по title
                                    of(val)
                                        .pipe(
                                            mergeMap((res) => res),
                                            groupBy(
                                                (frmTitle) => frmTitle.title_id
                                            ), // Группируем по title_id
                                            mergeMap((group) =>
                                                group.pipe(toArray())
                                            ) // Возврат массива по группам
                                        )
                                        .subscribe(
                                            (valTitle) => {
                                                resPokaz = [];
                                                //
                                                valTitle.forEach((item) => {
                                                    resPokaz.push({
                                                        name: this.getParamStrAndNull(
                                                            item.test_name
                                                        ),
                                                        val: this.getParamNumberAndNull(
                                                            item.value
                                                        ),
                                                        valEd: this.getParamStrAndNull(
                                                            item.unit
                                                        ),
                                                        min: this.getParamNumberAndNull(
                                                            item.norm_min
                                                        ),
                                                        max: this.getParamNumberAndNull(
                                                            item.norm_max
                                                        ),
                                                        last_res:
                                                            this.getParamNumberAndNull(
                                                                item.last_res
                                                            ),
                                                        patLevel:
                                                            this.getParamStrAndNull(
                                                                item.pat_level
                                                            ),
                                                        norm: this.getParamStrAndNull(
                                                            item.norm
                                                        ),
                                                    });
                                                });
                                                /* -- не показывал результаты без TITLE (COVID)
                                                if (valTitle[0].title) {
                                                  form.push({name: valTitle[0].title, listResult: resPokaz});
                                                }
                          */
                                                form.push({
                                                    name: valTitle[0].title,
                                                    listResult: resPokaz,
                                                });
                                            },
                                            (errTitle) => {
                                                console.error(
                                                    'ERRROr Title=',
                                                    errTitle
                                                );
                                                this.loadingResearchList =
                                                    false;
                                            }
                                        );
                                    //
                                    this.labResult.push({
                                        formBio: false,
                                        name: val[0].specimen_text,
                                        ids: val[0].ids,
                                        res: form,
                                    });
                                });
                        }
                        //
                        //

                        this.loadingResearchList = false;
                    },
                    (err) => {
                        console.error('ERRROr=', err);
                        this.loadingResearchList = false;
                    }
                );
            }
        } else {
            this.isNotShowResult = true;
            this.loadingResearchList = false;
        }
    }

    getParamStrAndNull(pr: any): string | null {
        if (pr) {
            return pr;
        } else {
            return null;
        }
    }
    getParamNumberAndNull(pr: any): number | null {
        if (pr) {
            return pr;
        } else {
            return null;
        }
    }

    //  getDateOrder(dt: Date | null): string {
    getDateOrder(dt: Date | null | undefined): string {
        if (dt) {
            return getTekDayDATE(dt);
            //      return moment(dt).format('D MMM YYYY');
        } else {
            return '';
        }
    }

    resForFormBio(
        r: IResearchFormBio[] | IResearchForm[] | null
    ): IResearchFormBio[] {
        return <IResearchFormBio[]>r;
    }

    resForForm(
        r: IResearchFormBio[] | IResearchForm[] | null
    ): IResearchForm[] {
        return <IResearchForm[]>r;
    }

    strEnd(val: number | undefined, str: string, massStr: string[]): string {
        return getStrEnd(val, str, massStr);
    }

    async goPdf(researchId: number | undefined) {
        this.pdfLoading = true;
        if (researchId) {
            try {
                const res = await this.http
                    .get(
                        this.getDownloadUrlForPDF(researchId),
                        null,
                        null,
                        true
                    )
                    .toPromise();

                const emptyPage = window.open('about:blank', '_blank');
                if (emptyPage) {
                    const file = new Blob([res], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    emptyPage.location.href = fileURL;
                }
            } catch (error) {
                this.alert.error('Ошибка загрузки');
            } finally {
                this.pdfLoading = false;
            }
        }
    }
    getDownloadUrlForPDF(researchId: number | undefined) {
        return `/report/pdf/${researchId}.pdf`;
    }

    //  isPrint(order: ILabOrder | null): boolean{
    isPrint(labResearch: ILabResearchForm[] | null): boolean {
        let f = false;
        // console.log(labResearch);
        if (labResearch && labResearch.length > 0) {
            //
            labResearch.forEach((item) => {
                if (item.res && item.res.length > 0) {
                    // @ts-ignore
                    item.res.forEach((itemRes) => {
                        //            if (itemRes && itemRes.name && itemRes.name.length>1){
                        if (itemRes) {
                            f = true;
                        }
                    });
                }
            });
        }
        return f;
    }

    public pdfPreview(file: IResearchFileList): void {
        //
        let pdfPatch = '';
        this.pdfHtml = null;
        if (file) {
            pdfPatch =
                this.configS.getValue('hostBackend') +
                `/labs/research/${file.researchId}/patient/${this.auth.patientId}/file/${file.id}/type/${file.file_type}/${file.file_name}`;
            this.pdfHtml = this.sanitizer.bypassSecurityTrustHtml(
                `<embed src="${pdfPatch}" type="application/pdf" width="100%" height="680px">`
            );
            //
        }
    }

    public selectPDFPreview(researchFileList: IResearchFileList[]): void {
        this.pdfHtml = null;
        if (researchFileList.length > 0) {
            let sFile: IResearchFileList;
            const lab_RESEARCH_DOCUMENT = researchFileList.filter(
                (fItem) => fItem.file_type === 'LAB_RESEARCH_DOCUMENT'
            );
            //

            if (lab_RESEARCH_DOCUMENT.length > 0) {
                sFile = lab_RESEARCH_DOCUMENT[0];
                lab_RESEARCH_DOCUMENT.forEach((item) => {
                    if (item.id > sFile.id) {
                        sFile = item;
                    }
                });
            } else {
                sFile = researchFileList[0];
                researchFileList.forEach((item) => {
                    if (item.id > sFile.id) {
                        sFile = item;
                    }
                });
            }
            this.pdfPreview(sFile);
        }
    }

    /* Получить список документов по номеру заказа */
    private getResearchFileList(researchId: number): void {
        let researchFileList: IResearchFileList[] = [];

        this.lab.getResearchFileList(researchId).subscribe(
            (res) => {
                researchFileList = res;
                //
                // Дополнение
                researchFileList.forEach((item) => {
                    item.researchId = researchId;
                    let fileName = ' ' + item.fileName;
                    fileName = fileName.split('/').join('');
                    fileName = fileName.split(String.fromCharCode(92)).join('');
                    fileName = fileName.split(String.fromCharCode(32)).join('');
                    item.fileName = fileName;
                });
                this.selectPDFPreview(researchFileList);
            },
            (err) => {
                console.error('getResearchFileList=', err);
                this.loadingResearchList = false;
            }
        );
    }
}
