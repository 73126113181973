import { Injectable } from '@angular/core';
import { Protocol, ProtocolInfo, ProtocolItem } from '../interfaces/protocol';
import { HttpNewService } from './application/http-new.service';
import { ProtocolBuilder } from '../interfaces/protocol';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class ProtocolService {
    constructor(
        private http: HttpNewService,
        private authService: AuthService
    ) {}

    async getProtocol(
        formResultId: number,
        visitID: number
    ): Promise<Protocol> {
        const token = this.authService.token;
        const data = {
            result_id: formResultId.toString(),
            visit_id: visitID.toString(),
        };
        const params = new URLSearchParams(data);

        const items = await this.http
            .get('/protocol/template?' + params, token)
            .toPromise<ProtocolItem[]>();

        const protocol = ProtocolBuilder(items);

        return protocol;
    }

    async saveProtocol(protocol: Protocol, visitID: number) {
        const token = this.authService.token;
        const fields = protocol.prepareToSave(visitID);
        return this.http.post('/protocol/save', { fields }, token).toPromise();
    }

    /**
     * Создание протокола после подтвержения записи на приём к врачу
     */
    async createProtocol(visitId: number, formId: number) {
        const token = this.authService.token;
        const data = {
            p_visit_id: visitId,
            p_form_id: formId,
        };

        return this.http.post('/protocol/create', data, token).toPromise();
    }

    /**
     * Проверка на наличе возмжности просмотра и редактирования протокола пациентом
     */
    async checkProtocol(
        patientId: number,
        formResultId: number,
        visitID: number
    ) {
        const token = this.authService.token;
        const data = {
            p_patient_id: patientId.toString(),
            p_form_result_id: formResultId.toString(),
            p_visit_id: visitID.toString(),
        };
        const params = new URLSearchParams(data);

        return this.http
            .get(`/protocol/check?${params}`, token)
            .toPromise<ProtocolInfo[]>();
    }
}
