import {
    MAX_FILE_SIZE,
    VISIT_ALLOWED_EXTENSIONS,
} from './../../interfaces/constants';
import { Component, Inject, OnInit } from '@angular/core';
import { RecordService } from '../../services/record.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    IResRecord,
    ITalonInfo,
    ITalonModal,
    ITalonResAttrs,
} from '../../interfaces/record.interface';
import { ConfigService } from '../../services/application/config.service.';
import {
    getNameDay,
    strToDate,
} from '../../components/application/global.function';
import * as moment from 'moment';
import { AlertService } from 'src/lib/ariadna/alert';
import { IPay, IPaySystem } from '../../interfaces/payment.interface';
import { PaymentsService } from '../../services/payments.service';
import { PatientService } from '../../services/patient.service';
import { IPatient } from '../../interfaces/patient';
import { RnumbService } from '../../services/rnumb.service';
import { LoadService } from '../../services/application/load.service';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

import {
    EUploadStatus,
    IAttachedFile,
} from 'src/app/interfaces/document.interface';
import { ProtocolService } from 'src/app/services/protocol.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SocketService } from 'src/app/services/socket/socket.service';
@Component({
    selector: 'app-new-talon-mobile',
    templateUrl: './talon.component.html',
    styleUrls: ['./talon.component.scss'],
})
export class NewTalonComponentMobile implements OnInit {
    page: string = 'talon'; // ['talon', 'record', 'pay', 'canselOK']; // какую страницу показывать
    params: ITalonModal | null;
    talon: ITalonInfo | null = null;
    isResRecord: IResRecord = {
        recTalon: false,
        payOnline: false,
        payClinic: false,
    }; // Есть результат записи
    payParam: IPay = { phone: '', email: '', sum: 0, abon_id: 0 }; // Оплата
    private _patient!: IPatient;
    recToPay: boolean = false; // Записаться к врачу на "Оплатить"
    attrTalonRec: ITalonResAttrs | null = null; // Аттрибуты талона
    paymentid: number = 0; // Номер платежа
    //  paySystem: IPaySystem = {isPhone: false, isEmail:false, code: '', requiredFieldCodes: []}; // Тип платежной системы
    paySystem: IPaySystem | null = null; // Тип платежной системы
    //  private _paySystemInfo: IPaySystemInfo  | null ; // Платежные системы
    loadingTalon: boolean = false; // Загрузка
    cancelingTalon: boolean = false; // Флаг отмены номерка
    loadingPay: boolean = false; // Флаг загрузки оплаты платежа
    f_appoitment: boolean = false; // Флаг записи на талон
    WEB_LK_PAY_LIFETIME = 60; // Время жизни платежа
    error: string | null = null;
    payable: boolean = false;
    attachedFiles = new Map<string, IAttachedFile>();
    allowedExt = '';
    //TODO убрать после полного перехода на node-web-pay (не успевает создавать услугу с платежом, если быстро нажать "оплатить")
    debounced = true;
    constructor(
        // private window: Window,
        private configS: ConfigService,
        private rs: RecordService,
        private rnumbS: RnumbService,
        private alertService: AlertService,
        private paymentsService: PaymentsService,
        private patientService: PatientService,
        private load: LoadService,
        public dialogRef: MatDialogRef<NewTalonComponentMobile>,
        @Inject(MAT_DIALOG_DATA) public dataIN: ITalonModal,
        private router: Router,
        @Inject(APP_BASE_HREF) private baseHref: string,
        private uploadService: FileUploadService,
        private protocolService: ProtocolService
    ) {
        this.params = dataIN;
        this.allowedExt = VISIT_ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(
            ','
        );

        if (dataIN.files) this.setFilesFromInput(dataIN.files);

        if (
            load.configData.pages &&
            load.configData.pages.payments &&
            load.configData.pages.payments.WEB_LK_PAY_LIFETIME
        ) {
            this.WEB_LK_PAY_LIFETIME = Number(
                load.configData.pages.payments.WEB_LK_PAY_LIFETIME
            );
        }
    }
    // private unsubscribe$ = new Subject<void>();

    // ngOnChanges(): void {
    //     if (!this.params?.rnumbID || this.f_appoitment) {
    //         return;
    //     }
    //     console.log('load', this.params.rnumbID, this.f_appoitment);
    //     this.unsubscribe$
    //         .pipe(
    //             take(1),
    //             switchMap(() => this.unlockTalon(this.params?.rnumbID!)),
    //             tap(() => console.log('load')),
    //             catchError((error) => {
    //                 console.error('Error unlocking talon:', error);
    //                 return EMPTY;
    //             }),
    //             take(1)
    //         )
    //         .subscribe();
    // }

    // private unlockTalon(rnumbID: number) {
    //     return this.rs.getRnumbUnlock(rnumbID).pipe(
    //         catchError((error) => {
    //             console.error('Error unlocking talon:', error);
    //             return EMPTY;
    //         })
    //     );
    // }

    ngOnInit(): void {
        window.addEventListener(
            'beforeunload',
            (event) => {
                localStorage.setItem(
                    'talonBlock',
                    JSON.stringify({
                        rnumbID: this.params?.rnumbID,
                        f_appoitment: this.f_appoitment,
                    })
                );
                // event.preventDefault();
                // return 'sssssssssssssssssuka';
            }
            // false
        );
        this.page = 'talon';
        if (this.params) {
            let rnumbID = this.params.rnumbID;
            this.getInfo(rnumbID);
        } else {
            console.error('Not params ID');
        }
        this.getPatientInfo();
        this.getPaySystem();
        if (this.dataIN.withUpload) this.setFilesFromDB();
        // this.dialogRef.beforeClosed().subscribe(() => {
        //     if (!this.f_appoitment && this.params?.rnumbID) {
        //         this.rs.getRnumbUnlock(this.params?.rnumbID).subscribe(
        //             (res) => {
        //                 if (res.err_code)
        //                     console.error(
        //                         `Rnumb unblock error: ${res.err_text}`
        //                     );
        //             },
        //             (error) => {
        //                 console.error(error);
        //             }
        //         );
        //     }
        // });
    }
    /* Получить данные по пациенту*/
    getPatientInfo() {
        this.patientService.getServerPatientInfo$().subscribe(
            (info) => {
                this._patient = info;
                //
                if (this._patient.email) {
                    this.payParam.email = this._patient.email;
                }
                if (this._patient.phone) {
                    this.payParam.phone = this._patient.phone;
                } else {
                    if (this._patient.cellular) {
                        this.payParam.phone = this._patient.cellular;
                    }
                }

                this.getPaySystem(); // Тип платежной системы
            },
            (error) => console.error(error)
        );
    }

    /* Получить тип платежной системы телефон почта !*/
    private getPaySystem() {
        this.paySystem = null;
        //    this.paySystemField ='';

        this.paymentsService.getPaySystem().subscribe(
            (info) => {
                this.paySystem = info.paysystems[0];
                //          this.paySystemField = this.paySystem.requiredFieldCodes[0];
                switch (this.paySystem.requiredfieldcodes[0]) {
                    case 'phone': {
                        this.paySystem.isPhone = true;
                        break;
                    }
                    case 'email': {
                        this.paySystem.isEmail = true;
                        break;
                    }
                }
            },
            (err) => {
                console.error('getPaySystem ERR1=', err);
            }
        );
    }

    getInfo(rnumbID: number) {
        this.rs.getRnumbInfo(rnumbID).subscribe(
            (res) => {
                //
                if (res && res[0]) {
                    this.talon = res[0];
                    this.talon.beginDate = strToDate(this.talon.dat_bgn);
                    this.talon.endDate = strToDate(this.talon.dat_end);
                    //
                    if (this.params && !this.params.srv) {
                        this.rnumbS.getRnumbSrv(rnumbID).subscribe(
                            (resS) => {
                                if (resS && this.params) {
                                    this.params.srv = resS;
                                    //
                                }
                            },
                            (errS) => {
                                console.error('getRnumbSrv ERRROr=', errS);
                            }
                        );
                    }
                }
            },
            (err) => {
                console.error('getRnumbInfo ERRROr=', err);
            }
        );
    }

    getImgSrcDoc(id: number | null | undefined): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    /* День и время*/
    getTalonDateTime(dt: Date | undefined): string {
        if (dt) {
            return (
                moment(dt).format('DD MMMM') +
                ' в ' +
                moment(dt).format('HH:mm')
            );
        } else {
            return '';
        }
    }

    /* День недели*/
    getNameDay(dt: Date | undefined): string {
        if (dt) {
            return getNameDay(dt);
        } else {
            return '';
        }
    }
    async onRecord() {
        this.loadingTalon = true;
        this.paymentid = 0;
        if (this.params) {
            
            const rnumbId = +this.params.rnumbID;
            let serviceId: number | null = null;
            if (this.params.srv && this.params.srv.keyid) {
                serviceId = +this.params.srv.keyid;
                this.payParam.sum = this.params.srv.price;
            }
            try {
                const response = await this.rs
                    .setAppointment(rnumbId, serviceId!)
                    .toPromise();
                if (response.err_code !== 0) {
                    throw new Error(`Запись невозможна, ${response.err_text}`);
                }
                this.f_appoitment = true;
                if (response.form_id) {
                    await this.createProtocol(
                        +response.visit_id,
                        +response.form_id
                    );
                }
                if (
                    this.params &&
                    this.params.srv &&
                    this.params.srv.keyid &&
                    this.params.srv.price > 0
                ) {
                    const attrs = await this.rs
                        .getAttrs(rnumbId, serviceId)
                        .toPromise();
                    this.attrTalonRec = attrs;
                    this.recToPay = true;
                    if (
                        attrs.is_online_pay === 0 &&
                        attrs.is_create_pay_order === 0
                    ) {
                        this.page = 'record';
                        this.isResRecord.recTalon = true;
                        this.loadingTalon = false;
                    } else {
                        if (attrs.is_create_pay_order === 1) {
                            if (attrs.is_telemed === 1) {
                                await this.rs
                                    .getСreateConference(rnumbId)
                                    .toPromise();
                            }
                            const resCPbyR = await this.rs
                                .getCPbyR(rnumbId, serviceId)
                                .toPromise();
                            if (resCPbyR.err_code !== 0) {
                                throw new Error(
                                    `Проблема с созданием платежа: ${resCPbyR.err_text}`
                                );
                            }
                            if (resCPbyR.paymentid) {
                                this.paymentid = resCPbyR.paymentid;
                            }
                            // await new Promise((resolve) =>
                            //     setTimeout(() => {
                            //         this.debounced = false; // legacy hell for web-pay Tomcat
                            //         resolve(true);
                            //     }, 3500)
                            // );

                            this.loadingTalon = false;
                        } else {
                            // this.page = 'record';
                            // this.isResRecord.recTalon = true;
                            // this.loadingTalon = false;
                        }
                    }
                    this.payable = true; // посоны, я не разбирался в этом дерьме, но мне надо было вставить этот флаг (вроде сюда), не могу ручаться, что работает 100%
                    this.loadingTalon = false;
                    await new Promise((resolve) =>
                        setTimeout(() => {
                            this.debounced = false; // legacy hell for web-pay Tomcat
                            resolve(true);
                        }, 3500)
                    );
                } else {
                    this.page = 'record';
                    this.isResRecord.recTalon = true;
                    this.loadingTalon = false;
                }
            } catch (error: any) {
                this.loadingTalon = false;
                // console.log(error.error.msg);
                this.handleError(
                    `Ошибка записи: ${
                        error.error.msg || (error as Error).message || error
                    }`,
                    rnumbId
                );
            }
        } else {
            this.loadingTalon = false;
        }
    }

    private handleError(error: any, rnumbID?: number) {
        if (this.params?.rnumbID && this.f_appoitment)
            this.rs.getRnumbCancel(this.params?.rnumbID).subscribe(
                (res) => {},
                (error) => {
                    console.error('Проблема с отменой записи на талон:', error);
                }
            );
        this.dialogRef.close({
            rnumbID: rnumbID,
            unlockTalon: true,
        });
        console.log(error);
        this.alertService.error(error);
    }

    async createProtocol(visitId: number, formId: number) {
        await this.protocolService.createProtocol(visitId, formId);
    }

    onCanselPage() {
        this.page = 'canselOK';
    }
    onClickPopTelemed() {
        this.page = 'goToTeleComp';
    }
    onCanselRecord() {
        if (this.params && this.params.rnumbID) {
            this.cancelingTalon = true;
            this.rs.getRnumbCancel(this.params.rnumbID).subscribe(
                (resCansel) => {
                    //
                    //
                    if (resCansel && resCansel.err_code === 0) {
                        this.alertService.success(
                            'Талон успешно отменен.',
                            4000
                        );
                        //              this.dialogRef.close(true);  // Закрываем
                        this.dialogRef.close({
                            rnumbID: this.params?.rnumbID,
                            canselRes: true,
                        }); // Закрываем
                    } else {
                        if (resCansel && resCansel.err_text) {
                            // this.alertService.error(resCansel.err_text);
                            this.showError(resCansel.err_text);
                        } else {
                            // this.alertService.error('Чтото пошло не так, попробуйте позже.');
                            this.showError(
                                'Чтото пошло не так, попробуйте позже.'
                            );
                        }
                    }
                    this.cancelingTalon = false;
                },
                (errCansel) => {
                    this.alertService.error(errCansel.error.msg, 7000);
                    console.error('getRnumbCancel ERRROr=', errCansel);
                    this.cancelingTalon = false;
                }
            );
        } else {
            // this.alertService.error('Нет номерка.')
            this.showError('Нет номерка.');
        }
    }
    //TODO убрать после полного перехода на node-web-pay (не успевает создавать услугу с платежом, если быстро нажать "оплатить")
    debouncePay() {
        setTimeout(() => {
            this.goToPay();
        }, 1000);
    }

    goToPay() {
        if (this.paymentid == 0) {
            this.loadingTalon = true;
            if (this.params && this.params.srv && this.params.srv.keyid) {
                this.rs
                    .getCPbyR(this.params.rnumbID, this.params.srv.keyid)
                    .subscribe(
                        (resCPbyR) => {
                            if (resCPbyR.paymentid) {
                                this.paymentid = resCPbyR.paymentid;
                            } else {
                                // this.alertService.error('Ошибка при создании платежа, не получили ID.');
                                this.showError(
                                    'Ошибка при создании платежа, не получили ID.'
                                );
                            }
                            this.loadingTalon = false;
                        },
                        (errCPbyR) => {
                            console.error(
                                'Ошибка при создании платежа.',
                                errCPbyR
                            );
                            // this.alertService.error('Ошибка при создании платежа.');
                            this.showError('Ошибка при создании платежа.');
                            this.loadingTalon = false;
                        }
                    );
            } else {
                // this.alertService.error('Нет услуги, нечего оплачивать.');
                this.showError('Нет услуги, нечего оплачивать.');
                this.loadingTalon = false;
            }
        }
        this.page = 'pay';
    }

    goPay() {
        if (this.params && this.params.rnumbID) {
            let rnumbID = this.params.rnumbID;
            let srvId: number | null = null;
            if (this.params && this.params.srv && this.params.srv.keyid) {
                srvId = this.params.srv.keyid;
            }
            this.loadingPay = true;

            if (this.attrTalonRec && this.attrTalonRec.is_online_pay == 1) {
                if (this.paymentid) {
                    this.rs
                        .getPaymentTemp(
                            this.paymentid,
                            this.payParam.email,
                            this.payParam.phone
                        )
                        .subscribe(
                            (resPay) => {
                                //
                                if (resPay.identity && resPay.identity > 0) {
                                    this.paymentsService
                                        .getPayOrder(resPay.identity)
                                        .subscribe(
                                            (resOrderPay) => {
                                                //
                                                if (
                                                    resOrderPay.confirmationurl
                                                ) {
                                                    window.open(
                                                        resOrderPay.confirmationurl
                                                    );
                                                    this.dialogRef.close();
                                                } else {
                                                    // this.alertService.error('Нет УРЛА');
                                                    this.showError(
                                                        'Не получилось создать платеж в платежной системе !'
                                                    );
                                                }
                                                this.loadingPay = false;
                                            },
                                            (errOrderPay) => {
                                                this.loadingPay = false;
                                                // this.alertService.error('Не получилось создать платеж в платежной системе, попробуйте позднее.');
                                                this.showError(
                                                    'Не получилось создать платеж в платежной системе, попробуйте позднее.'
                                                );
                                                console.error(
                                                    'getPayOrder ERRROr=',
                                                    errOrderPay
                                                );
                                            }
                                        );
                                } else {
                                    if (
                                        resPay.err_text &&
                                        resPay.err_text.length > 2
                                    ) {
                                        // this.alertService.error(resPay.err_text)
                                        this.showError(resPay.err_text);
                                    } else {
                                        // this.alertService.error('С платежами чтото не то.')
                                        this.showError(
                                            'С платежами чтото не то.'
                                        );
                                    }
                                    this.loadingPay = false;
                                }
                            },
                            (errPay) => {
                                // this.alertService.error('Ошибка создания платежа, попробуйте повторить операцию позднее.');
                                this.showError(
                                    'Ошибка создания платежа, попробуйте повторить операцию позднее.'
                                );
                                this.loadingPay = false;
                                console.error('getPaymentTemp ERRROr=', errPay);
                            }
                        );
                } else {
                    // Нет то генерим
                    this.rs.getCPbyR(rnumbID, srvId).subscribe(
                        (resCPbyR) => {
                            //
                            if (resCPbyR.paymentid) {
                                this.paymentid = resCPbyR.paymentid;
                            }
                            this.rs
                                .getPaymentTemp(
                                    this.paymentid,
                                    this.payParam.email,
                                    this.payParam.phone
                                )
                                .subscribe(
                                    (resPay) => {
                                        //
                                        this.loadingPay = false;
                                    },
                                    (errPay) => {
                                        this.loadingPay = false;
                                        console.error(
                                            'getPaymentTemp ERRROr=',
                                            errPay
                                        );
                                    }
                                );
                        },
                        (errCPbyR) => {
                            this.loadingPay = false;
                            console.error('getCPbyR ERRROr=', errCPbyR);
                        }
                    );
                }
            } else {
                if (this.paymentid) {
                    this.rs
                        .getPaymentTemp(
                            this.paymentid,
                            this.payParam.email,
                            this.payParam.phone
                        )
                        .subscribe(
                            (resPay) => {
                                //
                            },
                            (errPay) => {
                                console.error('getPaymentTemp ERRROr=', errPay);
                            }
                        );
                } else {
                    // this.alertService.error('Нет ИД платежа');
                    this.showError('Нет ИД платежа');
                }
                this.loadingPay = false;
            }
        }
    }

    validPay(): boolean {
        let f = true;
        if (
            (this.paySystem &&
                this.paySystem.isPhone &&
                this.payParam.phone &&
                this.payParam.phone.length > 10) ||
            (this.paySystem &&
                this.paySystem.isEmail &&
                this.payParam.email &&
                this.payParam.email.length > 5)
        ) {
            f = false;
        }
        return f;
    }

    setSumm(): string {
        if (
            this.params &&
            this.params.srv &&
            this.params.srv.price &&
            this.params.srv.price > 0
        ) {
            return `${this.params.srv.price.toFixed(0)} рублей`;
        } else {
            return 'Бесплатно';
        }
    }

    getFIODoc(talon: ITalonInfo | null): string {
        let s = '';
        if (talon) {
            if (talon.lastname && talon.lastname.length > 0) {
                s = talon.lastname;
            }
            if (talon.firstname && talon.firstname.length > 0) {
                s = s + ' ' + talon.firstname[0] + '.';
            }
            if (talon.secondname && talon.secondname.length > 0) {
                s = s + ' ' + talon.secondname[0] + '.';
            }
        }
        return s;
    }

    closeExit() {
        if (this.f_appoitment == true) {
            // Запись уже осуществилась
            this.dialogRef.close({
                rnumbID: this.params?.rnumbID,
                unlockTalon: false,
            }); // Закрываем
        } else {
            this.dialogRef.close({
                rnumbID: this.params?.rnumbID,
                unlockTalon: true,
            }); // Закрываем
        }
    }

    // Переход на телевидеоКонференцию
    goToTelemed(talon: ITalonInfo | null) {
        if (talon && talon.url_telemed && talon.url_telemed.length > 5) {
            window.open(talon.url_telemed, '_blank');
        }
    }

    showError(text: string) {
        this.error = text;
    }

    hideError() {
        this.error = null;
    }

    toPayments(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate([`/home/payments`]);
        this.dialogRef.close();
    }

    toHistory(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/history`]);
        this.dialogRef.close();
    }

    toRecord(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/rec`]);
        this.dialogRef.close();
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        const { dataTransfer } = event;
        if (!dataTransfer) return;

        const filelist = new DataTransfer();

        if (dataTransfer.items) {
            for (let i = 0; i < dataTransfer.items.length; i++) {
                if (dataTransfer.items[i].kind === 'file') {
                    const file = dataTransfer.items[i].getAsFile();
                    if (!file) continue;
                    filelist.items.add(file);
                }
            }
            this.setFilesFromInput(filelist.files);
            filelist.items.clear();
            dataTransfer.items.clear();
        }

        this.attachedFiles.forEach((file) => {
            this.uploadFile(
                file?.file!,
                this.params?.tag!,
                this.params?.link_id!
            );
        });
    }

    async uploadFile(file: File, tag: number, id: number) {
        const res = await this.uploadService.uploadFile(file, tag, id);
        const { success, data, msg } = res;

        if (success) {
            this.attachedFiles.set(file.name, {
                ...this.attachedFiles.get(file.name)!,
                id: Number(data?.id),
                status: EUploadStatus.SUCCESS,
                link_id: data?.id!,
            });
        } else {
            this.alertService.error(
                `Не удалось сохранить файл ${file.name} \n ${msg}`
            );
            this.deleteFile(file.name, id);
        }
    }

    async deleteFile(name: string, id: number) {
        this.attachedFiles.set(name, {
            ...this.attachedFiles.get(name)!,
            status: EUploadStatus.LOADING,
        });

        if (id) await this.uploadService.deleteFileFromDB(id);

        this.attachedFiles.set(name, {
            ...this.attachedFiles.get(name)!,
            status: EUploadStatus.SUCCESS,
        });

        this.attachedFiles.delete(name);
    }

    deleteFileHandler(event: Event, name: string, id: number) {
        event.stopPropagation();
        event.preventDefault();
        this.deleteFile(name, id);
    }

    getExt(name: string): string {
        return name.split('.').pop() ?? '';
    }

    onChangeInputUpload(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (!files) {
            console.log('NO FILES IN INPUT');
            return;
        }

        this.setFilesFromInput(files);
    }

    async setFilesFromInput(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!file) continue;
            const ext = this.getExt(file.name);
            const name = file.name.substring(0, file.name.indexOf(`.${ext}`));

            if (!VISIT_ALLOWED_EXTENSIONS.includes(ext)) {
                this.alertService.warn(
                    `Файл ${file.name} имеет неподходящее расширение`
                );
                continue;
            }

            if (file.size >= MAX_FILE_SIZE) {
                this.alertService.warn(`Файл ${file.name} силшком большой!`);
                continue;
            }

            if (this.attachedFiles.has(file.name)) {
                this.alertService.warn(
                    `${file.name} уже существует в базе или загружется сейчас`
                );
                continue;
            }

            this.attachedFiles.set(file.name, {
                file,
                ext,
                name,
                status: EUploadStatus.LOADING,
                id: null,
                link_id: null,
            });

            this.uploadFile(files[i], this.params?.tag!, this.params?.link_id!);
        }
    }

    // Get already uploaded files from db and set to attacheFiles
    async setFilesFromDB() {
        const filelist = await this.uploadService.getFilelist(
            this.params?.tag!,
            this.params?.link_id!
        );

        filelist.forEach((file) => {
            this.attachedFiles.set(`${file.name}${file.file_type}`, {
                name: file.name,
                id: file.id,
                ext: file.file_type,
                status: EUploadStatus.SUCCESS,
                link_id: this.params?.link_id!,
            });
        });
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    onDragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }
}
