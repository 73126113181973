<div
    [@showOpacityAnimation]="animState"
    (@showOpacityAnimation.done)="whenAnimate($event)"
>
    <form class="reg-form" [formGroup]="frmReg">
        <div class="loading-group">
            <!-- <div *ngIf="loading" class="loading"><img src="./assets/loading.gif"></div> -->

            <ng-container
                *ngFor="
                    let fieldName of createPatientListParamsR;
                    let i = index
                "
            >
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="fieldName === 'razdel'">
                        <div class="cls-block-razdel"></div>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'lastName' ||
                            fieldName === 'lastName*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="lastName"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                            <mat-label>Фамилия</mat-label>
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'firstName' ||
                            fieldName === 'firstName*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="firstName"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                            <mat-label>Имя</mat-label>
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'secondName' ||
                            fieldName === 'secondName*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Отчество</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="secondName"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'birthDate' ||
                            fieldName === 'birthDate*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Дата рождения</mat-label>
                            <input
                                type="date"
                                matInput
                                formControlName="birthDate"
                                required="true"
                                [max]="maxDate"
                                [readonly]="loading"
                                (keydown)="onDateKeydownHandler($event)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'snils' || fieldName === 'snils*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Снилс</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="XXX-XXX-XXX XX"
                                snilsMask
                                [preValue]="frmReg.value.snils"
                                maxlength="14"
                                formControlName="snils"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'email' || fieldName === 'email*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Почта</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="guest@mail.ru"
                                formControlName="email"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'phone' || fieldName === 'phone*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Телефон</mat-label>
                            <span matTextPrefix>+7 &nbsp;</span>
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="(999) 999-9999"
                                formControlName="phone"
                                phoneMask
                                [preValue]="frmReg.value.phone"
                                maxlength="14"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'inn' || fieldName === 'inn*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="ИНН"
                                formControlName="inn"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'iin' || fieldName === 'iin*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>ИИН</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="iin"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'card' || fieldName === 'card*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Номер карты пациента</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="card"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'tabNum' || fieldName === 'tabNum*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <mat-label>Табельный номер</mat-label>
                            <input
                                [type]="'text'"
                                matInput
                                formControlName="tabNum"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'polisNum' ||
                            fieldName === 'polisNum*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="Полис номер"
                                formControlName="polisNum"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>

                    <ng-container
                        *ngSwitchCase="
                            fieldName === 'polisSer' ||
                            fieldName === 'polisSer*'
                        "
                    >
                        <mat-form-field class="w-100">
                            <input
                                [type]="'text'"
                                matInput
                                placeholder="Полис серия"
                                formControlName="polisSer"
                                [readonly]="loading"
                                (keydown.space)="$event.preventDefault()"
                                [required]="requiredField(fieldName)"
                            />
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="listAuth">
                <div class="reg-form_acception">
                    <div class="acception__item">
                        <input
                            [type]="'checkbox'"
                            id="acception_pd"
                            (change)="checkAcception($event)"
                        />
                        <span class="acception__text" (click)="clickAcceptPd()"
                            ><label
                                for="acception_pd"
                                (click)="stopPropagation($event)"
                                >Согласен &#160;</label
                            >с политикой в отношении персональных данных</span
                        >
                    </div>

                    <div class="acception__item">
                        <input
                            [type]="'checkbox'"
                            id="acception_lk"
                            (change)="checkAcception($event)"
                        />
                        <span class="acception__text" (click)="clickAcceptLs()"
                            ><label
                                for="acception_lk"
                                (click)="stopPropagation($event)"
                                >Согласен &#160;</label
                            >с условиями использования сервиса личного
                            кабинета</span
                        >
                    </div>
                </div>
            </ng-container>

            <div class="phone__captcha captcha">
                <div class="captcha__left">
                    <mat-form-field class="input">
                        <input
                            [type]="'text'"
                            id="regcaptha"
                            matInput
                            placeholder="Введите код с картинки"
                            maxlength="4"
                            formControlName="captcha"
                            [readonly]="loading"
                            (keydown.space)="$event.preventDefault()"
                            required
                        />
                    </mat-form-field>
                </div>
                <div class="captcha__right" *ngIf="captcha; else loader">
                    <div
                        class="captcha__img"
                        [innerHTML]="captcha.svg | safe : 'html'"
                    ></div>

                    <button
                        class="captcha__refresh"
                        type="button"
                        (click)="refreshCaptcha()"
                    >
                        <mat-icon
                            svgIcon="refresh"
                            (click)="getCaptcha()"
                        ></mat-icon>
                    </button>
                </div>
            </div>
            <button
                class="w-100 btn btn-lg cls-btn cls-btn-reg"
                type="submit"
                [disabled]="loading || !frmReg.valid || notAcceptedCounter > 0"
                (click)="reg()"
            >
                <span *ngIf="!loading; else loaderBtn">
                    Зарегистрироваться
                </span>
            </button>

            <div class="cls-txt-center cls-footer1">Есть аккаунт?</div>
            <div
                class="cls-txt-center cls-footer2"
                (click)="goAuth()"
                placeholder="Регистрация"
            >
                <!-- <mat-icon
                    class="cls-icon-arrow-right"
                    svgIcon="arrow_left"
                ></mat-icon
                > -->
                Войти
            </div>
        </div>
    </form>
</div>

<ng-template #loader>
    <loader color="#1A8DCC" [isFullscreen]="true"></loader>
</ng-template>

<ng-template #loaderBtn>
    <loader color="#fff" [isFullscreen]="true"></loader>
</ng-template>
