import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { IService } from '../../../../interfaces/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    getTekDay,
    getNameDay,
    getTime,
    getDayWithYear,
} from '../../../application/global.function';
import { ConfigService } from '../../../../services/application/config.service.';
import { IDoctor } from '../../../../interfaces/record.interface';

@Component({
    selector: 'app-services-details-dialog',
    templateUrl: './services-details-dialog.component.html',
    styleUrls: ['./services-details-dialog.component.scss'],
})
export class ServicesDetailsDialogComponent implements OnInit {
    doctorImg: string;
    doctorId: number | null;

    constructor(
        public dialogRef: MatDialogRef<ServicesDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { service: IService },
        private configS: ConfigService
    ) {
        this.doctorId = data.service.doctor_id;
        this.doctorImg = this.doctorId ? this.getImgSrcDoc(this.doctorId) : '';
    }

    ngOnInit(): void {}

    /** Закрыть диалоговое окно */
    close() {
        this.dialogRef.close();
    }

    /** Получение даты в формате "D MM" */
    getTekDay(dt: Date): string {
        return getTekDay(dt);
    }

    /** Получение дня недели */
    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    /** Получение времени формата "HH:mm" */
    getTime(dt: Date): string {
        return getTime(dt);
    }

    /** Получение url для аватарки доктора */
    getImgSrcDoc(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    /** Получение фио в формате "Фамилия И. О." из строки "Фамилия Имя Отчество" */
    getFioDoc(doc: string): string {
        let result = doc;
        let fio = doc.split(' ');
        if (fio[0] && fio[0].length > 0) {
            result = fio[0];
            if (fio[1] && fio[1].length > 0) {
                result += ` ${fio[1][0]}.`;
            }
            if (fio[2] && fio[2].length > 0) {
                result += ` ${fio[2][0]}.`;
            }
        }
        return result;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param {any} event
     * @param {string} type
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }
}
