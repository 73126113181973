import {Pipe, PipeTransform} from "@angular/core";
import * as moment from 'moment';

@Pipe({
  name: 'isNotDate'
})

export class isNotDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {

    if (!value) {
      return 'Нет данных';
    } else {
      return value;
    }


  }
}
