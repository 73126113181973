<!--
<div (window:resize)="test($event)">
-->
<div #blockLine class="block-line--wrap"  (window:resize)="resize($event)" style="position: relative">
  <div class="label-block">
    <span class="block-min">{{min}}</span>
    <span class="block-max">{{max}}</span></div>
  <div class="line" #line>
<!--
    <div class="block-current" [style.left]="curentStyleLeft + 'px'"></div>
-->
<!--
    <div class="block-current" [ngClass]="{ 'norm': (curent >=min && curent <= max)}"
-->
<!--
    <div class="block-current" [ngClass]="{ 'norm': resNorm()}"
-->
    <div class="block-current"  [ngClass]="{ 'norm': (curent >=min && curent <= max)}"
         [ngStyle]=blockLinestyleLeft triggers="click" [autoClose]="'outside'" [ngbTooltip]="tipContentCurrent"></div>
           
    <ng-template #tipContentCurrent  class="tipContentCurrent" let-greeting="greeting">{{curent}}</ng-template>
  </div>
  <div class="lineNorm" #lineNorm></div>
</div>


