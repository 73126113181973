<div class="cls-panel cls-ptp panel-table-pagination">
    <div
        class="cls-ptp-header panel-table-pagination__header"
        *ngIf="HeaderHide == false"
    >
        <span class="cls-name">{{ nameBlock }}</span>
        <span class="cls-date">
            <!--
      <app-period [dtBegin]="dtBegin" [dtEnd]="dtEnd" (onChanged)="onChangedPeriod($event)"></app-period>
-->
            <app-date-range
                [dtBegin]="dtBegin"
                [dtEnd]="dtEnd"
                (onChanged)="onChangedPeriod($event)"
            ></app-date-range>
        </span>
    </div>

    <div class="cls-ptp-body panel-table-pagination__body">
        <ng-content></ng-content>
    </div>

    <div class="cls-ptp-footer panel-table-pagination__footer">
        <app-pagination
            [currentPage]="page"
            [countRecPage]="CountRecPage"
            [countRec]="CountRec"
            [textItogo]="textItogo"
            [textItogoStrEnd]="textItogoStrEnd"
            *ngIf="CountRec > 0"
            (onChangedPage)="changedPage($event)"
        >
        </app-pagination>
    </div>
</div>
