import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
//  @Input() styleStr = {}; // добавить стиль в виде массива
  @Input() // добавить стиль в виде массива
  set styleStr(value: object) {
// 
    this._styleStr = value;
  };

/*

  get styleStr(): Object{
    return this._styleStr;
  };
*/


  @Input() classSrt = ''; // добавочный класс

  public _styleStr: Object = {};

  constructor() { }

  ngOnInit(): void {
//    this._styleStr = this.styleStr;
  }

}
