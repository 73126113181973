export default function aggrQuery(
    params: Record<string, any>
): Record<string, any> {
    const keys = Object.keys(params) as Array<keyof typeof params>;

    return keys.reduce((acc, key) => {
        if (!params[key]) return acc;
        acc[key] = params[key];
        return acc;
    }, {} as Record<string, any>);
}
