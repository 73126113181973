import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpHeaders,
    HttpProgressEvent,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfigService } from './config.service.';
import { IHttpRequest } from './IHttpRes.interface';
import { Observable } from 'rxjs';
import { map, last, tap } from 'rxjs/operators';
import { findValueCookie } from '../../components/application/global.function';
import { Store } from '../store.service';
import { Router } from '@angular/router';

const cookieNameSession = 'cns';

@Injectable({
    providedIn: 'root',
})
export class HttpNewService {
    constructor(
        private http: HttpClient,
        private configS: ConfigService,
        private router: Router
    ) {}

    private cookieClear(): void {
        document.cookie = `pa-web_Patient=; path=/`;
    }

    private createHeaders(): HttpHeaders {
        let headers = new HttpHeaders();

        const tokenStore: any = Store.getData('Patient');
        const token = tokenStore.token;

        if (token === undefined) {
            this.cookieClear();

            this.router.navigate(['/login']);
            return headers;
        }
        //headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', 'TOKEN ' + tokenStore.token);
        return headers;
    }

    public get(
        url: string,
        token: string | null,
        queryParams?: Record<string, any> | null,
        isArrayBuffer?: boolean
    ): Observable<any> {
        let headerss = this.createHeaders();

        if (queryParams) {
            const keys = Object.keys(queryParams);
            const queryStrings = keys.map(
                (key) => `${key}=${queryParams[key]}`
            );

            url = url + `?${queryStrings.join('&')}`;
        }

        return new Observable((observer) => {
            const queryOptions: Record<string, any> = {
                headers: headerss,
                withCredentials: true,
            };

            if (isArrayBuffer) queryOptions.responseType = 'arrayBuffer';
            this.http
                .get(this.configS.getValue('hostBackend') + url, queryOptions)
                .subscribe(
                    (result) => {
                        const R: IHttpRequest = <IHttpRequest>result;
                        if (R instanceof ArrayBuffer && !!R) {
                            observer.next(R);
                        } else {
                            if (R.success === true) {
                                observer.next(R.data);
                            } else {
                                observer.error(R.msg);
                            }
                        }

                        observer.complete();
                    },
                    (err) => {
                        if (
                            (!err.success &&
                                err.error &&
                                err.error.data &&
                                err.error.data.errorCode &&
                                err.error.data.errorCode ===
                                    'AuthenticationException') ||
                            err.status === 401
                        ) {
                            if (location.pathname.indexOf('/login') === -1) {
                                this.cookieClear();
                                this.router.navigate(['/login']);
                            }
                        }
                        observer.error(err);
                    }
                );
        });
    }

    public put(url: string, data: {}, token: string | null): Observable<any> {
        let headerss = new HttpHeaders();
        if (token && token.length > 10) {
            headerss = headerss.set('Content-Type', 'application/json');
            headerss = headerss.set('Authorization', 'TOKEN ' + token);
        } else {
            const tokenStore: any = Store.getData('Patient');

            headerss = headerss.set('Content-Type', 'application/json');
            headerss = headerss.set(
                'Authorization',
                'TOKEN ' + tokenStore.token
            );
        }

        return new Observable((observer) => {
            this.http
                .put(this.configS.getValue('hostBackend') + url, data, {
                    headers: headerss,
                    withCredentials: true,
                })
                .subscribe(
                    (result) => {
                        const R: IHttpRequest = <IHttpRequest>result;
                        observer.next(R.data);
                        observer.complete();
                    },
                    (err) => {
                        observer.error(err);
                    }
                );
        });
    }

    public delete(url: string, data: Record<string, any>): Observable<any> {
        return this.http.delete(this.configS.getValue('hostBackend') + url, {
            body: data,
            withCredentials: true,
        });
    }

    public getALL(url: string, token: string | null): Observable<any> {
        let headerss = new HttpHeaders();
        if (token && token.length > 10) {
            headerss = headerss.set('Content-Type', 'application/json');
            headerss = headerss.set('Authorization', 'TOKEN ' + token);
        } else {
            const tokenStore: any = Store.getData('Patient');

            headerss = headerss.set('Content-Type', 'application/json');
            headerss = headerss.set(
                'Authorization',
                'TOKEN ' + tokenStore.token
            );
        }

        return new Observable((observer) => {
            this.http
                .get(this.configS.getValue('hostBackend') + url, {
                    headers: headerss,
                    withCredentials: true,
                })
                .subscribe(
                    (result) => {
                        observer.next(result);
                        observer.complete();
                    },
                    (err) => {
                        if (
                            !err.success &&
                            err.error &&
                            err.error.data &&
                            err.error.data.errorCode &&
                            err.error.data.errorCode ===
                                'AuthenticationException'
                        ) {
                            if (location.pathname.indexOf('/login') === -1) {
                                if (environment.production) {
                                    this.cookieClear();
                                    location.href =
                                        this.configS.getValue('hostBackend') +
                                        '/login';
                                } else {
                                    this.cookieClear();
                                    this.router.navigate(['/login']);
                                }
                            }
                        }
                        observer.error(err);
                    }
                );
        });
    }

    public getCORS(url: string): Observable<any> {
        let headerss = new HttpHeaders();
        headerss = headerss.set('Content-Type', 'application/json');

        return new Observable((observer) => {
            this.http.get(url, { headers: headerss }).subscribe(
                (result) => {
                    observer.next(result);
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                }
            );
        });
    }

    public post(
        url: string,
        data: {} | FormData | null,
        token: string | null,
        all: boolean | null = false,
        isFile = false
    ): Observable<any> {
        let headerss = new HttpHeaders();
        headerss = headerss.set('Content-Type', 'application/json');
        headerss = headerss.set(
            'Authorization',
            `TOKEN ${token ?? Store.getData('Patient').token}`
        );

        if (isFile)
            headerss = headerss.delete('Content-Type', 'application/json');

        return new Observable((observer) => {
            this.http
                .post(this.configS.getValue('hostBackend') + url, data, {
                    headers: headerss,
                    withCredentials: true,
                    reportProgress: true,
                })
                .subscribe(
                    (result) => {
                        const R: IHttpRequest = <IHttpRequest>result;
                        if (all) {
                            observer.next(R);
                        } else {
                            observer.next(R.data);
                        }
                        observer.complete();
                    },
                    (err) => {
                        if (
                            (!err.success &&
                                err.error &&
                                err.error.data &&
                                err.error.data.errorCode &&
                                err.error.data.errorCode ===
                                    'AuthenticationException') ||
                            err.status === 401
                        ) {
                            if (location.pathname.indexOf('/login') === -1) {
                                if (environment.production) {
                                    this.cookieClear();
                                    location.href =
                                        this.configS.getValue('hostBackend') +
                                        '/login';
                                } else {
                                    this.cookieClear();
                                    this.router.navigate(['/login']);
                                }
                            }
                        }
                        observer.next(err.error);
                        observer.complete();
                        // console.log(err);
                        observer.error(err);
                    }
                );
        });
    }
}
