import {Component, OnInit} from '@angular/core';
import {Size} from "../../services/size";

@Component({
  selector: 'app-rec',
  templateUrl: './rec.component.html',
  styleUrls: ['./rec.component.scss'],
})
export class RecComponent implements OnInit {
  public deviceType:string = '';

  constructor(public size: Size) {
    this.deviceType = this.size.getDeviceType();
  }

  ngOnInit(): void {
    this.deviceType = this.size.getDeviceType();
  }

}
