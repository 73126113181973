<h1
    mat-dialog-title
    style="
        min-height: 61px;
        min-width: 300px;
        box-shadow: 0px 7px 7px 0px rgb(173 189 209);
        padding-top: 8px;
        font-size: 16px;
        margin: 0px;
    "
>
    <div class="cls-icon-krest" *ngIf="!exitForm">
        <mat-icon svgIcon="krest" [mat-dialog-close]="false"></mat-icon>
    </div>

    <span class="cls-modal-block-header">Смена пароля</span>
</h1>

<div mat-dialog-content>
    <!--
  <div *ngIf="loading" class="loading loading-auth"><img src="./assets/loading.gif"></div>
-->

    <form
        class="cls-change-form"
        [formGroup]="frm"
        [ngClass]="{ loader: loading }"
    >
        <ng-container
            *ngIf="!exitForm; then showForm; else showFormExit"
        ></ng-container>

        <ng-template #showForm>
            <mat-form-field class="w-100">
                <mat-label>Текущий пароль</mat-label>
                <input
                    [type]="hidePasswordOLD ? 'password' : 'text'"
                    id="password1"
                    matInput
                    formControlName="oldPW"
                    [readonly]="loading"
                />
                <span
                    *ngIf="!hidePasswordOLD"
                    matSuffix
                    (click)="hidePasswordOLD = !hidePasswordOLD"
                >
                    <mat-icon svgIcon="visibility"></mat-icon>
                </span>

                <span
                    *ngIf="hidePasswordOLD"
                    matSuffix
                    (click)="hidePasswordOLD = !hidePasswordOLD"
                >
                    <mat-icon svgIcon="visibility_off"></mat-icon>
                </span>
            </mat-form-field>
            <a
                href="./login/recovery"
                class="cls-go-recovery"
                title="Восстановление доступа к личному кабинету"
                >Не помню пароль</a
            >

            <mat-form-field class="w-100">
                <mat-label>Новый пароль</mat-label>
                <input
                    [type]="hidePasswordNEW ? 'password' : 'text'"
                    id="password11"
                    matInput
                    formControlName="newPW"
                    [readonly]="loading"
                />
                <span
                    *ngIf="!hidePasswordNEW"
                    matSuffix
                    (click)="hidePasswordNEW = !hidePasswordNEW"
                >
                    <mat-icon svgIcon="visibility"></mat-icon>
                </span>

                <span
                    *ngIf="hidePasswordNEW"
                    matSuffix
                    (click)="hidePasswordNEW = !hidePasswordNEW"
                >
                    <mat-icon svgIcon="visibility_off"></mat-icon>
                </span>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Повтор нового пароля</mat-label>
                <input
                    [type]="hidePasswordNEW2 ? 'password' : 'text'"
                    id="password12"
                    matInput
                    placeholder="повтор нового пароля"
                    formControlName="new2PW"
                    [readonly]="loading"
                />
                <span
                    *ngIf="!hidePasswordNEW2"
                    matSuffix
                    (click)="hidePasswordNEW2 = !hidePasswordNEW2"
                >
                    <mat-icon svgIcon="visibility"></mat-icon>
                </span>

                <span
                    *ngIf="hidePasswordNEW2"
                    matSuffix
                    (click)="hidePasswordNEW2 = !hidePasswordNEW2"
                >
                    <mat-icon svgIcon="visibility_off"></mat-icon>
                </span>
            </mat-form-field>
        </ng-template>

        <ng-template #showFormExit>
            <div class="contentExit">Пароль успешно изменен</div>
        </ng-template>

        <button
            class="btn btn-lg w-100 cls-btn-second"
            *ngIf="!exitForm"
            [ngClass]="{
                'cls-btn-send': frm.valid,
                'cls-btn-send-disable': !testChangePW()
            }"
            type="submit"
            [disabled]="!testChangePW()"
            (click)="goToChange()"
            style="margin-bottom: 20px; margin-top: 50px"
        >
            Сменить
        </button>

        <button
            class="btn btn-lg w-100 cls-btn-second cls-btn-send"
            *ngIf="exitForm"
            type="submit"
            (click)="exit()"
            style="margin-bottom: 20px; margin-top: 50px"
        >
            Войти
        </button>
    </form>
</div>
