<div mat-dialog-content class="modal_documents">
    <div class="header">
        <span class="header_text">Документы</span>
        <span class="cls-icon-krest1">
            <mat-icon svgIcon="krest" [mat-dialog-close]="true"></mat-icon>
        </span>
    </div>

    <ul>
        <li *ngFor="let item of pdf | keyvalue, index as i">
            <button class="result-item" (mouseup)="gotToDownload($event, item)" (mouseover)="mouseEnt($event)" (mouseleave)="mouseOut($event)">
                <img
                *ngIf="!pdfLoading"
                class="result-item__img"
                [src]="pdfImageURL" />
            Документ по посещению {{i+1}} 
            </button>
        </li>
    </ul>
    <div class="modal_close_button">
        <button class="btn btn-dialog-close" [mat-dialog-close]="true">
            Закрыть
        </button>
    </div>
</div>