import { Component, Input, OnInit } from '@angular/core';
import { Imenu } from '../../../interfaces/menu.interface';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LoadService } from '../../../services/application/load.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnInit {
    menus: Imenu[] = [];
    lkActive = false; // Активны Личные данные
    private _setting: any;
    private settingMenu: String[];
    public routerDynamicLink: string = '';
    // @Input() dicommode!: boolean;

    constructor(
        private router: Router,
        private auth: AuthService,
        private load: LoadService,
        private modalService: NgbModal
    ) {
        router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                // По завершению загрузки
                this.routerDynamicLink = val.url.split('#')[0];
            }
        });

        this._setting = load.configData.pages.home;

        if (
            load.configData.menu.items &&
            load.configData.menu.items.length > 5
        ) {
            this.settingMenu = load.configData.menu.items.split(',');
        } else {
            this.settingMenu = [];
        }

        /* Отловим событие перехода */
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.findMenuActiveURL();
            }
        });
    }

    ngOnInit(): void {
        this.generMenu();
        // По URL делаем активное меню , F5
        this.findMenuActiveURL();
    }

    findMenuActiveURL() {
        this.lkActive = false;
        //
        if (this.router.url === '/home/account') {
            this.lkActive = true;
            this.menus.forEach((item) => {
                item.active = false;
            });
        } else {
            this.menus.forEach((item) => {
                if (item.url === this.router.url) {
                    this.setActiveMenu(item);
                }
            });
        }
    }

    generMenu(): void {
        this.menus = [];

        this.menus.push({
            name: 'На главную',
            img: 'm_home_new',
            url: '/home',
            order: 1,
            active: true,
        });

        if (this.settingMenu.indexOf('to-doctor') > -1) {
            this.menus.push({
                name: 'Запись к врачу',
                img: 'm_rec_new',
                url: '/home/rec',
                order: 1,
            });
        }

        if (this.settingMenu.indexOf('record-services') > -1) {
            this.menus.push({
                name: 'Запись на услугу',
                img: 'm_recService',
                url: '/home/record-services',
                order: 1,
            });
        }

        if (this.settingMenu.indexOf('laboratory') > -1) {
            this.menus.push({
                name: 'Лаборатория',
                img: 'm_lab_new',
                url: '/home/labs',
                order: 1,
            });
        }

        if (this.settingMenu.indexOf('payments') > -1) {
            this.menus.push({
                name: 'Финансы',
                img: 'm_fin_new',
                url: '/home/payments',
                order: 1,
            });
        }

        if (
            this.settingMenu.indexOf('h-doctor') > -1 ||
            this.settingMenu.indexOf('history') > -1
        ) {
            this.menus.push({
                name: 'Мои посещения',
                img: 'm_history_new',
                url: '/home/history',
                order: 1,
            });
        }

        if (this.settingMenu.indexOf('services') > -1) {
            this.menus.push({
                name: 'Услуги',
                img: 'm_serv_new',
                url: '/home/services',
                order: 1,
            });
        }
        if (this.settingMenu.indexOf('documents') > -1) {
            this.menus.push({
                name: 'Документы',
                img: 'm_files',
                url: '/home/patient-files',
                order: 1
            })
        }

        /*
        this.menus.push({name: 'Абонементы', img: 'm_abon', url:'', order:1});
    */
    }

    setActiveMenu(menu: Imenu) {
        this.menus.forEach((item) => {
            item.active = menu.name === item.name;
        });
    }

    closeAllModals(trigger: MatMenuTrigger) {
        trigger.closeMenu();
        this.modalService.dismissAll();
    }

    onExit() {
        this.auth.logout();
    }
}
