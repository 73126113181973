<h1 mat-dialog-title class="cls-modal-block-title">
    <div class="cls-icon-krest1">
        <mat-icon svgIcon="krest" [mat-dialog-close]="true"></mat-icon>
    </div>

    <!--
  <span class="cls-modal-block-header">Описание посещения пациента</span>
--></h1>

<div mat-dialog-content>
    <div [innerHTML]="resHtml"></div>
    <button class="btn btn-dialog-close" [mat-dialog-close]="true">
        Закрыть
    </button>
</div>
