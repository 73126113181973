import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../services/auth.service";
import { AlertService } from 'src/lib/ariadna/alert';
import {IPatient} from "../../interfaces/patient";
import {PatientService} from "../../services/patient.service";

@Component({
  selector: 'app-create-pw',
  templateUrl: './create-pw.component.html',
  styleUrls: ['./create-pw.component.scss']
})
export class CreatePwComponent implements OnInit {
  public loading = false;
  patient!: IPatient;
  exitForm = false;        // Форма выхода

  constructor(
    public dialogRef: MatDialogRef<CreatePwComponent>,
    private auth: AuthService,
    private alertService: AlertService,
    private ps: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true; // запрет закрытия окна (только командами)
  }

  ngOnInit(): void {
    this.getPatientInfo();
  }

  /**
   * Получение данных из Кэша или сервера в зависимости от наличия
   */
  private getPatientInfo(): void {
    this.loading = true;
    this.ps.getServerPatientInfo$()
      .subscribe(
        info => {
          this.patient = info;
          this.patient.birthdate = null;
          this.loading = false;
        }, err => {
          this.loading = false;
        }
      );
  }


  exit(){
    this.auth.logout();
    this.dialogRef.close();
  }

  goToCreate(){
    this.ps.create_pw$()
      .subscribe(
        info => {
          if (info && info.err_text) {
            this.alertService.error(info.err_text);
          } else {
            this.alertService.error('Чтото пошло не так, попробуйте снова через 10 мин.');
          }
          this.loading = false;
        }, err => {
          this.alertService.error(`Ошибка создания логина: ${err}`);
          this.loading = false;
        }
      );
  }


}
