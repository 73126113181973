import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdocType } from '../../interfaces/document.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpNewService } from '../../services/application/http-new.service';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
    selector: 'app-doc-img',
    templateUrl: './doc-img.component.html',
    styleUrls: ['./doc-img.component.scss'],
})
export class DocImgComponent implements OnInit {
    public frm!: FormGroup;
    loadingDoc = false;
    listTypeDoc: IdocType[] = []; // Список типов документов

    selectedFiles?: FileList;
    progressInfos: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<DocImgComponent>,
        private httpNew: HttpNewService,
        private fu: FileUploadService,
        @Inject(MAT_DIALOG_DATA) public dataIN: any
    ) {
        this.dialogRef.disableClose = true; // запрет закрытия окна (только командами)
    }

    ngOnInit(): void {
        this.getTypeList();
        this.initialize();
        //    this.loadingDoc = true;
    }

    private getTypeList() {
        this.listTypeDoc = [];
        this.loadingDoc = true;
        this.fu.getDocTypeList().subscribe(
            (res) => {
                this.listTypeDoc = res;
                this.loadingDoc = false;
            },
            (err) => {
                console.error('Errr=', err);
                this.loadingDoc = false;
            }
        );
    }

    /* Инициализация при создании компанента */
    private initialize(): void {
        this.frm = new FormGroup({
            selectType: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
            file: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
            ]),
        });
    }

    closeExit() {
        this.dialogRef.close({ success: false, msg: null }); // Закрываем
    }

    // onAddDoc() {
    //     this.loadingDoc = true;
    //     if (
    //         this.selectedFiles &&
    //         this.selectedFiles[0] &&
    //         this.frm.controls['selectType'] &&
    //         this.frm.controls['selectType'].value
    //     ) {
    //         const file = this.selectedFiles[0];
    //         this.fu
    //             .uploadTmp(file)
    //             .subscribe({
    //                 next: (event: any) => {
    //                     this.loadingDoc = false;
    //                     this.dialogRef.close({ success: true, msg: null }); // Закрываем
    //                 },
    //                 error: (err: any) => {
    //                     const msg = 'Не могу открыть файл: ' + file.name;
    //                     console.error('err msg=', msg);
    //                     this.loadingDoc = false;
    //                     this.dialogRef.close({
    //                         success: false,
    //                         msg: 'Ошибка добавления документа.',
    //                     }); // Закрываем
    //                 },
    //             });
    //     } else {
    //         this.loadingDoc = true;
    //     }
    // }

    changeTypeDoc(ev: any) {
        //
    }

    selectFiles(event: any): void {
        this.selectedFiles = event.target.files;
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    //
                    //          this.previews.push(e.target.result);
                };
                reader.readAsDataURL(this.selectedFiles[i]);
            }
        }
        //
    }
}
