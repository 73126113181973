<div class="select-wrapper">
  <text theme="label" *ngIf="label" [required]="required">{{ label }}</text>

  <select
    class="select"
    [ngClass]="[theme]"
    (ngModelChange)="onSelectionChangeHandler($event)"
    [ngModel]="value"
  >
    <option [ngValue]="undefined" disabled>{{ placeholder }}</option>
    <option class="option" *ngFor="let opt of options" [ngValue]="opt">
      {{ opt.text }}
    </option>
  </select>
</div>
