import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    IDateRange,
    IDateRangeHeader,
} from '../../../interfaces/date-range.interface';
import * as moment from 'moment';
import {
    expandedAnimation,
    rightPanelAnimation,
} from '../../../animations/height.animation';
import { DateRangeService } from '../../../services/date-range.service';
import { IPeriod } from '../../../interfaces/period.interface';

const tableLeftPrefix = 'dtLeft';
const tableRightPrefix = 'dtRight';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    animations: [expandedAnimation, rightPanelAnimation],
})
export class DateRangeComponent implements AfterViewInit {
    public dt: IDateRange;

    @ViewChild('calendar') calendar!: ElementRef;

    @Output() onChanged = new EventEmitter<IPeriod>(); // Смена даты
    @Input() // Дата начала
    set dtBegin(value: Date | null) {
        this.dt.dt_begin = value;
        this.setHeaderBtn();
        this.drs.CalendarySetDateRange(this.dt);
    }

    get dtBegin(): Date | null {
        //    console.error('GET Date BEGIN VALUE =', this.dt.dt_begin);
        return this.dt.dt_begin;
    }

    @Input() // Дата окончания
    set dtEnd(value: Date | null) {
        this.dt.dt_end = value;
        this.setHeaderBtn();
        this.drs.CalendarySetDateRange(this.dt);
    }

    get dtEnd(): Date | null {
        return this.dt.dt_end;
    }

    calendearyAnime = 'off'; // Показ календаря
    headerLeft: IDateRangeHeader = {
        mount: '',
        year: 0,
        dt: new Date(),
        left: true,
        right: true,
        prefix: tableLeftPrefix,
    };
    headerRight: IDateRangeHeader = {
        mount: '',
        year: 0,
        dt: new Date(),
        left: true,
        right: true,
        prefix: tableRightPrefix,
    };

    constructor(private drs: DateRangeService) {
        moment.locale('ru');
        this.dt = { str_begin: '', str_end: '', dt_begin: null, dt_end: null };
        // Событие изменения даты
        drs.CalendaryOnSelectDay$().subscribe((n) => {
            //
            // При заполненных обеих дат, скидываем и устанавливаем начальную
            if (this.dt.dt_begin && this.dt.dt_end) {
                this.dt.dt_begin = n;
                this.dt.dt_end = null;
            } else {
                if (this.dt.dt_begin == null) {
                    this.dt.dt_begin = n;
                } else {
                    if (this.dt.dt_begin > n) {
                        this.dt.dt_begin = n;
                        this.dt.dt_end = null;
                    } else {
                        this.dt.dt_end = n;
                    }
                }
            }

            this.setHeaderBtn();
            if (this.dt.dt_begin && this.dt.dt_end) {
                this.onChanged.emit({
                    begin: this.dt.dt_begin,
                    end: this.dt.dt_end,
                });
                this.calendearyAnime = 'off';
            }

            drs.CalendarySetDateRange(this.dt);
            //
        });
    }

    ngOnInit(): void {
        moment.locale('ru');
        //    this.dt.dt_begin = null;
        //    this.dt.dt_end = null;
        const minDt = new Date();
        // console.error('111 this.dtBegin=', Object.assign({}, this.dtBegin));
        //    this.drs.CalendarySetParams({prefix: tableLeftPrefix, curentDate: minDt, startDate: null, endDate: null});
        this.drs.CalendarySetParams({
            prefix: tableLeftPrefix,
            curentDate: minDt,
            startDate: this.dtBegin,
            endDate: this.dtEnd,
        });

        var lastDay = new Date(minDt.getFullYear(), minDt.getMonth() + 1, 1);
        //    this.drs.CalendarySetParams({prefix: tableRightPrefix, curentDate: lastDay, startDate: null, endDate: null});
        this.drs.CalendarySetParams({
            prefix: tableRightPrefix,
            curentDate: lastDay,
            startDate: this.dtBegin,
            endDate: this.dtEnd,
        });
        this.setDateHeader(this.headerLeft, minDt, true, false);
        this.setDateHeader(this.headerRight, lastDay, false, true);
        /*
    this.headerLeft = {mount: moment(minDt).format('MMMM'), year: minDt.getFullYear(), dt: minDt, left:true, right:false};
    this.headerRight = {mount: moment(lastDay).format('MMMM'), year: lastDay.getFullYear(), dt: lastDay, left:false, right:true};
*/
    }

    ngAfterViewInit(): void {
        // If calendar is out of window - sets the position
        if (
            this.calendar.nativeElement.getBoundingClientRect().right >
            window.innerWidth
        ) {
            this.calendar.nativeElement.style.right = 0;
        }
    }

    // Установка текста на кнопке
    setHeaderBtn() {
        if (this.dt.dt_begin) {
            this.dt.str_begin = moment(new Date(this.dt.dt_begin)).format(
                'D MMM YYYY'
            );
        } else {
            this.dt.str_begin = '';
        }

        if (this.dt.dt_end) {
            this.dt.str_end = moment(new Date(this.dt.dt_end)).format(
                'D MMM YYYY'
            );
        } else {
            this.dt.str_end = '';
        }
    }

    /* только числа */
    public numberOnly(event: any): boolean {
        const charCode = event.keyCode;
        // Проверка на ввод числа
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    test(ev: any, isBegin: boolean) {
        //формат строки
        let str: string = '';
        let dt: Date | null = null;
        if (isBegin) {
            str = this.dt.str_begin ? this.dt.str_begin : '';
        } else {
            str = this.dt.str_end ? this.dt.str_end : '';
        }
        if (str.length == 2 || str.length == 5) {
            str = str + '.';
        }

        if (str.length == 10) {
            // Возможно уже Date
            try {
                dt = new Date(
                    Number(str.substr(6, 4)),
                    Number(str.substr(3, 2)) - 1,
                    Number(str.substr(0, 2))
                );
            } catch (e) {
                console.error('Err convert DT=', e);
            }
        }

        if (dt) {
            // Проверка на год (кол месяцев иногда изменяют год)
            if (moment(dt).format('DD.MM.YYYY') != str) {
                dt = null;
            }
        }
        //
        if (isBegin) {
            this.dt.str_begin = str;
            this.dt.dt_begin = dt ? dt : null;
            if (dt) {
                // @ts-ignore
                document.getElementById('dr-end').focus();
            }
        } else {
            this.dt.str_end = str;
            this.dt.dt_end = dt ? dt : null;
        }
    }

    whenAnimateSearch(event: any) {
        // Окончание анимации
        //
        /*
        if (this.animState === 'off') {
          this.payAvansAnimeStatus = 1;
        } else {
          this.payAvansAnimeStatus = 0;
        }
    */
    }

    calendary() {
        if (this.calendearyAnime == 'on') {
            this.calendearyAnime = 'off';
        } else {
            this.calendearyAnime = 'on';
        }
    }

    setDateHeader(h: IDateRangeHeader, dt: Date, l: boolean, r: boolean) {
        h.dt = dt;
        h.mount = moment(h.dt).format('MMMM');
        h.year = h.dt.getFullYear();
        h.left = l;
        h.right = r;
    }

    mountPlusMinus(pr: IDateRangeHeader, znak: string) {
        // Добавить или уменьшить месяц
        switch (znak) {
            case '-': {
                if (pr.left) {
                    let lastDay = new Date(
                        pr.dt.getFullYear(),
                        pr.dt.getMonth() - 1,
                        1
                    ); // Уменьшаем на 1 месяц
                    this.setDateHeader(pr, lastDay, true, false);
                    this.drs.CalendarySetParams({
                        prefix: pr.prefix,
                        curentDate: pr.dt,
                        startDate: this.dt.dt_begin,
                        endDate: this.dt.dt_end,
                    });
                }
                break;
            }
            case '+': {
                if (pr.right) {
                    let lastDay = new Date(
                        pr.dt.getFullYear(),
                        pr.dt.getMonth() + 1,
                        1
                    ); // Увеличиваем на 1 месяц
                    this.setDateHeader(pr, lastDay, true, true);
                    this.drs.CalendarySetParams({
                        prefix: pr.prefix,
                        curentDate: pr.dt,
                        startDate: this.dt.dt_begin,
                        endDate: this.dt.dt_end,
                    });
                }
                break;
            }
        }

        // Общая проверка и блокировка
        // Проверка для левого
        let NextDay = new Date(
            this.headerLeft.dt.getFullYear(),
            this.headerLeft.dt.getMonth() + 1,
            1
        ); // + на 1 месяц
        if (
            this.headerRight.dt.getFullYear() == NextDay.getFullYear() &&
            this.headerRight.dt.getMonth() == NextDay.getMonth()
        ) {
            this.headerLeft.right = false;
            this.headerLeft.left = true;
        } else {
            this.headerLeft.right = true;
        }

        // Проверка для правого
        let PrevDay = new Date(
            this.headerRight.dt.getFullYear(),
            this.headerRight.dt.getMonth() - 1,
            1
        ); // + на 1 месяц
        if (
            this.headerLeft.dt.getFullYear() == PrevDay.getFullYear() &&
            this.headerLeft.dt.getMonth() == PrevDay.getMonth()
        ) {
            //      this.setDateHeader(this.headerRight, this.headerRight.dt, false, true);
            this.headerRight.left = false;
            this.headerRight.right = true;
        } else {
            this.headerRight.left = true;
        }
    }
}
