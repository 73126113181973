 <form
    class="form"
    [formGroup]="protocol.form"
    (ngSubmit)="onSubmit($event)">

    <div class="content">
        <protocol-control
            *ngFor="let control of protocol.controls"
            [formControlName]="control.name"
            [control]="control"
            [options]="control.options"
            [value]="protocol.form.get(control.name)?.value || ''">
        </protocol-control>
    </div>

    <div class="actions">
        <button
            class="button"
            type="submit"
            [disabled]="!protocol.form.valid">
            Отправить врачу
        </button>
    </div>
</form>