<ng-container *ngIf="isShowListMicro(); then showListMicro else NotListMicro"></ng-container>

<ng-template #NotListMicro>
  <!-- <div class="toJob cls-color-status-order4" *ngIf="statusRoot !='N'">в работе</div> -->
  <!--*ngIf="statusRoot =='N'"-->
  <div class="toJob-text" >ожидает обработки...</div>
</ng-template>

<ng-template #showListMicro>

  <div class="row bg-white">
  <div class="col-md-4 block-micro">
    <table class="w-100">
      <thead>
        <tr>
          <th class="title">Микроорганизм</th>
        </tr>
      </thead>
      <tr *ngFor="let result of data;let i = index" (click)="changeMicro(result)" [ngClass]="{ 'active': result == selectedMicro}">
<!--
        <td><mat-icon svgIcon="point" style="color: #9277ff; width: 11px;" *ngIf="result == selectedMicro"></mat-icon></td>
-->
        
        <td class="micro-res">{{result.name}}</td>
      </tr>
    </table>
  </div>

  <div class="col-md-12 block-antibiotic">
    <app-panel>
      <div class="row" >
        <div class="col-12">
          <table class="antibiotic-table">
            <thead>  <!-- Cabeçalho da Tabela -->
              <tr>
                  <td class="antibiotic-table-tdfirst">Антибиотик</td>
                  <td class="cls-td-center">SIR</td>
                  <td class="cls-td-center">MIC</td>
                  <td class="cls-td-center">DIA</td>
              </tr>
  
          </thead>
          <tbody>
          <!-- <tr>
            <th scope="col" style="width: 70%;">Антибиотик</th>
            <th scope="col" style="width: 10%;">SIR</th>
            <th scope="col" style="width: 10%;">MIC</th>
            <th scope="col" style="width: 10%;">DIA</th>
          </tr> -->
          <!--удалить это сделано для тестинга только эту tr -->
          

          <tr *ngFor="let resA of selectedMicro?.bactery | evenodd:'odd';let i = index;">
            <td>{{resA.name}}</td>
            <td class="cls-td-center">{{resA.sir}}</td>
            <td class="cls-td-center">{{resA.mic}}</td>
            <td class="cls-td-center">{{resA.dia}}</td>
          </tr>
          </tbody>
        </table>
        </div>

        <div class="col-12" >
          <table class="antibiotic-table">
            
            <thead>
            <tr>
              <td class="antibiotic-table-tdfirst">Антибиотик</td>
              <td class="cls-td-center">SIR</td>
              <td class="cls-td-center">MIC</td>
              <td class="cls-td-center">DIA</td>
            </tr>
            </thead>
          <tbody>
            
          
          <tr *ngFor="let resA of selectedMicro?.bactery | evenodd:'even';let i = index;">
            <td >{{resA.name}}</td>
            <td class="cls-td-center">{{resA.sir}}</td>
            <td class="cls-td-center">{{resA.mic}}</td>
            <td class="cls-td-center">{{resA.dia}}</td>
          </tr>
          </tbody>

        </table>
        </div>
      </div>

    </app-panel>
    <div class="row mat-bio-footer">
      <div class="col-12">
        <div class="mat-bio-footer--title">Услуга:</div>
        <div class="mat-bio-footer-res">{{srvText}}</div>
        <div class="mat-bio-footer--title">Результат услуги:</div>
        <div class="mat-bio-footer-res">{{srvRes}}</div>
      </div>

      <div class="col-12">
        <div class="mat-bio-footer--title">Заключение по антибиотикам:</div>
        <div class="mat-bio-footer-res">{{note}}</div>
      </div>

    </div>

  </div>

  </div>

</ng-template>
