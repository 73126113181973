import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadService } from '../services/application/load.service';

@Component({
    selector: 'app-service-unavailable',
    templateUrl: './service-unavailable.component.html',
    styleUrls: ['./service-unavailable.component.scss'],
})
export class ServiceUnavailableComponent {
    constructor(private route: Router, private load: LoadService) {}
    refresh(): void {
        location.reload();
    }

    ngOnInit(): void {
        if (this.load.configLoaded) {
            this.redirectTo('/login');
        }
    }

    redirectTo(uri: string) {
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.route.navigate([uri]);
        });
    }
}
