<label
    class="checkbox"
    [ngClass]="{
        disabled: disabled
    }"
>
    <div class="wrapper">
        <input
            type="checkbox"
            [checked]="checked"
            [disabled]="disabled"
            (change)="onChangeHandler($event)"
        />
        <span>
            <mat-icon svgIcon="tick" class="check-icon"></mat-icon>
        </span>
        <div class="text">{{ text }}</div>
        <div class="note">{{ note }}</div>
    </div>
</label>
