import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertComponent } from './alert.component';
import { AlertService } from './alert.service';

@NgModule({
    imports: [CommonModule],
    declarations: [AlertComponent],
    exports: [AlertComponent],
    providers: [AlertService],
})
export class AlertModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AlertModule
    ) {
        if (parentModule) {
            throw new Error(
                'AlertModule is already loaded. Import only in Root App Module'
            );
        }
    }
}
