import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-feed-page-anchor',
  templateUrl: './feed-page-anchor.component.html',
  styleUrls: ['./feed-page-anchor.component.scss']
})
export class FeedPageAnchorComponent implements OnInit {
   @Output() intersected = new EventEmitter<any>();
   @Input('is-hidden') isHidden = false;
  constructor() { }

  ngOnInit(): void {
  }

}
