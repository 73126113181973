<div class="list-completed-mobile">
    <nav class="list-completed-mobile__header">
        <div class="filterbar list-completed-mobile__filterbar">
            <div class="filter-wrapper">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.spec.searchText"
                    (search)="onSearchFilter(filters.spec.mode, $event)"
                    [matAutocomplete]="filterSpecRef"
                    class="filter filter-spec filterbar__filter-spec"
                    placeholder="Все специальности"
                />
                <mat-autocomplete #filterSpecRef class="filter__dropdown">
                    <mat-option
                        class="filter__option"
                        (click)="onChangeFilter(null, filters.spec.mode)"
                        >Все специальности</mat-option
                    >
                    <mat-option
                        *ngFor="
                            let spec of filters.spec.list
                                | searchFilter : filters.spec.searchText
                        "
                        [value]="spec.text"
                        class="filter__option"
                        (click)="onChangeFilter(spec.keyid, filters.spec.mode)"
                        >{{ spec.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>

            <div class="filter-wrapper">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.doc.searchText"
                    (search)="onSearchFilter(filters.doc.mode, $event)"
                    [matAutocomplete]="filterDocRef"
                    class="filter filter-spec filterbar__filter-spec"
                    placeholder="Все врачи"
                />
                <mat-autocomplete #filterDocRef class="filter__dropdown">
                    <mat-option
                        class="filter__option"
                        (click)="onChangeFilter(null, filters.doc.mode)"
                        >Все врачи</mat-option
                    >
                    <mat-option
                        *ngFor="
                            let doc of filters.doc.list
                                | searchFilter : filters.doc.searchText
                        "
                        [value]="doc.text"
                        (click)="onChangeFilter(doc.keyid, filters.doc.mode)"
                        class="filter__option"
                        >{{ doc.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>

            <div class="filter-wrapper" *ngIf="showFilials">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.dep.searchText"
                    (search)="onSearchFilter(filters.dep.mode, $event)"
                    [matAutocomplete]="filterDepRef"
                    class="filter filter-fil filterbar__filter-spec"
                    placeholder="Все филиалы"
                />
                <mat-autocomplete #filterDepRef class="filter__dropdown">
                    <mat-option
                        class="filter__option"
                        (click)="onChangeFilter(null, filters.dep.mode)"
                        >Все филиалы</mat-option
                    >
                    <mat-option
                        *ngFor="
                            let dep of filters.dep.list
                                | searchFilter : filters.dep.searchText
                        "
                        [value]="dep.text"
                        (click)="onChangeFilter(dep.keyid, filters.dep.mode)"
                        class="filter__option"
                        >{{ dep.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>
        </div>
    </nav>

    <app-mobile-feed-page-template
        [count-of-pages]="countOfPages"
        [loaded-pages]="loadedPages"
        [is-loading]="loadingMoreContent"
        [is-feed-empty]="historyPages.length === 0"
        [root-url]="'home/history'"
        [view-page]="viewPage"
        [pages]="this.historyPages"
        [no-more-content-text]="'Посещений больше нет.'"
        (load-next-page)="loadNextPage()"
        *ngIf="!loading; else pageLoader"
        #content
    >
        <feed-header-period
            header
            [periodText]="periodText"
            (onClick)="showPeriodDialog()"
            [canBeClear]="periodText !== 'Песещения за всё время'"
            (onClear)="btnFilterByDate(null)"
        ></feed-header-period>

        <ng-template *ngIf="true; then showNotRec"></ng-template>

        <section
            page
            [id]="'page' + (pageIndex + 1)"
            class="services-mobile__page"
            *ngFor="let historyPage of historyPages; let pageIndex = index"
        >
            <app-feed-page-anchor
                [is-hidden]="pageIndex === 0"
                (intersected)="viewPage = pageIndex + 1"
            >
                Страница {{ pageIndex + 1 }} из {{ countOfPages }}
            </app-feed-page-anchor>

            <article
                class="card"
                *ngFor="let history of historyPage; let i = index"
                (click)="showDetailsDialog(history)"
            >
                <header class="card__header">
                    <span class="card__day">
                        {{ getNameDay(history.dtSort) | titlecase }}
                    </span>
                    <span class="card__date">
                        {{ getDayWithYear(history.dtSort) }}
                    </span>
                </header>

                <div class="card__body">
                    <div class="card__time">
                        <!-- {{ getTime(history.dtSort) }} -->
                    </div>
                    <div class="card__spec">
                        <p class="spec">{{ history.spec }}</p>
                        <p class="name">{{ getTextDoc(history.doctor) }}</p>
                    </div>
                </div>
                <footer class="card__footer"></footer>
            </article>
        </section>

        <button fab class="app__calendar-btn" (click)="showPeriodDialog()">
            <mat-icon svgIcon="calendar"></mat-icon>
        </button>
    </app-mobile-feed-page-template>

    <ng-template #pageLoader>
        <div class="loader">
            <loader type="page" [isFullscreen]="true"></loader>
        </div>
    </ng-template>
</div>

<div class="list-completed-desktop">
    <nav class="list-completed-desktop__header">
        <div class="filterbar list-completed-desktop__filterbar">
            <div class="filter-wrapper">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.spec.searchText"
                    (search)="onSearchFilter(filters.spec.mode, $event)"
                    [matAutocomplete]="filterSpecRef"
                    class="filter filter-spec filterbar__filter-spec"
                    placeholder="Все специальности"
                />
                <mat-autocomplete #filterSpecRef class="filter__dropdown">
                    <mat-option
                        class="filter__option"
                        (click)="onChangeFilter(null, filters.spec.mode)"
                        >Все специальности</mat-option
                    >
                    <mat-option
                        *ngFor="
                            let spec of filters.spec.list
                                | searchFilter : filters.spec.searchText
                        "
                        [value]="spec.text"
                        class="filter__option"
                        (click)="onChangeFilter(spec.keyid, filters.spec.mode)"
                        >{{ spec.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>

            <div class="filter-wrapper">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.doc.searchText"
                    (search)="onSearchFilter(filters.doc.mode, $event)"
                    [matAutocomplete]="filterDocRef"
                    class="filter filter-spec filterbar__filter-spec"
                    placeholder="Все врачи"
                />
                <mat-autocomplete #filterDocRef class="filter__dropdown">
                    <mat-option
                        *ngFor="
                            let doc of filters.doc.list
                                | searchFilter : filters.doc.searchText
                        "
                        [value]="doc.text"
                        (click)="onChangeFilter(doc.keyid, filters.doc.mode)"
                        class="filter__option"
                        >{{ doc.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>

            <div class="filter-wrapper" *ngIf="showFilials">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filters.dep.searchText"
                    (search)="onSearchFilter(filters.dep.mode, $event)"
                    [matAutocomplete]="filterDepRef"
                    class="filter filter-fil filterbar__filter-spec"
                    placeholder="Все филиалы"
                />
                <mat-autocomplete #filterDepRef class="filter__dropdown">
                    <mat-option
                        class="filter__option"
                        (click)="onChangeFilter(null, filters.dep.mode)"
                        >Все филиалы</mat-option
                    >
                    <mat-option
                        *ngFor="
                            let dep of filters.dep.list
                                | searchFilter : filters.dep.searchText
                        "
                        [value]="dep.text"
                        (click)="onChangeFilter(dep.keyid, filters.dep.mode)"
                        class="filter__option"
                        >{{ dep.text }}</mat-option
                    >
                </mat-autocomplete>
            </div>
        </div>
    </nav>

    <div *ngIf="loading" class="loading">
        <img src="./assets/loading.gif" />
    </div>

    <ng-container
        *ngIf="HistoryEvents.length > 0; then showTable; else showNotRec"
    ></ng-container>

    <ng-template #showTable>
        <section
            class="visits list-completed-desktop__visits"
            [ngStyle]="{ 'grid-template-columns': gridTemplateColumns }"
        >
            <div
                class="visits__header"
                [ngStyle]="{ 'grid-template-columns': gridTemplateColumns }"
            >
                <div>Дата посещения</div>
                <div>Врач</div>
                <div>Исследование</div>
                <div *ngIf="showFilials">Филиалы</div>
                <div>Отделение</div>
                <div>Повторная запись</div>
                <div>Заключение</div>
            </div>
            <div class="visits__body">
                <ng-container
                    *ngFor="let visit of HistoryEvents; let i = index"
                >
                    <div class="yeer" *ngIf="isBlockYeer(i)">
                        {{ getYeer(i) }}
                    </div>

                    <app-visit-card
                        [visit]="visit"
                        [gridTemplateColumns]="gridTemplateColumns"
                    ></app-visit-card>
                </ng-container>
            </div>
        </section>
        <div class="cls-ptp-footer visits__footer">
            <app-pagination
                [currentPage]="1"
                [countRecPage]="CountRecPage"
                [countRec]="CountRec"
                textItogo="исследований"
                *ngIf="CountRec > 0"
                (onChangedPage)="changedPage($event)"
            ></app-pagination>
        </div>
    </ng-template>
</div>

<ng-template #showNotRec>
    <div class="cls-not-rec">Нет посещений</div>
</ng-template>
