<section class="dicom">
    <ng-container *ngIf="loadingApp; then loader"></ng-container>

    <header class="dicom__header header">
        <button
            class="header__button menu-open"
            (click)="onMenuMobileButtonClick($event)"
        >
            <img src="./assets/img/svg/dicom-menu.svg" />
        </button>
        <span class="header__title">{{ currentIndex + 1 }} серия</span>
        <button class="header__button modal-close" (click)="closeApp($event)">
            <img src="./assets/img/svg/close-icon.svg" />
        </button>
    </header>

    <div class="dicom__body">
        <div
            class="dicom__sidebar sidebar"
            [ngClass]="{ 'mobile--opened': seriesbarMenuOpened }"
        >
            <ul class="dicom__info info">
                <li class="info__name info__item">
                    Пациент: {{ patient.name }}
                </li>
                <li class="info__birthdate info__item">
                    Дата рождения: {{ patient.birthdate }}
                </li>
                <li class="info__studydate info__item">
                    Дата исследования: {{ studyDate }}
                </li>
            </ul>

            <div class="dicom__seriesbar seriesbar">
                <ul class="seriesbar__switch">
                    <li
                        *ngFor="let serie of seriesInstanceUIDs; index as i"
                        class="seriesbar__item"
                        [ngClass]="{
                            'seriesbar__item--active': i === currentIndex
                        }"
                        (click)="switchSerie(i, $event)"
                    >
                        Серия {{ i + 1 }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="dicom__viewlayer" (wheel)="onViewWheel($event)">
            <div id="{{ layerDivID }}"></div>
            <div *ngIf="loadingSerie" class="viewlayer__loader">
                <span>Подготовка кадров</span>
                <span class="viewlayer__progress" #loaderSeriesProgress>0</span>
            </div>
        </div>

        <div class="dicom__framebar framebar" *ngIf="seriesPaths.length !== 0">
            <div class="framebar__display">
                <span class="framebar__display--current">{{
                    currentDick + 1
                }}</span
                >/<span class="framebar__display--total">{{
                    seriesPaths.length
                }}</span>
            </div>

            <input
                type="range"
                class="framebar__scroll input-range"
                [style.width.px]="inputFrameWidth"
                [style.right.px]="-inputFrameWidth / 2"
                min="0"
                #frameScrollInput
                (input)="onFrameInputChange($event)"
            />
        </div>
    </div>

    <ng-template #loader>
        <div class="dicom__loader">
            {{
                isError
                    ? "Не удалось получить снимки исследования"
                    : "Загрузка..."
            }}
        </div>
    </ng-template>
</section>
