<div class="cls-block-header header">
    <h2 class="header__title">Лаборатория</h2>
    <button
        class="btn cls-btn-second cls-btn-filter-local"
        (click)="btnFiltrTek()"
    >
        за сегодня
    </button>
    <button
        class="btn cls-btn-second cls-btn-filter-local"
        (click)="btnFiltr3Day()"
    >
        за 3 дня
    </button>
    <button
        class="btn cls-btn-second cls-btn-filter"
        (click)="btnFiltrOrderUp()"
    >
        {{ orderUPText }}
        <mat-icon
            *ngIf="orderUP == false"
            svgIcon="arrow_down"
            class="cls-btn-icon cls-btn-icon-down"
        ></mat-icon>
        <mat-icon
            *ngIf="orderUP == true"
            svgIcon="arrow_up"
            class="cls-btn-icon cls-btn-icon-up"
        ></mat-icon>
    </button>
</div>

<div class="loading-group cls-block-body row">
    <app-panel
        class="col-md-4 h-100"
        classSrt="cls-position-relative h-100 loading-group"
    >
        <div *ngIf="loadingOrderList" class="loading">
            <img src="./assets/loading.gif" />
        </div>

        <app-panel-table-pagination
            nameBlock="Заказы"
            (onChangePeriod)="changePeriod($event)"
            [dtBegin]="dtBegin"
            [dtEnd]="dtEnd"
            textItogo="заказов"
            [CountRec]="orderListLength"
            [CountRecPage]="countRectoPage"
            (onChangedPage)="changedPage($event)"
        >
            <ng-container
                *ngIf="orderListLength > 0; then showTable; else showNotRec"
            ></ng-container>

            <ng-template #showNotRec>
                <div class="cls-not-rec">Нет заказов</div>
            </ng-template>

            <ng-template #showTable>
                <!--
                        <table class="table table-borderless table-hover" style=" height: 98%; display: block; overflow: auto; font-size: 20px;">
                -->
                <table class="table table-borderless table-hover">
                    <thead
                        style="
                            position: sticky;
                            top: 0px;
                            background-color: white;
                        "
                    >
                        <tr>
                            <!--
                                    <th scope="col" class="cls-header-table" style="width: 10%;"></th>
                        -->
                            <th
                                scope="col"
                                class="cls-header-table cls-header-table-dt"
                                style="width: 40%"
                            >
                                Дата Заказа
                            </th>
                            <th
                                scope="col"
                                class="cls-header-table"
                                style="width: 600px"
                            >
                                Статус
                            </th>
                            <!--
                                    <th scope="col" class="cls-header-table" style="width: 10%;"></th>
                        --></tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let order of orderList; let i = index"
                        >
                            <tr *ngIf="isBlockYeer(i)">
                                <!--
                                          <td colspan="4" class="cls-td-yeer">{{getYeer(i)}}</td>
                            -->
                                <td colspan="2" class="cls-td-yeer">
                                    {{ getYeer(i) }}
                                </td>
                            </tr>

                            <tr
                                (click)="selectOrder(order)"
                                [ngClass]="{ active: order == selectedOrder }"
                                class="cls-cp"
                            >
                                <!--
                                          <td class="cls-table-td-event"></td>
                            -->
                                <td class="cls-table-td-event">
                                    {{ getTekDay(order.dtSort) }} в
                                    {{ getTime(order.dtSort) }}
                                    <div style="color: #c5c5c5">
                                        {{ getNameDay(order.dtSort) }}
                                    </div>
                                </td>

                                <td
                                    class="cls-table-td-event cls-status-order"
                                    [ngClass]="{
                                        'cls-color-status-order1':
                                            order.status == 'N',
                                        'cls-color-status-order2':
                                            order.status == 'X',
                                        'cls-color-status-order3':
                                            order.status == 'R',
                                        'cls-color-status-orderF':
                                            order.status == 'F',
                                        'cls-color-status-order4':
                                            order.status == 'P',
                                        'cls-color-status-orderW':
                                            order.status == 'W'
                                    }"
                                >
                                    <mat-icon svgIcon="point"></mat-icon>
                                    <span>{{ order.text_status }}</span>
                                </td>
                                <!--
                                          <td class="cls-table-td-event"></td>
                            --></tr>
                        </ng-container>
                    </tbody>
                </table>
            </ng-template>
        </app-panel-table-pagination>
    </app-panel>

    <app-panel
        class="col-md-8 h-100"
        classSrt="h-100 loading-group"
        style="padding-left: 0px"
    >
        <div *ngIf="loadingResearchList" class="loading">
            <img src="./assets/loading.gif" />
        </div>

        <div class="research-header">
            <span class="date-research">{{
                getDateOrder(selectedOrder?.dtSort)
            }}</span>
            <span class="name-research" *ngIf="selectedOrder"
                >Заказ N{{ selectedOrder.material_id }}</span
            >
            <span class="count-research" *ngIf="selectedOrder"
                >{{ labResult.length }}
                {{ strEnd(labResult.length, "Биоматериал", ["", "а", "ов"]) }}
                <mat-icon
                    svgIcon="question"
                    [ngbTooltip]="tipContentHelp"
                    container="body"
                ></mat-icon
            ></span>
            <!-- Добавил условие для отображения кнопки печати -->
            <ng-container
                *ngIf="
                    (isPrint(labResult) &&
                        selectedOrder?.text_status === 'выполнен') ||
                    selectedOrder?.text_status === 'частично выполнен'
                "
            >
                <mat-icon
                    *ngIf="!pdfLoading"
                    svgIcon="print"
                    class="cls-btn-icon cls-btn-print"
                    (click)="goPdf(selectedOrder?.research_id)"
                ></mat-icon>
                <img
                    *ngIf="pdfLoading"
                    class="pdf-loader loader-rotate"
                    src="./assets/img/svg/loader-btn.svg"
                    alt=""
                />
            </ng-container>
            <ng-template #tipContentHelp let-greeting="greeting">
                <ul>
                    <li
                        *ngFor="let result of labResult; let i = index"
                        style="text-align: left"
                    >
                        {{ result.name }}
                    </li>
                </ul>
            </ng-template>
        </div>

        <div style="overflow: auto; height: 95% !important" *ngIf="!onlyPdf">
            <div
                *ngFor="let result of labResult; let i = index"
                class="block-material-item"
            >
                <div class="item-header" *ngIf="!isNotShowResult">
                    <span class="name-res-item">{{ result.name }}</span>
                    <span class="ids-res-item"
                        >Код пробирки (IDS): {{ result.ids }}</span
                    >
                </div>

                <ng-container
                    *ngIf="result.formBio; then showFormBio; else showForm"
                ></ng-container>

                <ng-template #showFormBio>
                    <app-forma-biomaterial
                        *ngIf="!isNotShowResult"
                        [data]="resForFormBio(result.res)"
                        [srvText]="result.srvText"
                        [srvRes]="result.srvRes"
                        [note]="result.note"
                        [statusRoot]="selectedOrder?.status"
                    ></app-forma-biomaterial>
                </ng-template>

                <ng-template #showForm>
                    <app-forma-material
                        *ngIf="!isNotShowResult"
                        [data]="resForForm(result.res)"
                    ></app-forma-material>
                </ng-template>
            </div>
            <div *ngIf="isNotShowResult" class="res-in-work">
                {{ selectedOrder?.text_status ?? "Результат обрабатывается" }}
            </div>
        </div>

        <div
            style="overflow: auto; height: 95% !important"
            *ngIf="onlyPdf && pdfHtml"
        >
            <div [innerHtml]="pdfHtml"></div>
        </div>
    </app-panel>
</div>
