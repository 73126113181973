import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from "moment";
import {ICalendarDate, ICalendarHeader} from "../../../../../interfaces/calendar";
import {ICalendarData, IDep} from "../../../../../interfaces/record.interface";
import {RecordService} from "../../../../../services/record.service";

@Component({
  selector: 'app-rec-calendary',
  templateUrl: './rec-calendary.component.html',
  styleUrls: ['./rec-calendary.component.scss']
})
export class RecCalendaryComponent implements OnInit {
  @Output() onClickDate = new EventEmitter<string>(); // выбрана Дата формат YYYY-MM-DD
  dayArray: ICalendarDate[] = [];  // Массив дней
  _params: ICalendarData | null = null; // Данные дял инициализации
  selectedDep: IDep | null = null;  // Выбранный деп

  headerText: ICalendarHeader  = {mount: '', year: ''};

  constructor(private rs: RecordService) {
    rs.CalendaryonInit$().subscribe(n =>{
      this.initCalendary(n, null);
    });

    rs.DepOnSelect$().subscribe(n => {  // Смена DEP
// 
      if (this._params) {
        this.initCalendary(this._params, n);
      }
    });


  }

  ngOnInit(): void {
    this._params = null;
  }

  creatHeader(minDt: Date, maxDt: Date){
    if (minDt.getFullYear() == maxDt.getFullYear()) { // Года одинаковые
      if (minDt.getMonth() == maxDt.getMonth()) {
        this.headerText.mount = `${moment(minDt).format('MMMM')}`;
      } else {
        this.headerText.mount = `${moment(minDt).format('MMMM')}-${moment(maxDt).format('MMMM')}`;
      }
      this.headerText.year = maxDt.getFullYear() + '';

    } else {
      this.headerText.year = `${minDt.getFullYear()}-${maxDt.getFullYear()}`;
      this.headerText.mount = `${moment(minDt).format('MMMM')}-${moment(maxDt).format('MMMM')}`;
    }
  }

  initCalendary(params: ICalendarData, dep: IDep | null){
// 
    this._params = params;
    if (dep) {
      this.selectedDep = dep;
    } else {
      this.selectedDep = null;
    }
//    Number(moment(dt).format('YYYYMMDD'))
    this.creatHeader(params.periodStart, params.periodEnd);
    this.genCalendaryDay(params.periodStart);
    // Автоматом выбор первого дня с талоном
    let i = 0; let f = true;
    while ((i <= this.dayArray.length -1) && (f)){
      if (this.dayArray[i].isAction) {
        this.clicDate(this.dayArray[i]);
        f = false;
      }
      i++;
    }
  }

  /* генератор календаря */
  genCalendaryDay(minDt: Date){
    let firstDay = minDt;
    let weekday1 = 0;
    this.dayArray = [];
    weekday1 = moment(minDt).isoWeekday(); // День недели первого числа периода
    // Проверка если 1 число не понедельник
    if (weekday1>1) {
      // Добавляем даты предыдущего месяца
      for (var i = weekday1 - 1 ; i >= 1; i--) {
        this.addDay(false, moment(firstDay).subtract(i, 'days').toDate(), false);
      }
    }
    let countDay = 35-this.dayArray.length; // 5 недель (5*7)
    for (var i = 0 ; i < countDay; i++) { // добавляем оставшиеся дни
      const d = moment(firstDay).add(i, 'days').toDate();
      this.addDay(true, d, this.isActive(d));
    }

// 
  }

  addDay(courrentMonth: boolean, dt: Date, activ: boolean){
    const curent: boolean = (moment(dt).format('DD-MM-YYYY') == moment(new Date()).format('DD-MM-YYYY') );
    this.dayArray.push({
      isCourrentMonth: courrentMonth
      , data: dt
      , day: dt.getDate()
      , isAction: activ
      , isSelected: false
      , isCourrentDay: curent && courrentMonth
    });
  }

// Проверка на активность даты
  isActive(dt: Date): boolean {
    let n = Number(moment(dt).format('YYYYMMDD'));
// 
// @ts-ignore
//    

    if (this._params){
      if (this.selectedDep && this.selectedDep.keyid >0 ) {
        // @ts-ignore
        return this._params.talonList.filter(item => (item.dtFilter === n && item.depid === this.selectedDep.keyid)).length>0;
      } else {
        return this._params.talonList.filter(item => item.dtFilter === n).length>0;
      }
    } else {
      return false;
    }
  }

  clicDate(dt: ICalendarDate){
// 
    this.dayArray.forEach(item => {
      item.isSelected = (item == dt && item.isAction);
    });
    if (dt.isAction) {
      if (this.selectedDep) {
        this.rs.TalonsDaySetParams({dt: dt.data, dep: this.selectedDep});
      } else {
        this.rs.TalonsDaySetParams({dt: dt.data});
      }
    }
  }


}
