import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

/**
 * Создаёт обратный вызов visible при пересечении элемента и viewport.
 *
 *  @param {number} debounceTime Время срабатывания события visible.
 *  @param {number} threshold Пороговое значение, при котором срабатывает обратный вызов. По умолчанию сразу.
 *  @return {EventEmitter<any>} visible Событие пересечения элемента и viewport.
 */
@Directive({
    selector: '[observeVisibility]',
})
export class ObserveVisibilityDirective
    implements OnDestroy, OnInit, AfterViewInit
{
    /** Время срабатывания события */
    @Input() debounceTime = 0;

    /** Пороговое свойство указывает, при каком проценте должен выполняться обратный вызов. По умолчанию сразу. */
    @Input() threshold = 1;

    /**
     * Событие, срабатывающее, когда элемент виден на экране втечении debounceTime.
     *  @return {IntersectionObserverEntry} entry
     */
    @Output() onVisible = new EventEmitter<any>();
    @Output() onHidden = new EventEmitter<any>();

    private observer: IntersectionObserver | undefined;

    constructor(private element: ElementRef) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.createObserver();
        this.startObserve();
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }
    }

    /** Создание и настройка observer */
    private createObserver() {
        const options = {
            rootMargin: '0px',
            threshold: this.threshold,
        };

        const isIntersecting = (entry: IntersectionObserverEntry) =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (isIntersecting(entry)) {
                    this.onVisible.emit();
                } else {
                    this.onHidden.emit();
                }
            });
        }, options);
    }

    private startObserve() {
        this.observer?.observe(this.element.nativeElement);
    }
}
