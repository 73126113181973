<!-- Файл представления компонента вывода и выбора филиалов раздела "Запись к врачу" мобильной версии -->
<div class="staps__dropdown dropdown-staps" [ngClass]="{'--active': shownDeps == true}">
  <ng-template [ngIf]="depSelectName">
    <button class="dropdown-staps__button list-btn reset" [ngClass]="{'--active': shownDeps == true}" (click)="toggleShowDeps()">{{depSelectName}}</button>
  </ng-template>
  <ng-template [ngIf]="!depSelectName">
    <button class="dropdown-staps__button list-btn reset" [ngClass]="{'--active': shownDeps == true}" (click)="toggleShowDeps()">Выберите филиал</button>
  </ng-template>
  <div class="dropdown-staps__list" [ngClass]="{'--visible': shownDeps == true}">
    <button class="dropdown-staps__btn reset" *ngFor="let dep of depList" (click)="selectDep(dep)">{{dep.text}}</button>
  </div>
  <input type="text" name="select-category" value="" class="dropdown-staps__input">
</div>

<div class="staps__time time-choice calendar-container" [ngClass]="{'--active': shownTalons == true}">
  <app-mobile-talons></app-mobile-talons>
</div>
<app-rec-mobile-calendary></app-rec-mobile-calendary>