import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICalendarInit } from '../interfaces/calendar';

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    initSubject = new Subject<ICalendarInit>();

    setParams(num: ICalendarInit) {
        if (this.initSubject) {
            this.initSubject.next(num);
        }
    }

    // Событие инициализации
    onInit$(): Observable<ICalendarInit> {
        return this.initSubject.asObservable();
    }

    // Событие, отмены выбора
    notSelectSubject = new Subject<boolean>();

    notSelect(num: boolean) {
        // Отменить выбор в календаре
        if (this.notSelectSubject) {
            this.notSelectSubject.next(num);
        }
    }

    // Событие - отмена выбранной даты
    onNotSelect$(): Observable<boolean> {
        return this.notSelectSubject.asObservable();
    }

    // Устанока выбранной даты
    setSDSubject = new Subject<string>();

    setSelectedDate(dt: string) {
        // Отменить выбор в календаре
        this.setSDSubject.next(dt);
    }

    onSelectedDate$(): Observable<string> {
        return this.setSDSubject.asObservable();
    }
}
