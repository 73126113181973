import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatService } from '../../services/stat.service';

@Component({
    selector: 'app-stat',
    templateUrl: './stat.component.html',
    styleUrls: ['./stat.component.scss'],
})
export class StatComponent implements OnInit {
    constructor(private router: Router, public ss: StatService) {
        const url = '/stat?key=';
        if (this.router.url.indexOf(url) == 0) {
            let paramStat = this.router.url.substring(url.length);

            ss.setStatLog(paramStat).subscribe(
                (resStat) => {
                    
                },
                (err) => {
                    
                }
            );
            

            window.location.href = '/login';
        }
    }

    ngOnInit(): void {
        //     
    }
}
