import { FeedbackComponent } from './components/feedback/feedback.component';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AccountComponent } from './components/account/account.component';
import { IndexComponent } from './components/home/index/index.component';
import { ServicesComponent } from './components/services/services.component';
import { LabsComponent } from './components/labs/labs.component';
import { RecComponent } from './components/rec/rec.component';
import { HistoryComponent } from './components/history/history.component';
import { StatComponent } from './components/stat/stat.component';
import { VisitProtocolComponent } from './components/visit-protocol/visit-protocol.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { RecServicesComponent } from './components/rec-services/rec-services.component';
import { PatientFilesComponent } from './components/patient-files/patient-files.component';

export interface RouteInfo extends Route {
    title?: string;
    position?: number;
}

// const routes: Routes = [
const routes: RouteInfo[] = [
    /**
     * Главная
     */
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        title: 'Главная',
        path: 'home',
        component: HomeComponent,
        position: -1,
        children: [
            {
                path: 'account',
                component: AccountComponent,
            },
            {
                path: 'services',
                component: ServicesComponent,
            },
            {
                path: 'payments',
                component: PaymentsComponent,
            },
            {
                path: 'labs',
                component: LabsComponent,
            },
            {
                path: 'rec',
                component: RecComponent,
            },
            {
                path: 'history',
                component: HistoryComponent,
            },
            {
                path: 'visit-protocol/:visitID/:formID',
                component: VisitProtocolComponent,
            },
            {
                path: 'record-services',
                component: RecServicesComponent,
            },
            {
                path: '',
                component: IndexComponent,
            },
            { 
                path: 'feedback', 
                component: FeedbackComponent 
            },
            {
                path: 'patient-files',
                component: PatientFilesComponent
            }
        ],
    },

    /**
     * Аунтификация в приложении
     */
    {
        title: 'Авторизация',
        path: 'login',
        component: LoginComponent,
        children: [
            { path: 'recovery', component: LoginComponent },
            { path: 'recovery/:tokenR', component: LoginComponent },
        ],
        position: -1,
    },

    /**
     * Аунтификация в приложении
     */
    {
        title: '',
        path: 'stat',
        component: StatComponent,
        position: -1,
    },

    // Error 503 scree
    {
        title: 'Сервис не доступен',
        path: '503',
        component: ServiceUnavailableComponent,
        position: -1,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
