import {
    getNameDay,
    getTekDay,
    getTime,
    strToDate,
} from './../../application/global.function';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/application/config.service.';
import { RnumbService } from 'src/app/services/rnumb.service';
import { RecDoctorService } from './../../../services/to-doctor/rec-doctor.service';
import { IRnumbList } from 'src/app/interfaces/rnumb.interface';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import * as moment from 'moment';
import { from } from 'rxjs';
import { CommonModule } from '@angular/common';

export interface ITalonTime {
    time: string;
    talon_id: number;
    type_r: string;
}

export interface ITalon {
    tekDay: string; // сегодня
    Weekday: string; // день недели
    times?: ITalonTime[]; // Время
}

@Component({
    selector: 'app-block-talons',
    templateUrl: './block-talons.component.html',
    styleUrls: ['./block-talons.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class BlockTalonsComponent implements OnInit {
    //  talons: Ihdoctor[] = [];
    talons: IRnumbList[] = [];
    talonsTime: ITalon[] = [];

    private _loadHistoryTalons = false;

    constructor(
        private recDoc: RecDoctorService,
        private rs: RnumbService,
        private configS: ConfigService,
        private auth: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.talons = [];
        this.talonsTime = [];
        this.rs.getRnumbList().subscribe(
            (results) => {
                //
                this.talons = results;
                this.talons.forEach((item) => {
                    //
                    /*
              item.beginDate = new Date(item.dat_bgn.replace(/(\d+).(\d+).(\d+) (\d+):(\d+)/, '$3/$2/$1 $4:$5'));
              item.endDate = new Date(item.dat_end.replace(/(\d+).(\d+).(\d+) (\d+):(\d+)/, '$3/$2/$1 $4:$5'));
  */
                    item.beginDate = strToDate(item.dat_bgn);
                    item.endDate = strToDate(item.dat_end);
                });

                this._loadHistoryTalons = false;

                //

                // Сортировка
                this.talons = this.talons.sort((a, b) => {
                    // @ts-ignore
                    return a.beginDate.getTime() - b.beginDate.getTime();
                });
                //Группируем по дате
                const source = from(this.talons);
                // @ts-ignore
                const temp = source.pipe(
                    groupBy((resspec) =>
                        moment(resspec.beginDate).format('DD.MM.YYYY')
                    ),
                    mergeMap((group) => group.pipe(toArray()))
                );

                temp.subscribe((val) => {
                    let tmpTime: ITalonTime[] = [];
                    val.splice(3); // Кол во талонов на день
                    val.forEach((item) => {
                        tmpTime.push({
                            time: this.getTime(item.beginDate!),
                            talon_id: item.rnumb_id,
                            type_r: item.type_r,
                        });
                    });

                    this.talonsTime.push({
                        tekDay: this.getTekDay(val[0].beginDate!),
                        Weekday: this.getNameDay(val[0].beginDate!),
                        times: tmpTime,
                    });
                    //
                });
                // Только 3 записи
                this.talonsTime.splice(2); // Кол дней
            },
            (error) => {
                this._loadHistoryTalons = false;
                console.error(error);
            }
        );
    }
    navigateToHistory() {
        this.router.navigate(['/home/history']);
      }

    //TODO PIECE OF SHIT BELOW

    getTime(dt: Date): string {
        //    return moment(dt).utcOffset(0, false).format('HH:mm');
        return getTime(dt);
    }

    getTekDay(dt: Date): string {
        return getTekDay(dt);
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    goTalon(talon: ITalon) {
        // @ts-ignore
        if (
            talon &&
            talon.times &&
            talon.times.length > 0 &&
            talon.times[0].type_r
        ) {
            // @ts-ignore
            if (talon.times[0].type_r == 'visit') {
                // @ts-ignore
                window.open(
                    `${this.configS.getValue(
                        'hostBackend'
                    )}/history/visit/pdf/${this.auth.token}/${
                        talon.times[0].talon_id
                    }.pdf`,
                    '_blank'
                );
            } else {
                this.router.navigate(['/home/history']);
            }
        } else {
            this.router.navigate(['/home/history']);
        }
    }
}
