import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";


@Component({
  selector: 'app-history-item',
  templateUrl: './history-pdf.component.html',
  styleUrls: ['./history-pdf.component.scss']
})
export class HistoryPdfModal implements OnInit {
  resHtml: string = '';
  pdfLoading: boolean = false;
  pdfImageURL = 'assets/img/svg/pdf-view.svg';
  pdf: Map<string, ArrayBuffer> = new Map();
  constructor(@Inject(MAT_DIALOG_DATA) public dataIn: any) {}

  ngOnInit() {
    for (let i = 0; i < this.dataIn.length; i++) {
        this.pdf.set(this.dataIn[i].file_name, this.dataIn[i].content.data);
    }
  }
  ngOnDestroy() {
    this.pdfLoading = false;
    this.dataIn = null;
    this.pdf.clear();
  }

  gotToDownload(event: Event, item:any) {
    event.preventDefault();
    const pdfBuffer: ArrayBuffer = item.value;
    const arr = new Uint8Array(pdfBuffer);
    const blob = new Blob([arr], { type: 'application/pdf'});
    const fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl, "_blank");
  }

  mouseEnt(event: any) {
    event.stopPropagation();
    event.target.children[0].src = 'assets/img/svg/pdf_hover.svg'
  }
  mouseOut(event: any) {
    event.stopPropagation();
    event.target.children[0].src = 'assets/img/svg/pdf-view.svg';
  }
}