<!-- Файл представления компонента вывода и выбора талонов раздела "Запись к врачу" мобильной версии -->
<div class="time-choice__nav">
    <ng-template [ngIf]="!prevDate">
        <button class="time-choice__prev time-choice__arrow reset" disabled>
            <img src="./assets/img/svg/prev.svg" alt="prev" />
        </button>
    </ng-template>
    <ng-template [ngIf]="prevDate">
        <button
            class="time-choice__prev time-choice__arrow reset"
            (click)="clickDate(prevDate)"
        >
            <img src="./assets/img/svg/prev.svg" alt="prev" />
        </button>
    </ng-template>
    <div class="time-choice__info" (click)="toggleClass()">
        <span class="time-choice__label">Свободно на</span>
        <span class="time-choice__date">{{ headerTxt }}</span>
    </div>
    <ng-template [ngIf]="!nextDate">
        <button class="time-choice__next time-choice__arrow reset" disabled>
            <img src="./assets/img/svg/next.svg" alt="next" />
        </button>
    </ng-template>
    <ng-template [ngIf]="nextDate">
        <button
            class="time-choice__next time-choice__arrow reset"
            (click)="clickDate(nextDate)"
        >
            <img src="./assets/img/svg/next.svg" alt="next" />
        </button>
    </ng-template>
</div>
<div class="time-choice__grid">
    <div
        class="checkbox-time"
        *ngFor="let d of talonListDay; let i = index"
        [ngClass]="{ disabled: talonDisable(d) }"
    >
        <input
            id="time-{{ d.rnumbid }}"
            type="radio"
            name="time"
            value="{{ d.rnumbid }}"
            class="checkbox-time__input"
            ng-checked="d == selectedTalon"
        />

        <label
            for="time-{{ d.rnumbid }}"
            class="checkbox-time__label"
            (click)="clickTalon(d)"
        >
            {{ formatTalon(d.dtBegin) }}</label
        >
    </div>
</div>
<ng-template [ngIf]="talonListDay.length == 0">
    Талончиков не найдено
</ng-template>
<div
    class="app-calendar-inhistory-wrap"
    [class.active]="classApplied"
    [ngStyle]="blokCalendaryStyleHeight"
>
    <app-rec-calendar-mobile
        *ngIf="isNotDate === false"
        (onClickDate)="calendarClick($event)"
        (onCalendarClose)="toggleClass()"
    ></app-rec-calendar-mobile>
</div>
