import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})

export class LoaderComponent implements OnInit {
  @Input() type: 'page' | 'component' = 'component';
  @Input() bgFill = false;
  @Input() isFullscreen = true;
  @Input() color: '#fff' | '#1A8DCC' = '#fff';
  @Input() message?: string;

  constructor() {
  }

  ngOnInit() {
  }
}
