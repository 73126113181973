import { Injectable } from '@angular/core';
import * as EventEmitter from 'events';
import { T_EVENT } from './types';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private _emitter = new EventEmitter();

    constructor() {}

    on<T>(ev: T_EVENT, handler: () => void) {
        this._emitter.on(ev, handler);
    }

    emit<T>(ev: T_EVENT, data?: T | undefined) {
        this._emitter.emit(ev, data);
    }
}
