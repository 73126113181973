<div class="calendar-list unselectable">
    <div class="calendar-list__wrapper row w-100">

        <div class="cal-date-begin col-md-6">
            <div class="cal-dr-header">

                <button
                class="img-btn-arrow-left calendar--btn"
                (click)="mountPlusMinus(headerLeft, '-')"
                [ngClass]="{ 'img-btn-lr-not-active': headerLeft.left==false}"
                >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.64471 0.368328C7.75733 0.484325 7.84669 0.622106 7.90765 0.773786C7.96862 0.925467 8 1.08807 8 1.25228C8 1.41649 7.96862 1.57909 7.90765 1.73077C7.84669 1.88245 7.75733 2.02023 7.64471 2.13623L2.93089 7.00109L7.64471 11.866C7.87187 12.1004 7.99949 12.4184 7.99949 12.7499C7.99949 13.0815 7.87187 13.3994 7.64471 13.6339C7.41755 13.8683 7.10945 14 6.7882 14C6.46695 14 6.15886 13.8683 5.9317 13.6339L0.35529 7.87877C0.242664 7.76278 0.153311 7.625 0.0923452 7.47332C0.0313797 7.32164 0 7.15904 0 6.99482C0 6.83061 0.0313797 6.66801 0.0923452 6.51633C0.153311 6.36465 0.242664 6.22687 0.35529 6.11087L5.9317 0.35579C6.39336 -0.120666 7.1709 -0.120666 7.64471 0.368328Z" fill="#9AACDB"/>
                </svg>

                </button>
                <!-- <mat-icon svgIcon="arrow_left_old"
                          class="img-btn-arrow-left"
                          (click)="mountPlusMinus(headerLeft, '-')"
                          [ngClass]="{ 'img-btn-lr-not-active': headerLeft.left==false}">
                </mat-icon> -->

                <span class="cal-dr-header-mount" (click)="setDateAndMonth()">{{ headerLeft.mount | titlecase }} {{ headerLeft.year }}</span>

                <span>
                    <!-- *ngIf="headerLeft.right" -->
                    <button
                    class="img-btn-arrow-right calendar--btn"
                    (click)="mountPlusMinus(headerLeft, '+')"
                    >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.355291 0.368328C0.242666 0.484325 0.153312 0.622106 0.0923468 0.773786C0.0313812 0.925467 0 1.08807 0 1.25228C0 1.41649 0.0313812 1.57909 0.0923468 1.73077C0.153312 1.88245 0.242666 2.02023 0.355291 2.13623L5.06911 7.00109L0.355291 11.866C0.128132 12.1004 0.000514428 12.4184 0.000514428 12.7499C0.000514428 13.0815 0.128132 13.3994 0.355291 13.6339C0.582451 13.8683 0.890546 14 1.2118 14C1.53305 14 1.84114 13.8683 2.0683 13.6339L7.64471 7.87877C7.75734 7.76278 7.84669 7.625 7.90765 7.47332C7.96862 7.32164 8 7.15904 8 6.99482C8 6.83061 7.96862 6.66801 7.90765 6.51633C7.84669 6.36465 7.75734 6.22687 7.64471 6.11087L2.0683 0.35579C1.60664 -0.120666 0.829104 -0.120666 0.355291 0.368328Z" fill="#9AACDB"/>
                    </svg>

                    </button>
                    <!-- <mat-icon *ngIf="headerLeft.right"
                              svgIcon="arrow_right_old"
                              class="img-btn-arrow-right"
                              (click)="mountPlusMinus(headerLeft, '+')"
                    >
                    </mat-icon> -->
                </span>

            </div>
           <div class="mobile-date-range__table-day">
               <app-table-day class="mobile" prefix="dtLeft" ></app-table-day>
           </div>
        </div>

        <div *ngIf="toggle" class="myModel--ovrflow">
            <div class="myModel--ovrflow--close1" (click)="setDateAndMonth()"></div>
            <div class="myModal--content">
                <div  class="mobile-period__close"><button (click)="toggle=false" class="mobile-period__close--btn"><svg  width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path  fill-rule="evenodd" clip-rule="evenodd" d="M11.0492 9.44691L18.1713 16.569C18.5956 16.9933 18.5956 17.6631 18.1713 18.0649C17.7471 18.4891 17.0773 18.4891 16.6755 18.0649L9.55336 10.9428L2.43124 18.0649C2.007 18.4891 1.3372 18.4891 0.935369 18.0649C0.511127 17.6407 0.511127 16.9709 0.935369 16.569L8.05749 9.44691L0.935369 2.32479C0.511127 1.90055 0.511127 1.23076 0.935369 0.828923C1.35961 0.404682 2.0294 0.404682 2.43124 0.828923L9.55336 7.95104L16.6755 0.828923C17.0997 0.404682 17.7695 0.404682 18.1713 0.828923C18.5956 1.25316 18.5956 1.92296 18.1713 2.32479L11.0492 9.44691Z" fill="#B7C9DF"></path></svg></button></div>



                <div class="picker22--wrap">


                    <div class="picker22" >



                        <div class="time-list-container">
                            <span class="time-highlight">
                            </span>

                            <div class="time-list--over__wrap">
                                <div class="time-list--over">
                                    <div class="time-list-wrapper state-focused">

                                        <div class="time-list">
                                            <div #hContainer class="time-container content scrollable" (scroll)="handleHHScroll($event)" id="h_container">
                                                <ul class="reset text--align">
                                                    <li class="item item-capitalize" (click)="scrollTo(i, $event, 'hh')" *ngFor="let item of monthList; let i = index;">
                                                        <span [ngClass]="{'active--pickerdate': hhValue == item}">{{item.name}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="time-separator">:</div> -->
                                    <div class="time-list-wrapper state-focused">

                                        <div class="time-list">
                                            <div #mContainer class="time-container content scrollable" (scroll)="handleMMScroll($event)" id="m_container">
                                                <ul class="reset text--align-left">
                                                    <li class="item" (click)="scrollTo(i, $event, 'mm')" *ngFor="let year of fastYears; let i = index;">
                                                        <span [ngClass]="{'active--pickerdate': mmValue == year}">{{year}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <!-- <div class="picker-window" ></div>
                        <div class="triangle"></div> -->

                        <!-- <div> {{ headerLeft.mount | titlecase }}</div>  -->

                       <!-- <ul class="picker-day" (scroll)="onscrollMr($event)">
                            <li *ngFor="let month of monthList"  (click)="changeMySelect($event, month.id, null)"  class="month">{{month.name}}</li>
                        </ul>

                        <ul class="picker-hour">
                            <li class="year" *ngFor="let year of fastYears" (click)="changeMySelect($event, null, year)">{{year}}</li>

                        </ul> -->
                        <!-- <div style="position: relative" >
                            <mat-select   placeholder="{{headerLeft.mount}}" class="myFormAppereacnce-select" panelClass="testClass">
                                <mat-option *ngFor="let month of monthList" (click)="changeMySelect($event, month.id, null)"   value="{{month.name}}"  >{{month.name}}</mat-option>
                              </mat-select>
                        </div>
                        <div style="position: relative">
                            <mat-select   placeholder="{{ headerLeft.year }}" class="myFormAppereacnce-select" panelClass="testClass2">
                                <mat-option *ngFor="let year of fastYears" (click)="changeMySelect($event, null, year)" value="{{year}}"  >{{year}}</mat-option>
                              </mat-select>
                        </div> -->
                    </div>



                </div>

                <div class="btn-wrapp">
                    <button (click)="setFastDate()" class="mobile-period__apply fastDate--btn">Выбрать</button>
                </div>

            </div>
        </div>

<!--

        &lt;!&ndash;    Вторая половина календаря    &ndash;&gt;
        <div class="split-lines-block">
            <div class="split-lines" *ngIf="headerLeft.right">
            </div>
        </div>

        <div class="cal-date-end col-md-6">
            <div class="cal-dr-header">
                            <span style="min-width: 24px; min-height: 24px; float: left">
                            <mat-icon *ngIf="headerRight.left" svgIcon="arrow_left_old" class="img-btn-arrow-left"
                                      (click)="mountPlusMinus(headerRight, '-')"></mat-icon>
                            </span>
                <span class="cal-dr-header-mount">{{headerRight.mount}} {{headerRight.year}}</span>
                <mat-icon svgIcon="arrow_right_old" class="img-btn-arrow-right"
                          (click)="mountPlusMinus(headerRight, '+')"
                          [ngClass]="{ 'img-btn-lr-not-active': headerRight.right==false}"></mat-icon>
            </div>
            <app-table-day prefix="dtRight"></app-table-day>
        </div>
-->




    </div>
</div>
