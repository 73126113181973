import {Component, OnInit} from '@angular/core';
import {expandedAnimation} from "../../../../../animations/height.animation";
import {RecordService} from "../../../../../services/record.service";
import {IDep} from "../../../../../interfaces/record.interface";

const SELECT_ALL_OPTION_TEXT = 'Все';

@Component({
  selector: 'app-rec-dep-list',
  templateUrl: './rec-dep-list.component.html',
  styleUrls: ['./rec-dep-list.component.scss'],
  animations: [expandedAnimation]
})
export class RecDepListComponent implements OnInit {
  depList: IDep[] = [];  // Список филиалов
  depSelect: IDep | null = null; // Выбранный филиал
  depSelectName: string = ''; // Выбранный адрес текст

  searchAmine = 'off';

  constructor(private rs: RecordService) {
    rs.DepOnInit$().subscribe(n => {
      this.initDep(n);
    });

  }

  ngOnInit(): void {
  }

  btnFiltrAdr(num: number){

  }

  initDep(deps:IDep[]){
// 
    this.depList = deps;
// Добавляю ВСЕ
    if (!this.depList.find(({ text }) => text === SELECT_ALL_OPTION_TEXT)) {
      this.depList.unshift({keyid: -1, text: SELECT_ALL_OPTION_TEXT})
    };
    this.depSelect = null;
    this.depSelectName = '';
  }

  searchClick() {
//    
    if (this.searchAmine === 'off') {
      this.searchAmine = 'on';
    } else {
      this.searchAmine = 'off';
    }
  }

  changeSearch(event: any){
//    
//    this.rs.DepSelect()
  }

  whenAnimateSearch(event: any) {
//    
  }

  selectDep(dep: IDep) {
    this.searchAmine = 'off';
    this.depSelect = dep;
    this.depSelectName = '   '+this.depSelect.text;
    this.rs.DepSelect(dep);
  }

}
