import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {AlertService} from './alert.service';
import {Alert, AlertTypeEnum} from './alert.model';


@Component({
  selector: 'ariadna-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({opacity: 0, transform: 'translateX(-100%)'}),
        animate('250ms ease-out')
      ]),
      transition('* => void', [
        animate(
          '250ms ease-out',
          style({opacity: 0, transform: 'translateX(100%)'})
        )
      ])
    ])
  ]
})
export class AlertComponent implements OnInit {
  @Input()
  classes = 'top-right';

  alerts: Alert[] = [];

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // очищать события при получении пустого предупреждения
        this.alerts = [];
        return;
      }

      // Добавить новое событие в массив
      this.alerts.push(alert);
      setTimeout(() => {
        this.removeAlert(alert);
      }, alert.time);
    });
  }

  public removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  /**
   * Вернуть класс типа события
   * @param {Alert} alert
   * @returns {string}
   */
  public cssClass(alert: Alert): string {
    if (!alert) {
      return '';
    }

    switch (alert.type) {
      case AlertTypeEnum.Success:
        return 'alert alert-success';
      case AlertTypeEnum.Error:
        return 'alert alert-error';
      case AlertTypeEnum.Info:
        return 'alert alert-info';
      case AlertTypeEnum.Warning:
        return 'alert alert-warning';
    }
  }
}
