import { Component, OnInit } from '@angular/core';
import { HttpNewService } from '../../services/application/http-new.service';
import { AlertService } from '../../../lib/ariadna/alert';
import { RecordService } from '../../services/record.service';
import { IRnumbDate, IServ } from '../../interfaces/record.interface';
import * as moment from 'moment';
import { ConfigService } from 'src/app/services/application/config.service.';

interface Services {
    code: string;
    is_online_pay: 1 | 0;
    is_telemed: 1 | 0;
    keyid: number;
    price: number;
    text: string;
}

interface Doctor {
    docdepid: number;
    docid: number;
    specid: number;
    specid_1: string;
    text: string;
}

@Component({
    selector: 'rec-services',
    templateUrl: './rec-services.component.html',
    styleUrls: ['./rec-services.component.scss'],
})
export class RecServicesComponent implements OnInit {
    constructor(
        private httpService: HttpNewService,
        private alertService: AlertService,
        private rs: RecordService,
        private config: ConfigService
    ) {}

    panelSetStyleRnum = {
        height: '100%',
        'box-shadow': '-7px 3px 4px 0px rgb(212 212 212 / 50%)',
    };

    panelSetStyle = { height: '100%' };

    loadingServices = true;
    loadingImgUrl = '../../../assets/loading.gif';

    selectedSrv: IServ | undefined;
    selectedDoctor: Doctor | undefined;
    filterSearchText = '';
    services: IServ[] = [];
    doctors: Doctor[] | undefined;

    showDoctors = true;
    hideDatePicker = true;
    ngOnInit(): void {
        this.rs.TalonsDayInitSubject.subscribe((res) => {
            this.hideDatePicker = false;
            this.loadingServices = false;
        });
        moment.locale('ru');
        this.getServicesFullList();
    }

    async getServicesFullList() {
        try {
            const params = {
                beginDate: '2024-01-24',
                endDate: '2024-02-24',
            };

            const res = await this.httpService
                .post('/record/rnumb/services-full-list', null, null)
                .toPromise();

            if (!res) {
                this.alertService.error(
                    'Нет свободных врачей на даннную услугу'
                );
            }
            this.services = res;

            this.loadingServices = false;
        } catch (e) {
            console.log(e);
        }
    }

    isEmptyObject(obj: Object) {
        return JSON.stringify(obj) === '{}';
    }

    async pickDoctor(doctor: Doctor) {
        try {
            this.loadingServices = true;
            // this.hideDatePicker = true;
            this.selectedDoctor = doctor;

            const periodStart = new Date();
            const periodEnd = moment(periodStart).add(30, 'days').toDate();

            let param: IRnumbDate = {
                doctorId: this.selectedDoctor.docid,
                specId: this.selectedDoctor.specid,
                periodStart,
                periodEnd,
                srv: this.selectedSrv,
            };

            // this.rs.RnumbDateSetParams(param);
            this.rs.RnumbDateSetParams(param);
        } catch (e) {
            console.log(e);
        }
    }

    async pickService(service: IServ) {
        try {
            this.selectedSrv = service;
            this.hideDatePicker = true;
            this.loadingServices = true;
            const res = await this.httpService
                .get(`/doctor/findByServices/${service.keyid}`, null)
                .toPromise();

            this.doctors = res;
            this.loadingServices = false;
            this.showDoctors = true;
        } catch (e: any) {
            this.loadingServices = false;
            if (e.status === 400) {
                return this.alertService.error(e.error.msg);
            }
        }
    }

    getDocImg(id: number) {
        return `${this.config.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.config.getValue('hostBackend')}/img/doc/not.png`;
    }
}
