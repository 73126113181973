import { Component, OnDestroy, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadService } from 'src/app/services/application/load.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { DownloadTypes, ImgTypes } from './patient-files-types';
type TDocument = {
    dat_str: string;
    file_name: string;
    id: number;
    text?: string;
};
type TDocumentList = Array<TDocument>;
interface IDocumentResult {
    filebuffer: {
        data: Buffer;
    };
    filetype: string;
}
@Component({
    selector: 'patient-files',
    templateUrl: './patient-files.component.html',
    styleUrls: ['./patient-files.component.scss'],
})
export class PatientFilesComponent implements AfterContentInit, OnDestroy {
    public filesSection: boolean = false;
    loading: boolean = false;
    load_document: boolean = false;
    $documents: Subscription | undefined;
    documentsList: TDocumentList = [];
    selectedIndex: number | null = null;
    activeImgInBase64: string | null = null;
    downloadLink: string | null = null;
    fileNameWithExt: string | null = null;
    constructor(
        private fileServices: FileUploadService,
        private load: LoadService,
        private router: Router,
        private alertService: AlertService
    ) {
        if (load.configData.menu.items.indexOf('documents') === -1) {
            this.router.navigate(['/']);
        } else {
            // console.log(this.filesSection);
            this.filesSection = true;
        }
    }

    ngAfterContentInit() {
        this.getDocumentsList();
    }
    getDocumentsList(): void {
        this.loading = true;
        this.fileServices
            .getDocumentsList()
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                (value: TDocumentList) => {
                    this.documentsList = value;
                },
                (err: Error) => {
                    this.alertService.error(
                        'Ошибка при получении списка документов'
                    );
                    this.documentsList = [];
                    console.error('Error in getDocumentsList', err);
                }
            );
    }
    async getDocument(
        $event: any,
        id: number,
        file_name: string,
        activeIndex: number
    ): Promise<void> {
        $event.stopPropagation();
        this.selectedIndex = activeIndex;
        this.load_document = true;
        this.downloadLink = null;
        this.activeImgInBase64 = null;
        this.fileNameWithExt = null;
        try {
            const result: IDocumentResult = await this.fileServices
                .getDocument(id, file_name)
                .toPromise();
            if (this.isEnumValue(ImgTypes, result.filetype)) {
                await this.openImg(result.filebuffer.data, result.filetype);
            } else if (this.isEnumValue(DownloadTypes, result.filetype)) {
                this.createUrlForDownload(
                    result.filebuffer.data,
                    result.filetype
                );
            } else {
                throw new Error('Неизвестный тип документа');
            }
            this.fileNameWithExt = file_name.toLowerCase();
        } catch (e) {
            console.log(e);
            this.alertService.error(
                `${
                    e instanceof Error ? e.message : 'Ошибка загрузки документа'
                }`
            );
        } finally {
            this.load_document = false;
        }
    }
    ngOnDestroy(): void {
        this.$documents?.unsubscribe();
    }

    async _arrayBufferToBase64(buffer: Buffer): Promise<string> {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    async openImg(filebuffer: Buffer, filetype: string): Promise<void> {
        this.activeImgInBase64 = `data:image/${filetype};base64,${await this._arrayBufferToBase64(
            filebuffer
        )}`;
        // console.log(this.activeImgInBase64);
        this.createUrlForDownload(filebuffer, filetype);
    }
    isEnumValue(enumObj: Record<string, string>, value: string): boolean {
        return Object.values(enumObj).includes(value.toLowerCase());
    }
    createUrlForDownload(filebuffer: Buffer, filetype: string): void {
        try {
            const bytes = new Uint8Array(filebuffer);
            const blob = new Blob([bytes], {
                type: filetype,
            });
            // console.log(blob);
            const url = window.URL.createObjectURL(blob);
            this.downloadLink = url;
        } catch (e) {
            throw e;
        }
    }
}
