import {Component, Inject, OnInit} from '@angular/core';
import {HistoryService} from "../../services/history.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ICalendarDateActive} from "../../interfaces/calendar";
import * as moment from "moment";

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent implements OnInit {
  historyId: number = 0;
  historyType: string = '';
  resHtml: string = '';

  constructor(private hs: HistoryService, @Inject(MAT_DIALOG_DATA) public dataIN: any) {
// 
    if (dataIN && dataIN.type && dataIN.id) {
      this.historyId = dataIN.id;
      this.historyType = dataIN.type;
    }

  }

  ngOnInit(): void {
    this.GetItem(this.historyId);
  }

  GetItem(id: number) {
    this.hs.getHistoryItem({id: this.historyId, typeHistory: this.historyType})
      .subscribe(res => {
//          
          this.resHtml = '';
          res.forEach(item => {
            this.resHtml = this.resHtml + item.text;
          });
          //if () resHtml
        },
        err => {
          console.error('getHistoryItem ERRROr=', err);
        })
  }

}
