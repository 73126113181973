import {
    Component,
    DoCheck,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import {
    expandedAnimation,
    rightPanelAnimation,
} from '../../../animations/height.animation';
import { IPeriod } from '../../../interfaces/period.interface';
import {
    IDoctor,
    IRnumbDate,
    IServ,
    ISpec,
    IStaticFilter,
} from '../../../interfaces/record.interface';
import { IService } from '../../../interfaces/services';
import { ConfigService } from '../../../services/application/config.service.';
import { LoadService } from '../../../services/application/load.service';
import { RecordService } from '../../../services/record.service';
import { Size } from '../../../services/size';
import { strToDate } from '../../application/global.function';
import { DoctorService } from 'src/app/services/doctor.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocInfoModalComponent } from 'src/app/modals/doc-info-modal/doc-info-modal.component';

@Component({
    selector: 'app-rec-pc',
    templateUrl: './rec-pc.component.html',
    styleUrls: ['./rec-pc.component.scss'],
    animations: [expandedAnimation, rightPanelAnimation],
})
export class RecPcComponent implements OnInit {
    //    blockBodystyleHeight = {height:'auto'};
    panelSetStyle = { height: '100%' };
    panelSetStyleRnum = {
        height: '100%',
        'box-shadow': '-7px 3px 4px 0px rgb(212 212 212 / 50%)',
    };
    loadingListSpec = false; // Загрузка специальностей
    loadingListDoc = false; // Загрузка докторов

    @ViewChild('InputBlocSpec') InputBlocSpecEL: ElementRef | undefined;

    _specList: ISpec[] = []; // полный список
    specList: ISpec[] = []; // Отфильтрованный
    searchStr: string = ''; // Строка поиска
    searchAmine = 'off';
    panelRnumbAmine = 'off';
    staticFilterBTNs: IStaticFilter[] = []; // Список кнопок
    staticFilterSelected: IStaticFilter | null = null; // выбраные фильтр

    selectDoc: IDoctor | null = null;
    //  selectedSpec: number | null = null;
    periodStart: Date = new Date();
    periodEnd: Date = new Date();

    //  _searchList: ISearchServ[] = [];
    //  searchList: ISearchServ[] = [];
    selectedSpec: ISpec | null = null;

    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';

    recList: IService[] | null = null;
    //  recDoctors: IDocRec[] | null = null;
    recDoctors: IDoctor[] | null = null;
    selectedSrv: IServ | null = null; // Выбранная услуга

    recListLength: number = 0; // Количество записей

    loadingRecList = false; // Загрузка
    loadingSpecList = false; // Загрузка специальностей
    private _setting: any; // Настройки из файла

    constructor(
        private size: Size,
        private configS: ConfigService,
        private ds: DoctorService,
        private rs: RecordService,
        private title: Title,
        private load: LoadService,
        private alertService: AlertService,
        private modalService: NgbModal
    ) {
        moment.locale('ru');
        this._setting = load.configData.pages.todoctor;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        /*
        this.size.onResize$().subscribe(val => {
            let h = val.outletH;
            if (val.blockHeaderH && val.blockHeaderH>0){
                h = h- val.blockHeaderH;
            }
            this.blockBodystyleHeight.height = h +'px';
//      
        });
*/

        let srvList: IServ[] = [];
        //
    }

    ngOnInit(): void {
        //
        // Установка периуда
        this.periodStart = new Date();
        this.periodEnd = moment(this.periodStart).add(30, 'days').toDate();

        //    this.searchList = this._searchList;
        this.btnFiltrTek();
        this.getStaticfilterBTN();
        this.getSpecList();
        this.selectedSpec = null;
        this.selectedSrv = null;
        this.searchClick();
    }

    ngAfterViewInit() {
        if (this.InputBlocSpecEL) {
            this.InputBlocSpecEL.nativeElement.focus();
        }
    }

    // Получить список специальностей
    getSpecList() {
        //    this.selectedSpec = null;
        this.loadingSpecList = true;
        this.specList = [];
        this._specList = [];
        let staticId: number | null = null;
        if (this.staticFilterSelected && this.staticFilterSelected.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }
        this.rs
            .getSpecList(this.periodStart, this.periodEnd, staticId)
            .subscribe(
                (val) => {
                    //
                    this.specList = val;
                    this._specList = this.specList;
                    this.loadingSpecList = false;
                },
                (error) => {
                    console.error(error);
                    this.loadingSpecList = false;
                }
            );
    }

    // Получить список специальностей
    getDocList(p_SpecID: number) {
        //    this.selectedSpec = p_SpecID;
        this.recDoctors = [];
        this.loadingListDoc = true;

        let staticId: number | null = null;
        if (this.staticFilterSelected && this.staticFilterSelected.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }

        this.rs
            .getDocList(p_SpecID, this.periodStart, this.periodEnd, staticId)
            .subscribe(
                (val) => {
                    this.recDoctors = val;
                    this.recDoctors.forEach((item) => {
                        item.rdatemin = strToDate(item.dat_bgn);
                        item.rdatemax = strToDate(item.dat_end);
                    });

                    const docInfoPromises = this.recDoctors.map((doc) =>
                        this.ds.getDocAboutInfo(doc.doctorid).toPromise()
                    );
                    Promise.allSettled(docInfoPromises)
                        .then((res) => {
                            res.forEach((info, i) => {
                                if (
                                    this.recDoctors !== null &&
                                    info.status === 'fulfilled'
                                ) {
                                    this.recDoctors[i].info = info.value;
                                }
                            });
                        })
                        .catch(console.log)
                        .finally(() => {
                            this.loadingListDoc = false;
                        });
                },
                (error) => {
                    console.error(error);
                    this.loadingListDoc = false;
                }
            );
    }

    selectSpec(srvID: number) {
        //
        this.selectedSpec = this.specList.filter(
            (item) => item.keyid == srvID
        )[0];
        this.searchAmine = 'off';
        this.searchStr = this.selectedSpec.text;
        this.getDocList(srvID);
    }

    whenAnimateSearch(event: any) {
        // Окончание анимации
        //
        /*
            if (this.animState === 'off') {
              this.payAvansAnimeStatus = 1;
            } else {
              this.payAvansAnimeStatus = 0;
            }
        */
    }

    onSearchChange(searchValue: string): void {}

    changeSearch(str: string) {
        if (str.length > 0) {
            this.specList = this._specList.filter(
                (item) =>
                    item.text.toUpperCase().indexOf(str.toUpperCase()) !== -1
            );
        } else {
            this.specList = this._specList;
        }
    }

    searchClick() {
        this.panelRnumbAmine = 'off';
        if (this.searchAmine === 'off') {
            this.searchAmine = 'on';
        } else {
            this.searchAmine = 'off';
        }

        // Если специальность не меняется
        if (this.searchAmine === 'off' && this.selectDoc) {
            if (this.selectDoc.srvlist && this.selectDoc.srvlist.length > 0) {
                if (this.selectedSrv) {
                    this.panelRnumbAmine = 'on';
                }
            } else {
                this.panelRnumbAmine = 'on';
            }
        }
    }

    changePeriod(dt: IPeriod) {
        //
        this.dtBegin = dt.begin;
        this.dtEnd = dt.end;
        //    this.getServicesSize();
    }

    // За сегодня
    btnFiltrTek() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    // За 3 дня
    btnFiltr3Day() {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 3);
        this.dtEnd = new Date();
        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    getPeriodStr(dt1?: Date, dt2?: Date): string {
        let tmp = '';
        if (dt1 && dt2 && dt1.getMonth() == dt2.getMonth()) {
            // Одинаковый месяц
            tmp =
                '(' +
                moment(dt1).format('D') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        } else {
            tmp =
                '(' +
                moment(dt1).format('D MMM') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        }
        return tmp;
    }

    getImgSrcSpec(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/spec/${id}.png`;
    }

    getImgSrcDoc(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getImgSrcErr() {
        return `${this.configS.getValue('hostBackend')}/img/doc/not.png`;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    /* Выбор доктора */
    selectedDoc(doc: IDoctor) {
        if (this.selectDoc != doc) {
            this.selectDoc = doc;
            this.selectedSrv = null;
            this.RnumbDateSetParams();
        }
    }

    /* передача параметров в номерки */
    RnumbDateSetParams() {
        if (
            this.selectDoc &&
            this.selectDoc.srvlist &&
            this.selectDoc.srvlist.length > 0
        ) {
            // с услугами
            if (this.selectedSrv) {
                this.panelRnumbAmine = 'on'; // Включить анимацию

                let param: IRnumbDate = {
                    doctorId: 0,
                    specId: 0,
                    periodStart: new Date(),
                    periodEnd: new Date(),
                    srv: this.selectedSrv,
                };
                param.doctorId = this.selectDoc.doctorid;
                if (this.selectedSpec) {
                    param.specId = this.selectedSpec.keyid;
                }
                param.periodStart = this.periodStart;
                param.periodEnd = this.periodEnd;
                if (this.staticFilterSelected) {
                    param.staticFilterSelected = this.staticFilterSelected;
                }
                this.rs.RnumbDateSetParams(param);
            } else {
                this.panelRnumbAmine = 'off';
            }
        } else {
            // Без услуг
            this.panelRnumbAmine = 'on'; // Включить анимацию
            let param: IRnumbDate = {
                doctorId: 0,
                specId: 0,
                periodStart: new Date(),
                periodEnd: new Date(),
            };
            // @ts-ignore
            param.doctorId = this.selectDoc.doctorid;
            if (this.selectedSpec) {
                param.specId = this.selectedSpec.keyid;
            }
            param.periodStart = this.periodStart;
            param.periodEnd = this.periodEnd;
            if (this.staticFilterSelected) {
                param.staticFilterSelected = this.staticFilterSelected;
            }
            this.rs.RnumbDateSetParams(param);
        }
    }
    /* Список кнопок */
    getStaticfilterBTN() {
        this.staticFilterBTNs = [];
        this.staticFilterSelected = null;
        this.rs.getStacfilterList().subscribe(
            (val) => {
                this.staticFilterBTNs = val;
            },
            (error) => {
                console.error(error);
            }
        );
    }

    btnStaticFiltr(btnST: IStaticFilter) {
        if (this.staticFilterSelected == btnST) {
            this.staticFilterSelected = null;
        } else {
            this.staticFilterSelected = btnST;
        }

        this.getSpecList();
        this.recDoctors = [];
        this.searchStr = '';
        this.searchAmine = 'on';
    }

    onClosePanelTalon(event: number) {
        //
        this.selectDoc = null;
        this.panelRnumbAmine = 'off';
    }

    isShowSrvList(doc: IDoctor): boolean {
        if (doc && doc.srvlist && doc.srvlist.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    invalidSrv(doctorid: number): boolean {
        let f: boolean = false;
        if (
            this.selectDoc &&
            this.selectDoc.doctorid == doctorid &&
            !this.selectedSrv
        ) {
            f = true;
        }
        return f;
    }

    selectedServise(doc: IDoctor) {
        this.selectDoc = doc;
        this.RnumbDateSetParams();
    }

    getFIODoc(doc: IDoctor): string {
        let s = '';
        if (doc.l_name && doc.l_name.length > 0) {
            s = doc.l_name;
        }
        if (doc.f_name && doc.f_name.length > 0) {
            s = s + ' ' + doc.f_name[0] + '.';
        }
        if (doc.s_name && doc.s_name.length > 0) {
            s = s + ' ' + doc.s_name[0] + '.';
        }
        return s;
    }

    openDocInfoModal(doc: IDoctor, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(DocInfoModalComponent);
        modal.componentInstance.doc = doc;
        modal.componentInstance.spec = this.selectedSpec?.text;
    }
}
