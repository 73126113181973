<div class="loader-container" *ngIf="loadingServices">
    <div *ngIf="loadingServices">
        <img src="./assets/loading.gif" />
    </div>
</div>
<div class="container" *ngIf="!loadingServices">
    <div class="filterbar">
        <div class="filter-wrapper">
            <input
                type="search"
                matInput
                [(ngModel)]="filterSearchText"
                placeholder="Выберите услугу"
                [matAutocomplete]="filterSrvRef"
                class="filter filterbar__filter"
            />
            <mat-autocomplete #filterSrvRef class="filter__dropdown">
                <mat-option
                    *ngFor="
                        let serv of services | searchFilter : filterSearchText
                    "
                    [value]="serv.text"
                    class="filter__option"
                    (click)="pickService(serv)"
                >
                    <div class="filter__option-container">
                        <div class="filter__option-text">
                            <span>{{ serv.text }}</span>
                            <div
                                class="filter__option-icon"
                                *ngIf="serv.is_online_pay"
                            >
                                <img
                                    src="./assets/img/svg/rub.svg"
                                    alt="rub_svg"
                                />
                            </div>
                        </div>

                        <div class="filter__option-price">
                            <span *ngIf="serv.price != 0"
                                >{{ serv.price }}р.</span
                            >
                            <span *ngIf="serv.price == 0">Бесплатно</span>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>

        <!-- 
            <div class="content" *ngIf="!loadingServices">
                <div class="cls-date-rnum" *ngIf="showDatePicker">
                    <app-panel [styleStr]="panelSetStyleRnum">
                        <app-rnumb-selected></app-rnumb-selected>
                    </app-panel>
                </div>
    
                <div class="services" *ngIf="!showDoctors">
                    <ng-container *ngFor="let service of services">
                        <button
                        class="services__item"
                        (click)="pickService(service)"
                        >
                        {{ service.text }}
                    </button>
                </ng-container>
            </div>
            
            
        </div> -->
    </div>
    <div class="doctors" *ngIf="showDoctors">
        <div
            class="cls-date-rnum"
            [ngClass]="hideDatePicker ? 'hideDatePicker' : ''"
        >
            <app-panel [styleStr]="panelSetStyleRnum">
                <app-rnumb-selected></app-rnumb-selected>
            </app-panel>
        </div>
        <ng-container *ngFor="let doctor of doctors">
            <button class="doctors__item" (click)="pickDoctor(doctor)">
                <div class="doctors__item-container">
                    <img
                        class="doctors__img"
                        src="{{ getDocImg(doctor.docid) }}"
                        (error)="getDocImgDefault($event)"
                        alt="doc"
                        width="68"
                        height="68"
                    />
                    <div class="doctors__item-info">
                        <div class="doctors__name">
                            {{ doctor.text }}
                        </div>
                        <div class="doctors__spec">
                            {{ doctor.specid_1 }} (специальность)
                        </div>
                    </div>
                </div>
            </button>
        </ng-container>
    </div>
</div>
