import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import { Alert, AlertTypeEnum } from './alert.model';
import { DomSanitizer } from '@angular/platform-browser';

const ALERT_TIME = 4000;

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router, private sanitizer: DomSanitizer) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // Только одно изменение маршрута
                    this.keepAfterRouteChange = false;
                } else {
                    // Очистить все сообщения
                    this.clear();
                }
            }
        });
    }

    private clear(): void {
        this.subject.next(new Alert());
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    public success(
        message: string,
        time = ALERT_TIME,
        keepAfterRouteChange = false
    ) {
        this.alert(
            AlertTypeEnum.Success,
            `<p>${message}</p>`,
            time,
            keepAfterRouteChange
        );
    }

    public error(
        message: string,
        time = ALERT_TIME,
        keepAfterRouteChange = false
    ) {
        this.alert(
            AlertTypeEnum.Error,
            `<p>${message}</p>`,
            time,
            keepAfterRouteChange
        );
    }

    public info(
        message: string,
        time = ALERT_TIME,
        keepAfterRouteChange = false
    ) {
        this.alert(
            AlertTypeEnum.Info,
            `<p>${message}</p>`,
            time,
            keepAfterRouteChange
        );
    }

    public warn(
        message: string,
        time = ALERT_TIME,
        keepAfterRouteChange = false
    ) {
        this.alert(
            AlertTypeEnum.Warning,
            `<p>${message}</p>`,
            time,
            keepAfterRouteChange
        );
    }

    public alert(
        type: AlertTypeEnum,
        message: string,
        time = ALERT_TIME,
        keepAfterRouteChange = false
    ) {
        const html = this.sanitizer.bypassSecurityTrustHtml(message);
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type: type, message: html, time: time });
    }
}
