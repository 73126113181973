export interface IHistoryEvents {
    dat: string;
    dtSort: Date; // Для сортировки
    doctor: string;
    keyid: number;
    typetext: string; // Наименование
    typehistory: string; // Тип
    sortcode: number;
    doctorid: number; //id для картинки
    spec: string;
    dep_root_name: string; // Филиал
    dep_name: string; // Отделение
    sched_exists_for_dd_on_visit: number; // 1-Возможна запись ОНЛАЙН
    pacs_study_id: string;
    accession_no: string;
    study_date: string;
    study_iuid: string;
    cnt_prot: number;
}

export interface IHistoryFilterItem {
    keyid: number;
    text: string;
}

export interface IHistoryFilter {
    list: IHistoryFilterItem[];
    mode: number;
    selectedID: number | null;
    searchText: string;
}

export enum EHistoryEventsFiltersMode {
    SPEC = 1,
    DOC = 2,
    DEP = 3,
    USL = 4,
}

export interface IHistoryEventsFilterParams {
    begin_dat: string | null;
    end_dat: string | null;
    mode: number;
}

export interface IHistoryEventList {
    type: string;
    id: number;
    date: Date;
    doctor: string;
    spec: string;
    researchType: string;
}

export interface IHistoryItemHtmlList {
    text: string;
    row_num: number;
}

export interface IRegistrationData {
    calcStatusText: string;
    doctor: IRegistrationDoctor;
    place: string;
    fullDate: Date;
    target: IRegistrationTarget;
    caseInfo: IRegistrationCaseInfo;
    mainDiagnos: IRegistrationMainDiagnos;
    complDiagnosis: string;
    disabilityText: string;
    secondDiagnos: string;
}

interface IRegistrationDoctor {
    name: string;
    spec: string;
}

interface IRegistrationTarget {
    visType: number;
    visName: string;
}

interface IRegistrationCaseInfo {
    caseText: string;
    caseType: string;
    caseResult: string;
}

interface IRegistrationMainDiagnos {
    mkbCode: string;
    mkbText: string;
    illType: string;
    disp: string;
    travma: string;
    worse: string;
    stage: number;
    crime: string;
}

export interface IProtocolListTable {
    type: string;
    value: string;
}

export interface IProtocolList {
    type: string;
    question: string;
    answer: string;
    bgcolor: string;
    rows: Array<IProtocolListTable>;
}
export interface IHistoryProtocol {
    id: number;
    formId: number;
    date: Date;
    header: string;
    listData: Array<IProtocolList>;
}

export class IHistoryVisit {
    /*
  type: string;
  id: number;
  date: Date;
  regInfo: IRegistrationData;
  protocols: Array<IHistoryProtocol>;
  descriptionVisits: string;
*/
}

export interface IDiagDoctor {
    post: string;
    doctor: string;
}

export interface IHistoryDiag {
    type: string;
    id: number;
    date: Date;
    researchType: string;
    type1: string;
    type2: string;
    type3: string;
    type4: string;
    doctors: Array<IDiagDoctor>;
    results: string;
    protocols: Array<IHistoryProtocol>;
}
