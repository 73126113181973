import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'priceSpace'
})

export class PriceSpacePipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any{
    if (!value) {
      return value;
    }

    const result = super.transform(value, args);
  
    return result?.toString().replace(',', ' ');
  }
}
