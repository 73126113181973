<ng-container [ngSwitch]="deviceType">
    <ng-template [ngSwitchCase]="size.pc">
        <app-labs-pc></app-labs-pc>
    </ng-template>

    <ng-template [ngSwitchCase]="size.mobile">
        <app-labs-mobile></app-labs-mobile>
    </ng-template>

    <ng-template ngSwitchDefault>
        <app-labs-mobile></app-labs-mobile>
    </ng-template>
</ng-container>


