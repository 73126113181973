<div  class="unselectable">

  <div class="cal-header">
    <span class="cal-header-mount">{{headerText.mount}}</span>
    <span class="cal-header-year">{{headerText.year}}</span>
  </div>
  <table class="cal-table">
    <tr style="height: 3em;">
      <th class="cal-h">пн</th>
      <th class="cal-h">вт</th>
      <th class="cal-h">ср</th>
      <th class="cal-h">чт</th>
      <th class="cal-h">пт</th>
      <th class="cal-h">сб</th>
      <th class="cal-h">вс</th>
    </tr>

    <tr *ngFor="let dsl of daySlice">
      <td class="cal-row calendar-date--item" *ngFor="let dt of dayArray | slice:dsl.begin:dsl.end; let i = index;"
          [ngClass]="{ 'day-not-activ': dt.isCourrentMonth==false
                     , 'day-activ': dt.isAction==true
                     , 'day-selected': dt.isSelected==true
                     , 'cls-dr-courrent-day' : dt.isCourrentDay }"
          (click)="clicDate(dt)">{{dt.day}}
        <mat-icon *ngIf="dt.isAction" svgIcon="point" class="cal-point"></mat-icon>
      </td>
    </tr>

  </table>
</div>
