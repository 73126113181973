import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDoctor } from 'src/app/interfaces/record.interface';
import { ConfigService } from 'src/app/services/application/config.service.';

@Component({
    selector: 'app-doc-info-modal',
    templateUrl: './doc-info-modal.component.html',
    styleUrls: ['./doc-info-modal.component.scss'],
})
export class DocInfoModalComponent {
    doc!: IDoctor;
    spec!: string;

    constructor(
        private config: ConfigService,
        private activeModal: NgbActiveModal
    ) {}

    getDocImg(id: number) {
        return `${this.config.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.config.getValue('hostBackend')}/img/doc/not.png`;
    }

    closeModal(e: Event) {
        e.stopPropagation();
        this.activeModal.close();
    }
}
