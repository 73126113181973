import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {ICalendarDate, ICalendarDateActive, ICalendarHeader, IdaySlice} from "../../../../interfaces/calendar";

@Component({
  selector: 'app-item-calendar-mobile',
  templateUrl: './item-calendar.component.html',
  styleUrls: ['./item-calendar.component.scss']
})
export class ItemCalendarComponentMobile implements OnInit {
  @Input() in_month: number = 1;  // месяц
  @Input() in_year: number = 1;   // год
  @Input() daysActive: ICalendarDateActive[] = [];     // Массов дней - активный
  @Output() onClickDate = new EventEmitter<string>(); // выбрана Дата формат YYYY-MM-DD

  _selectedDate: string = '';   // выбранная дата
  @Input()
  set selectedDate(value: string) {
    this._selectedDate = value;
/*
    this._selectedDate = value;
    this.selectedDateAction(this._selectedDate);
*/
    this.selectedDateAction(this._selectedDate);
// 
  };

  daySlice: IdaySlice[] = [];

  dayArray: ICalendarDate[] = [];  // Массов дней
  headerText: ICalendarHeader = {mount: '', year: ''};


  constructor() { }

  ngOnInit(): void {
    this.generationdaySlice();
    moment.locale('ru');
    this.genCalendaryDay();
  }

  generationdaySlice(){
    this.daySlice = [];
    for (var i = 0 ; i <= 5; i++) {
      this.daySlice.push({begin: i*7, end: i*7+7});
    }
//    
  };

  genCalendaryDay(){
    let firstDay = null;
    let weekday1 = 0;
    this.dayArray = [];
    firstDay = new Date(this.in_year, this.in_month-1, 1); // Первое число месяца
    let dayEnd = 0; // Число последнего дня месяца
    let lastDay = new Date(this.in_year, this.in_month, 0); // Последняя дата месяца
    dayEnd = lastDay.getDate();
    weekday1 = moment(firstDay).isoWeekday(); // День недели первого числа месяца
    this.headerText.year = moment(firstDay).format('YYYY');
    this.headerText.mount = this.getMounthText(firstDay.getMonth());

    // Проверка если 1 число не понедельник
    if (weekday1>1) {
      // Добавляем даты предыдущего месяца
      for (var i = weekday1 - 1 ; i >= 1; i--) {
//        
        this.addDay(false, moment(firstDay).subtract(i, 'days').toDate(), false);
      }
    }

    for (var i = 0 ; i < dayEnd; i++) { // добавляем все числа месяца
      const d = moment(firstDay).add(i, 'days').toDate();
      this.addDay(true, d, this.isActive(d));
    }

    let weekdayEnd = moment(lastDay).isoWeekday();

    // Добавляем до 42 дней (6 недель)
    if (this.dayArray.length < 42) {
      let col = 42 - this.dayArray.length;
      for (var i =  1 ; i <= col ; i++) {
        this.addDay(false, moment(lastDay).add(i, 'days').toDate(), false);
      }
    }
// 
    this.selectedDateAction(this._selectedDate);
  }

  private addDay(courrentMonth: boolean, dt: Date, activ: boolean){
    const curent: boolean = (moment(dt).format('DD-MM-YYYY') == moment(new Date()).format('DD-MM-YYYY') );
    this.dayArray.push({
      isCourrentMonth: courrentMonth
      , data: dt
      , day: dt.getDate()
      , isAction: activ
      , isSelected: false
      , isCourrentDay: curent && courrentMonth
    });
  }

  private getMounthText(i: number): string {
    const months = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];  // месяцы
    return months[i];
  }

  private isActive(dt: Date): boolean {
    let n = Number(moment(dt).format('YYYYMMDD'));
    return this.daysActive.filter(item => item.date === n).length>0;
  }

  clicDate(dt: ICalendarDate){
    if (dt.isAction) {
      this.onClickDate.next(moment(dt.data).format('YYYY-MM-DD'));
    }
  }

  selectedDateAction(dt: string){
// 
    this.dayArray.forEach(item => {
      if (item.isCourrentMonth){
        item.isSelected = (moment(item.data).format('YYYY-MM-DD') == dt);
      }
    });
// 
  }

}
