import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ChipOption } from 'src/app/interfaces/chip';

@Component({
    selector: 'chip',
    templateUrl: 'chip.component.html',
    styleUrls: ['chip.component.scss']
})

export class ChipComponent implements OnInit {
    @Input() value: string | number = '';
    @Input() text?: string;
    @Input('text-align') textAlign: 'start' | 'center' | 'end' = 'center';
    @Input() disabled = false;
    @Input() selected = false;
    @Input() stratch = false;
    @Input() hint?: string;

    @Output() onSelectionChange = new EventEmitter<ChipOption>();

    constructor() { }

    ngOnInit() { }

    onSelectionHendler(e: MouseEvent) {
        if (!this.disabled) {
            this.onSelectionChange.emit({
                value: this.value,
                selected: !this.selected,
            })
        }
    }
}